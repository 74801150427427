import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'title':
      case 'content':
        return CommonValidation.requiredValue(value);
      case 'publishStartDate':
      case 'publishEndDate':
        return CommonValidation.requiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
