import React, { useState, useCallback } from 'react';
import {
  Box, Button, Paper,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import EditOrderContent from './EditOrderContent';
import BlogContentDialog from './BlogContentDialog';
import DeleteDialog from '../../../../components/templates/DeleteDialog';
import AddContent from './AddContent';
import { blogContentActions } from '../../../../redux/pageSetting/blog/blogContentState';
import DisplayContent from './DisplayContent';

function ContentItem({ data, onEdit, setDeleteForm }) {
  const renderContent = () => {
    switch (data.contentType) {
      case 1:
        return (
          <a href={data.link} className="c-btn01__link" target="_blank" rel="noreferrer">
            {data.linkText}
          </a>
        );
      case 2:
        return <img src={!data.imagePath ? URL.createObjectURL(data.imageFile) : process.env.REACT_APP_AWS_PUBLIC_URL + data.imagePath} alt="画像" />;
      case 3:
        return <div dangerouslySetInnerHTML={{ __html: data.content }} />;
      case 4:
        return (
          <>
            <Box>関連記事</Box>
            <Box>{data.relateBlogTitle}</Box>
          </>
        );
      case 5:
        return <p className="toc_title">目次</p>;
      case 6:
        return <div style={{ height: '20px', textAlign: 'center' }}>スペース</div>;
      default:
        return null;
    }
  };

  return (
    <Paper variant="outlined" sx={{ padding: 1, marginBottom: 1 }}>
      <Box
        sx={{ background: '#fff' }}
        display="flex"
        mb={1}
        position="sticky"
        top={90}
        left={0}
        zIndex={99}
        textAlign="right"
        width="fit-content"
        px={1}
      >
        {data.contentType < 5 && (
          <Button size="small" onClick={() => onEdit(data)}>編集</Button>
        )}
        <Button
          size="small"
          color="secondary"
          onClick={() => setDeleteForm({ id: data.id, open: true })}
        >
          削除
        </Button>
      </Box>
      {renderContent()}
    </Paper>
  );
}

function EditContent({ initialDatas, onClose, blogId }) {
  const [datas, setDatas] = useState(initialDatas);
  const [isSort, setSort] = useState(false);
  const [isPreview, setPreview] = useState(false);
  const [isContentOpen, setContentOpen] = useState(false);
  const [content, setContent] = useState({});
  const [addContentType, setAddContentType] = useState('');
  const [deleteForm, setDeleteForm] = useState({ open: false, id: '' });
  const dispatch = useDispatch();

  const generateTableOfContents = () => {
    const tocItems = [];
    const parser = new DOMParser();
    let h2Counter = 0; // h2 の番号
    let h3Counter = 0; // h3 の番号

    const updatedDatas = datas.map((data) => {
      if (data.contentType === 3) {
        const doc = parser.parseFromString(data.content, 'text/html');
        const elements = Array.from(doc.body.children); // 全ての子要素を配列化
        let subItems = []; // 現在の h2 に対応する h3 のリスト

        elements.forEach((el) => {
          if (el.tagName === 'H2') {
            // 新しい h2 が始まる場合、現在の subItems を保存
            if (subItems.length > 0) {
              tocItems.push(`<ul>${subItems.join('')}</ul>`);
              subItems = [];
            }

            h2Counter += 1;
            h3Counter = 0; // h2 が変わるごとに h3 をリセット
            const h2Id = `h2_${h2Counter}`;
            el.setAttribute('id', h2Id);

            tocItems.push(
              `<li><a href="#${h2Id}"><span class="toc_number toc_depth_1">${h2Counter}</span> ${el.textContent.trim()}</a></li>`,
            );
          } else if (el.tagName === 'H3') {
            h3Counter += 1;
            const h3Id = `h3_${h2Counter}_${h3Counter}`;
            el.setAttribute('id', h3Id);

            subItems.push(
              `<li><a href="#${h3Id}"><span class="toc_number toc_depth_2">${h2Counter}.${h3Counter}</span> ${el.textContent.trim()}</a></li>`,
            );
          }
        });

        // 最後に残った h3 の subItems を保存
        if (subItems.length > 0) {
          tocItems.push(`<ul>${subItems.join('')}</ul>`);
        }

        return {
          ...data,
          content: doc.body.innerHTML,
        };
      }
      return data;
    });

    const tocHtml = `<ul class="toc_list">${tocItems.join('')}</ul>`;
    return { updatedDatas, tocHtml };
  };

  const appendTableOfContentsToType5 = (tocHtml, tmpDatas) => {
    const updatedDatas = tmpDatas.map((data) => {
      if (data.contentType === 5) {
        return {
          ...data,
          content: tocHtml,
        };
      }
      return data;
    });

    return updatedDatas;
  };

  const onSave = () => {
    const { updatedDatas, tocHtml } = generateTableOfContents();

    const tmpDatas = appendTableOfContentsToType5(tocHtml, updatedDatas);

    dispatch(blogContentActions.saveData({ datas: tmpDatas, blogId: Number(blogId) }));
  };

  const onOrderClose = useCallback((data) => {
    setDatas(data);
    setSort(false);
  }, []);

  const onContentSave = useCallback((form) => {
    setDatas((prevDatas) => {
      const maxId = prevDatas.reduce((max, data) => (data.id > max ? data.id : max), 0);
      if (form.id) {
        return prevDatas.map((data) => (data.id === form.id ? form : data));
      }
      return [...prevDatas, { ...form, newData: true, id: maxId + 1 }];
    });
    setContent({});
    setContentOpen(false);
  }, []);

  const onEdit = useCallback((tmpData) => {
    setContent(tmpData);
    setContentOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    setDatas((prevDatas) => prevDatas.filter((data) => data.id !== deleteForm.id));
    setDeleteForm({ id: '', open: false });
  }, [deleteForm.id]);

  const onAddContent = (contentType) => {
    if (contentType === 5) {
      setDatas((prevDatas) => {
        const maxId = prevDatas.reduce((max, data) => (data.id > max ? data.id : max), 0);
        const newId = maxId + 1;
        return [
          ...prevDatas,
          {
            id: newId,
            contentType: 5,
            newData: true,
          },
        ];
      });
      return;
    }
    if (contentType === 6) {
      setDatas((prevDatas) => {
        const maxId = prevDatas.reduce((max, data) => (data.id > max ? data.id : max), 0);
        const newId = maxId + 1;
        return [
          ...prevDatas,
          {
            id: newId,
            contentType: 6,
            content: '<div style="height:20px"></div>',
            newData: true,
          },
        ];
      });
      return;
    }
    setContent({ content: '<p> </p>' });
    setContentOpen(true);
    setAddContentType(contentType);
  };

  return (
    <Box>
      {!isSort && !isPreview && (
        <Box
          display="flex"
          position="sticky"
          top={90}
          left={0}
          zIndex={99}
          mb={1}
          textAlign="right"
          width="100%"
          px={1}
        >
          <Box style={{ flex: 1 }} textAlign="left" />
          <Button
            color="primary"
            style={{ marginRight: '10px' }}
            size="small"
            variant="contained"
            onClick={() => setPreview(true)}
          >
            プレビュー
          </Button>
          <Button
            color="primary"
            style={{ marginRight: '10px' }}
            size="small"
            variant="contained"
            onClick={() => setSort(true)}
          >
            並び替えモード
          </Button>
          <Button
            color="inherit"
            style={{ marginRight: '10px' }}
            size="small"
            variant="contained"
            onClick={onClose}
          >
            編集キャンセル
          </Button>
          <Button color="primary" size="small" variant="contained" onClick={onSave}>
            保存
          </Button>
        </Box>
      )}
      {!isSort && !isPreview && (
        <Paper variant="outlined" className="contentBody" style={{ padding: '10px', marginBottom: 16 }}>
          {datas.map((data) => (
            <ContentItem
              key={data.id}
              data={data}
              onEdit={onEdit}
              setDeleteForm={setDeleteForm}
            />
          ))}
          <AddContent onAdd={onAddContent} />
        </Paper>
      )}
      {isSort && (
        <EditOrderContent initialDatas={datas} onClose={onOrderClose} />
      )}
      {isPreview && (
      <>
        <Box position="sticky" top={90} left={0} zIndex={99} mb={1} textAlign="right" width="100%" px={1}>
          <Box textAlign="right">
            <Button color="primary" style={{ marginRight: '10px' }} size="small" variant="contained" onClick={() => setPreview(false)}>プレビュー終了</Button>
          </Box>
        </Box>
        <DisplayContent
          datas={datas}
        />
      </>
      )}
      <BlogContentDialog
        isOpen={isContentOpen}
        onClose={() => setContentOpen(false)}
        onSave={onContentSave}
        content={content}
        contentType={addContentType}
      />
      <DeleteDialog
        procName="コンテンツ"
        onDelete={onDelete}
        open={deleteForm.open}
        onClose={() => setDeleteForm({ open: false, id: '' })}
      />
    </Box>
  );
}

export default React.memo(EditContent);
