import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { reviewActions } from './reviewState';
import {
  getDatas, deleteDatas, getDataById, uploadFiles, updateData,
} from '../../../services/base.service';

function* getReviewDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.REVIEW.REVIEW, payload);
    yield put(reviewActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.REVIEW.REVIEW_CSV, payload);
    yield put(reviewActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.PAGE_SETTING.REVIEW.REVIEW, payload);
    yield put(reviewActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.REVIEW.REVIEW_ID, payload);
    yield put(reviewActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFiles') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    for (let i = 0; i < payload.imgForm.images.length; i += 1) {
      const tmpData = payload.imgForm.images[i];
      if (tmpData.isNew) {
        const files = tmpData[0];
        formData.append(`imageFiles_${tmpData.id}`, files);
      }
    }

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.REVIEW.REVIEW, formData);
    yield put(reviewActions.saveDataSuccess(result));
  } catch (_) { }
}

function* saveStatus({ payload }) {
  try {
    const result = yield call(updateData, REST_API.PAGE_SETTING.REVIEW.REVIEW_STATUS, payload);
    yield put(reviewActions.saveStatusSuccess(result));
  } catch (_) { }
}

function* reviewSaga() {
  yield takeLatest('review/getDatas', getReviewDatas);
  yield takeLatest('review/getCsvDatas', getCsvDatas);
  yield takeLatest('review/deleteData', deleteData);
  yield takeLatest('review/getData', getData);
  yield takeLatest('review/saveData', save);
  yield takeLatest('review/saveStatus', saveStatus);
}

export default reviewSaga;
