import { createSlice } from '@reduxjs/toolkit';

export const claimAttachSlice = createSlice({
  name: 'claimAttach',
  initialState: {
    datas: [],
    isRelationDeleteSuccess: false,
    isRelationSaveSuccess: false,
  },
  reducers: {
    getRelationDatas: () => {},
    getRelationDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getRelationData: () => {},
    getRelationDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveRelationData: (state) => {
      state.isRelationSaveSuccess = false;
    },
    saveRelationDataSuccess: (state) => {
      state.isRelationSaveSuccess = true;
    },
    deleteRelationData: (state) => {
      state.isRelationDeleteSuccess = false;
    },
    deleteRelationDataSuccess: (state) => {
      state.isRelationDeleteSuccess = true;
    },
    resetFlgs: (state) => {
      state.isRelationDeleteSuccess = false;
      state.isRelationSaveSuccess = false;
    },
    getPdfData: () => {},
    getPdfDataSuccess: (state, action) => {
      state.pdfData = action.payload;
    },
  },
});

export const claimAttachActions = claimAttachSlice.actions;

export default claimAttachSlice.reducer;
