import React from 'react';
import {
  Container, Box, Tooltip, Button,
  Paper, Toolbar, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import detailUrl from '../../../../constants/frontUrls';
import SelectDialog from './SelectPlanDialog';
import DraggableList from './DraggableList';
import { lpPlanControlActions } from '../../../../redux/pageSetting/lpPlanControl/lpPlanControlState';
import ErrorAlert from './ErrorAlert';

export default function PickupList(props) {
  const [datas, setDatas] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [target, setTarget] = React.useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.commonStore.isLoading);

  React.useEffect(() => {
    setDatas(props.datas);
  }, [props.datas]);

  const onAddOpen = (_, t) => {
    setTarget(t);
    setOpen(true);
  };

  const onAdd = (_, t) => {
    const data = {
      id: datas.length > 0 ? Math.max(...datas.map((item) => item.id || 0)) + 1 : 1,
      planId: t.value,
      planName: t.label,
      attributeId: target === 'shop' ? 2 : 1,
      isEdit: true,
    };

    const exists = datas.some((item) => item.attributeId === data.attributeId
    && item.planId === data.planId);

    if (!exists) {
      const newDatas = [...datas, data];
      setDatas(newDatas);
    }
    setOpen(false);
  };

  const onDelete = (_, attributeId, planId) => {
    const newDatas = datas.map((item) => {
      if (item.attributeId === attributeId && item.planId === planId) {
        return { ...item, isDelete: true };
      }
      return item;
    });
    setDatas(newDatas);
  };

  const onUndo = (_, attributeId, planId) => {
    const countNotDeleted = datas.filter(
      (item) => item.attributeId === attributeId
      && item.planId !== planId && !item.isDelete,
    ).length;

    if (countNotDeleted >= 5) {
      setErrorOpen(true);
      return;
    }

    const newDatas = datas.map((item) => {
      if (item.attributeId === attributeId && item.planId === planId) {
        return { ...item, isDelete: false };
      }
      return item;
    });
    setDatas(newDatas);
  };

  const onSave = () => {
    const planIds = [];
    const shopIds = [];
    datas.forEach((data) => {
      if (data.attributeId === 1 && !data.isDelete) {
        planIds.push(data.planId);
      } else if (data.attributeId === 2 && !data.isDelete) {
        shopIds.push(data.planId);
      }
    });
    dispatch(lpPlanControlActions.saveData({ target: '1', planIds, shopIds }));
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box textAlign="right" mb={1}>
          <LoadingButton loading={isLoading} variant="contained" disabled={datas.filter((e) => e.isDelete || e.isEdit).length === 0} onClick={onSave}>保存する</LoadingButton>
        </Box>
        <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <>
              <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div">プラン</Typography>
              <Tooltip title="プランの追加" placement="top">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={(e) => onAddOpen(e, 'plan')}
                  disabled={datas.filter((data) => data.attributeId === 1
                    && !data.isDelete).length >= 5}
                >
                  プランの追加
                </Button>
              </Tooltip>
            </>
          </Toolbar>
          <DraggableList
            datas={datas.filter((data) => data.attributeId === 1)}
            setDatas={setDatas}
            detailUrl={detailUrl.PLAN_DETAIL}
            onDelete={onDelete}
            onUndo={onUndo}
            attributeId={1}
          />
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 1 }}>
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <>
              <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div">店舗</Typography>
              <Tooltip title="店舗の追加" placement="top">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={(e) => onAddOpen(e, 'shop')}
                  disabled={datas.filter((data) => data.attributeId === 2
                    && !data.isDelete).length >= 5}
                >
                  店舗の追加
                </Button>
              </Tooltip>
            </>
          </Toolbar>
          <DraggableList
            datas={datas.filter((data) => data.attributeId === 2)}
            setDatas={setDatas}
            detailUrl={detailUrl.SHOP_DETAIL}
            onDelete={onDelete}
            onUndo={onUndo}
            attributeId={2}
          />
        </Paper>
      </Container>
      <SelectDialog open={open} onClose={() => setOpen(false)} onClick={onAdd} target={target} />
      <ErrorAlert open={errorOpen} setErrorOpen={setErrorOpen} count={5} />
    </>
  );
}
