import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DeliveryDialog from './DeliveryDialog';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

const HEAD_CELLS = [
  { id: 'serviceTypeName', label: 'サービスタイプ名', sortId: 'service_type_name' },
  { id: 'name', label: '条件名', sortId: 'name' },
  {
    id: 'minPrice', label: '最低価格', sortId: 'min_price', numeric: true,
  },
  { id: 'areas', label: '配達可能エリア', sortId: 'areas' },
  { id: 'remarks', label: '備考', sortId: 'remarks' },
];

export default function DeliveryDataTable(props) {
  const { shopId, isNotDelete, isNotEdit } = props;
  const dispatch = useDispatch();
  const deliveryDatas = useSelector((state) => state.shopDetailStore.deliveryDatas);
  const isSaveSuccess = useSelector((state) => state.shopDetailStore.isDeliverySaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.shopDetailStore.isDeliveryDeleteSuccess);
  const [deliveries, setDeliveries] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [deliveryId, setDeliveryId] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const search = () => {
    dispatch(shopDetailActions.getDeliveryData(searchCondition));
  };

  React.useEffect(() => {
    if (shopId) {
      setSearchCondition({ ...searchCondition, shopId });
    }
  }, [shopId]);

  React.useEffect(() => {
    if (searchCondition.shopId) {
      search();
    }
  }, [searchCondition]);

  React.useEffect(() => {
    if (deliveryDatas) {
      setDeliveries(deliveryDatas);
    }
  }, [deliveryDatas]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(shopDetailActions.resetFlgs());
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('削除しました');
      dispatch(shopDetailActions.resetFlgs());
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    setSearchCondition({
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    });
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setDeliveryId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setDeliveryId(openedMenuRowId);
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(shopDetailActions.deleteDeliveryData({ ids: [openedMenuRowId] }));
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={deliveries.content || []}
        procName="配達"
        headCells={HEAD_CELLS}
        totalElements={deliveries.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <DeliveryDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        openedMenuRowId={deliveryId}
        shopId={shopId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
