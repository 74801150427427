import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { shopNoticeActions } from './shopNoticeState';
import {
  getDatas, deleteDatas, getDataById, saveData,
} from '../../../services/base.service';

function* getNoticeData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_NOTICE.SHOP_NOTICE, payload);
    yield put(shopNoticeActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_NOTICE.SHOP_NOTICE_CSV, payload);
    yield put(shopNoticeActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SHOP.SHOP_NOTICE.SHOP_NOTICE, payload);
    yield put(shopNoticeActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP_NOTICE.SHOP_NOTICE_ID, payload);
    yield put(shopNoticeActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.SHOP_NOTICE.SHOP_NOTICE, payload);
    yield put(shopNoticeActions.saveDataSuccess(result));
  } catch (_) { }
}

function* getCategories({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_NOTICE.CATEGORIES, payload);

    yield put(shopNoticeActions.getCategoriesSuccess(result));
  } catch (_) { }
}

function* shopNoticeSaga() {
  yield takeLatest('shopNotice/getDatas', getNoticeData);
  yield takeLatest('shopNotice/getCsvDatas', getCsvDatas);
  yield takeLatest('shopNotice/deleteData', deleteData);
  yield takeLatest('shopNotice/getData', getData);
  yield takeLatest('shopNotice/saveData', save);
  yield takeLatest('shopNotice/getCategories', getCategories);
}

export default shopNoticeSaga;
