import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { opportunityAttachActions } from '../../../../../redux/opportunity/opportunity/opportunityAttachState';

const headCells = [
  { id: 'beforeValue', label: '変更前', sortId: 'before_value' },
  { id: 'afterValue', label: '変更後', sortId: 'after_value' },
  { id: 'createdAt', label: '変更日', sortId: 'created_at' },
  { id: 'createdByName', label: '変更者', sortId: 'created_by_name' },
];

export default function StatusHistoryList(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [datas, setData] = useState({});
  const dbDatas = useSelector((state) => state.opportunityAttachStore.statusHistories);
  const [searchCondition, setSearchCondition] = React.useState({
    oppId: id,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });

  React.useEffect(() => {
    if (id && dbDatas) {
      setData(dbDatas);
    }
  }, [dbDatas]);

  const search = (condition) => {
    dispatch(opportunityAttachActions.getStatusHistory(condition));
  };

  React.useEffect(() => {
    if (id) {
      const tempCondition = {
        ...searchCondition,
        oppId: id,
      };
      setSearchCondition(tempCondition);
      search(tempCondition);
    }
  }, []);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <DataTableWithMenu
      rows={datas.content || []}
      headCells={headCells}
      totalElements={datas.totalElements}
      rowsPerPage={searchCondition.records}
      page={searchCondition.page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleRequestSort={handleRequestSort}
      order={searchCondition.order}
      orderBy={searchCondition.orderBy}
      isNotEdit
      isNotDelete
    />
  );
}
