import React, { useState } from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Tooltip, Button, useMediaQuery,
} from '@mui/material';
import TableHeaderCell from '../../../../components/atoms/TableHeaderCell';
import DeleteDialog from '../../../../components/templates/DeleteDialog';
import PcDetailTable from './PcDetailTable';
import SmartPhoneDetailTable from './SmartPhoneDetailTable';

export default function DetailTable(props) {
  const [openedMenuRowId, setOpenedMenuRowId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    procName,
    headCells,
    handleRequestSort,
    order,
    orderBy,
    isNotEdit,
    isNotDelete,
    onAddOpen,
    onEdit,
    onDelete,
  } = props;

  const onPreDelete = () => {
    onDelete(openedMenuRowId);
    setOpenDelete(false);
    setOpenedMenuRowId(null);
  };

  const onLocalSort = (e, property) => {
    const value = {
      orderBy: property.orderBy || 'display_order',
      order: property.order || 'asc',
    };
    handleRequestSort(e, value);
  };

  const colSpan = headCells.length + (!isNotEdit ? 2 : 1);

  return (
    <div>
      <Paper>
        <Toolbar style={{ paddingLeft: 0 }}>
          <>
            <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
            {(!isNotEdit) && (
              <Tooltip title={`${procName}の追加`}>
                <Button color="primary" variant="outlined" onClick={onAddOpen}>{`${procName}の追加`}</Button>
              </Tooltip>
            )}
          </>
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={onLocalSort}
                  />
                ))}
                {(isPc && ((onDelete && !isNotDelete) || (onEdit && !isNotEdit))) && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{ height: '56px' }}
                >
                  {isPc && (
                    <PcDetailTable
                      row={row}
                      headCells={headCells}
                      isNotEdit={isNotEdit}
                      isNotDelete={isNotDelete}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenDelete={setOpenDelete}
                    />
                  )}
                  {!isPc && (
                    <SmartPhoneDetailTable
                      row={row}
                      headCells={headCells}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      isNotEdit={isNotEdit}
                      isNotDelete={isNotDelete}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenDelete={setOpenDelete}
                    />
                  )}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <DeleteDialog
          open={openDelete}
          onClose={() => {
            setOpenedMenuRowId(null);
            setOpenDelete(false);
          }}
          procName={procName}
          onDelete={onPreDelete}
        />
      </Paper>
    </div>
  );
}
