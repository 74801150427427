import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { customerActions } from './customerState';
import {
  getDatas, saveData, deleteDatas, getDataById,
} from '../../../services/base.service';

function* getCustomerDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER, payload);
    yield put(customerActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER_CSV, payload);
    yield put(customerActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* bulkPointGrant({ payload }) {
  try {
    yield call(saveData, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER_POINT, payload);
    yield put(customerActions.bulkPointGrantSuccess());
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER, payload);
    yield put(customerActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER_ID, payload);
    yield put(customerActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.CUSTOMER.CUSTOMER, payload);
    yield put(customerActions.saveDataSuccess(result));
  } catch (_) { }
}

function* customerSaga() {
  yield takeLatest('customer/getDatas', getCustomerDatas);
  yield takeLatest('customer/getCsvDatas', getCsvDatas);
  yield takeLatest('customer/bulkPointGrant', bulkPointGrant);
  yield takeLatest('customer/deleteData', deleteData);
  yield takeLatest('customer/getData', getData);
  yield takeLatest('customer/saveData', saveDatas);
}

export default customerSaga;
