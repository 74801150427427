import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { pointRuleActions } from './pointRuleState';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getDataRules({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.POINT_RULE.POINT_RULE, payload);
    yield put(pointRuleActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.POINT_RULE.POINT_RULE_CSV, payload);
    yield put(pointRuleActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SYSTEM_SETTING.POINT_RULE.POINT_RULE, payload);
    yield put(pointRuleActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.POINT_RULE.POINT_RULE_ID, payload);
    yield put(pointRuleActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.POINT_RULE.POINT_RULE, payload);
    yield put(pointRuleActions.saveDataSuccess(result));
  } catch (_) { }
}

function* pointSaga() {
  yield takeLatest('pointRule/getDatas', getDataRules);
  yield takeLatest('pointRule/getCsvDatas', getCsvDatas);
  yield takeLatest('pointRule/deleteData', deleteData);
  yield takeLatest('pointRule/getData', getData);
  yield takeLatest('pointRule/saveData', save);
}

export default pointSaga;
