import { createSlice } from '@reduxjs/toolkit';

export const frontNoticeSlice = createSlice({
  name: 'frontNotice',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
    categories: [],
  },
  reducers: {
    setSearchCondition(state, action) {
      state.searchCondition = action.payload;
    },
    getDatas(state) {
      state.datas = {};
    },
    getDatasSuccess(state, action) {
      state.datas = action.payload;
    },
    getCsvDatas: (state) => {
      state.csvDatas = null;
    },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData(state) {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess(state) {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
  },
});

export const frontNoticeActions = frontNoticeSlice.actions;

export default frontNoticeSlice.reducer;
