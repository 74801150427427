import { call, put, takeLatest } from 'redux-saga/effects';
import { loginActions } from './loginState';
import { REST_API } from '../../constants/serverUrls';
import { getDatas, saveData, updateData } from '../../services/base.service';

function* fetchLogin({ payload }) {
  try {
    const result = yield call(saveData, REST_API.COMMON.LOGIN, payload);
    yield put(loginActions.executeLoginSuccess(result.token));
  } catch (error) {
    yield put(loginActions.executeLoginFailure());
  }
}

function* fetchLoginInfo() {
  try {
    const datas = yield call(getDatas, REST_API.COMMON.LOGIN_INFO);
    yield put(loginActions.executeGetLoginInfoSuccess(datas));
  } catch (error) {
    yield put(loginActions.executeGetLoginInfoFailure());
  }
}

function* resetPasswordToken({ payload }) {
  try {
    yield call(saveData, REST_API.COMMON.RESET_PASSWORD, payload);
    yield put(loginActions.resetPasswordTokenSuccess());
  } catch (_) { }
}

function* getResetPasswordToken({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.RESET_PASSWORD, payload);
    yield put(loginActions.getResetPasswordTokenSuccess(result));
  } catch (_) { }
}

function* resetPassword({ payload }) {
  try {
    const result = yield call(updateData, REST_API.COMMON.RESET_PASSWORD, payload);
    yield put(loginActions.resetPasswordSuccess(result));
  } catch (_) { }
}

function* loginSaga() {
  yield takeLatest('login/executeLogin', fetchLogin);
  yield takeLatest('login/executeGetLoginInfo', fetchLoginInfo);
  yield takeLatest('login/resetPasswordToken', resetPasswordToken);
  yield takeLatest('login/getResetPasswordToken', getResetPasswordToken);
  yield takeLatest('login/resetPassword', resetPassword);
}

export default loginSaga;
