import {
  startOfMonth, endOfMonth, format,
  getDate, eachDayOfInterval,
  endOfWeek, eachWeekOfInterval,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

function isSomeMonth(d1, d2) {
  return (d1.getFullYear() + d1.getMonth()) - (d2.getFullYear() + d2.getMonth()) === 0;
}

export default function getCalnedarMonthly(paramDate) {
  const targetDate = new Date(paramDate);

  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(targetDate),
      end: endOfMonth(targetDate),
    },
    { weekStartsOn: 0 },
  );
  const monthly = weeks.map((week) => eachDayOfInterval(
    {
      start: week,
      end: endOfWeek(week, { weekStartsOn: 0 }),
    },
  ));
  const monthArray = [];
  monthly.forEach((item) => {
    const weekArray = [];
    item.forEach((fnsDate) => {
      weekArray.push(
        {
          date: fnsDate, day: getDate(fnsDate), formatDate: format(fnsDate, 'yyyy/MM/dd', { locale: ja }), week: format(fnsDate, 'EEEEEE', { locale: ja }), isSomeMonth: isSomeMonth(fnsDate, targetDate),
        },
      );
    });
    monthArray.push(weekArray);
  });
  return monthArray;
}
