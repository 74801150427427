import React, { useState, useEffect } from 'react';
import {
  DialogActions, Box, Button, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';
import DataTable from './DataTable';
import { shopActions } from '../../../redux/shop/shop/shopState';
import { FormTitle, DialogContent } from '../../../components/atoms/Base';

const headCells = [
  { id: 'shopCode', label: '店舗コード', sortId: 'shop_code' },
  { id: 'displayOrder', label: '表示順', sortId: 'display_order' },
  { id: 'prefectureName', label: '都道府県', sortId: 'prefecture_name' },
  { id: 'cityName', label: '市区町村', sortId: 'city_name' },
  { id: 'name', label: '店舗名', sortId: 'name' },
  { id: 'phone', label: '電話番号', sortId: 'phone' },
  { id: 'email', label: 'メール', sortId: 'email' },
  { id: 'published', label: '公開状況', sortId: 'published' },
];

export default function SelectShopDialog(props) {
  const { isOpen, onClose, onSelect } = props;
  const [datas, setDatas] = useState({});
  const [selected, setSelected] = React.useState([]);
  const [condition, setCondition] = useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
    shopCode: '',
    shopName: '',
  });
  const dispatch = useDispatch();
  const dbDatas = useSelector((state) => state.shopStore.datas);

  useEffect(() => {
    if (isOpen) {
      const tmpCondition = {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
        shopCode: '',
        shopName: '',
      };
      setCondition(tmpCondition);
      dispatch(shopActions.getDatas(tmpCondition));
      setSelected([]);
    }
  }, [isOpen]);
  useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onChangePage = (_, newPage) => {
    const tmpCondition = {
      ...condition,
      page: newPage,
    };
    setCondition(tmpCondition);
    dispatch(shopActions.getDatas(tmpCondition));
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tmpCondition = {
      ...condition,
      page: 0,
      records: rowsPerPage,
    };
    setCondition(tmpCondition);
    dispatch(shopActions.getDatas(tmpCondition));
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tmpCondition = {
      ...condition,
      page: 0,
      orderBy,
      order,
    };
    setCondition(tmpCondition);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    const tmpCondition = {
      ...condition,
      [name]: value,
      page: 0,
    };
    setCondition(tmpCondition);
  };

  const onSearch = () => {
    dispatch(shopActions.getDatas(condition));
  };

  return (
    <Dialog
      fullWidth
      title="店舗選択"
      maxWidth="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Box>
          <Box mb={2} display={{ xs: 'block', md: 'flex' }}>
            <Box>
              <FormTitle title="店舗コード" />
              <TextField autoComplete="off" name="shopCode" value={condition.shopCode} onChange={onChange} />
            </Box>
            <Box mx={{ xs: 0, md: 2 }}>
              <FormTitle title="店舗名" />
              <TextField autoComplete="off" name="shopName" value={condition.shopName} onChange={onChange} />
            </Box>
            <Box mt={{ xs: 2, md: 0 }} display="flex" alignItems="flex-end"><Button variant="contained" size="small" onClick={onSearch}>検索</Button></Box>
          </Box>
          <DataTable
            rows={datas.content || []}
            headCells={headCells}
            page={condition.page}
            rowsPerPage={condition.records}
            totalElements={datas.totalElements}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            order={condition.order}
            orderBy={condition.orderBy}
            setSelected={(v) => setSelected(v)}
            selected={selected}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>キャンセル</Button>
        <Button disabled={selected.length <= 0} onClick={() => onSelect(selected)} color="primary">設定</Button>
      </DialogActions>

    </Dialog>
  );
}
