import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import {
  getDatas, getDataById, deleteDatas, saveData,
} from '../../../services/base.service';
import { planAttachedActions } from './planAttachedState';

function* getOptions({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.PLAN_ATTACHED.OPTION, payload);
    yield put(planAttachedActions.getOptionsSuccess(result));
  } catch (_) { }
}

function* getOption({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.PLAN_ATTACHED.OPTION_ID, payload);
    yield put(planAttachedActions.getOptionSuccess(result));
  } catch (_) { }
}

function* deleteOption({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SHOP.PLAN_ATTACHED.OPTION, payload);
    yield put(planAttachedActions.deleteOptionSuccess());
  } catch (_) { }
}

function* saveOption({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.PLAN_ATTACHED.OPTION, payload);
    yield put(planAttachedActions.saveOptionSuccess(result));
  } catch (_) { }
}

function* planAttachedPlanSaga() {
  yield takeLatest('planAttached/getOptions', getOptions);
  yield takeLatest('planAttached/getOption', getOption);
  yield takeLatest('planAttached/deleteOption', deleteOption);
  yield takeLatest('planAttached/saveOption', saveOption);
}

export default planAttachedPlanSaga;
