import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { planAttachedActions } from '../../../redux/shop/plan/planAttachedState';
import OptionDialog from './OptionDialog';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import DetailTable from '../../opportunity/common/detailTable/DetailTable';

const HEAD_CELLS = [
  { id: 'shopPlanOptionName', label: 'オプション名', sortId: 'shopPlanOptionName' },
  { id: 'displayOrder', label: '表示順', sortId: 'display_order' },
];

export default function OptionDataTable(props) {
  const {
    shopId, planId, isNotEdit, isNotDelete,
  } = props;
  const dispatch = useDispatch();
  const optionDatas = useSelector((state) => state.planAttachedStore.options);
  const isSaveSuccess = useSelector((state) => state.planAttachedStore.isSaveOptionSuccess);
  const [options, setOptions] = React.useState([]);
  const [searchCondition, setSearchCondition] = React.useState({
    order: 'asc',
    orderBy: '',
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [rowId, setRowId] = React.useState('');
  const [isSuccessOpen, setIsSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const isDeleteSuccess = useSelector((state) => state.planAttachedStore.isOptionDeleteSuccess);

  const search = () => {
    dispatch(planAttachedActions.getOptions(searchCondition));
  };

  React.useEffect(() => {
    if (planId) {
      const tempSearchCondition = { ...searchCondition, planId };
      setSearchCondition(tempSearchCondition);
    }
  }, []);

  React.useEffect(() => {
    if (searchCondition.planId) {
      search();
    }
  }, [searchCondition]);

  React.useEffect(() => {
    if (optionDatas && planId) {
      setOptions(optionDatas);
    }
  }, [optionDatas]);

  React.useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setIsSuccessOpen(true);
      search();
      dispatch(planAttachedActions.resetFlg());
    }
  }, [isDeleteSuccess]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setSuccessMessage('保存しました');
      setIsSuccessOpen(true);
      search();
      dispatch(planAttachedActions.resetFlg());
    }
  }, [isSaveSuccess]);

  const onAdd = () => {
    setDialogOpen(true);
    setRowId('');
  };

  const onEdit = (_, id) => {
    setDialogOpen(true);
    setRowId(id);
  };

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const onDelete = (deleteId) => {
    dispatch(planAttachedActions.deleteOption({ ids: [deleteId] }));
  };

  return (
    <>
      <DetailTable
        rows={options}
        procName="オプション"
        headCells={HEAD_CELLS}
        onAddOpen={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        isNotDelete={isNotDelete}
        isNotEdit={isNotEdit}
      />
      <OptionDialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        id={rowId}
        planId={planId}
        shopId={shopId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}
