import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../../../constants/frontUrls';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { claimActions } from '../../../../../redux/opportunity/claim/claimState';
import { SuccessSnackbar } from '../../../../../components/atoms/Base';
import { isScreenDeleteDisabledFromUrl } from '../../../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  opportunityId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  {
    id: 'claimNumber', label: '請求番号', sortId: 'claim_number',
  },
  {
    id: 'claimStatusName', label: 'ステータス', sortId: 'claim_status_code',
  },
  {
    id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
  },
  {
    id: 'customerBillPrice', label: '顧客税込請求額', sortId: 'customer_bill_price', numeric: true,
  },
];

export default function ClaimList(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const claimDatas = useSelector((state) => state.claimStore.datas);
  const [successMessage, setSuccessMessage] = useState('');
  const isDeleteSuccess = useSelector((state) => state.claimStore.isDeleteSuccess);
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/claim'));

  React.useEffect(() => {
    if (id && claimDatas) {
      setDatas(claimDatas);
    }
  }, [claimDatas]);

  const search = () => {
    dispatch(claimActions.getDatas(searchCondition));
  };

  React.useEffect(() => {
    if (id) {
      const tempSearchCondition = { ...DEFAULT_SEARCH_CONDITION, opportunityId: id };
      setSearchCondition(tempSearchCondition);
    }
  }, []);

  React.useEffect(() => {
    if (searchCondition.opportunityId) {
      search();
    }
  }, [searchCondition]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setIsSuccessOpen(true);
      search(searchCondition);
      dispatch(claimActions.resetFlgs());
    }
  }, [isDeleteSuccess]);

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
  };

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
  };

  const onDelete = (deleteId) => {
    dispatch(claimActions.deleteData({ ids: [deleteId] }));
  };

  return (
    <>
      <DataTableWithMenu
        url={detailUrl.CLAIM_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        procName="請求"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onDelete={onDelete}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
        isNotAdd
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}
