import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { trashActions } from './trashState';
import {
  getDatas as callGetData, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.SYSTEM_SETTING.TRASH.TRASH, payload);
    yield put(trashActions.getDatasSuccess(result));
  } catch (_) { }
}

function* restorData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.TRASH.TRASH, payload);
    yield put(trashActions.restorDataSuccess(result));
  } catch (_) { }
}

function* trashSaga() {
  yield takeLatest('trash/getDatas', getData);
  yield takeLatest('trash/restorData', restorData);
}
export default trashSaga;
