import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { withParams, TabPanel } from '../../../components/atoms/Base';
import ShopDetailInfoCard from './ShopDetailInfoCard';
import PlanDataTable from './plan/PlanDataTable';
import AccountUserDataTable from '../accountUser/AccountUserDataTable';
import { shopActions } from '../../../redux/shop/shop/shopState';
import OptionDataTable from './option/OptionDataTable';
import DeliveryDataTable from './delivery/DeliveryDataTable';
import HolidayDataTable from './holiday/HolidayDataTable';
import Tabs from '../../../components/templates/Tabs';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import CancelRateList from './cancelRate/CancelRateList';

class ShopDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;
    if (id) {
      this.props.dispatch(shopActions.getData({ id }));
    }
    this.state = {
      id,
      tabValue: 0,
      isOpen: true,
      datas: { accountId: '' },
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopInfo.data !== this.props.shopInfo.data) {
      if (this.props.shopInfo.data) {
        this.setDatas(this.props.shopInfo.data.data);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const {
      tabValue,
      isOpen,
      id,
      datas,
      isNotEdit,
      isNotDelete,
    } = this.state;

    return (
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={5}>
          <ShopDetailInfoCard id={id} isDisabled={isNotEdit} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Tabs
            value={tabValue}
            onChange={this.onTabChange}
            tabs={['プラン', 'オプション', '配達', '休業', 'キャンセル規定', '担当者']}
          />
          <TabPanel value={tabValue} index={0}>
            <PlanDataTable shopId={id} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <OptionDataTable shopId={id} isNotEdit={isNotEdit} isNotDelete={isNotDelete} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <DeliveryDataTable shopId={id} isNotEdit={isNotEdit} isNotDelete={isNotDelete} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <HolidayDataTable shopId={id} isNotEdit={isNotEdit} isNotDelete={isNotDelete} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <CancelRateList id={id} isNotEdit={isNotEdit} isNotDelete={isNotDelete} />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <AccountUserDataTable
              isOpen={isOpen}
              shopId={id}
              accountId={datas.accountId}
              isNotEdit={isNotEdit}
              isNotDelete={isNotDelete}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  shopInfo: state.shopStore,
});

export default withParams(connect(mapStateToProps)(ShopDetail));
