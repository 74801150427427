import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField,
} from '@mui/material';
import { reviewActions } from '../../../redux/pageSetting/review/reviewState';
import StatusDialog from './StatusDialog';
import {
  RecordBox, withParams, FormTitle, Link, RatingStar,
  TextArea, SuccessSnackbar, FormErrorText,
} from '../../../components/atoms/Base';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import DataGrid from '../../../components/templates/DataGrid';
import detailUrl from '../../../constants/frontUrls';
import SelectOpportunityDialog from '../../opportunity/common/SelectOpportunityDialog/SelectOpportunityDialog';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import Validation from './validation';
import NumberInput from '../../../components/atoms/NumberInput';
import SystemInfo from '../../../components/templates/SystemInfo';
import RecordHeader from '../../../components/templates/RecordHeader';

class ReviewDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      data: {
        id, planType: '1', nickname: '', shopId: '', planId: '', title: '', budgetPrice: '',
      },
      images: [],
    };

    let isSuccessOpen = false;
    let successMessage = '';
    if (props.reviewInfo.isSaveSuccess) {
      isSuccessOpen = true;
      successMessage = '保存しました';
      props.dispatch(reviewActions.resetFlg());
    }

    this.state = {
      id,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isCheckStatusOpen: false,
      isSuccessOpen,
      successMessage,
      errorMessages: {
        nickname: '',
        title: '',
      },
      form,
      inputForm: form,
      isEdit: !form.data.id,
      tempImageList: form.images,
      isSelectOpen: false,
    };
    if (id) {
      props.dispatch(reviewActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviewInfo.data !== this.props.reviewInfo.data) {
      if (this.props.reviewInfo.data) {
        this.setData(this.props.reviewInfo.data);
      }
    }
    if (prevProps.reviewInfo.isSaveSuccess !== this.props.reviewInfo.isSaveSuccess) {
      if (this.props.reviewInfo.isSaveSuccess) {
        this.setSuccess(this.props.reviewInfo.saveId);
      }
    }
    if (prevProps.reviewInfo.isSaveStatusSuccess !== this.props.reviewInfo.isSaveStatusSuccess) {
      if (this.props.reviewInfo.isSaveStatusSuccess) {
        this.setSuccess();
      }
    }
  }

  setData = (data) => {
    this.setState({ form: data, inputForm: { ...data, planType: data.data.planId ? '1' : '2' }, tempImageList: data.images });
  };

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.data.id;
    if (form.data.id) {
      this.props.dispatch(reviewActions.resetFlg());
    }
    if (!form.data.id) {
      window.location.href = detailUrl.REVIEW_DETAIL + searchId;
      return;
    }
    this.setState({
      isEdit: false,
      isSuccessOpen: true,
      successMessage: '保存しました',
      isCheckStatusOpen: false,
    });
    this.props.dispatch(reviewActions.getData({ id: searchId }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    const tmp = {
      ...inputForm,
      data: {
        ...inputForm.data,
        [name]: value,
      },
    };
    this.setState({
      inputForm: tmp,
      errorMessages: { ...errorMessages, [name]: name === 'grantedPoint' ? value : Validation.formValidate(name, value) },
    });
  };

  onChangeRating = (name, newValue) => {
    const { inputForm } = this.state;
    const tmp = {
      ...inputForm,
      data: {
        ...inputForm.data,
        [name]: newValue,
      },
    };
    this.setState({ inputForm: tmp });
  };

  onClickEdit = () => {
    const { form } = this.state;
    this.setState({
      isEdit: true,
      inputForm: { data: { budgetPrice: '' } },
      errorMessages: {
        nickname: '',
        title: '',
      },
    });
    this.props.dispatch(reviewActions.getData({ id: form.data.id }));
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      let msg = Validation.nickNameValidate(form);
      if (msg === '' && key !== 'grantedPoint') {
        msg = Validation.formValidate(key, form[key]);
      }
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  getUploadTargetFile = () => {
    const { tempImageList } = this.state;
    const uploadFiles = [];
    tempImageList.forEach((img) => {
      if (img.isNew) {
        uploadFiles.push(img[0]);
      }
    });
    return uploadFiles;
  };

  onSave = () => {
    const { inputForm, errorMessages, tempImageList } = this.state;
    const { id } = this.props;
    if (this.beforeSaveCheck(inputForm.data, errorMessages)) return;
    const uploadImages = this.getUploadTargetFile();
    const saveForm = {
      id,
      data: inputForm.data,
      imgForm: { images: tempImageList },
      imageFiles: uploadImages,
    };
    this.props.dispatch(reviewActions.saveData(saveForm));
    this.onClose();
  };

  onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isCheckStatusOpen: false });
  };

  onClickStatus = () => {
    const { id } = this.state;
    this.props.dispatch(reviewActions.getData({ id }));
    this.setState({
      isCheckStatusOpen: true,
    });
  };

  onClickSave = (data, pointData, isGrantPoint) => {
    this.props.dispatch(reviewActions.saveStatus({ data, point: pointData, isGrantPoint }));
  };

  onAddImg = (value) => {
    const { tempImageList } = this.state;
    let imgArray = [];
    if (value.isNew) {
      const inputVal = { ...value, displayOrder: tempImageList.length + 1 };
      imgArray = [...tempImageList, inputVal];
    } else {
      imgArray = tempImageList.filter((img) => img.id !== value.id);
      const index = tempImageList.findIndex((img) => img.id === value.id);
      imgArray.splice(index, 0, value);
    }
    this.setState({ tempImageList: imgArray });
  };

  onDeleteImg = (deleteId) => {
    const { tempImageList } = this.state;
    const imgArray = tempImageList.filter((img) => img.id !== deleteId);
    this.setState({ tempImageList: imgArray });
  };

  onChangeImg = (imgs) => {
    this.setState({ tempImageList: imgs });
  };

  onSelect = (_, selected) => {
    const { inputForm, errorMessages } = this.state;
    const updated = {
      ...inputForm.data,
      opportunityId: selected.id,
      opportunityNumber: selected.opportunityNumber,
    };
    const tmpForm = inputForm;
    tmpForm.data = updated;
    const tempErrorMessage = {
      ...errorMessages,
      opportunityId: '',
    };
    this.setState({ isSelectOpen: false, inputForm: tmpForm, errorMessages: tempErrorMessage });
  };

  render() {
    const {
      form,
      inputForm,
      isSuccessOpen,
      successMessage,
      isDisabled,
      isEdit,
      isCheckStatusOpen,
      errorMessages,
      tempImageList,
      isSelectOpen,
    } = this.state;
    const isExist = form.data && form.data.id;

    return (
      <>
        {!isDisabled && !isEdit && form.data.reviewStatusName && (
          <RecordHeader
            bottomSection={(
              <>
                <Button variant="contained" size="small" onClick={this.onClickStatus}>ステータス変更</Button>
                <Button variant="contained" size="small" onClick={this.onClickEdit}>編集</Button>
              </>
            )}
          />
        )}
        <RecordBox>
          <DataGrid
            title="会員"
            value={(
              <Link to={detailUrl.CUSTOMER_DETAIL + form.data.customerId}>
                {form.data.customerName}
              </Link>
            )}
            isEdit={isEdit}
          >
            <DataSelectList
              name="customerId"
              target="customer"
              value={inputForm.data.customerId}
              onChange={this.onChange}
              error={!!errorMessages.customerId}
              fullWidth
            />
          </DataGrid>
          <DataGrid title="ニックネーム" value={form.data.nickname} isEdit={isEdit}>
            <TextField error={!!errorMessages.nickname} helperText="会員のニックネームを使用しない場合、新規追加の場合は入力してください。" name="nickname" fullWidth autoComplete="off" value={inputForm.data.nickname} onChange={this.onChange} inputProps={{ maxLength: 100 }} />
            <FormErrorText>{errorMessages.nickname}</FormErrorText>
          </DataGrid>
          <DataGrid
            title="案件番号"
            value={(
              <Link to={detailUrl.OPPORTUNITY_DETAIL + form.data.opportunityId}>
                {form.data.opportunityNumber}
              </Link>
          )}
            isRequired
            isEdit={!isExist && isEdit}
          >
            <Box>{inputForm.data.opportunityNumber}</Box>
            <Box mt={2}>
              <Button size="small" variant="contained" onClick={() => this.setState({ isSelectOpen: true })}>案件選択</Button>
            </Box>
            <FormErrorText>{errorMessages.opportunityId}</FormErrorText>
          </DataGrid>
          <DataGrid
            title="店舗"
            isEdit={isEdit}
            value={(
              <Link to={detailUrl.SHOP_DETAIL + form.data.shopId}>
                {form.data.shopName}
              </Link>
            )}
          >
            <DataSelectList
              name="shopId"
              target="shops"
              error={!!errorMessages.shopId}
              value={inputForm.data.shopId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessages.shopId}</FormErrorText>
          </DataGrid>

          <DataGrid
            title="プラン"
            isEdit={isEdit}
            value={(
              <Link to={detailUrl.SHOP_DETAIL + form.data.planId}>
                {form.data.planName}
              </Link>
            )}
          >
            <DataSelectList
              name="planId"
              target="plans"
              parameter={inputForm.data.shopId ? { shopId: inputForm.data.shopId } : null}
              error={!!errorMessages.planId}
              value={inputForm.data.planId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessages.planId}</FormErrorText>
          </DataGrid>
          <DataGrid title="タイトル" value={form.data.title} isEdit={isEdit} isRequired>
            <TextField error={!!errorMessages.title} name="title" fullWidth autoComplete="off" value={inputForm.data.title} onChange={this.onChange} inputProps={{ maxLength: 100 }} />
            <FormErrorText>{errorMessages.title}</FormErrorText>
          </DataGrid>
          <DataGrid title="主な年代" isEdit={isEdit} value={form.data.mainAgeName}>
            <DataSelectList
              name="mainAgeCode"
              target="codes"
              parameter={{ codeCategory: 'main_age' }}
              value={inputForm.data.mainAgeCode}
              onChange={this.onChange}
            />
          </DataGrid>
          <DataGrid title="利用シーン" isEdit={isEdit} value={form.data.sceneAttributeItemName}>
            <DataSelectList
              name="sceneAttributeItemId"
              target="attribute-items"
              parameter={{ attributeType: 'scene' }}
              value={inputForm.data.sceneAttributeItemId}
              onChange={this.onChange}
            />
          </DataGrid>
          <Grid container spacing={1} mb={2} style={{ wordBreak: 'break-all' }}>
            <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <FormTitle title="評価" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1} mb={2.5} style={{ wordBreak: 'break-all' }}>
                {!isEdit && (
                  <Grid item xs={12} md={1.5} sx={{ display: 'flex' }}>
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <Box>平均</Box>
                      <Box style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>{form.data.averageEvaluation}</Box>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} md={10.5}>
                  <Grid container style={{ wordBreak: 'break-all' }}>
                    <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <Box>味</Box>
                    </Grid>
                    <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <RatingStar
                        name="tasteEvaluation"
                        value={inputForm.data.tasteEvaluation}
                        onChange={this.onChangeRating}
                        readOnly={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ wordBreak: 'break-all' }}>
                    <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <Box display="flex">ボリューム</Box>
                    </Grid>
                    <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <RatingStar
                        name="volumeEvaluation"
                        value={inputForm.data.volumeEvaluation}
                        onChange={this.onChangeRating}
                        readOnly={!isEdit}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ wordBreak: 'break-all' }}>
                    <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <Box display="flex">色どり</Box>
                    </Grid>
                    <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                      <RatingStar
                        name="colorfulEvaluation"
                        value={inputForm.data.colorfulEvaluation}
                        onChange={this.onChangeRating}
                        readOnly={!isEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DataGrid title="コメント" isEdit={isEdit} value={form.data.comment}>
            <TextArea
              name="comment"
              value={inputForm.data.comment}
              onChange={this.onChange}
            />
          </DataGrid>
          <DataGrid title="予算" isEdit={isEdit} value={form.data.budgetPrice}>
            <NumberInput value={inputForm.data.budgetPrice} fullWidth name="budgetPrice" onChange={this.onChange} />
          </DataGrid>
          <DataGrid
            title="画像"
            isEdit={isEdit}
            value={(
              <PublicImageList itemDatas={form.images} />
            )}
          >
            <PublicImageList
              itemDatas={tempImageList}
              isEdit={isEdit}
              onAddImg={this.onAddImg}
              onDelete={this.onDeleteImg}
              onChange={this.onChangeImg}
              isNoTitle
              noEdit
            />
          </DataGrid>
          {!isEdit && (
            <>
              <DataGrid title="投稿日" value={form.data.postDate} />
              <DataGrid title="公開日" value={form.data.publishDate} />
              <DataGrid title="ステータス" value={form.data.reviewStatusName} />
              <DataGrid
                title="公開情報"
                value={(
                  <>
                    {form.data.evaluationPublished && <Box>評価を公開</Box>}
                    {form.data.commentPublished && <Box>コメントを公開</Box>}
                    {form.data.imagePublished && <Box>画像を公開</Box>}
                  </>
                )}
              />
              {form.data.id && (
                <Box mt={2}><SystemInfo data={form.data} /></Box>
              )}
            </>
          )}
          {isEdit && (
            <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
              {form.data.id && (
                <Button color="inherit" size="small" variant="contained" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
              )}
              <Button color="primary" size="small" variant="contained" onClick={this.onSave}>保存</Button>
            </Box>
          )}
        </RecordBox>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <StatusDialog
          isOpen={isCheckStatusOpen}
          onClose={this.onClose}
          onClickSave={this.onClickSave}
        />
        <SelectOpportunityDialog
          isOpen={isSelectOpen}
          onSelect={this.onSelect}
          onClose={() => this.setState({ isSelectOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewInfo: state.reviewStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ReviewDetail));
