import React, { useState } from 'react';
import {
  Paper, Button, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { specialFeatureAttachedActions } from '../../../../redux/pageSetting/specialFeature/specialFeatureAttachedState';
import AreaInput from '../../../../components/templates/AreaInput';
import { isScreenEditDisabled } from '../../../../utils/authCheck.helper';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

export default function SpecialFeatureArea(props) {
  const { specialFeatureId } = props;
  const dispatch = useDispatch();
  const dbDatas = useSelector((state) => state.specialFeatureAttachedStore.areas);
  const [datas, setDatas] = useState([{ prefectureGroups: [] }]);
  const [value, setValue] = useState([]);
  const [edit, setEdit] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const isDisabled = useSelector((state) => isScreenEditDisabled(state.loginStore.userInfo));
  const isSaveAreaSuccess = useSelector(
    (state) => state.specialFeatureAttachedStore.isSaveAreaSuccess,
  );

  const search = () => {
    dispatch(specialFeatureAttachedActions.getAreas({ specialFeatureId }));
  };

  React.useEffect(() => {
    if (isSaveAreaSuccess) {
      setSuccessOpen(true);
      setEdit(false);
      dispatch(specialFeatureAttachedActions.resetFlg());
      search();
    }
  }, [isSaveAreaSuccess]);
  React.useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);
  React.useEffect(() => {
    if (specialFeatureId) {
      search();
    }
  }, [specialFeatureId]);

  const onSave = () => {
    dispatch(specialFeatureAttachedActions.saveArea({ specialFeatureId, data: value }));
  };

  const onCloseSnackbar = () => {
    setSuccessOpen(false);
  };

  return (
    <>
      <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
        {!edit && !isDisabled && (
          <Box textAlign="right">
            <Button variant="contained" size="small" onClick={() => setEdit(true)}>編集</Button>
          </Box>
        )}
        <AreaInput datas={datas} isEdit={edit} onChange={(v) => setValue(v)} />
        {(edit && !isDisabled) && (
          <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <Button color="inherit" size="small" variant="contained" onClick={() => setEdit(false)} style={{ marginRight: '10px' }}>キャンセル</Button>
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
      </Paper>
      <SuccessSnackbar
        open={successOpen}
        onClose={onCloseSnackbar}
        message="保存しました。"
      />
    </>
  );
}
