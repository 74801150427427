import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField,
} from '@mui/material';
import { frontNoticeActions } from '../../../redux/pageSetting/frontNotice/frontNoticeState';
import {
  FormTitle, SuccessSnackbar, RecordBox, withParams,
  DatePicker, FormErrorText,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import Editor from '../../../components/templates/wysiwyg/Editor';
import RichTextReadOnly from '../../../components/templates/wysiwyg/RichTextReadOnly';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import SystemInfo from '../../../components/templates/SystemInfo';
import Validation from './validation';
import RecordHeader from '../../../components/templates/RecordHeader';

const ERROR_MESSAGES = {
  publishStartDate: '',
  publishEndDate: '',
  category: '',
  title: '',
  content: '',
};

class FrontNoticeDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id, publishStartDate: '', publishEndDate: '', title: '', content: '',
    };

    let isSuccessOpen = false;
    if (props.frontNoticeInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(frontNoticeActions.resetFlg());
    }

    this.state = {
      isSuccessOpen,
      form,
      inputForm: form,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isEdit: !id,
      errorMessages: ERROR_MESSAGES,
    };
    if (id) {
      props.dispatch(frontNoticeActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.frontNoticeInfo.data !== this.props.frontNoticeInfo.data) {
      if (this.props.frontNoticeInfo.data) {
        this.setData(this.props.frontNoticeInfo.data);
      }
    }
    if (prevProps.frontNoticeInfo.isSaveSuccess !== this.props.frontNoticeInfo.isSaveSuccess) {
      if (this.props.frontNoticeInfo.isSaveSuccess) {
        this.setSuccess(this.props.frontNoticeInfo.saveId);
      }
    }
  }

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if ((form.id && form.id !== id) || !form.id) {
      window.location.href = detailUrl.FRONT_NOTICE_DETAIL + id;
      return;
    }
    this.props.dispatch(frontNoticeActions.resetFlg());
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(frontNoticeActions.getData({ id: searchId }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    this.setState({
      isEdit: true,
      errorMessages: ERROR_MESSAGES,
    });
    this.props.dispatch(frontNoticeActions.getData({ id: form.id }));
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(inputForm).forEach((key) => {
      const msg = Validation.formValidate(key, inputForm[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (this.dateCheck()) {
      isError = true;
      const msg = '開始日が終了日より後に設定されています';
      tempMessage = { ...tempMessage, publishStartDate: msg, publishEndDate: msg };
    }
    if (isError) {
      this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
      return;
    }
    this.props.dispatch(frontNoticeActions.saveData(inputForm));
  };

  dateCheck = () => {
    const { inputForm } = this.state;
    const { publishStartDate, publishEndDate } = inputForm;
    return publishStartDate > publishEndDate;
  };

  onClickCancel = () => {
    const { form } = this.state;
    this.setState({ isEdit: false, inputForm: form });
  };

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onCopy = () => {
    const { form } = this.state;
    this.setState({
      inputForm: { ...form, id: null },
      isEdit: true,
      errorMessages: ERROR_MESSAGES,
    });
  };

  render() {
    const {
      form,
      inputForm,
      isSuccessOpen,
      isDisabled,
      isEdit,
      errorMessages,
    } = this.state;

    return (
      <>
        {(!isEdit && !isDisabled) && (
          <RecordHeader
            bottomSection={(
              <>
                <Button color="primary" variant="contained" size="small" onClick={this.onCopy}>コピーする</Button>
                <Button color="primary" variant="contained" size="small" onClick={this.onEdit}>編集</Button>
              </>
            )}
          />
        )}

        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={12}>
            <RecordBox>
              <DataGrid title="公開開始日" isRequired isEdit={isEdit} value={form.publishStartDate}>
                <DatePicker error={!!errorMessages.publishStartDate} name="publishStartDate" value={inputForm.publishStartDate} onChange={this.onChange} />
                <FormErrorText>{errorMessages.publishStartDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="公開終了日" isRequired isEdit={isEdit} value={form.publishEndDate}>
                <DatePicker error={!!errorMessages.publishEndDate} name="publishEndDate" value={inputForm.publishEndDate} onChange={this.onChange} />
                <FormErrorText>{errorMessages.publishEndDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="件名" isRequired isEdit={isEdit} value={form.title}>
                <TextField error={!!errorMessages.title} name="title" value={inputForm.title} onChange={this.onChange} fullWidth inputProps={{ maxLength: 255 }} />
                <FormErrorText>{errorMessages.title}</FormErrorText>
              </DataGrid>
              <Grid item sx={{ marginLeft: '8px', marginTop: '8px' }}><FormTitle title="内容" isRequired={isEdit} isNonFlex /></Grid>
              {!isEdit && <Box ml={1}><RichTextReadOnly content={form.content} /></Box>}
              {isEdit && (
              <>
                <Editor title="お知らせの" name="content" onChange={this.onChange} content={inputForm.content} />
                <FormErrorText>{errorMessages.content}</FormErrorText>
              </>
              )}

              {isEdit && (
                <Box position="sticky" bottom={0} left={0} zIndex={3} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {form.id && (<Button color="inherit" variant="contained" size="small" onClick={this.onClickCancel} sx={{ marginRight: '10px' }}>キャンセル</Button>)}
                  <Button variant="contained" size="small" onClick={this.onSave}>保存</Button>
                </Box>
              )}
              {!isEdit && form.id && (
                <Box mt={2}><SystemInfo data={form} /></Box>
              )}
            </RecordBox>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  frontNoticeInfo: state.frontNoticeStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(FrontNoticeDetail));
