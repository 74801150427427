import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    datas: {},
    searchCondition: null,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: (state) => {
      state.csvDatas = null;
    },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
