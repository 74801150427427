import React, { useState, useEffect } from 'react';
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material';
import HttpConnection from '../../../utils/http-connection';
import { REST_URL } from '../../../constants/serverUrls';
import Dialog from '../Dialog';
import DataTable from './DataTable';
import { FormTitle, DialogContent } from '../Base';

export default function SelectDataDialog(props) {
  const {
    isOpen, onClose, onSelect, parameter, target,
  } = props;
  const [datas, setDatas] = useState({});
  const [condition, setCondition] = useState({
    page: 0,
    name: '',
    records: 50,
    order: 'asc',
    orderBy: '',
  });

  const fetchData = async (tmpCondition) => {
    const httpConnection = new HttpConnection();
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, { ...parameter, ...tmpCondition });
    setDatas(tmpData);
  };

  useEffect(() => {
    if (isOpen) {
      const tmpCondition = {
        ...condition,
        name: '',
      };
      fetchData(tmpCondition);
    }
  }, [isOpen]);

  const onChangePage = (_, newPage) => {
    const tmpCondition = {
      ...condition,
      page: newPage,
    };
    setCondition(tmpCondition);
    fetchData(tmpCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tmpCondition = {
      ...condition,
      page: 0,
      records: rowsPerPage,
    };
    setCondition(tmpCondition);
    fetchData(tmpCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tmpCondition = {
      ...condition,
      page: 0,
      orderBy,
      order,
    };
    setCondition(tmpCondition);
    fetchData(tmpCondition);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    const tmpCondition = {
      ...condition,
      [name]: value,
      page: 0,
    };
    setCondition(tmpCondition);
  };

  const onSearch = () => {
    fetchData(condition);
  };

  return (
    <Dialog
      title="選択"
      isOpen={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Box mb={2} display={{ xs: 'block', md: 'flex' }}>
          <Box>
            <FormTitle title="名前" />
            <TextField autoComplete="off" name="name" value={condition.name} onChange={onChange} />
          </Box>
          <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }} display="flex" alignItems="flex-end"><Button variant="contained" size="small" onClick={onSearch}>検索</Button></Box>
        </Box>
        <DataTable
          rows={datas.content || []}
          page={condition.page}
          rowsPerPage={condition.records}
          totalElements={datas.totalElements}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={condition.order}
          orderBy={condition.orderBy}
          onSelect={onSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  );
}
