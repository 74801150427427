import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { selectActions } from './selectState';
import {
  getDatas,
} from '../../../services/base.service';

function* getCodes({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SELECT.CODE, payload);
    yield put(selectActions.getCodesSuccess(result));
  } catch (_) { }
}

function* getAreas() {
  try {
    const result = yield call(getDatas, REST_API.SELECT.AREA);
    yield put(selectActions.getAreasSuccess(result));
  } catch (_) { }
}

function* selectSaga() {
  yield takeLatest('select/getCodes', getCodes);
  yield takeLatest('select/getAreas', getAreas);
}

export default selectSaga;
