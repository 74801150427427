import React from 'react';
import FullScreenDialog from './FullScreenDialog';
import FileViewer from './FileViewer';

export default function FilePreviewDialog(props) {
  const {
    isOpen, onClose, url, loading, title,
  } = props;

  return (
    <FullScreenDialog isOpen={isOpen} title="ファイルプレビュー" onClose={onClose} loading={loading}>
      <div>
        <FileViewer url={url} onError={onClose} title={title} />
      </div>
    </FullScreenDialog>
  );
}
