import {
  call,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityDetailActions } from './opportunityDetailState';
import {
  getDatas as getListData,
  getDataById,
  saveData as callSaveData,
} from '../../../services/base.service';

function* getDatas({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_ID, payload);
    yield put(opportunityDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.REPORT.ORDER, payload);
    yield put(opportunityDetailActions.getPdfDataSuccess(result));
  } catch (_) { }
}

function* getAffiliations({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_AFFILIATION, payload);
    yield put(opportunityDetailActions.getAffiliationsSuccess(result));
  } catch (_) { }
}

function* getCustomers({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CUSTOMER, payload);
    yield put(opportunityDetailActions.getCustomersSuccess(result));
  } catch (_) { }
}

function* saveData({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY, payload);
    yield put(opportunityDetailActions.saveDataSuccess(result));
  } catch (_) { }
}

function* saveCancel({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CANCEL, payload);
    yield put(opportunityDetailActions.saveCancelSuccess(result));
  } catch (_) { }
}

function* createClaimData({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CLAIM, payload);
    yield put(opportunityDetailActions.createClaimDataSuccess(result));
  } catch (_) { }
}

function* getCancelPrice({ payload }) {
  try {
    const result = yield call(getListData, REST_API.OPPORTUNITY.OPPORTUNITY.CANCEL_PRICE, payload);
    yield put(opportunityDetailActions.getCancelPriceSuccess(result));
  } catch (_) { }
}

function* opportunityDetailSaga() {
  yield takeLatest('opportunityDetail/getData', getDatas);
  yield takeLatest('opportunityDetail/getPdfData', getPdfData);
  yield takeLatest('opportunityDetail/saveData', saveData);
  yield takeLatest('opportunityDetail/saveCancel', saveCancel);
  yield takeLatest('opportunityDetail/getAffiliations', getAffiliations);
  yield takeLatest('opportunityDetail/getCustomers', getCustomers);
  yield takeLatest('opportunityDetail/createClaimData', createClaimData);
  yield takeLatest('opportunityDetail/getCancelPrice', getCancelPrice);
}

export default opportunityDetailSaga;
