import React from 'react';
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTabs = styled(MaterialTabs)({
  borderBottom: '1px solid #e8e8e8',
  marginBottom: '10px',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const CustomTab = styled((props) => <MaterialTab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

export default function Tabs(props) {
  const {
    value, onChange, tabs,
  } = props;
  return (
    <CustomTabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabs.map((tab) => (
        <CustomTab label={tab} key={tab} />
      ))}
    </CustomTabs>
  );
}
