import {
  call,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityAttachActions } from './opportunityAttachState';
import {
  getDataById,
  saveData as callSaveData,
  deleteDatas,
  getDatas,
  uploadFiles,
} from '../../../services/base.service';

function* getCancelData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.CANCEL_ID, payload);
    yield put(opportunityAttachActions.getCancelDataSuccess(result));
  } catch (_) { }
}

function* getCancelDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.CANCEL, payload);
    yield put(opportunityAttachActions.getCancelDatasSuccess(result));
  } catch (_) { }
}

function* saveCancelData({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.CANCEL, payload);
    yield put(opportunityAttachActions.saveCancelDataSuccess(result));
  } catch (_) { }
}

function* deleteCancelData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.CANCEL, payload);
    yield put(opportunityAttachActions.deleteCancelDataSuccess(result));
  } catch (_) { }
}

function* getStatusHistory({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.HISTORY, payload);
    yield put(opportunityAttachActions.getStatusHistorySuccess(result));
  } catch (_) { }
}

function* getDeliveryData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.DELIVERY_ID, payload);
    yield put(opportunityAttachActions.getDeliveryDataSuccess(result));
  } catch (_) { }
}

function* getDeliveryDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.DELIVERY, payload);
    yield put(opportunityAttachActions.getDeliveryDatasSuccess(result));
  } catch (_) { }
}

function* saveDeliveryData({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'file') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    if (payload.file) {
      formData.append('file', payload.file);
    }

    const result = yield call(uploadFiles, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.DELIVERY, formData);
    yield put(opportunityAttachActions.saveDeliveryDataSuccess(result));
  } catch (_) { }
}

function* deleteDeliveryData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.OPPORTUNITY_ATTACH.DELIVERY, payload);
    yield put(opportunityAttachActions.deleteDeliveryDataSuccess(result));
  } catch (_) { }
}

function* getUrl({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.FILE.FILE, payload);
    yield put(opportunityAttachActions.getUrlSuccess(result));
  } catch (_) { }
}

function* opportunityAttachSaga() {
  yield takeLatest('opportunityAttach/getCancelDatas', getCancelDatas);
  yield takeLatest('opportunityAttach/getCancelData', getCancelData);
  yield takeLatest('opportunityAttach/saveCancelData', saveCancelData);
  yield takeLatest('opportunityAttach/deleteCancelData', deleteCancelData);
  yield takeLatest('opportunityAttach/getStatusHistory', getStatusHistory);
  yield takeLatest('opportunityAttach/getDeliveryDatas', getDeliveryDatas);
  yield takeLatest('opportunityAttach/getDeliveryData', getDeliveryData);
  yield takeLatest('opportunityAttach/saveDeliveryData', saveDeliveryData);
  yield takeLatest('opportunityAttach/deleteDeliveryData', deleteDeliveryData);
  yield takeLatest('opportunityAttach/getUrl', getUrl);
}

export default opportunityAttachSaga;
