import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { claimDetailActions } from './claimDetailState';
import {
  getDatas as callGetData,
  deleteDatas as callDeleteDatas,
  saveData as callSaveData,
} from '../../../services/base.service';

function* getDatas({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM.CLAIM_DETAIL, payload);
    yield put(claimDetailActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM.CLAIM_DETAIL_ID, payload);
    yield put(claimDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveData({ payload }) {
  try {
    yield call(callSaveData, REST_API.OPPORTUNITY.CLAIM.CLAIM_DETAIL, payload);
    yield put(claimDetailActions.saveDataSuccess());
  } catch (_) { }
}

function* deleteDatas({ payload }) {
  try {
    const result = yield call(callDeleteDatas, REST_API.OPPORTUNITY.CLAIM.CLAIM_DETAIL, payload);
    yield put(claimDetailActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* claimDetailSaga() {
  yield takeLatest('claimDetail/getDatas', getDatas);
  yield takeLatest('claimDetail/getData', getData);
  yield takeLatest('claimDetail/saveData', saveData);
  yield takeLatest('claimDetail/deleteData', deleteDatas);
}

export default claimDetailSaga;
