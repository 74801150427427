import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../../../constants/frontUrls';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { estimateActions } from '../../../../../redux/opportunity/estimate/estimateState';
import AddEstimateDialog from './AddEstimateDialog';
import { SuccessSnackbar } from '../../../../../components/atoms/Base';
import { isScreenDeleteDisabledFromUrl, isScreenEditDisabledFromUrl } from '../../../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  opportunityId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  { id: 'estimateNumber', label: '見積番号', sortId: 'estimate_number' },
  { id: 'estimateStatusName', label: 'ステータス', sortId: 'estimate_status_name' },
  { id: 'shopVendorName', label: '店舗/業者', sortId: 'shop_vendor_name' },
  {
    id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
  },
];

export default function EstimateList(props) {
  const {
    id, isOpen, opportunityNumber,
  } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const estimateDatas = useSelector((state) => state.estimateStore.datas);
  const isSaveSuccess = useSelector((state) => state.estimateStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.estimateStore.isDeleteSuccess);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/estimate'));
  const isNotEdit = useSelector((state) => isScreenEditDisabledFromUrl(state.loginStore.userInfo, '/estimate'));

  const search = () => {
    dispatch(estimateActions.getDatas(searchCondition));
  };

  React.useEffect(() => {
    if (id && isOpen) {
      const tempSearchCondition = { ...DEFAULT_SEARCH_CONDITION, opportunityId: id };
      setSearchCondition(tempSearchCondition);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (searchCondition.opportunityId) {
      search();
    }
  }, [searchCondition]);

  React.useEffect(() => {
    if (id && estimateDatas) {
      setDatas(estimateDatas);
    }
  }, [estimateDatas]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setIsSuccessOpen(true);
      setSuccessMessage('保存しました。');
      dispatch(estimateActions.resetFlgs());
      search();
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (isDeleteSuccess) {
      setIsSuccessOpen(true);
      setSuccessMessage('削除しました。');
      dispatch(estimateActions.resetFlgs());
      search();
    }
  }, [isDeleteSuccess]);

  const onAdd = () => {
    setIsAddOpen(true);
  };

  const onClose = () => {
    setIsAddOpen(false);
  };

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
  };

  const onDelete = (deleteId) => {
    dispatch(estimateActions.deleteData({ ids: [deleteId] }));
  };

  return (
    <>
      <DataTableWithMenu
        url={detailUrl.ESTIMATE_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        onAddOpen={onAdd}
        procName="見積"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onDelete={onDelete}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
        isNotEdit={isNotEdit}
      />
      <AddEstimateDialog
        opportunityId={id}
        isOpen={isAddOpen}
        onClose={onClose}
        opportunityNumber={opportunityNumber}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}
