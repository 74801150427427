import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { estimateDetailActions } from './estimateDetailState';
import {
  getDatas,
  getDataById,
  saveData,
  deleteDatas,
} from '../../../services/base.service';

function* getEstimateDetailDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL, payload);
    yield put(estimateDetailActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL_ID, payload);
    yield put(estimateDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveEstimateDetailData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL, payload);
    yield put(estimateDetailActions.saveDataSuccess(result));
  } catch (_) { }
}

function* deleteEstimateDetailDatas({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL, payload);
    yield put(estimateDetailActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* getOutputHistories({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_HISTORY, payload);
    yield put(estimateDetailActions.getOutputHistoriesSuccess(result));
  } catch (_) { }
}

function* getOutputHistoryDetails({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_HISTORY_ID, payload);
    yield put(estimateDetailActions.getOutputHistoryDetailsSuccess(result));
  } catch (_) { }
}

function* bulkData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL_BULK, payload);
    yield put(estimateDetailActions.bulkDataSuccess(result));
  } catch (_) { }
}

function* estimateDetailSaga() {
  yield takeLatest('estimateDetail/getDatas', getEstimateDetailDatas);
  yield takeLatest('estimateDetail/getData', getData);
  yield takeLatest('estimateDetail/saveData', saveEstimateDetailData);
  yield takeLatest('estimateDetail/deleteData', deleteEstimateDetailDatas);
  yield takeLatest('estimateDetail/getOutputHistories', getOutputHistories);
  yield takeLatest('estimateDetail/getOutputHistoryDetails', getOutputHistoryDetails);
  yield takeLatest('estimateDetail/bulkData', bulkData);
}

export default estimateDetailSaga;
