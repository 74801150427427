import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { roleListActions } from './roleListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.ROLE.ROLE, payload);
    yield put(roleListActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.ROLE.ROLE_CSV, payload);
    yield put(roleListActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SYSTEM_SETTING.ROLE.ROLE, payload);
    yield put(roleListActions.deleteDataSuccess());
  } catch (_) { }
}

function* roleListSaga() {
  yield takeLatest('roleList/getDatas', getData);
  yield takeLatest('roleList/deleteData', deleteData);
  yield takeLatest('roleList/getCsvDatas', getCsvDatas);
}

export default roleListSaga;
