import { createSlice } from '@reduxjs/toolkit';

export const conditionSlice = createSlice({
  name: 'condition',
  initialState: {
    isCreateSuccess: false,
    isDeleteSuccess: false,
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.conditionDatas = action.payload;
    },
    insert: (state) => {
      state.isCreateSuccess = false;
    },
    insertSuccess: (state) => {
      state.isCreateSuccess = true;
    },
    update: (state) => {
      state.isUpdateSuccess = false;
    },
    updateSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    delete: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlg: (state) => {
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const conditionActions = conditionSlice.actions;

export default conditionSlice.reducer;
