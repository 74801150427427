import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, useMediaQuery,
  Checkbox, IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Pagination } from '../../../components/atoms/Base';
import TableHeaderCell from '../../../components/atoms/TableHeaderCell';
import PcTable from '../../../components/templates/dataTableWithCheckbox/PcTable';
import SmartPhoneTable from '../../../components/templates/dataTableWithCheckbox/SmartPhoneTable';

export default function DataTableWithCheckbox(props) {
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    headCells,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    setSelected,
    selected,
  } = props;

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      selectedData = rows;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, row) => {
    const selectedIndex = selected.findIndex((item) => item.id === row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, row];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.findIndex((item) => item.id === id) !== -1;

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
              <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
                {selected.length}
                {' '}
              件選択中
            </Typography>
          )}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell key="table-header-check" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && rows.length !== selected.length
                    }
                    checked={
                      selected.length > 0 && rows.length === selected.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color="primary"
                    disabled={rows.length === 0}
                  />
                </TableCell>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {!isPc && (
                  <TableCell> </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        onClick={(event) => handleClick(event, row)}
                      />
                    </TableCell>
                    {isPc && (
                      <PcTable
                        row={row}
                        headCells={headCells}
                      />
                    )}
                    {!isPc && (
                      <SmartPhoneTable
                        row={row}
                        isNotDetail
                        headCells={headCells}
                      />
                    )}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
