import { createSlice } from '@reduxjs/toolkit';

export const shopDetailSlice = createSlice({
  name: 'shopDetail',
  initialState: {

  },
  reducers: {
    getPlanData: () => {},
    getPlanDataSuccess: (state, action) => {
      state.plans = action.payload;
    },
    getOptionData: () => {},
    getOptionDataSuccess: (state, action) => {
      state.options = action.payload;
    },
    getOptionDataById: () => {},
    getOptionDataByIdSuccess: (state, action) => {
      state.optionData = action.payload;
    },
    saveOptionData: (state) => {
      state.isOptionSaveSuccess = false;
    },
    saveOptionDataSuccess: (state) => {
      state.isOptionSaveSuccess = true;
    },
    deleteOption: (state) => {
      state.isOptionDeleteSuccess = false;
    },
    deleteOptionSuccess: (state) => {
      state.isOptionDeleteSuccess = true;
    },
    getDeliveryData: (state) => {
      state.deliveryDatas = {};
    },
    getDeliveryDataSuccess: (state, action) => {
      state.deliveryDatas = action.payload;
    },
    getDeliveryDataById: () => {},
    getDeliveryDataByIdSuccess: (state, action) => {
      state.delivery = action.payload;
    },
    saveDeliveryData: (state) => {
      state.isDeliverySaveSuccess = false;
    },
    saveDeliveryDataSuccess: (state) => {
      state.isDeliverySaveSuccess = true;
    },
    deleteDeliveryData: (state) => {
      state.isDeliveryDeleteSuccess = false;
    },
    deleteDeliveryDataSuccess: (state) => {
      state.isDeliveryDeleteSuccess = true;
    },
    getHolidayData: () => {},
    getHolidayDataSuccess: (state, action) => {
      state.holidayDatas = action.payload;
    },
    getHolidayDataById: () => {},
    getHolidayDataByIdSuccess: (state, action) => {
      state.holiday = action.payload;
    },
    saveHolidayData: (state) => {
      state.isHolidaySaveSuccess = false;
    },
    saveHolidayDataSuccess: (state) => {
      state.isHolidaySaveSuccess = true;
    },
    deleteHolidayData: (state) => {
      state.isHolidayDeleteSuccess = false;
    },
    deleteHolidayDataSuccess: (state) => {
      state.isHolidayDeleteSuccess = true;
    },
    getCancelDatas: () => {},
    getCancelDatasSuccess: (state, action) => {
      state.cancelDatas = action.payload;
    },
    getCancelData: () => {},
    getCancelDataSuccess: (state, action) => {
      state.cancel = action.payload;
    },
    saveCancelData: (state) => {
      state.isCancelSaveSuccess = false;
    },
    saveCancelDataSuccess: (state) => {
      state.isCancelSaveSuccess = true;
    },
    deleteCancelData: (state) => {
      state.isCancelDeleteSuccess = false;
    },
    deleteCancelDataSuccess: (state) => {
      state.isCancelDeleteSuccess = true;
    },

    resetFlgs: (state) => {
      state.isOptionDeleteSuccess = false;
      state.isOptionSaveSuccess = false;
      state.isDeliverySaveSuccess = false;
      state.isDeliveryDeleteSuccess = false;
      state.isHolidaySaveSuccess = false;
      state.isHolidayDeleteSuccess = false;
      state.isCancelSaveSuccess = false;
      state.isCancelDeleteSuccess = false;
    },
  },
});

export const shopDetailActions = shopDetailSlice.actions;

export default shopDetailSlice.reducer;
