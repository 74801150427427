import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Dialog from '../../../../components/atoms/Dialog';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';

export default function SelectAttributeDialog(props) {
  const [value, setValue] = React.useState({});
  const {
    open, onClose, onClick, target, title,
  } = props;
  React.useEffect(() => {
    if (open) {
      setValue({});
    }
  }, [open]);

  const onChange = (e) => {
    setValue(e.target);
  };

  return (
    <Dialog fullWidth maxWidth="xs" isOpen={open} onClose={onClose} title={`${title}の追加`}>
      <DialogContent dividers>
        {target === 'prefectures' && (
          <DataSelectList
            name="value"
            target="prefectures"
            value={value.value}
            onChange={onChange}
          />
        )}
        {target !== 'prefectures' && (
          <DataSelectList
            name="value"
            target="attribute-items"
            parameter={{ attributeType: target }}
            value={value.value}
            onChange={onChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button
          onClick={(e) => onClick(e, value)}
          color="primary"
        >
          設定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
