import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { deliveryAgencyUserActions } from '../../../redux/shop/deliveryAgencyUser/deliveryAgencyUserState';
import DeliveryAgencyUserDialog from './DeliveryAgencyUserDialog';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const HEAD_CELLS = [
  { id: 'name', label: '担当者名', sortId: 'name' },
  { id: 'email', label: 'メールアドレス', sortId: 'email' },
];

export default function DeliveryAgencyUserDataTable(props) {
  const { deliveryAgencyId, deliveryAgencyName } = props;
  const dispatch = useDispatch();
  const deliveryAgencyUsers = useSelector((state) => state.deliveryAgencyUserStore.datas);
  const isSaveSuccess = useSelector((state) => state.deliveryAgencyUserStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.deliveryAgencyUserStore.isDeleteSuccess);
  const isNotEdit = useSelector((state) => isScreenEditDisabled(state.loginStore.userInfo));
  const isNotDelete = useSelector((state) => isScreenDeleteDisabled(state.loginStore.userInfo));

  const [datas, setDatas] = React.useState({});
  const [searchCondition, setSearchCondition] = React.useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [deliveryAgencyUserId, setDeliveryAgencyUserId] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);

  const search = (sc) => {
    dispatch(deliveryAgencyUserActions.getDatas({
      ...sc, deliveryAgencyId: deliveryAgencyId || null,
    }));
  };

  const onSearch = () => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const success = (msg) => {
    dispatch(deliveryAgencyUserActions.resetFlg());
    setSuccessMessage(msg);
    setSuccessOpen(true);
    setDialogOpen(false);
  };

  React.useEffect(() => {
    if (deliveryAgencyId) {
      onSearch(searchCondition);
    }
  }, [deliveryAgencyId]);
  React.useEffect(() => {
    if (deliveryAgencyUsers) {
      setDatas(deliveryAgencyUsers);
    }
  }, [deliveryAgencyUsers]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      onSearch(searchCondition);
      success('保存しました。');
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      onSearch(searchCondition);
      success('削除しました。');
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setDeliveryAgencyUserId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setDeliveryAgencyUserId(openedMenuRowId);
  };

  const onDelete = (selectedId) => {
    dispatch(deliveryAgencyUserActions.deleteData({ ids: [selectedId] }));
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  const onSuccessSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={datas.content || []}
        procName="担当者"
        headCells={HEAD_CELLS}
        totalElements={datas.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
        isNotEdit={isNotEdit}
      />
      <DeliveryAgencyUserDialog
        isOpen={isDialogOpen}
        onClose={dialogClose}
        id={deliveryAgencyUserId}
        deliveryAgencyId={deliveryAgencyId}
        deliveryAgencyName={deliveryAgencyName}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSuccessSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
