import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, Alert,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';
import {
  withParams, SuccessSnackbar, FireIconTitle, TabPanel,
} from '../../../../components/atoms/Base';
import FileDataTable from '../../opportunityFile/FileDataTable';
import Tabs from '../../../../components/templates/Tabs';
import OpportunityDetailInfo from './OpportunityDetailInfo';
import OpportunityCancelDialog from './OpportunityCancelDialog';
import EstimateList from './attached/EstimateList';
import OrderList from './attached/OrderList';
import ClaimList from './attached/ClaimList';
import ReviewList from './attached/ReviewList';
import IllustrationList from './attached/IllustrationList';
import ConfirmFileDialog from '../file/ConfirmFileDialog';
import detailUrl from '../../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../../utils/authCheck.helper';
import CancelRateList from './attached/CancelRateList';
import ComfimationDownloadButton from '../file/ComfimationDownloadButton';
import { opportunityFileActions } from '../../../../redux/opportunity/opportunityFile/opportunityFileState';
import DeliveryFileTable from './attached/DeliveryFileList';
import StatusHistoryList from './attached/StatusHistoryList';
import RecordHeader from '../../../../components/templates/RecordHeader';

class OpportunityDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    const form = {
      id,
      name: '', // お客様名
      opportunityNumber: '', // 案件番号
      opportunityStatusCode: '', // ステータスコード
      sales: '', // 営業(id)
      salesName: '', // 営業名
      orderReceptionDate: '', // 注文受付日
      complianceDeadlineDate: '', // 対応期限日時
      totalAmount: '', // 総合計金額
      eventDate: '', // 開催日
      openTime: '', // 開催時間
      route: '', // 受注経路(id)
      routeName: '', // 受注経路名
      paymentMethod: '', // 支払方法
      paymentMethodName: '', // 支払方法名
      receipt: '', // 領収書
      paymentDay: '', // 入金日
      deliveryAddress: '', // 納品先住所
      paymentLimitDay: '', // 支払期限
      receiptAddress: '', // 領収書宛名
      note: '', // 但書注意
      inputTargetCheck: '', // 入力対象チェック
      isSendConfirmation: false, // 確定メール送付
      isClaimed: false, // 請求済
      isYuizenArrangement: false, // 結膳手配有無
      account: '', // 提携企業(id)
      accountName: '', // 提携企業
      accountNumber: 0, // 提携企業候補数
      companyName: '', // 所属先会社
      branchOfficeName: '', // 支店営業所
      phone: '', // 電話
      mail: '', // メール
      poNumber: '', // PO番号
      numberOfAgency: '', // 代行案件数
      numberOfYuzenShops: '', // 結膳店舗数
      orderStatusDetails: '', // 注文状態詳細
      notesForInternal: '', // 社内用備考
      notesForShop: '', // 店舗向け備考
      notesForProduct: '', // 商品内容備考
      notesForUser: '', // ユーザー向け備考
    };

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.opportunityInfo.isCreateSuccess) {
      props.dispatch(opportunityDetailActions.resetFlg());
      successMessage = '作成しました。';
      isSuccessOpen = true;
    }
    if (props.opportunityInfo.isCancelSuccess) {
      props.dispatch(opportunityDetailActions.resetFlg());
      successMessage = 'キャンセルにしました。';
      isSuccessOpen = true;
    }
    this.state = {
      id,
      isSuccessOpen,
      successMessage,
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      data: {
        ...form,
      },
      tabValue: 0,
      isCancelModalOpen: false,
      isOpenConfirmDialog: false,
      isExistOrder: true,
    };
    if (id) {
      props.dispatch(opportunityDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.data !== this.props.opportunityInfo.data) {
      if (this.props.opportunityInfo.data) {
        this.setData(this.props.opportunityInfo.data);
      }
    }
    if (prevProps.opportunityInfo.isSaveSuccess
      !== this.props.opportunityInfo.isSaveSuccess) {
      if (this.props.opportunityInfo.isSaveSuccess) {
        this.saveSuccess(this.props.opportunityInfo.saveId);
      }
    }
    if (prevProps.opportunityInfo.isCancelSuccess
      !== this.props.opportunityInfo.isCancelSuccess) {
      if (this.props.opportunityInfo.isCancelSuccess) {
        this.saveCancelSuccess();
      }
    }
    if (prevProps.order.datas
      !== this.props.order.datas) {
      if (this.props.order.datas && this.props.order.datas.content) {
        this.setExistOrder(this.props.order.datas);
      }
    }
  }

  saveSuccess = (saveId) => {
    const { id } = this.state;
    this.props.dispatch(opportunityDetailActions.resetFlg());
    if (!id) {
      this.props.dispatch(opportunityDetailActions.setCreateSuccess());
      window.location.href = detailUrl.OPPORTUNITY_DETAIL + saveId;
      return;
    }
    this.setState({ id: saveId, isSuccessOpen: true, successMessage: '保存しました。' });
    if (this.props.opportunityInfo.isChangeStatus) {
      this.props.dispatch(opportunityDetailActions.setOrderResearch());
    }
    this.props.dispatch(opportunityDetailActions.getData({ id: saveId || id }));
  };

  saveCancelSuccess = () => {
    const { id } = this.state;
    window.location.href = detailUrl.OPPORTUNITY_DETAIL + id;
  };

  setData = (data) => {
    this.setState({ data });
  };

  setExistOrder = (data) => {
    const isExistOrder = data.content && data.content.length > 0;
    this.setState({ isExistOrder });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onOpenCancelModal = () => {
    this.setState({ isCancelModalOpen: true });
  };

  onCloseCancelModal = () => {
    this.setState({ isCancelModalOpen: false });
  };

  onOpenConfirmDialog = () => {
    this.setState({ isOpenConfirmDialog: true });
  };

  onCloseConfirmDialog = () => {
    this.setState({ isOpenConfirmDialog: false });
  };

  onCloseAtSendFileSuccess = () => {
    this.setState({
      isOpenConfirmDialog: false,
      isSuccessOpen: true,
      successMessage: '送信しました。',
    });
    this.props.dispatch(opportunityFileActions.onReload());
  };

  render() {
    const {
      isSuccessOpen,
      isCancelModalOpen,
      successMessage,
      isDeleteDisabled,
      isEditDisabled,
      data,
      tabValue,
      id,
      isOpenConfirmDialog,
      isExistOrder,
    } = this.state;

    return (
      <>
        { id && (
          <RecordHeader
            topSection={(
              <>
                <FireIconTitle checkDateTime={data.fireComplianceDt || ''} isDispText />
                {(data.suggestAffiliation > 0) && (
                  <Box ml={2}>
                    <Alert severity="warning" sx={{ padding: 0, paddingLeft: '10px', paddingRight: '10px' }}>
                      提携企業ではありませんか
                    </Alert>
                  </Box>
                )}
                {(data.suggestCustomer > 0) && (
                  <Box ml={2}>
                    <Alert severity="warning" sx={{ padding: 0, paddingLeft: '10px', paddingRight: '10px' }}>
                      会員ではありませんか
                    </Alert>
                  </Box>
                )}
              </>
            )}
            bottomSection={(
              <>
                {!isEditDisabled && data.opportunityStatusCode < 98 && (<Button variant="contained" color="secondary" size="small" onClick={this.onOpenCancelModal}>案件のキャンセル</Button>)}
                {isExistOrder && (<ComfimationDownloadButton id={id} />)}
                {isExistOrder && (<Button variant="contained" startIcon={<SendIcon />} size="small" onClick={this.onOpenConfirmDialog}>ユーザー確定書送付</Button>)}
              </>
            )}
          />
        )}
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={id ? 5 : 12}>
            <OpportunityDetailInfo
              id={id}
              onOpenAccountDialog={this.onOpenAccountDialog}
              isDisabled={isEditDisabled}
            />
          </Grid>
          {id && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => this.setState({ tabValue: value })}
              tabs={['見積・注文', 'ファイル', '搬入経路ファイル', '請求', 'キャンセル規定', 'ステータス変更履歴', '口コミ', '実例']}
            />
            <TabPanel value={tabValue} index={0}>
              <Grid item xs={12}>
                {data && isExistOrder ? (
                  <OrderList
                    id={id}
                    opportunityNumber={data.opportunityNumber}
                    setExistOrder={this.setExistOrder}
                    isOpen={tabValue === 0}
                  />
                ) : (
                  <EstimateList
                    id={id}
                    opportunityNumber={data.opportunityNumber}
                    isOpen={tabValue === 0}
                  />
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: '1em' }}>
                {data && isExistOrder ? (
                  <EstimateList
                    id={id}
                    opportunityNumber={data.opportunityNumber}
                    isOpen={tabValue === 0}
                  />
                ) : (
                  <OrderList
                    id={id}
                    opportunityNumber={data.opportunityNumber}
                    setExistOrder={this.setExistOrder}
                    isOpen={tabValue === 0}
                  />
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box mt={3}>
                <FileDataTable
                  procName="ファイル"
                  isNotDelete={isDeleteDisabled}
                  isNotEdit={isEditDisabled}
                  opportunityId={id}
                  opportunityNum={data ? data.opportunityNumber : null}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Box mt={3}>
                <DeliveryFileTable
                  opportunityId={id}
                  opportunityNum={data ? data.opportunityNumber : null}
                  isNotDelete={isDeleteDisabled}
                  isNotEdit={isEditDisabled}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Box mt={3}>
                <ClaimList id={id} />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <Box mt={3}>
                <CancelRateList id={id} isNotEdit={isEditDisabled} isNotDelete={isDeleteDisabled} />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <Box mt={3}>
                <StatusHistoryList id={id} />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
              <Box mt={3}>
                <ReviewList id={id} />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
              <Box mt={3}>
                <IllustrationList id={id} />
              </Box>
            </TabPanel>
          </Grid>
          )}
          <OpportunityCancelDialog
            isOpen={isCancelModalOpen}
            onClose={this.onCloseCancelModal}
            id={id}
          />
          <ConfirmFileDialog
            isOpen={isOpenConfirmDialog}
            sendSuccess={this.onCloseAtSendFileSuccess}
            onClose={this.onCloseConfirmDialog}
            form={data}
          />
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityDetailStore,
  loginUser: state.loginStore,
  order: state.orderStore,
  estimate: state.estimateStore,
});

export default withParams(connect(mapStateToProps)(OpportunityDetail));
