import { createSlice } from '@reduxjs/toolkit';

export const reviewSlice = createSlice({
  name: 'review',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    saveStatus: (state) => {
      state.isSaveStatusSuccess = false;
    },
    saveStatusSuccess: (state) => {
      state.isSaveStatusSuccess = true;
    },
    resetFlg: (state) => {
      state.isSaveStatus = false;
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const reviewActions = reviewSlice.actions;

export default reviewSlice.reducer;
