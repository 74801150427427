import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customerPointHistoryActions } from '../../../redux/opportunity/customer/customerPointHistoryState';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import EditPointDialog from './EditPointDialog';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const pointHeadCells = [
  { id: 'pointGranted', label: '区分', sortId: 'point_granted' },
  { id: 'targetDate', label: '対象日', sortId: 'target_date' },
  { id: 'reason', label: '名目', sortId: 'reason' },
  { id: 'point', label: 'ポイント', sortId: 'point' },
  { id: 'remainPoint', label: '残ポイント', sortId: 'remainPoint' },
  { id: 'expirationDate', label: '有効期限', sortId: 'expiration_date' },
];

export default function PointDataTable(props) {
  const { customerId, isOpen } = props;
  const [points, setPoints] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    customerId: customerId || null,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: 'target_date',
  });
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [point, setPoint] = React.useState({});
  const dispatch = useDispatch();
  const isNotEdit = useSelector((state) => isScreenEditDisabled(state.loginStore.userInfo));
  const isNotDelete = useSelector((state) => isScreenDeleteDisabled(state.loginStore.userInfo));
  const datas = useSelector((state) => state.customerPointHistoryStore.datas);
  const isSaveSuccess = useSelector((state) => state.customerPointHistoryStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.customerPointHistoryStore.isDeleteSuccess);

  const search = (sc) => {
    dispatch(customerPointHistoryActions.getDatasByPointHistory(sc));
  };

  const onSearch = () => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  React.useEffect(() => {
    if (isOpen) {
      onSearch();
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (datas && customerId) {
      setPoints(datas);
    }
  }, [datas]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(customerPointHistoryActions.resetFlg());
      onSearch();
      setSuccessMessage('保存しました。');
      setSuccessOpen(true);
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(customerPointHistoryActions.resetFlg());
      onSearch();
      setSuccessMessage('削除しました。');
      setSuccessOpen(true);
    }
  }, [isDeleteSuccess]);

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setPoint({});
  };

  const dialogOpenEdit = (_, openedMenuRowId, row) => {
    setDialogOpen(true);
    setPoint(row);
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  const onDelete = (openedMenuRowId, openedMenuRow) => {
    dispatch(customerPointHistoryActions.deleteDataById({
      id: openedMenuRowId,
      pointGrantTypeCode: openedMenuRow.pointGrantTypeCode,
      customerId,
    }));
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={points.content || []}
        procName="ポイント"
        headCells={pointHeadCells}
        totalElements={points.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        isNotAdd={!customerId}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <EditPointDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        point={point}
        customerId={customerId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
