import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../constants/serverUrls';
import { homeActions } from './homeState';
import { getDatas } from '../../services/base.service';

function* getTodo() {
  const result = yield call(getDatas, REST_API.HOME.TODO);
  yield put(homeActions.getTodoSuccess(result));
}

function* getOpportunityStatus({ payload }) {
  const result = yield call(getDatas, REST_API.HOME.OPPORTUNITY_STATUS, payload);
  yield put(homeActions.getOpportunityStatusSuccess(result));
}

function* homeSaga() {
  yield takeLatest('home/getTodo', getTodo);
  yield takeLatest('home/getOpportunityStatus', getOpportunityStatus);
}

export default homeSaga;
