import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import {
  getDatas, getDataById, uploadFiles, deleteDatas, saveData,
} from '../../../services/base.service';
import { specialFeatureAttachedActions } from './specialFeatureAttachedState';

function* getContents({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_CONTENT, payload);
    yield put(specialFeatureAttachedActions.getContentsSuccess(result));
  } catch (_) { }
}

function* getContent({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_CONTENT_ID, payload);
    yield put(specialFeatureAttachedActions.getContentSuccess(result));
  } catch (_) { }
}

function* deleteContent({ payload }) {
  try {
    yield call(deleteDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_CONTENT, payload);
    yield put(specialFeatureAttachedActions.deleteContentSuccess());
  } catch (_) { }
}

function* saveContent({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFile') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    if (payload.imageFile) {
      formData.append('imagePath', payload.imageFile);
    }

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_CONTENT, formData);
    yield put(specialFeatureAttachedActions.saveContentSuccess(result));
  } catch (_) { }
}

function* getPlans({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_PLAN, payload);
    yield put(specialFeatureAttachedActions.getPlansSuccess(result));
  } catch (_) { }
}

function* getPlan({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_PLAN_ID, payload);
    yield put(specialFeatureAttachedActions.getPlanSuccess(result));
  } catch (_) { }
}

function* deletePlan({ payload }) {
  try {
    yield call(deleteDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_PLAN, payload);
    yield put(specialFeatureAttachedActions.deletePlanSuccess());
  } catch (_) { }
}

function* savePlan({ payload }) {
  try {
    const result = yield call(saveData, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_PLAN, payload);
    yield put(specialFeatureAttachedActions.savePlanSuccess(result));
  } catch (_) { }
}

function* getShops({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_SHOP, payload);
    yield put(specialFeatureAttachedActions.getShopsSuccess(result));
  } catch (_) { }
}

function* getShop({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_SHOP_ID, payload);
    yield put(specialFeatureAttachedActions.getShopSuccess(result));
  } catch (_) { }
}

function* deleteShop({ payload }) {
  try {
    yield call(deleteDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_SHOP, payload);
    yield put(specialFeatureAttachedActions.deleteShopSuccess());
  } catch (_) { }
}

function* saveShop({ payload }) {
  try {
    const result = yield call(saveData, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_SHOP, payload);
    yield put(specialFeatureAttachedActions.saveShopSuccess(result));
  } catch (_) { }
}

function* getAreas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_AREA, payload);
    yield put(specialFeatureAttachedActions.getAreasSuccess(result));
  } catch (_) { }
}

function* saveArea({ payload }) {
  try {
    const result = yield call(saveData, REST_API.PAGE_SETTING.SPECIAL_FEATURE_ATTACHED.SPECIAL_FEATURE_AREA, payload);
    yield put(specialFeatureAttachedActions.saveAreaSuccess(result));
  } catch (_) { }
}

function* specialFeaturePlanSaga() {
  yield takeLatest('specialFeatureAttached/getContents', getContents);
  yield takeLatest('specialFeatureAttached/getContent', getContent);
  yield takeLatest('specialFeatureAttached/deleteContent', deleteContent);
  yield takeLatest('specialFeatureAttached/saveContent', saveContent);
  yield takeLatest('specialFeatureAttached/getPlans', getPlans);
  yield takeLatest('specialFeatureAttached/getPlan', getPlan);
  yield takeLatest('specialFeatureAttached/deletePlan', deletePlan);
  yield takeLatest('specialFeatureAttached/savePlan', savePlan);
  yield takeLatest('specialFeatureAttached/getShops', getShops);
  yield takeLatest('specialFeatureAttached/getShop', getShop);
  yield takeLatest('specialFeatureAttached/deleteShop', deleteShop);
  yield takeLatest('specialFeatureAttached/saveShop', saveShop);
  yield takeLatest('specialFeatureAttached/getAreas', getAreas);
  yield takeLatest('specialFeatureAttached/saveArea', saveArea);
}

export default specialFeaturePlanSaga;
