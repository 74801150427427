import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Button, TextField, Grid,
} from '@mui/material';
import { illustrationActions } from '../../../redux/pageSetting/illustration/illustrationState';
import detailUrl from '../../../constants/frontUrls';
import Validation from './validation';
import DataGrid from '../../../components/templates/DataGrid';
import SystemInfo from '../../../components/templates/SystemInfo';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import SelectOpportunityDialog from '../../opportunity/common/SelectOpportunityDialog/SelectOpportunityDialog';
import NumberInput from '../../../components/atoms/NumberInput';
import ServiceType from '../../../components/atoms/ServiceType';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import {
  FormTitle, RecordBox, withParams, Link, SuccessSnackbar, FormErrorText,
  DatePicker, FormSwitch,
} from '../../../components/atoms/Base';
import Editor from '../../../components/templates/wysiwyg/Editor';
import RichTextReadOnly from '../../../components/templates/wysiwyg/RichTextReadOnly';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

const DEFAULT_FORM = {
  id: '',
  opportunityId: '',
  opportunityNumber: '',
  shopId: '',
  shopName: '',
  title: '',
  planName: '',
  budgetPrice: '',
  joinNumber: '',
  cityId: '',
  cityName: '',
  prefectureId: '',
  prefectureName: '',
  sceneAttributeItemId: '',
  sceneAttributeItemName: '',
  serviceTypeCode: 1,
  serviceTypeName: '',
  eventDate: '',
  menuDetail: '',
  pickup: false,
  illustrationStatusCode: '',
  illustrationStatusName: '',
  imageUploadCompleted: false,
  confirmed: false,
  publishDt: '',
  overview: '',
  displayOrder: '',
  createdAt: '',
  createdByName: '',
  updatedAt: '',
  updatedByName: '',
  images: [],
};
const DEFAULT_ERROR_MESSAGES = {
  opportunityId: '',
  shopId: '',
  eventDate: '',
  publishDt: '',
};

class IllustrationDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.illustrationInfo.isSaveSuccess) {
      isSuccessOpen = true;
      successMessage = '保存しました';
      props.dispatch(illustrationActions.resetFlg());
    }
    this.state = {
      id,
      form: DEFAULT_FORM,
      isSuccessOpen,
      successMessage,
      data: DEFAULT_FORM,
      isEdit: !id,
      imageList: [],
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      tempImageList: [],
      isSelectOpen: false,
      errorMessages: DEFAULT_ERROR_MESSAGES,
    };
    if (id) {
      this.props.dispatch(illustrationActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.illustrationInfo.isSaveSuccess !== this.props.illustrationInfo.isSaveSuccess) {
      if (this.props.illustrationInfo.isSaveSuccess) {
        this.setSuccess(this.props.illustrationInfo.saveId);
      }
    }
    if (prevProps.illustrationInfo.data !== this.props.illustrationInfo.data) {
      if (this.props.illustrationInfo.data) {
        this.setData(this.props.illustrationInfo.data);
      }
    }
  }

  setSuccess = (saveId) => {
    const { id } = this.state;
    if (id) {
      this.props.dispatch(illustrationActions.resetFlg());
    }
    if (!id) {
      window.location.href = detailUrl.ILLUSTRATION_DETAIL + saveId;
      return;
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存しました',
      isEdit: false,
    });
    this.props.dispatch(illustrationActions.getData({ id }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  setData = (dbData) => {
    this.setState({
      form: dbData.data,
      data: dbData.data,
      imageList: dbData.images,
      tempImageList: dbData.images,
      errorMessages: DEFAULT_ERROR_MESSAGES,
    });
  };

  onEdit = () => {
    const { id } = this.state;
    this.props.dispatch(illustrationActions.getData({ id }));
    this.setState({ isEdit: true });
  };

  onClose = () => {
    const { imageList } = this.state;
    this.setState({ isEdit: false, tempImageList: imageList });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  beforeSaveCheck = (form) => {
    const { errorMessages } = this.state;
    let tempMessage = errorMessages;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (!isError && form.illustrationStatusCode === 2 && !form.postDate) {
      tempMessage = {
        ...tempMessage,
        postDate: '公開する場合、投稿日は必須です。',
      };
      isError = true;
    }
    if (isError) {
      this.setState({ errorMessages: tempMessage });
    }
    return isError;
  };

  onSave = () => {
    const {
      tempImageList, id, form, data,
    } = this.state;
    if (this.beforeSaveCheck(form)) return;
    if (form.illustrationStatusCode === 2 && data.illustrationStatusCode !== 2) {
      form.confirmed = true;
    }
    const uploadImages = [];
    tempImageList.forEach((img) => {
      if (img.isNew) {
        uploadImages.push(img[0]);
      }
    });

    const saveForm = {
      id,
      data: form,
      imgForm: { images: tempImageList },
      imageFiles: uploadImages,
    };
    this.props.dispatch(illustrationActions.saveData(saveForm));
  };

  addImg = (value) => {
    const { tempImageList } = this.state;
    let imgArray = [];
    const exists = tempImageList.some((img) => img.id === value.id);

    if (exists) {
      imgArray = tempImageList.map((img) => (img.id === value.id ? value : img));
    } else {
      const inputVal = { ...value, displayOrder: tempImageList.length + 1 };
      imgArray = [...tempImageList, inputVal];
    }
    this.setState({ tempImageList: imgArray });
  };

  deleteImg = (deleteId) => {
    const { tempImageList } = this.state;
    const imgArray = tempImageList.filter((img) => img.id !== deleteId);
    this.setState({ tempImageList: imgArray });
  };

  onSelect = (_, selected) => {
    const { form, errorMessages } = this.state;
    const updatedForm = {
      ...form,
      opportunityId: selected.id,
      opportunityNumber: selected.opportunityNumber,
    };
    const tempErrorMessage = {
      ...errorMessages,
      opportunityId: '',
    };
    this.setState({ isSelectOpen: false, form: updatedForm, errorMessages: tempErrorMessage });
  };

  onChangePre = (event) => {
    const { form } = this.state;
    const { name, value } = event.target;
    const newRow = {
      ...form,
      [name]: value,
      cityId: form[name] === value ? form.cityId : null,
    };
    this.setState({ form: newRow });
  };

  render() {
    const {
      isEdit, form, data, isSelectOpen,
      isSuccessOpen, errorMessages,
      successMessage, tempImageList, isDisabled,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={12}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isDisabled}
              onEdit={this.onEdit}
            />
            <RecordBox>
              <DataGrid
                title="案件番号"
                value={(
                  <Link to={detailUrl.OPPORTUNITY_DETAIL + data.opportunityId}>
                    {data.opportunityNumber}
                  </Link>
                )}
                isRequired
                isEdit={isEdit}
              >
                <Box>{form.opportunityNumber}</Box>
                <Box mt={2}>
                  <Button size="small" variant="contained" onClick={() => this.setState({ isSelectOpen: true })}>案件選択</Button>
                </Box>
                <FormErrorText>{errorMessages.opportunityId}</FormErrorText>
              </DataGrid>
              <DataGrid
                title="店舗"
                isEdit={isEdit}
                isRequired
                value={(
                  <Link to={detailUrl.SHOP_DETAIL + data.shopId}>
                    {data.shopName}
                  </Link>
                )}
              >
                <DataSelectList
                  name="shopId"
                  value={form.shopId}
                  target="shops"
                  onChange={this.onChange}
                  disableClearable
                  error={!!errorMessages.shopId}
                />
                <FormErrorText>{errorMessages.shopId}</FormErrorText>
              </DataGrid>
              <DataGrid title="タイトル" isEdit={isEdit} value={data.title}>
                <TextField value={form.title} fullWidth name="title" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
              </DataGrid>
              <DataGrid title="プラン名" isEdit={isEdit} value={data.planName}>
                <TextField value={form.planName} fullWidth name="planName" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
              </DataGrid>
              <DataGrid title="予算" isEdit={isEdit} value={data.budgetPrice}>
                <NumberInput value={form.budgetPrice} fullWidth name="budgetPrice" onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="人数" isEdit={isEdit} value={data.joinNumber}>
                <NumberInput name="joinNumber" fullWidth value={form.joinNumber} onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="都道府県" isEdit={isEdit} value={data.prefectureName}>
                <DataSelectList
                  name="prefectureId"
                  target="prefectures"
                  value={form.prefectureId}
                  onChange={this.onChangePre}
                  fullWidth
                />
              </DataGrid>
              <DataGrid title="市区町村" isEdit={isEdit} value={data.cityName}>
                <DataSelectList
                  name="cityId"
                  target="citys"
                  parameter={{ prefectureId: form.prefectureId }}
                  value={form.cityId}
                  onChange={this.onChange}
                  fullWidth
                />
              </DataGrid>
              <DataGrid title="利用シーン" value={data.sceneAttributeItemName} isEdit={isEdit}>
                <DataSelectList
                  name="sceneAttributeItemId"
                  value={form.sceneAttributeItemId}
                  target="attribute-items"
                  parameter={{ attributeType: 'scene' }}
                  onChange={this.onChange}
                />
              </DataGrid>
              <DataGrid title="サービスタイプ" value={data.serviceTypeName} isEdit={isEdit} isRequired>
                <ServiceType
                  name="serviceTypeCode"
                  error={errorMessages.serviceTypeCode}
                  value={form.serviceTypeCode}
                  onChange={this.onChange}
                />
                <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
              </DataGrid>
              <DataGrid title="開催日" value={data.eventDate} isEdit={isEdit}>
                <DatePicker error={!!errorMessages.eventDate} value={form.eventDate} name="eventDate" onChange={this.onChange} />
                <FormErrorText>{errorMessages.eventDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="ピックアップ" isEdit={isEdit} value={data.pickup && 'ピックアップ'}>
                <FormSwitch checked={form.pickup} onChange={this.onChange} label="ピックアップにする" name="pickup" />
              </DataGrid>
              <DataGrid title="ステータス" isRequired value={data.illustrationStatusName} isEdit={isEdit}>
                <DataSelectList
                  error={!!errorMessages.illustrationStatusCode}
                  name="illustrationStatusCode"
                  value={form.illustrationStatusCode}
                  target="codes"
                  parameter={{ codeCategory: 'illustration_status' }}
                  onChange={this.onChange}
                  disableClearable
                />
                <FormErrorText>{errorMessages.illustrationStatusCode}</FormErrorText>
              </DataGrid>
              <DataGrid title="公開後cqree確認済" isEdit={isEdit} value={data.confirmed && '確認済'}>
                <FormSwitch checked={form.confirmed} onChange={this.onChange} label="確認済にする" name="confirmed" />
              </DataGrid>
              <DataGrid title="画像アップロード完了" isEdit={isEdit} value={data.imageUploadCompleted && '完了'}>
                <FormSwitch checked={form.imageUploadCompleted} onChange={this.onChange} label="完了にする" name="imageUploadCompleted" />
              </DataGrid>
              <DataGrid title="投稿日" value={data.postDate} isEdit={isEdit}>
                <DatePicker error={!!errorMessages.postDate} value={form.postDate} name="postDate" onChange={this.onChange} />
                <FormErrorText>{errorMessages.postDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="表示順" isEdit={isEdit} value={data.displayOrder}>
                <NumberInput name="displayOrder" value={form.displayOrder} onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="実例画像" isEdit>
                <PublicImageList
                  itemDatas={tempImageList}
                  isEdit={isEdit}
                  onAddImg={this.addImg}
                  onDelete={this.deleteImg}
                  onChange={(e) => this.setState({ tempImageList: e })}
                  isNoTitle
                  noEdit
                />
              </DataGrid>
              <Box mb={2}>
                <FormTitle title="概要" isNonFlex />
                {!isEdit && <RichTextReadOnly content={data.overview} />}
                {isEdit && <Editor title="概要の" name="overview" onChange={this.onChange} content={form.overview} />}
              </Box>

              <FormTitle title="メニュー詳細" isNonFlex />
              {!isEdit && <RichTextReadOnly content={data.menuDetail} />}
              {isEdit && <Editor title="メニュー詳細の" name="menuDetail" onChange={this.onChange} content={form.menuDetail} />}

              {!isEdit && data.id && (
                <Box mt={2}><SystemInfo data={data} /></Box>
              )}

              {isEdit && (
              <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" zIndex={999} borderTop="1px solid rgba(0, 0, 0, .125)">
                <Button color="inherit" variant="contained" size="small" onClick={this.onClose} style={{ marginRight: '10px' }}>キャンセル</Button>
                <Button variant="contained" size="small" onClick={this.onSave}>保存</Button>
              </Box>
              )}
            </RecordBox>
            <SelectOpportunityDialog
              isOpen={isSelectOpen}
              onSelect={this.onSelect}
              onClose={() => this.setState({ isSelectOpen: false })}
            />
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  illustrationInfo: state.illustrationStore,
});

export default withParams(connect(mapStateToProps)(IllustrationDetail));
