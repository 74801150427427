import { isValid } from 'date-fns';
import { red, yellow } from '@mui/material/colors';
import { pdf } from '@react-pdf/renderer';
import HttpConnection from './http-connection';

// 現在日時と貰った日時の比較で色を返す、貰った日時が今日なら黄色、現在時刻>貰った日時なら赤
export default function compareDateTime(dateTimeString) {
  // もらったパラメータの日時をDateオブジェクトに変換
  const givenDateTime = new Date(dateTimeString);
  // 変換できない場合はnullを返す
  if (!isValid(new Date(givenDateTime))) return null;

  // 現在の日時を取得
  const currentDateTime = new Date();

  // 今日の日付を取得
  const today = new Date();
  today.setHours(0, 0, 0, 0); // 時刻を0時0分0秒に設定
  // もらった日時と今日の日付を比較
  if (currentDateTime > givenDateTime) {
    return red[500]; // もらった日時が現在の日時より超えている場合は赤色
  }
  if (givenDateTime.getFullYear() === today.getFullYear()
    && givenDateTime.getMonth() === today.getMonth()
    && givenDateTime.getDate() === today.getDate()
  ) {
    return yellow[700]; // もらった日時が今日なら黄色
  }
  return null;
}

/**
 * オブジェクトのすべてのkeyの名前で文字列を検索して、対象文字内の'%key%'をすべてvalueに置換して返す
 * @param {*} obj
 * @param {*} targetString 置換対象
 * @param {*} isNotReplacePassword trueの場合、%password%は置換しない
 * @returns string 置換後の文字
 */
export function replace(obj, targetString, isNotReplacePassword) {
  const regex = /%([a-zA-Z0-9_]+)%/g;
  return targetString.replace(regex, (match) => {
    // %を取り除いてキーの名前を取得
    const key = match.slice(1, -1);
    // %password%は置換しない
    if (isNotReplacePassword && key === 'password') return match;
    const { [key]: value } = obj;

    // 値が undefined または null の場合は空文字に置き換える
    return value !== undefined && value !== null ? value : '';
  });
}

/**
 * カンマ区切りでStringを配列に変換して返します
 * @param {*} targetString 対象文字
 * @returns array[]
 */
export function convertStringToList(targetString) {
  return targetString.split(',')
    .map((item) => item.replace(/\s+/g, ''))
    .filter((item) => item !== '');
}

const generateAndSendPdf = async (file, fileName) => {
  const document = pdf(file);
  // PDFドキュメントをBlobに変換する
  const blob = await document.toBlob();
  // フォームデータにBlobを追加する
  const formData = new FormData();
  formData.append('file', blob, `${fileName}.pdf`);
  return formData;
};

/**
 * pdfファイル添付Api呼び出し
 * @param {*} file
 * @param {*} param
 * @param {*} url
 */
export async function upload(file, param, url, fileName) {
  const formData = await generateAndSendPdf(file, fileName);
  formData.append('request', new Blob([JSON.stringify(param)], { type: 'application/json' }));
  const httpConnection = new HttpConnection();
  try {
    const result = await httpConnection.upload(url, formData);
    return result;
  } catch (error) { return null; }
}

/**
 * オブジェクト配列をkeyNameの値ごとの配列に分別してオブジェクトを返却
 * @param {*} detailArray
 * @param {*} keyName
 */
export function groupBy(detailArray, keyName) {
  return detailArray.reduce((acc, detail) => {
    const key = detail[keyName];
    if (!acc[key]) {
      return { ...acc, [key]: [detail] };
    }
    return { ...acc, [key]: [...acc[key], detail] };
  }, {});
}

/**
 * 選択商品タイプに対応しない商品項目を空に
 * @param {*}
 * @param {*}
 */
export function emptyItemsOtherSelectedProduct(form) {
  const tempForm = { ...form };
  switch (form.productInputTypeCode) {
    case 1:
      tempForm.freeProductName = null;
      tempForm.shopPlanOptionId = null;
      break;
    case 2:
      tempForm.planId = null;
      tempForm.shopPlanOptionId = null;
      break;
    case 3:
      tempForm.planId = null;
      tempForm.freeProductName = null;
      break;
    default:
      break;
  }
  return tempForm;
}

/**
 * 店舗タイプに対応しないショップor業者項目を空に
 * @param {*}
 * @param {*}
 */
export function emptyItemsOtherSelectedStore(form, type) {
  const tempForm = { ...form };
  switch (type) {
    case 1:
      tempForm.vendorId = null;
      break;
    case 2:
      tempForm.shopId = null;
      break;
    default:
      break;
  }
  return tempForm;
}
