import React from 'react';
import {
  Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, Table, TableRow, TableCell, TableDataCell, Page,
} from '../../../components/pdf/Common';
import logo from '../../../asset/cqreeLogo.png';
import syaban from '../../../asset/syaban.png';
import { groupBy } from '../../../utils/common.helper';

export default function ClaimEnglishFile(props) {
  const { header, details } = props.data || {};
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1);
  const dd = String(today.getDate());
  const formattedDate = `${yyyy}年${mm}月${dd}日`;
  const createShopName = (item) => (
    <>
      <Text>
        {item.shopName}
      </Text>
      <Text>
        {item.invoiceTargeted ? '※Taxable entity' : '※tax exempt business'}
      </Text>
    </>

  );
  const detailMap = details ? groupBy(details, 'shopName') : {};
  const detailRowStyle = (index, length) => {
    const base = {
      flex: '1 0 auto',
      display: 'flex',
    };
    return index < length - 1 ? { ...base, borderLeft: 'none', borderTop: 'none' } : { ...base, border: 'none' };
  };

  let cnt = 0;
  let isNextPage = false;

  const detItem = (shopName, detArray, idx) => (
    <React.Fragment key={shopName + idx}>
      <TableRow style={{ display: 'flex', alignItems: 'stretch' }} borderTop={idx > 0}>
        <TableDataCell
          flexBasis="21%"
          style={{
            textAlign: 'center', justifyContent: 'center', flexWrap: 'wrap', display: 'flex', flexDirection: 'column',
          }}
        >
          {createShopName(detailMap[shopName][0])}
        </TableDataCell>
        <Col flexBasis="79%" style={{ display: 'flex', flexDirection: 'column' }}>
          {detArray.map((item, index) => (
            <TableRow
              key={item.id}
              style={detailRowStyle(index, detArray.length)}
            >
              <TableDataCell flexBasis={`${46 / 0.79} %`} left style={{ paddingLeft: '5px', flexWrap: 'wrap' }}>
                <Text>{item.productName}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${9 / 0.79} %`} right>
                <Text>{item.unitPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${6 / 0.79} %`} right>
                <Text>{item.quantity}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${12 / 0.79} %`} right>
                <Text>{item.totalPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${6 / 0.79} %`}>
                <Text>{`${item.taxRate}%`}</Text>
              </TableDataCell>
            </TableRow>
          ))}
        </Col>
      </TableRow>
      <View break={isNextPage} />
    </React.Fragment>
  );
  const detailLine = (shopName) => {
    let shopAllItems = detailMap[shopName];
    const tempCnt = cnt + detailMap[shopName].length;
    const nextPageColumnSize = isNextPage ? 30 : 15;
    if (tempCnt <= nextPageColumnSize) {
      cnt = tempCnt;
      return detItem(shopName, shopAllItems);
    }
    isNextPage = true;
    const result = [];
    const firstChunkSize = 15 - cnt;
    let currentItems = shopAllItems.slice(0, firstChunkSize);
    result.push(currentItems);
    shopAllItems = shopAllItems.slice(firstChunkSize);
    while (shopAllItems.length > 0) {
      currentItems = shopAllItems.slice(0, 30);
      result.push(currentItems);
      shopAllItems = shopAllItems.slice(30);
    }
    cnt = result[result.length - 1].length;
    return (
      result.map((items, index) => (
        detItem(shopName, items, index)
      )));
  };
  return (
    <Document>
      <Page
        size="A4"
        wrap
      >
        <Root>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Image
              src={logo}
              style={{
                width: '20%',
                height: 'auto',
              }}
            />
          </View>

          <View style={{ fontSize: '15px', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center', borderBottom: '1px solid #000', fontFamily: 'NotoSansJP-Bold' }}>{`MESSRS:${header.companyName}`}</Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>
              {`issue date：${formattedDate}`}
            </Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`Management number：${header.claimNumber}`}</Text>
          </View>
          <Box style={{
            borderBottom: '3px double #000', marginTop: '15px', marginBottom: '15px', textAlign: 'center',
          }}
          >
            <Title>請求書</Title>
          </Box>
          <Grid>
            <Col flexBasis="50%">
              <Grid>
                <Col
                  flexBasis="35%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>subject</Text></View>
                </Col>
                <Col
                  borderBottom
                  flexBasis="60%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '250px', paddingLeft: '3px', flexDirection: 'row',
                  }}
                >
                  <Col
                    flexBasis="45%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldDate}</Text></View>
                  </Col>
                  <Col
                    flexBasis="55%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldCharTime}</Text></View>
                  </Col>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="35%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
                  <View><Text>total amount</Text></View>
                </Col>
                <Col
                  borderBottom
                  flexBasis="60%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                >
                  <Text>{`¥${header.customerBillPrice ? header.customerBillPrice : ''}`}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="35%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
                  <View><Text>Payment terms</Text></View>
                </Col>
                <Col
                  flexBasis="60%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentMethodEnglishName}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="35%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>Payment due date</Text></View>
                </Col>
                <Col
                  flexBasis="60%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentDueDate}</Text>
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="50%">
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="100%" style={{ alignItems: 'center' }}>
                  <Text> </Text>
                </Col>
              </Grid>
              <Box style={{ position: 'relative', fontSize: '9px', textAlign: 'left' }}>
                <Text>Cqree INC.</Text>
                <Text>4F,Suzunaka Bldg Omori,</Text>
                <Text>6-16-16, Minamioi, Shinagawa-ku,</Text>
                <Text>Tokyo,140-0013,Japan </Text>
                <Text>TEL：03-6447-7971 </Text>
                <Text>Registration number：T3-0104-0111-4543</Text>
              </Box>
              <Image
                src={syaban}
                style={{
                  width: '30%', position: 'absolute', bottom: '5px', right: '-5px',
                }}
              />
            </Col>
          </Grid>
          <Table style={{ marginTop: '15px' }}>
            <TableRow borderTop subTitle>
              <TableCell
                flexBasis="21%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>restaurant</Text>
              </TableCell>
              <TableCell
                flexBasis="46%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>DESCRIPTION</Text>
              </TableCell>
              <TableCell
                flexBasis="9%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>
                  unit price
                </Text>
                <Text>
                  (tax included)
                </Text>
              </TableCell>
              <TableCell
                flexBasis="6%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>quantity</Text>
              </TableCell>
              <TableCell
                flexBasis="12%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>amount</Text>
              </TableCell>
              <TableCell
                flexBasis="6%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>tax rate</Text>
              </TableCell>
            </TableRow>
            {Object.keys(detailMap).map((shopName) => (
              (
                detailLine(shopName)
              )

            ))}
          </Table>

          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              justifyContent: 'center',
            }}
            >
              <Text>Eligible bill issuing business usage amount</Text>
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '6px',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>Store Cost</Text>
              <Text>店舗原価</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>82%</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.storeCost ? header.storeCost : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%', fontSize: '8px' }}><View><Text>⑧：Subject to 8% consumption tax</Text></View></Col>
                <Col style={{ flexBasis: '17%', marginRight: '5px' }} right><View><Text>{`¥${header.taxationEightPercentTotalPrice ? header.taxationEightPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%', fontSize: '8px' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col style={{ flexBasis: '19%', marginRight: '5px' }} right><View><Text>{`¥${header.taxationEightPercentTotalTaxPrice ? header.taxationEightPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>Arrangement fee</Text>
              <Text>手配手数料</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>18%</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.commissionPrice ? header.commissionPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%' }}><View><Text>⑩：Subject to 10% consumption tax</Text></View></Col>
                <Col style={{ flexBasis: '17%', marginRight: '5px' }} right><View><Text>{`¥${header.taxationTenPercentTotalPrice ? header.taxationTenPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col style={{ flexBasis: '19%', marginRight: '5px' }} right><View><Text>{`¥${header.taxationTenPercentTotalTaxPrice ? header.taxationTenPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '15%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>Amount eligible for discount</Text>
              <Text>割引対象金額</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.discountTargetPrice ? header.discountTargetPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              justifyContent: 'center',
            }}
            >
              <Text>Amount used by non-qualified invoice issuers</Text>
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>Discount amount</Text>
              <Text>割引金額</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
              fontSize: '8px',
            }}
            >
              <Text>{`${header.discountRate}%`}</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.discountPrice ? header.discountPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%', fontSize: '8px' }}><View><Text>⑧：Subject to 8% consumption tax</Text></View></Col>
                <Col right style={{ flexBasis: '17%', marginRight: '5px' }}><View><Text>{`¥${header.exemptionEightPercentTotalPrice ? header.exemptionEightPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%', fontSize: '8px' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col right style={{ flexBasis: '19%', marginRight: '5px' }}><View><Text>{`¥${header.exemptionEightPercentTotalTaxPrice ? header.exemptionEightPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '15%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>total amount(tax included）</Text>
              <Text>割引後総合計（税込）</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.customerBillPrice ? header.customerBillPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              alignItems: 'center',
              justifyContent: 'center',
              // borderRight: '0.5px solid #000',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%', fontSize: '8px' }}><View><Text>⑩：Subject to 10% consumption tax</Text></View></Col>
                <Col right style={{ flexBasis: '17%', marginRight: '5px' }}><View><Text>{`¥${header.exemptionTenPercentTotalPrice ? header.exemptionTenPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%', fontSize: '8px' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col right style={{ flexBasis: '19%', marginRight: '5px' }}><View><Text>{`¥${header.exemptionTenPercentTotalTaxPrice ? header.exemptionTenPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{ flexBasis: '32%' }} />
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <View><Text>■SPECIAL NOTES AND INSTRUCTIONS：</Text></View>
            <Col borderTop borderRight borderBottom borderLeft left style={{ paddingLeft: '4px', justifyContent: 'start', minHeight: '40px' }}>
              {header.remarks && header.remarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <View><Text>■Remarks ：</Text></View>
            <Col borderTop borderRight borderBottom borderLeft left style={{ paddingLeft: '4px', justifyContent: 'start', minHeight: '40px' }}>
              {header.poNumber && header.poNumber.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Box>
          <Box
            style={{
              marginTop: '20px',
            }}
          >
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'center',
                height: '40px',
                backgroundColor: '#E7E6E6',
                textAlign: 'center',
                borderLeft: '1px solid #000',
                borderRight: '1px solid #000',
                borderBottom: '1px solid #000',
              }}
            >
              <Text>
                Transfer destination：
                {header.accountInfo}
              </Text>
              <Text>
                ※Please note that the transfer fee will be borne by the customer.
              </Text>
            </Col>
          </Box>
        </Root>
      </Page>
    </Document>
  );
}
