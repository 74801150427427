import React, { useState, useEffect } from 'react';
import {
  FormControlLabel, Radio,
} from '@mui/material';
import HttpConnection from '../../utils/http-connection';
import { REST_URL } from '../../constants/serverUrls';
import { FormRadioGroup } from './Base';

export default function ServiceType(props) {
  const {
    error,
    value,
    onChange,
  } = props;
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/codes`, {
      page: 0, records: 50, id: value, first: true, codeCategory: 'service_type',
    });
    const tmpOptions = tmpData.content;
    tmpOptions.splice(tmpOptions.findIndex((option) => option.id === 3), 1);

    setOptions(tmpOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormRadioGroup
      row
      value={value}
      name="serviceTypeCode"
      onChange={onChange}
      error={error}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={option.name}
        />
      ))}
    </FormRadioGroup>
  );
}
