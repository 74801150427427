import { createSlice } from '@reduxjs/toolkit';

export const specialFeatureAttachedSlice = createSlice({
  name: 'specialFeatureAttached',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
  },
  reducers: {
    getContents: () => {},
    getContentsSuccess: (state, action) => {
      state.contents = action.payload;
    },
    getContent: () => {},
    getContentSuccess: (state, action) => {
      state.content = action.payload;
    },
    deleteContent: (state) => {
      state.isContentDeleteSuccess = false;
    },
    deleteContentSuccess: (state) => {
      state.isContentDeleteSuccess = true;
    },
    saveContent: (state) => {
      state.isSaveContentSuccess = false;
    },
    saveContentSuccess: (state, action) => {
      state.isSaveContentSuccess = true;
      state.saveContentId = action.payload;
    },
    getPlans: () => {},
    getPlansSuccess: (state, action) => {
      state.plans = action.payload;
    },
    getPlan: () => {},
    getPlanSuccess: (state, action) => {
      state.plan = action.payload;
    },
    deletePlan: (state) => {
      state.isPlanDeleteSuccess = false;
    },
    deletePlanSuccess: (state) => {
      state.isPlanDeleteSuccess = true;
    },
    savePlan: (state) => {
      state.isSavePlanSuccess = false;
    },
    savePlanSuccess: (state, action) => {
      state.isSavePlanSuccess = true;
      state.savePlanId = action.payload;
    },
    getShops: () => {},
    getShopsSuccess: (state, action) => {
      state.shops = action.payload;
    },
    getShop: () => {},
    getShopSuccess: (state, action) => {
      state.shop = action.payload;
    },
    deleteShop: (state) => {
      state.isShopDeleteSuccess = false;
    },
    deleteShopSuccess: (state) => {
      state.isShopDeleteSuccess = true;
    },
    saveShop: (state) => {
      state.isSaveShopSuccess = false;
    },
    saveShopSuccess: (state, action) => {
      state.isSaveShopSuccess = true;
      state.saveShopId = action.payload;
    },
    getAreas: () => {},
    getAreasSuccess: (state, action) => {
      state.areas = action.payload;
    },
    saveArea: (state) => {
      state.isSaveAreaSuccess = false;
    },
    saveAreaSuccess: (state) => {
      state.isSaveAreaSuccess = true;
    },
    resetFlg: (state) => {
      state.isContentDeleteSuccess = false;
      state.isSaveContentSuccess = false;
      state.isSavePlanSuccess = false;
      state.isPlanDeleteSuccess = false;
      state.isSaveShopSuccess = false;
      state.isShopDeleteSuccess = false;
      state.isSaveAreaSuccess = false;
    },
  },
});

export const specialFeatureAttachedActions = specialFeatureAttachedSlice.actions;

export default specialFeatureAttachedSlice.reducer;
