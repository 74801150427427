import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityAttachActions } from '../../../../../redux/opportunity/opportunity/opportunityAttachState';
import { SuccessSnackbar } from '../../../../../components/atoms/Base';
import FileUploadDialog from './FileUploadDialog';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import FilePreviewDialog from '../../../../../components/templates/FilePreviewDialog';

const headCells = [
  { id: 'fileName', label: '件名', sortId: 'file_name' },
  { id: 'createdAt', label: '作成日', sortId: 'created_at' },
  { id: 'createdBy', label: '作成者', sortId: 'created_by' },
];

export default function DeliveryFileTable(props) {
  const [data, setData] = useState({ content: [], totalElements: 0 });
  const [openedMenuRowId, setOpenedMenuRowId] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchCondition, setSearchCondition] = useState({
    page: 0, records: 50, order: 'desc', orderBy: 'created_at',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const fileDatas = useSelector((state) => state.opportunityAttachStore.deliveryDatas);
  const isSaveSuccess = useSelector((state) => state.opportunityAttachStore.isSaveDeliverySuccess);
  const isDeleteSuccess = useSelector(
    (state) => state.opportunityAttachStore.isDeleteDeliverySuccess,
  );
  const fileUrl = useSelector((state) => state.opportunityAttachStore.url);

  const {
    isNotEdit, isNotDelete,
    opportunityId,
    opportunityNum,
  } = props;

  const search = (condition) => {
    dispatch(opportunityAttachActions.getDeliveryDatas({ ...condition, oppId: opportunityId }));
  };

  useEffect(() => {
    if (opportunityId) {
      dispatch(opportunityAttachActions.getDeliveryDatas(
        { ...searchCondition, oppId: opportunityId },
      ));
    }
  }, [opportunityId]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setOpenSuccess(true);
      search(searchCondition);
      dispatch(opportunityAttachActions.resetFlg());
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isSaveSuccess) {
      setSuccessMessage('保存しました');
      setOpenSuccess(true);
      search(searchCondition);
      dispatch(opportunityAttachActions.resetFlg());
      setIsOpenDialog(false);
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (fileUrl && loading) {
      setUrl(fileUrl);
      setLoading(false);
    }
  }, [fileUrl]);

  useEffect(() => {
    search(searchCondition);
  }, []);
  useEffect(() => {
    if (opportunityId && fileDatas) {
      setData(fileDatas);
    }
  }, [fileDatas]);

  const onDelete = (id) => {
    const ids = [id];
    dispatch(opportunityAttachActions.deleteDeliveryData({ ids }));
    setOpenedMenuRowId('');
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy: orderBy || 'created_at',
      order: (orderBy || searchCondition.orderBy === 'created_at') ? order : 'desc',
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onUpload = () => {
    setOpenedMenuRowId(null);
    setIsOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenedMenuRowId(null);
    setIsOpenDialog(false);
  };

  const onEdit = (_, rowId) => {
    setOpenedMenuRowId(rowId);
    setIsOpenDialog(true);
  };

  const getFile = (row) => {
    const fileForm = {
      filePath: row.filePath,
    };
    dispatch(opportunityAttachActions.getUrl(fileForm));
    setUrl('');
    setLoading(true);
    setOpenPreview(true);
  };

  return (
    <div>
      <DataTableWithMenu
        rows={data.content || []}
        headCells={headCells}
        onAddOpen={onUpload}
        procName="ファイル"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={data.totalElements}
        buttonTargets={[{ label: 'fileName', id: 'fileName' }]}
        buttonFunc={getFile}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onDelete={onDelete}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
        onEdit={onEdit}
      />
      <FileUploadDialog
        openedMenuRowId={openedMenuRowId}
        isOpen={isOpenDialog}
        onClose={onCloseDialog}
        opportunityNum={opportunityNum}
        opportunityId={opportunityId}
      />
      <SuccessSnackbar
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        message={successMessage}
      />
      <FilePreviewDialog
        isOpen={openPreview}
        onClose={() => { setOpenPreview(false); opportunityAttachActions.resetFlg(); }}
        loading={loading}
        url={url}
      />
    </div>
  );
}
