import * as React from 'react';
import {
  Box, Button,
} from '@mui/material';
import {
  AddLink as AddLinkIcon, AddPhotoAlternate as AddPhotoAlternateIcon,
  TextIncrease as TextIncreaseIcon, PostAdd as PostAddIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon, AddRoad as AddRoadIcon,
} from '@mui/icons-material';

const actions = [
  { icon: <AddLinkIcon />, name: 'リンク', id: 1 },
  { icon: <AddPhotoAlternateIcon />, name: '画像', id: 2 },
  { icon: <TextIncreaseIcon />, name: 'テキスト', id: 3 },
  { icon: <PostAddIcon />, name: '関連幹事のコツ', id: 4 },
  { icon: <PlaylistAddCheckIcon />, name: '目次', id: 5 },
  { icon: <AddRoadIcon />, name: 'スペース', id: 6 },
];

export default function AddContent(props) {
  const onLocalAdd = (_, id) => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
    props.onAdd(id);
  };

  return (
    <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="2px solid rgba(0, 0, 0, .125)" mt={2}>
      <Box mb={1} fontWeight="bold">コンテンツ追加</Box>
      {actions.map((action) => (
        <Button key={action.id} size="small" style={{ marginLeft: '10px' }} variant="contained" startIcon={action.icon} onClick={(e) => onLocalAdd(e, action.id)}>
          {action.name}
        </Button>
      ))}
    </Box>
  );
}
