import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button,
} from '@mui/material';
import BlogDetailInfo from './BlogDetailInfo';
import { SuccessSnackbar, withParams } from '../../../components/atoms/Base';
import { blogActions } from '../../../redux/pageSetting/blog/blogState';
import { blogContentActions } from '../../../redux/pageSetting/blog/blogContentState';
import DisplayContent from './content/DisplayContent';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import DeleteDialog from '../../../components/templates/DeleteDialog';
import EditContent from './content/EditContent';

class BlogDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;
    if (id) {
      props.dispatch(blogActions.getData({ id }));
      props.dispatch(blogContentActions.getDatas({ id }));
    }
    let isSuccessOpen = false;
    if (props.blogInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(blogActions.resetFlg());
    }

    this.state = {
      id,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      contents: [],
      isOpenDeleteDialog: false,
      deleteContentId: '',
      isSuccessOpen,
      isContentEdit: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blogContentInfo.datas !== this.props.blogContentInfo.datas) {
      if (this.props.blogContentInfo.datas) {
        this.setContents(this.props.blogContentInfo.datas);
      }
    }
    if (prevProps.blogContentInfo.isSaveSuccess !== this.props.blogContentInfo.isSaveSuccess) {
      if (this.props.blogContentInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    const { id } = this.state;
    this.props.dispatch(blogContentActions.resetFlg());
    this.setState({ isSuccessOpen: true, isContentEdit: false });
    this.props.dispatch(blogContentActions.getDatas({ id }));
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onEdit = () => {
    this.setState({ isContentEdit: true });
  };

  onCancelEdit = () => {
    this.setState({ isContentEdit: false });
  };

  onDeleteContent = () => {
    this.setState({ isOpenDeleteDialog: false });
    const { deleteContentId } = this.state;
    this.props.dispatch(blogContentActions.deleteDatas({ ids: [deleteContentId] }));
  };

  setContents = (contents) => {
    this.setState({ contents });
  };

  render() {
    const {
      contents,
      id,
      isDisabled,
      isOpenDeleteDialog,
      isSuccessOpen,
      isContentEdit,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          {!isContentEdit && (
            <Grid item xs={12} md={id ? 3 : 12}>
              <BlogDetailInfo id={id} isDisabled={isDisabled} />
            </Grid>
          )}
          {id && (
            <Grid item xs={12} md={isContentEdit ? 12 : 9}>
              {!isContentEdit && (
                <>
                  <Box position="sticky" top={90} left={0} zIndex={99} mb={1} textAlign="right" width="100%" px={1}>
                    <Button color="primary" size="small" variant="contained" onClick={this.onEdit}>編集</Button>
                  </Box>
                  <DisplayContent datas={contents} />
                </>
              )}
              {isContentEdit && (
                <EditContent initialDatas={contents} onClose={this.onCancelEdit} blogId={id} />
              )}
            </Grid>
          )}
        </Grid>
        <DeleteDialog
          open={isOpenDeleteDialog}
          onClose={this.onClose}
          procName="幹事のコツコンテンツ"
          onDelete={this.onDeleteContent}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  blogInfo: state.blogStore,
  blogContentInfo: state.blogContentStore,
});

export default withParams(connect(mapStateToProps)(BlogDetail));
