import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { trashActions } from '../../../redux/systemSetting/trash/trashState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormErrorText } from '../../../components/atoms/Base';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import TrashRestorDialog from './TrashRestorDialog';
import SearchSelectBox from './SearchSelectBox';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  target: 'contact',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

class TrashList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '対象データ', sortId: 'name' },
      { id: 'deletedAt', label: '削除日時', sortId: 'deleted_at' },
      { id: 'deletedUserName', label: '削除ユーザー', sortId: 'deleted_user_name' },
    ];

    this.targets = [
      { id: 'contact', name: '問合せ', category: '案件管理' },
      { id: 'opportunity', name: '案件', category: '案件管理' },
      { id: 'estimate', name: '見積', category: '案件管理' },
      { id: 'estimateDetail', name: '見積明細', category: '案件管理' },
      { id: 'order', name: '注文', category: '案件管理' },
      { id: 'orderDetail', name: '注文明細', category: '案件管理' },
      { id: 'claim', name: '請求', category: '案件管理' },
      { id: 'claimDetail', name: '請求明細', category: '案件管理' },
      { id: 'customer', name: '会員', category: '案件管理' },
      { id: 'account', name: '加盟企業', category: '店舗管理' },
      { id: 'shop', name: '店舗', category: '店舗管理' },
      { id: 'shopOption', name: '店舗プランオプション', category: '店舗管理' },
      { id: 'shopDelivery', name: '店舗配送条件', category: '店舗管理' },
      { id: 'shopHoliday', name: '店舗休日', category: '店舗管理' },
      { id: 'shopCancelRate', name: '店舗キャンセル規定', category: '店舗管理' },
      { id: 'shopNotice', name: '店舗お知らせ', category: '店舗管理' },
      { id: 'plan', name: 'プラン', category: '店舗管理' },
      { id: 'planOption', name: 'プランオプション', category: '店舗管理' },
      { id: 'vendor', name: '業者', category: '店舗管理' },
      { id: 'deliveryAgency', name: '配送代行会社', category: '店舗管理' },
      { id: 'review', name: '口コミ', category: 'ページ設定' },
      { id: 'specialFeature', name: '特集', category: 'ページ設定' },
      { id: 'specialFeatureContent', name: '特集コンテンツ', category: 'ページ設定' },
      { id: 'specialFeaturePlan', name: '特集プラン', category: 'ページ設定' },
      { id: 'specialFeatureShop', name: '特集店舗', category: 'ページ設定' },
      { id: 'specialFeatureArea', name: '特集エリア', category: 'ページ設定' },
      { id: 'illustration', name: '実例', category: 'ページ設定' },
      { id: 'blog', name: '幹事のコツ', category: 'ページ設定' },
      { id: 'role', name: '権限', category: 'システム設定' },
      { id: 'loginUser', name: 'ログインユーザー', category: 'システム設定' },
      { id: 'pointRule', name: 'ポイントルール', category: 'システム設定' },
      { id: 'mailTemplate', name: 'メールテンプレート', category: 'システム設定' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.trashInfo.searchCondition) {
      searchCondition = props.trashInfo.searchCondition;
    }

    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      errorMessage: '',
      row: {},
      isOpen: false,
    };

    this.props.dispatch(trashActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trashInfo.datas !== this.props.trashInfo.datas) {
      if (this.props.trashInfo.datas) {
        this.setDatas(this.props.trashInfo.datas);
      }
    }
  }

  search = (searchCondition) => {
    this.props.dispatch(trashActions.setSearchCondition(searchCondition));
    this.props.dispatch(trashActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    let tempErrorMessage = '';
    if (!value) tempErrorMessage = '選択して下さい';
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition, errorMessage: tempErrorMessage });
  };

  onClickModalClose = () => {
    this.setState({ isOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    let tempErrorMessage = '';
    if (!searchCondition.target) {
      tempErrorMessage = '選択して下さい';
    }
    if (tempErrorMessage) {
      this.setState({ errorMessage: tempErrorMessage });
      return;
    }
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    if (!tempErrorMessage) this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onRestor = (_, row) => {
    this.setState({ row, isOpen: true });
  };

  onClose = () => {
    this.setState({ row: {}, isOpen: false });
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      row,
      isOpen,
      errorMessage,
      isDisabled,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          simpleAreaOneText="対象データ"
          reSearch={this.reSearch}
          targetScreen="trash"
          condition={searchCondition}
          simpleAreaOne={(
            <Grid item xs={12} md={12}>
              <SearchSelectBox
                disableClearable
                name="target"
                options={this.targets}
                value={searchCondition.target}
                onChange={this.onChange}
                error={!!errorMessage}
              />
              <FormErrorText>{errorMessage}</FormErrorText>
            </Grid>
          )}
        />
        <DataTableWithMenu
          rows={datas.content || []}
          headCells={this.headCells}
          procName="ごみ箱"
          isNotEdit={isDisabled}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          secondFuncText="復元する"
          secondFunc={this.onRestor}
          isNotAdd
        />
        <TrashRestorDialog
          isOpen={isOpen}
          onClickModalClose={this.onClickModalClose}
          row={row}
          searchCondition={searchCondition}
          onClose={this.onClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  trashInfo: state.trashStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(TrashList);
