import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { roleDetailActions } from './roleDetailState';
import {
  getDatas, getDataById, saveData, updateData,
} from '../../../services/base.service';

function* getScreens({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.ROLE.ROLE_SCREEN, payload);
    yield put(roleDetailActions.getScreensSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.ROLE.ROLE_ID, payload);
    yield put(roleDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveRole({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.ROLE.ROLE, payload);
    yield put(roleDetailActions.saveRoleSuccess(result));
  } catch (_) { }
}

function* updateScreens({ payload }) {
  try {
    yield call(updateData, REST_API.SYSTEM_SETTING.ROLE.ROLE_ID, payload);
    yield put(roleDetailActions.updateScreensSuccess());
  } catch (_) { }
}

function* copy({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.ROLE.ROLE_COPY, payload);
    yield put(roleDetailActions.copySuccess(result));
  } catch (_) { }
}

function* roleListSaga() {
  yield takeLatest('roleDetail/getScreens', getScreens);
  yield takeLatest('roleDetail/getData', getData);
  yield takeLatest('roleDetail/saveRole', saveRole);
  yield takeLatest('roleDetail/updateScreens', updateScreens);
  yield takeLatest('roleDetail/copy', copy);
}

export default roleListSaga;
