import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { orderActions } from './orderListState';
import {
  getDatas, deleteDatas, getDataById, saveData,
} from '../../../services/base.service';

function* getOrderDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ORDER.ORDER, payload);
    yield put(orderActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getPdfDatas({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.REPORT.PURCHASE_ORDER, payload);
    yield put(orderActions.getPdfDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ORDER.ORDER_CSV, payload);
    yield put(orderActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteOrderDatas({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.ORDER.ORDER, payload);
    yield put(orderActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ORDER.ORDER_ID, payload);
    yield put(orderActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveOrderData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ORDER.ORDER, payload);
    yield put(orderActions.saveDataSuccess(result));
  } catch (_) { }
}

function* sendText({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ORDER.ORDER_SEND_TEXT, payload);
    yield put(orderActions.sendTextSuccess(result));
  } catch (_) { }
}

function* orderListSaga() {
  yield takeLatest('order/getDatas', getOrderDatas);
  yield takeLatest('order/getPdfDatas', getPdfDatas);
  yield takeLatest('order/getCsvDatas', getCsvDatas);
  yield takeLatest('order/deleteData', deleteOrderDatas);
  yield takeLatest('order/getData', getData);
  yield takeLatest('order/saveData', saveOrderData);
  yield takeLatest('order/sendText', sendText);
}

export default orderListSaga;
