import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, DialogActions, Box,
} from '@mui/material';
import Dialog from '../../../../components/atoms/Dialog';
import Validation from '../validation';
import DataGrid from '../../../../components/templates/DataGrid';
import { claimAttachActions } from '../../../../redux/opportunity/claim/claimAttachState';
import SystemInfo from '../../../../components/templates/SystemInfo';
import { DialogContent, FormErrorText } from '../../../../components/atoms/Base';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';

const baseForm = {
  id: '',
  orderId: '',
  claimId: '',
  updateAt: '',
};

export default function AddClaimOrderRelationDialog(props) {
  const [form, setForm] = React.useState(baseForm);
  const [errorMessages, setErrorMessages] = React.useState(baseForm);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.claimAttachStore.data);
  const {
    isOpen, onClose, claimId, id, opportunityId,
  } = props;

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (isOpen) {
      setErrorMessages(baseForm);
      setForm({ ...baseForm, claimId, id });
      if (id) {
        dispatch(claimAttachActions.getRelationData({ id }));
      }
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.relationValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) {
      return;
    }
    const tempForm = { ...form, claimId };
    dispatch(claimAttachActions.saveRelationData(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setForm(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.relationValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title={`請求注文リレーションの${id ? '編集' : '追加'}`} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>

        <DataGrid title="注文" isEdit isRequired>
          <DataSelectList
            name="orderId"
            target="orders"
            parameter={{ opportunityId }}
            value={form.orderId}
            onChange={onChange}
            error={!!errorMessages.orderId}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.orderId}</FormErrorText>
        </DataGrid>
        {id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
