import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, TableRow, Table, TableCell, Paper, TableHead, TableBody,
  TableContainer,
} from '@mui/material';
import { homeActions } from '../../redux/home/homeState';
import TableHeaderCell from '../../components/atoms/TableHeaderCell';

const DEFAULT_SEARCH_CONDITION = {
  order: 'asc',
  orderBy: '',
};

export default function OpportunityStatus() {
  const dispatch = useDispatch();
  const [datas, setDatas] = React.useState([]);
  const [searchCondition, setSearchCondition] = React.useState(DEFAULT_SEARCH_CONDITION);
  const dbDatas = useSelector((state) => state.homeStore.opportunityStatus);

  const headCells = [
    { id: 'salesUserName', label: '名前', sortId: 'sales_user_name' },
    {
      id: 'unsupportedNumber', label: '未対応数', numeric: true, sortId: 'unsupported_number',
    },
    {
      id: 'duringNumber', label: '対応中数', numeric: true, sortId: 'during_number',
    },
    {
      id: 'totalPrice', label: '金額', numeric: true, sortId: 'total_price',
    },
  ];

  const search = (condition) => {
    dispatch(homeActions.getOpportunityStatus(condition));
  };

  React.useEffect(() => {
    search(searchCondition);
  }, []);

  React.useEffect(() => {
    if (dbDatas && dbDatas.length > 0) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <Box>
      {datas.length > 0 && (
        <Paper elevation={3}>
          <Box display="flex" pt={2} pl={2} style={{ marginBottom: '10px' }}>
            <Box sx={{ fontWeight: 'bold' }}>案件対応状況</Box>
          </Box>
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table
              sx={{
                bgcolor: 'background.paper',
              }}
              stickyHeader
            >
              <TableHead>
                <TableRow style={{ height: '41px' }}>
                  {headCells.map((headCell) => (
                    <TableHeaderCell
                      headCell={headCell}
                      orderBy={searchCondition.orderBy}
                      order={searchCondition.order}
                      key={headCell.id}
                      handleRequestSort={handleRequestSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.salesUserName}</TableCell>
                    <TableCell align="right">{data.unsupportedNumber}</TableCell>
                    <TableCell align="right">{data.duringNumber}</TableCell>
                    <TableCell align="right">{data.totalPrice.toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}
