import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { shopDetailActions } from './shopDetailState';
import { REST_API } from '../../../constants/serverUrls';
import {
  deleteDatas,
  getDataById,
  getDatas,
  saveData,
  uploadFiles,
} from '../../../services/base.service';

function* getOptionData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_ATTACHED.OPTION, payload);
    yield put(shopDetailActions.getOptionDataSuccess(result));
  } catch (_) { }
}

function* getOptionDataById({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP_ATTACHED.OPTION_ID, payload);
    yield put(shopDetailActions.getOptionDataByIdSuccess(result));
  } catch (_) { }
}

function* saveOptionData({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFile') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
    formData.append('imageFile', payload.imageFile || null);
    const result = yield call(uploadFiles, REST_API.SHOP.SHOP_ATTACHED.OPTION, formData);
    yield put(shopDetailActions.saveOptionDataSuccess(result));
  } catch (_) { }
}

function* deleteOption({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.SHOP_ATTACHED.OPTION, payload);
    yield put(shopDetailActions.deleteOptionSuccess(result));
  } catch (_) { }
}

function* getDeliveryData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_ATTACHED.DELIVERY, payload);
    yield put(shopDetailActions.getDeliveryDataSuccess(result));
  } catch (_) { }
}

function* getDeliveryDataById({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP_ATTACHED.DELIVERY_ID, payload);
    yield put(shopDetailActions.getDeliveryDataByIdSuccess(result));
  } catch (_) { }
}

function* saveDeliveryData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.SHOP_ATTACHED.DELIVERY, payload);
    yield put(shopDetailActions.saveDeliveryDataSuccess(result));
  } catch (_) { }
}

function* deleteDeliveryDataById({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.SHOP_ATTACHED.DELIVERY, payload);
    yield put(shopDetailActions.deleteDeliveryDataSuccess(result));
  } catch (_) { }
}

function* getHolidayData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopDetailActions.getHolidayDataSuccess(result));
  } catch (_) { }
}

function* getHolidayDataById({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP_ATTACHED.HOLIDAY_ID, payload);
    yield put(shopDetailActions.getHolidayDataByIdSuccess(result));
  } catch (_) { }
}

function* saveHolidayData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopDetailActions.saveHolidayDataSuccess(result));
  } catch (_) { }
}

function* deleteHolidayData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopDetailActions.deleteHolidayDataSuccess(result));
  } catch (_) { }
}

function* getCancelData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP_ATTACHED.CANCEL_RATE_ID, payload);
    yield put(shopDetailActions.getCancelDataSuccess(result));
  } catch (_) { }
}

function* getCancelDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP_ATTACHED.CANCEL_RATE, payload);
    yield put(shopDetailActions.getCancelDatasSuccess(result));
  } catch (_) { }
}

function* saveCancelData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.SHOP_ATTACHED.CANCEL_RATE, payload);
    yield put(shopDetailActions.saveCancelDataSuccess(result));
  } catch (_) { }
}

function* deleteCancelData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.SHOP_ATTACHED.CANCEL_RATE, payload);
    yield put(shopDetailActions.deleteCancelDataSuccess(result));
  } catch (_) { }
}

function* shopDetailSaga() {
  yield takeLatest('shopDetail/getOptionData', getOptionData);
  yield takeLatest('shopDetail/saveOptionData', saveOptionData);
  yield takeLatest('shopDetail/deleteOption', deleteOption);
  yield takeLatest('shopDetail/getOptionDataById', getOptionDataById);
  yield takeLatest('shopDetail/getDeliveryData', getDeliveryData);
  yield takeLatest('shopDetail/getDeliveryDataById', getDeliveryDataById);
  yield takeLatest('shopDetail/saveDeliveryData', saveDeliveryData);
  yield takeLatest('shopDetail/deleteDeliveryData', deleteDeliveryDataById);
  yield takeLatest('shopDetail/getHolidayData', getHolidayData);
  yield takeLatest('shopDetail/getHolidayDataById', getHolidayDataById);
  yield takeLatest('shopDetail/saveHolidayData', saveHolidayData);
  yield takeLatest('shopDetail/deleteHolidayData', deleteHolidayData);
  yield takeLatest('shopDetail/getCancelDatas', getCancelDatas);
  yield takeLatest('shopDetail/getCancelData', getCancelData);
  yield takeLatest('shopDetail/saveCancelData', saveCancelData);
  yield takeLatest('shopDetail/deleteCancelData', deleteCancelData);
}

export default shopDetailSaga;
