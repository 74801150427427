import { createSlice } from '@reduxjs/toolkit';

export const estimateSlice = createSlice({
  name: 'estimate',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
    isGetPdfDataSuccess: false,
    isSendTextSuccess: false,
    isCreateOrderSuccess: false,
  },
  reducers: {
    resetFlgs: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
      state.isGetPdfDataSuccess = false;
      state.isSendTextSuccess = false;
      state.isCreateOrderSuccess = false;
    },
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    getPdfData: (state) => {
      state.isGetPdfDataSuccess = false;
    },
    getPdfDataSuccess: (state, action) => {
      state.isGetPdfDataSuccess = true;
      state.pdfData = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.id = action.payload;
    },
    createOrder: (state) => {
      state.isCreateOrderSuccess = false;
    },
    createOrderSuccess: (state, action) => {
      state.orderId = action.payload;
      state.isCreateOrderSuccess = true;
    },
  },
});

export const estimateActions = estimateSlice.actions;

export default estimateSlice.reducer;
