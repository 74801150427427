import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'file':
        return CommonValidation.requiredArray(value);
      case 'fileName':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };
}

export default Validation;
