import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FireIconTitle } from '../../atoms/Base';

export default function SmartPhoneTableWithMenu(props) {
  const {
    row, headCells, secondFuncText, secondFunc, url,
    onDelete, onEdit, isNotDelete, isNotEdit, onShare,
    setOpenedMenuRowId, setOpenedMenuRow, setOpenDelete,
  } = props;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(url + row.id);
  };

  return (
    <TableCell sx={{ padding: 1 }}>
      <Box textAlign="right" sx={{ minHeight: '45px' }}>
        {url && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={onClick}
          >
            詳細へ
          </Button>
        )}
        {secondFunc && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={(e) => secondFunc(e, row)}
          >
            {secondFuncText}
          </Button>
        )}
        {(onDelete && row.deleteable && !isNotDelete) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              setOpenDelete(true); setOpenedMenuRowId(row.id); setOpenedMenuRow(row);
            }}
          >
            削除
          </Button>
        )}
        {(onEdit && !isNotEdit) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={(e) => {
              onEdit(e, row.id, row);
            }}
          >
            編集
          </Button>
        )}
        {(onShare && !isNotEdit) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={(e) => {
              onShare(e, row.id, row);
            }}
          >
            共有の変更
          </Button>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px' }}>{`${headCell.label}`}</TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  {headCell.numeric && typeof row[headCell.id] === 'number' && !Number.isNaN(row[headCell.id])
                    ? row[headCell.id].toLocaleString()
                    : row[headCell.id]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FireIconTitle checkDateTime={row.fireComplianceDt} />
    </TableCell>
  );
}
