import CommonValidation from '../../../utils/common.validation';

const checkNickname = (form) => {
  if (!form.customerId && !form.nickname) return '入力してください';
  return '';
};

class Validation {
  static nickNameValidate = (type, form) => {
    switch (type) {
      case 'nickname':
        return checkNickname(form);
      default:
        return '';
    }
  };

  static formValidate = (type, value) => {
    switch (type) {
      case 'title':
        return CommonValidation.requiredValue(value);
      case 'grantedPoint':
      case 'periodDays':
        return CommonValidation.requiredNumber(value);
      case 'grantedDate':
        return CommonValidation.requiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
