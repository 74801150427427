import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'estimateStatusCode':
      case 'productInputTypeCode':
      case 'taxRate':
      case 'opportunityId':
        return CommonValidation.requiredSelect(value);
      case 'displayOrder':
        return CommonValidation.notMinusNumber(value);
      case 'quantity':
      case 'unitPrice':
        return CommonValidation.requiredNotMinusNumber(value);
      case 'expireDate':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
