import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, DialogActions, Box,
} from '@mui/material';
import Dialog from '../../../../../components/atoms/Dialog';
import Validation from '../validation';
import DataGrid from '../../../../../components/templates/DataGrid';
import { opportunityAttachActions } from '../../../../../redux/opportunity/opportunity/opportunityAttachState';
import NumberInput from '../../../../../components/atoms/NumberInput';
import SystemInfo from '../../../../../components/templates/SystemInfo';
import { DialogContent, TimePicker, FormErrorText } from '../../../../../components/atoms/Base';

const baseForm = {
  chargeDays: '',
  chargeTime: '',
  chargeRate: '',
  opportunityId: '',
  updateAt: '',
};

export default function AddCancelDialog(props) {
  const [form, setForm] = React.useState(baseForm);
  const [errorMessages, setErrorMessages] = React.useState(baseForm);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.opportunityAttachStore.data);
  const {
    isOpen, onClose, opportunityId, id,
  } = props;

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (isOpen) {
      setErrorMessages(baseForm);
      setForm({ ...baseForm, opportunityId, id });
      if (id) {
        dispatch(opportunityAttachActions.getCancelData({ id }));
      }
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.cancelRateValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) {
      return;
    }
    const tempForm = { ...form, opportunityId };
    dispatch(opportunityAttachActions.saveCancelData(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setForm(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.cancelRateValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title={`キャンセル規定の${id ? '編集' : '追加'}`} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <DataGrid title="チャージ日" isEdit isRequired>
          <NumberInput value={form.chargeDays} name="chargeDays" onChange={onChange} error={!!errorMessages.chargeDays} endAdornment="日前" />
          <FormErrorText>{errorMessages.chargeDays}</FormErrorText>
        </DataGrid>
        <DataGrid title="チャージ時間" isEdit isRequired>
          <TimePicker value={form.chargeTime} name="chargeTime" onChange={onChange} error={!!errorMessages.chargeTime} endAdornment="時以降" fullWidth />
          <FormErrorText>{errorMessages.chargeTime}</FormErrorText>
        </DataGrid>

        <DataGrid title="キャンセル率" isEdit isRequired>
          <NumberInput value={form.chargeRate} name="chargeRate" onChange={onChange} error={!!errorMessages.chargeRate} max={100} endAdornment="%" />
          <FormErrorText>{errorMessages.chargeRate}</FormErrorText>
        </DataGrid>
        {id && (
          <Box mt={2} fontSize={14}><SystemInfo data={data} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
