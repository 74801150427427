import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField, InputAdornment } from '@mui/material';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onChange={(e) => {
        const { value } = e.target;
        const numericValue = value.replace(/,/g, '');
        onChange({
          target: {
            name: props.name,
            value: numericValue,
          },
        });
      }}
      thousandSeparator
    />
  );
});

export default function NumberInput(props) {
  const {
    value, onChange, error, name, isDisabled, onBlur, endAdornment, isDecimal, helperText,
  } = props;
  const handleKeyDown = (event) => {
    if ((!isDecimal && event.key === '.') || event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };
  return (
    <TextField
      error={error}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      name={name}
      fullWidth
      autoComplete="off"
      variant="outlined"
      disabled={isDisabled}
      InputProps={{
        inputComponent: NumberFormatCustom,
        endAdornment: endAdornment ? (<InputAdornment position="end">{endAdornment}</InputAdornment>) : null,
        inputProps: { maxLength: 11 },
      }}
      onKeyDown={(e) => { handleKeyDown(e); }}
    />
  );
}
