const characters = [
  { // 案件
    emailUsedTypeCode: 1,
    values: [
      { label: '案件番号', value: '%opportunityNumber%' },
      { label: 'お客様名', value: '%customerNameOpp%' },
      { label: '所属会社', value: '%companyNameOpp%' },
      { label: '支払リンク', value: '%paymentLink%' },
      { label: '領収書発行用キー', value: '%receiptKey%' },
      { label: '営業担当姓', value: '%salesUserLastName%' },
      { label: '開催日', value: '%heldDateChar%' },
    ],
  },
  { // 加盟企業
    emailUsedTypeCode: 2,
    values: [
      { label: '加盟企業名', value: '%accountName%' },
      { label: '店舗名', value: '%shopName%' },
      { label: '姓', value: '%lastName%' },
      { label: '名', value: '%firstName%' },
      { label: 'パスワード', value: '%password%' },
      { label: 'メール', value: '%email%' },
    ],
  },
  { // 配送会社
    emailUsedTypeCode: 3,
    values: [
      { label: '配送会社名', value: '%deliveryAgencyName%' },
      { label: '姓', value: '%lastName%' },
      { label: '名', value: '%firstName%' },
      { label: 'パスワード', value: '%password%' },
      { label: 'メール', value: '%email%' },
    ],
  },
  { // 店舗へ確認依頼
    emailUsedTypeCode: 7,
    values: [
      { label: 'レコードID', value: '%id%' },
      { label: '案件番号', value: '%opportunityNumber%' },
      { label: 'お客様名', value: '%customerNameOpp%' },
      { label: '所属会社', value: '%companyNameOpp%' },
      { label: '営業担当姓', value: '%salesUserLastName%' },
      { label: '開催日', value: '%heldDateChar%' },
    ],
  },
  { // 自動店舗キャンセル
    emailUsedTypeCode: 8,
    values: [
      { label: '案件番号', value: '%opportunityNumber%' },
      { label: 'お客様名', value: '%customerNameOpp%' },
      { label: '所属会社', value: '%companyNameOpp%' },
      { label: '営業担当姓', value: '%salesUserLastName%' },
      { label: '開催日', value: '%heldDateChar%' },
    ],
  },
  { // 管理パスワードリセット
    emailUsedTypeCode: 14,
    values: [
      { label: 'リセット用キー', value: '%token%' },
      { label: '名前', value: '%name%' },
    ],
  },
  { // 店舗パスワードリセット
    emailUsedTypeCode: 15,
    values: [
      { label: 'リセット用キー', value: '%token%' },
      { label: '名前', value: '%name%' },
    ],
  },
  { // 配送代行パスワードリセット
    emailUsedTypeCode: 16,
    values: [
      { label: 'リセット用キー', value: '%token%' },
      { label: '名前', value: '%name%' },
    ],
  },
];

export default characters;
