import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import {
  getDatas, getDataById, uploadFiles, deleteDatas, saveData,
} from '../../../services/base.service';
import { planActions } from './planState';

function* getDataList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.PLAN.PLAN, payload);
    yield put(planActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.PLAN.PLAN_CSV, payload);
    yield put(planActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SHOP.PLAN.PLAN, payload);
    yield put(planActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.PLAN.PLAN_ID, payload);
    yield put(planActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      requestData[key] = value;
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    for (let i = 0; i < payload.images.length; i += 1) {
      const tmpData = payload.images[i];
      if (tmpData.isNew) {
        const files = tmpData[0];
        formData.append(`imageFiles_${tmpData.id}`, files);
      }
    }

    const result = yield call(uploadFiles, REST_API.SHOP.PLAN.PLAN, formData);
    yield put(planActions.saveDataSuccess(result));
  } catch (_) { }
}

function* copyData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.PLAN.COPY, payload);
    yield put(planActions.saveDataSuccess(result));
  } catch (_) { }
}

function* planSaga() {
  yield takeLatest('plan/getDatas', getDataList);
  yield takeLatest('plan/getCsvDatas', getCsvDatas);
  yield takeLatest('plan/deleteData', deleteData);
  yield takeLatest('plan/getData', getData);
  yield takeLatest('plan/saveData', save);
  yield takeLatest('plan/copyData', copyData);
}

export default planSaga;
