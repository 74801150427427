import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button, Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import Dialog from '../atoms/Dialog';

export default function ConfirmDialog(props) {
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const {
    open, onClose, title, content, onClick, actionText,
  } = props;

  return (
    <Dialog fullWidth maxWidth="xs" isOpen={open} onClose={onClose} title={title}>
      <DialogContent dividers>
        <Box>{content}</Box>
        <Box>よろしいですか？</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={onClick}
          loading={isLoading}
          color="primary"
        >
          {actionText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
