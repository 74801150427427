import React from 'react';
import {
  Table, TableBody, TableCell, Paper,
  TableContainer, TableHead, TableRow,
} from '@mui/material';

export default function HistoryDetailTable(props) {
  const {
    rows, headCells,
  } = props;

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.map((data) => (
                  <TableCell key={data.id}>{data.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                >
                  {Object.keys(row).filter((value) => value !== 'id' && value !== 'deleteable' && value !== 'deadlineClassification').map((key) => (
                    <TableCell key={key}>{row[key]}</TableCell>
                  ))}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
