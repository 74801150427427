import React, { useState, useEffect } from 'react';
import {
  Box, Grid, List,
  ListItemButton, ListItemText, Divider, FormControl,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FormTitle, Pagination, SearchSelectBox } from '../../atoms/Base';
import { mailTemplateActions } from '../../../redux/systemSetting/mailTemplate/mailTemplateState';
import FullScreenDialog from '../FullScreenDialog';

export default function SelectMailTemplate(props) {
  const { isOpen, onClose, onSelectMailTemplate } = props;
  const [mailTemplates, setMailTemplates] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedData, setSelectedData] = useState({
    id: null,
    cc: '',
    subject: '',
    body: '',
  });
  const [searchCondition, setSearchCondition] = useState({
    folders: [''],
    emailUsedTypeCodes: ['1', '2', '3'],
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
  });

  const dispatch = useDispatch();
  const mailTemplateDatas = useSelector((state) => state.mailTemplateStore.datas);
  const folderDatas = useSelector((state) => state.mailTemplateStore.folders);

  useEffect(() => {
    if (mailTemplateDatas && mailTemplateDatas.content) {
      setMailTemplates(mailTemplateDatas.content);
    }
  }, [mailTemplateDatas]);

  useEffect(() => {
    if (folderDatas) {
      const noSelect = [{ id: '', name: '未選択' }];
      setFolders(noSelect.concat(folderDatas));
    }
  }, [folderDatas]);

  useEffect(() => {
    if (isOpen) {
      dispatch(mailTemplateActions.getFolders({ notAuto: true }));
      dispatch(mailTemplateActions.getDatas(searchCondition));
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(mailTemplateActions.getDatas(searchCondition));
  }, [searchCondition]);

  const handleListItemClick = (_, mailTemplate) => {
    setSelectedData({
      id: mailTemplate.id,
      cc: mailTemplate.defaultCc,
      subject: mailTemplate.subject,
      body: mailTemplate.content,
    });
  };

  const onChangeFolder = (event) => {
    const { value } = event.target;
    setSearchCondition({
      ...searchCondition,
      folders: [value],
    });
  };

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (e) => {
    const rowsPerPage = parseInt(e.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
  };

  return (

    <FullScreenDialog isOpen={isOpen} title="テンプレート選択" onClose={onClose} onClickAction={() => onSelectMailTemplate(selectedData)} actionName="このテンプレートを選択する" isNoPadding>
      <Grid sx={{ height: 'calc(100vh - 64px)' }} container>
        <Grid item xs={12} md={4} style={{ padding: '2em 0.5em 0px 2em' }}>
          <FormTitle title="フォルダ" />
          <FormControl>
            <SearchSelectBox
              options={folders}
              value={searchCondition.folders[0]}
              onChange={onChangeFolder}
              disableClearable
              name="selectFolder"
            />
          </FormControl>
          <List
            sx={{
              marginTop: '10px',
              overflowY: 'auto',
              height: '64vh',
            }}
          >
            {mailTemplates.map((mailTemplate) => (
              <React.Fragment key={mailTemplate.id}>
                <ListItemButton
                  selected={selectedData.id === mailTemplate.id}
                  onClick={(e) => handleListItemClick(e, mailTemplate)}
                >
                  <ListItemText primary={mailTemplate.title} />
                  <NavigateNextIcon fontSize="small" />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Pagination
            totalElements={mailTemplates.length}
            rowsPerPage={searchCondition.records}
            page={searchCondition.page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            isShort
          />
        </Grid>
        <Divider orientation="vertical" />
        <Grid item xs={12} md={7} style={{ padding: '2em 0.5em 0px 2em' }}>
          <Box mb={2}>
            <FormTitle title="デフォルトCC" />
            <div style={{ height: '45px' }}>{selectedData.cc}</div>
          </Box>
          <Box mb={2}>
            <FormTitle title="件名" />
            <div style={{ height: '45px' }}>{selectedData.subject}</div>
          </Box>
          <Box mb={2}>
            <FormTitle title="本文" />
            {selectedData.body && selectedData.body.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
          </Box>
        </Grid>
      </Grid>
    </FullScreenDialog>
  );
}
