import { createSlice } from '@reduxjs/toolkit';

export const claimSlice = createSlice({
  name: 'claim',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
    isSendSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvData = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    createPaymentLink: () => {},
    createPaymentLinkSuccess: (state, action) => {
      state.paymentLink = action.payload;
    },
    sendText: (state) => {
      state.isSendSuccess = false;
    },
    sendTextSuccess: (state) => {
      state.isSendSuccess = true;
    },
    getPdfData: (state) => {
      state.isGetPdfData = false;
    },
    getPdfDataSuccess: (state, action) => {
      state.pdfDatas = action.payload;
      state.isGetPdfData = true;
    },
    getReceiptData: (state) => {
      state.isGetReceipt = false;
    },
    getReceiptDataSuccess: (state, action) => {
      state.receiptData = action.payload;
      state.isGetReceipt = true;
    },
    createSuccess: (state) => {
      state.isCreateSuccess = true;
    },
    resetFlgs: (state) => {
      state.isSendSuccess = false;
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
      state.isGetReceipt = false;
      state.isCreateSuccess = false;
      state.isGetPdfData = false;
    },
  },
});

export const claimActions = claimSlice.actions;

export default claimSlice.reducer;
