import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'name':
      case 'price':
        return CommonValidation.requiredValue(value);
      case 'shopId':
      case 'taxRate':
      case 'planTypeCode':
      case 'serviceTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'publishDt':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };

  static optionValidate = (type, value) => {
    switch (type) {
      case 'shopPlanOptionId':
        return CommonValidation.requiredSelect(value);
      case 'displayOrder':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };
}

export default Validation;
