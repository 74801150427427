import CommonValidation from '../../utils/common.validation';

class Validation {
  static formValidate = (type, value, checkValue) => {
    switch (type) {
      case 'password':
        return CommonValidation.requiredPassword(value);
      case 'checkPassword':
        return CommonValidation.requiredPasswordCheck(value, checkValue);
      default:
        return '';
    }
  };
}

export default Validation;
