import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField,
} from '@mui/material';
import { estimateActions } from '../../../redux/opportunity/estimate/estimateState';
import {
  FormTitle, SuccessSnackbar, DatePicker, SearchSelectBox,
} from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  customerName: '',
  companyName: '',
  customerEmail: '',
  customerPhone: '',
  opportunityNumber: '',
  heldDate: '',
  shopVendorName: '',
  ball: '',
  salesUsers: [],
  statuses: [],
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'opportunityId', label: '案件ID' },
  { key: 'opportunityNumber', label: '案件番号' },
  { key: 'estimateNumber', label: '見積番号' },
  { key: 'storeId', label: '店舗ID' },
  { key: 'storeName', label: '店舗名' },
  { key: 'vendorId', label: '業者ID' },
  { key: 'vendorName', label: '業者名' },
  { key: 'expireDate', label: '有効期限' },
  { key: 'eightPercentTotalPrice', label: '8%小計(税込)' },
  { key: 'eightPercentTotalTaxPrice', label: '8%消費税' },
  { key: 'eightPercentDiscountPrice', label: '8%割引額(税込)' },
  { key: 'tenPercentTotalPrice', label: '10%小計(税込)' },
  { key: 'tenPercentTotalTaxPrice', label: '10%消費税' },
  { key: 'tenPercentDiscountPrice', label: '10%割引額(税込)' },
  { key: 'totalPrice', label: '合計金額(税込)' },
  { key: 'discountTargetPrice', label: '割引対象額(税抜)' },
  { key: 'customerBillPrice', label: '顧客請求額(税込)' },
  { key: 'pointUsagePrice', label: 'ポイント利用額' },
  { key: 'salesFeeBillPrice', label: '販売手数料請求額(税抜)' },
  { key: 'refundPrice', label: '返金対象額(税込)' },
  { key: 'commissionPrice', label: '手数料(税込)' },
  { key: 'ball', label: 'ボール' },
  { key: 'requestDetail', label: '店舗への依頼内容' },
  { key: 'destination', label: '英語用見積書送り先' },
  { key: 'estimateStatusCode', label: 'ステータスコード' },
  { key: 'estimateStatusName', label: 'ステータス名' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

const CSV_DETAIL_HEADER = [
  { key: 'detailId', label: '明細ID' },
  { key: 'productInputTypeCode', label: '商品入力タイプコード' },
  { key: 'productInputTypeName', label: '商品入力タイプ名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'freeProductName', label: '自由入力商品' },
  { key: 'shopPlanOptionId', label: 'オプションID' },
  { key: 'shopPlanOptionName', label: 'オプション名' },
  { key: 'unitPrice', label: '単価(税込)' },
  { key: 'quantity', label: '個数' },
  { key: 'taxRate', label: '税率' },
  { key: 'burdenPrice', label: 'Cqree負担額(税込)' },
  { key: 'detailTotalPrice', label: '明細合計金額(税込)' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'claimed', label: '請求対象' },
  { key: 'repaid', label: '返金対象' },
  { key: 'reportHidden', label: '帳票非表示' },
  { key: 'detailCreatedAt', label: '明細作成日時' },
  { key: 'detailCreatedByName', label: '明細作成者' },
  { key: 'detailUpdatedAt', label: '明細更新日時' },
  { key: 'detailUpdatedByName', label: '明細更新者' },
];

class EstimateList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'estimateNumber', label: '見積番号', sortId: 'estimate_number' },
      { id: 'estimateStatusName', label: 'ステータス', sortId: 'estimate_status_name' },
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'shopVendorName', label: '店舗/業者', sortId: 'shop_vendor_name' },
      {
        id: 'totalPrice', label: '税込合計金額', sortId: 'total_price', numeric: true,
      },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.estimateInfo.searchCondition) {
      searchCondition = props.estimateInfo.searchCondition;
    }

    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      download: {
        headerItems: CSV_HEADER,
        detailCsvItem: CSV_DETAIL_HEADER,
        fileName: '見積',
        done: false,
        datas: [],
      },
    };

    this.props.dispatch(estimateActions.setSearchCondition(searchCondition));
    this.props.dispatch(estimateActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateInfo.datas !== this.props.estimateInfo.datas) {
      if (this.props.estimateInfo.datas) {
        this.setDatas(this.props.estimateInfo.datas);
      }
    }
    if (prevProps.estimateInfo.csvDatas !== this.props.estimateInfo.csvDatas) {
      if (this.props.estimateInfo.csvDatas) {
        this.setCsvDatas(this.props.estimateInfo.csvDatas);
      }
    }
    if (prevProps.estimateInfo.isDeleteSuccess
      !== this.props.estimateInfo.isDeleteSuccess) {
      if (this.props.estimateInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  setSuccess = () => {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(estimateActions.setSearchCondition(searchCondition));
    this.props.dispatch(estimateActions.getDatas(searchCondition));
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(estimateActions.deleteData({ ids }));
  };

  onDownload = (showDetail) => {
    const { searchCondition } = this.state;
    this.props.dispatch(estimateActions.getCsvDatas({ ...searchCondition, showDetail }));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="estimate"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="ステータス"
          simpleAreaOne={(
            <DataMultiSelectList
              name="statuses"
              target="codes"
              parameter={{ codeCategory: 'estimate_status' }}
              values={searchCondition.statuses}
              onChange={this.onChange}
              fullWidth
            />
          )}
          simpleAreaTwoText="担当者"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="salesUsers"
              target="users"
              values={searchCondition.salesUsers}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="お客様名" />
              <TextField size="small" value={searchCondition.customerName} fullWidth name="customerName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="会社名" />
              <TextField size="small" value={searchCondition.companyName} fullWidth name="companyName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="お客様メールアドレス" />
              <TextField size="small" value={searchCondition.customerEmail} fullWidth name="customerEmail" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="お客様電話番号" />
              <TextField size="small" value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="案件番号" />
              <TextField size="small" value={searchCondition.opportunityNumber} fullWidth name="opportunityNumber" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="開催日" />
              <DatePicker value={searchCondition.heldDate} fullWidth name="heldDate" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="店舗/業者" />
              <TextField size="small" value={searchCondition.shopVendorName} fullWidth name="shopVendorName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="ボール" />
              <SearchSelectBox
                name="ball"
                options={[{ id: 'cqree', name: 'cqree' }, { id: '店舗', name: '店舗' }]}
                value={searchCondition.ball}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.ESTIMATE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          urlTargets={[{ url: detailUrl.OPPORTUNITY_DETAIL, label: 'opportunityNumber', id: 'opportunityId' }]}
          procName="見積"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateInfo: state.estimateStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(EstimateList);
