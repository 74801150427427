import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Paper,
} from '@mui/material';
import { deliveryAgencyActions } from '../../../redux/shop/deliveryAgency/deliveryAgencyState';
import { withParams, TabPanel } from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import DeliveryAgencyUserDataTable from '../deliveryAgencyUser/DeliveryAgencyUserDataTable';
import Tabs from '../../../components/templates/Tabs';

class DeliveryAgencyDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      form: { id },
    };
    if (id) {
      props.dispatch(deliveryAgencyActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryAgencyInfo.data !== this.props.deliveryAgencyInfo.data) {
      if (this.props.deliveryAgencyInfo.data) {
        this.setData(this.props.deliveryAgencyInfo.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ form: data });
  };

  render() {
    const { form } = this.state;

    return (
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{ display: { xs: 'none', md: 'block' } }}
            style={{ minHeight: '50px' }}
          />
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <DataGrid title="配送会社名" value={form.name} />
            <DataGrid title="メールアドレス" value={form.email} />
            <DataGrid title="SalesforceID" value={form.salesforceId} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Tabs
            value={0}
            tabs={['ログイン情報']}
          />
          <TabPanel>
            <DeliveryAgencyUserDataTable
              deliveryAgencyId={form.id}
              deliveryAgencyName={form.name}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  deliveryAgencyInfo: state.deliveryAgencyStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(DeliveryAgencyDetail));
