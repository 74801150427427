import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Box,
} from '@mui/material';
import { reviewActions } from '../../../redux/pageSetting/review/reviewState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SuccessSnackbar, FormSwitch } from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

export const DEFAULT_SEARCH_CONDITION = {
  reviewStatuses: [],
  opportunityNumber: '',
  shopCode: '',
  planName: '',
  imageExisted: false,
  commentExisted: false,
  tasteEvaluationStart: '',
  tasteEvaluationEnd: '',
  volumeEvaluationStart: '',
  volumeEvaluationEnd: '',
  colorfulEvaluationStart: '',
  colorfulEvaluationEnd: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'customerId', label: '会員ID' },
  { key: 'customerName', label: '会員名' },
  { key: 'nickname', label: 'ニックネーム' },
  { key: 'opportunityId', label: '案件ID' },
  { key: 'opportunityNumber', label: '案件番号' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'title', label: 'タイトル' },
  { key: 'mainAgeCode', label: '主な年代コード' },
  { key: 'mainAgeName', label: '主な年代名' },
  { key: 'sceneAttributeItemId', label: '利用シーンID' },
  { key: 'sceneAttributeItemName', label: '利用シーン名' },
  { key: 'tasteEvaluation', label: '味評価' },
  { key: 'volumeEvaluation', label: 'ボリューム評価' },
  { key: 'colorfulEvaluation', label: '色どり評価' },
  { key: 'comment', label: 'コメント' },
  { key: 'budgetPrice', label: '予算' },
  { key: 'postDate', label: '投稿日' },
  { key: 'publishDate', label: '公開日' },
  { key: 'reviewStatusCode', label: 'ステータスコード' },
  { key: 'reviewStatusName', label: 'ステータス' },
  { key: 'evaluationPublished', label: '評価を公開' },
  { key: 'commentPublished', label: 'コメントを公開' },
  { key: 'imagePublished', label: '画像を公開' },
  { key: 'grantedPoint', label: 'ポイント付与済' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class ReviewList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'customerName', label: '投稿者名', sortId: 'customer_name' },
      { id: 'opportunityNumber', label: '案件', sortId: 'opportunity_number' },
      { id: 'averageEvaluation', label: '平均評価', sortId: 'average_evaluation' },
      { id: 'commentExisted', label: 'コメント', sortId: 'comment_existed' },
      { id: 'imageExisted', label: '画像', sortId: 'image_existed' },
      { id: 'postDate', label: '投稿日', sortId: 'postDate' },
      { id: 'reviewStatusName', label: 'ステータス', sortId: 'reviewStatusName' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.reviewInfo.searchCondition) {
      searchCondition = props.reviewInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '口コミ',
        done: false,
        datas: [],
      },
    };
    this.props.dispatch(reviewActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviewInfo.datas !== this.props.reviewInfo.datas) {
      if (this.props.reviewInfo.datas) {
        this.setDatas(this.props.reviewInfo.datas);
      }
    }
    if (prevProps.reviewInfo.csvDatas !== this.props.reviewInfo.csvDatas) {
      if (this.props.reviewInfo.csvDatas) {
        this.setCsvDatas(this.props.reviewInfo.csvDatas);
      }
    }
    if (prevProps.reviewInfo.isDeleteSuccess
      !== this.props.reviewInfo.isDeleteSuccess) {
      if (this.props.reviewInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(reviewActions.setSearchCondition(searchCondition));
    this.props.dispatch(reviewActions.getDatas(searchCondition));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;

    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };

    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(reviewActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(reviewActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isDeleteDisabled,
      isEditDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="review"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="ステータス"
          simpleAreaOne={(
            <DataMultiSelectList
              name="reviewStatuses"
              target="codes"
              parameter={{ codeCategory: 'review_status' }}
              values={searchCondition.reviewStatuses}
              onChange={this.onChange}
              fullWidth
            />
          )}
          simpleAreaTwoText="案件番号"
          simpleAreaTwo={(
            <TextField
              value={searchCondition.opportunityNumber}
              fullWidth
              name="opportunityNumber"
              onChange={this.onChange}
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={4}>
              <FormTitle title="店舗コード" />
              <TextField value={searchCondition.shopCode} fullWidth name="shopCode" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormTitle title="プラン名" />
              <TextField
                value={searchCondition.planName}
                fullWidth
                name="planName"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="画像" />
              <FormSwitch
                checked={searchCondition.imageExisted}
                label="画像あり"
                name="imageExisted"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="コメント" />
              <FormSwitch
                checked={searchCondition.commentExisted}
                label="コメントあり"
                name="commentExisted"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="味" />
              <Box display="flex" alignItems="center">
                <Box>
                  <TextField value={searchCondition.tasteEvaluationStart} fullWidth name="tasteEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <TextField value={searchCondition.tasteEvaluationEnd} fullWidth name="tasteEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="ボリューム" />
              <Box display="flex" alignItems="center">
                <Box>
                  <TextField value={searchCondition.volumeEvaluationStart} fullWidth name="volumeEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <TextField value={searchCondition.volumeEvaluationEnd} fullWidth name="volumeEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="色どり" />
              <Box display="flex" alignItems="center">
                <Box>
                  <TextField value={searchCondition.colorfulEvaluationStart} fullWidth name="colorfulEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <TextField value={searchCondition.colorfulEvaluationEnd} fullWidth name="colorfulEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.REVIEW_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="口コミ"
          urlTargets={[{ url: detailUrl.OPPORTUNITY_DETAIL, label: 'opportunityNumber', id: 'opportunityId' }]}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewInfo: state.reviewStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ReviewList);
