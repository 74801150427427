import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField,
} from '@mui/material';
import { vendorDetailActions } from '../../../redux/shop/vendor/vendorDetailState';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';
import {
  RecordBox, withParams, SuccessSnackbar, FormErrorText, FormSwitch,
} from '../../../components/atoms/Base';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import detailUrl from '../../../constants/frontUrls';
import SystemInfo from '../../../components/templates/SystemInfo';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

class VendorDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    const form = {
      id,
      name: '',
      reportName: '',
      phone: '',
      invoiceTargeted: false,
      createdBy: '',
      createdAt: '',
      updatedBy: '',
      updatedAt: '',
    };

    let isSuccessOpen = false;
    if (props.vendorDetailInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(vendorDetailActions.resetFlg());
    }

    this.state = {
      id,
      isSuccessOpen,
      form,
      newForm: { ...form },
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isEdit: !id,
      errorMessages: {
        name: '',
        phone: '',
      },
    };
    if (id) {
      props.dispatch(vendorDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vendorDetailInfo.data !== this.props.vendorDetailInfo.data) {
      if (this.props.vendorDetailInfo.data) {
        this.setData(this.props.vendorDetailInfo.data);
      }
    }
    if (prevProps.vendorDetailInfo.isSaveSuccess !== this.props.vendorDetailInfo.isSaveSuccess) {
      if (this.props.vendorDetailInfo.isSaveSuccess) {
        this.setSuccess(this.props.vendorDetailInfo.saveId);
      }
    }
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { newForm, errorMessages } = this.state;

    this.setState({
      newForm: {
        ...newForm,
        [name]: value,
      },
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onClickEditvendor = () => {
    const { form } = this.state;
    this.setState({ isEdit: true });
    this.props.dispatch(vendorDetailActions.getData({ id: form.id }));
  };

  onClickSave = () => {
    const { newForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(newForm, errorMessages)) {
      return;
    }

    this.props.dispatch(vendorDetailActions.updateVendor(newForm));
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if (form.id) {
      this.props.dispatch(vendorDetailActions.resetFlg());
    }
    if (!form.id) {
      window.location.href = detailUrl.VENDOR_DETAIL + searchId;
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(vendorDetailActions.getData({ id: searchId }));
  };

  onClickCancel = () => {
    const { form } = this.state;
    this.setState({ newForm: form, isEdit: false });
  };

  setData = (data) => {
    const form = {
      ...data,
    };
    this.setState({ form, newForm: form });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      id,
      form,
      newForm,
      isSuccessOpen,
      isDisabled,
      isEdit,
      errorMessages,
    } = this.state;

    return (
      <>
        <RecordHeaderButton
          edit={isEdit}
          disabled={isDisabled}
          onEdit={this.onClickEditvendor}
        />
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={12}>
            <RecordBox>
              <DataGrid title="業者名" isRequired value={form.name} isEdit={isEdit}>
                <TextField error={!!errorMessages.name} value={newForm.name} name="name" fullWidth onChange={this.onChange} inputProps={{ maxLength: 50 }} />
                <FormErrorText>{errorMessages.name}</FormErrorText>
              </DataGrid>
              <DataGrid title="帳票表示名" isRequired value={form.reportName} isEdit={isEdit}>
                <TextField error={!!errorMessages.reportName} value={newForm.reportName} name="reportName" fullWidth onChange={this.onChange} inputProps={{ maxLength: 50 }} />
                <FormErrorText>{errorMessages.reportName}</FormErrorText>
              </DataGrid>
              <DataGrid title="電話番号" value={form.phone} isEdit={isEdit}>
                <TextField error={!!errorMessages.phone} value={newForm.phone} name="phone" fullWidth onChange={this.onChange} inputProps={{ maxLength: 20 }} />
                <FormErrorText>{errorMessages.phone}</FormErrorText>
              </DataGrid>
              <DataGrid title="インボイス" value={form.invoiceTargeted ? '対象' : null} isEdit={isEdit}>
                <FormSwitch checked={newForm.invoiceTargeted} name="invoiceTargeted" label="対象にする" onChange={this.onChange} />
              </DataGrid>
              {!isEdit && form.id && (
                <SystemInfo data={form} />
              )}
              {isEdit && (
                <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {id && (
                    <Button color="inherit" variant="contained" size="small" onClick={this.onClickCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                  )}
                  <Button variant="contained" size="small" onClick={this.onClickSave}>保存</Button>
                </Box>
              )}
            </RecordBox>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  vendorDetailInfo: state.vendorDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(VendorDetail));
