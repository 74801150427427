import React, { useState, useEffect, useRef } from 'react';
import {
  TextField, Autocomplete, CircularProgress, Button, List, ListSubheader,
} from '@mui/material';
import HttpConnection from '../../../utils/http-connection';
import { REST_URL } from '../../../constants/serverUrls';
import SelectDataDialog from './SelectDataDialog';

const CustomListboxComponent = React.forwardRef((props, ref) => (
  <List
    ref={ref}
    {...props}
    style={{ maxHeight: '300px', overflow: 'auto', position: 'relative' }}
    subheader={<li />}
  >
    {props.children}
  </List>
));

export default function DataSelectList(props) {
  const {
    error,
    value,
    onChange,
    disableClearable = false,
    isDisabled = false,
    name,
    target,
    parameter,
    exclusions,
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const prevValue = useRef(null);
  const prevName = useRef(null);
  const prevParameter = useRef(parameter);

  const fetchData = async () => {
    setLoading(true);
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, {
      ...parameter,
      page: 0,
      records: 50,
      id: value || null,
      first: true,
    });
    if (!tmpData) {
      setLoading(false);
      return;
    }
    const o = tmpData.content;
    if (tmpData.content.length !== tmpData.totalElements) {
      o.push(
        { specialOption: true, label: 'さらに見る...' },
      );
    }
    setOptions(o);
    setLoading(false);
  };

  useEffect(() => {
    if (value && !prevValue.current) {
      fetchData();
      prevValue.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (name && (!prevName.current || prevName.current !== name)) {
      fetchData();
      prevName.current = name;
    }
  }, [name]);

  useEffect(() => {
    if (prevName.current !== null
      && prevName.current === name && parameter
      && JSON.stringify(prevParameter.current) !== JSON.stringify(parameter)) {
      fetchData();
    }
    prevParameter.current = parameter;
  }, [parameter]);

  useEffect(() => {
    if (exclusions) {
      if (exclusions && Array.isArray(exclusions)) {
        setOptions(options.filter((n) => !exclusions.includes(n.id)));
      }
    }
  }, [exclusions]);

  const targetValues = options ? options.filter((row) => row.id === value) : [];
  let [targetValue] = targetValues;

  targetValue = targetValue ?? null;

  const handleClose = () => setOpen(false);

  const onSelect = (e, row) => {
    if (!options.some((option) => option.id === row.id)) {
      const newOptions = [...options];
      const insertAt = Math.max(0, newOptions.length - 1);
      newOptions.splice(insertAt, 0, row);
      setOptions(newOptions);
    }
    onChange({ target: { name, value: row.id } });
    setOpen(false);
  };

  return (
    <>
      <Autocomplete
        style={{ minWidth: '200px' }}
        size="small"
        options={options}
        ListboxComponent={CustomListboxComponent}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            autoComplete="off"
            variant="outlined"
            error={error}
            placeholder="選択してください"
          />
        )}
        renderOption={(p, option) => {
          if (option.specialOption) {
            return (
              <ListSubheader
                component="div"
                style={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 2,
                  backgroundColor: '#f5f5f5',
                  margin: 0,
                }}
                key="dataMore"
              >
                <Button style={{ width: '100%' }} onClick={() => setOpen(true)}>
                  {option.label}
                </Button>
              </ListSubheader>
            );
          }
          const { key, ...restProps } = p;
          return <li key={option.id} {...restProps}>{option.name}</li>;
        }}
        value={targetValue}
        onChange={(_, selectValue) => {
          onChange({
            target: {
              name,
              value: selectValue && selectValue.id,
              label: selectValue && selectValue.name,
              unitPrice: selectValue && selectValue.price,
              taxRate: selectValue && selectValue.taxRate,
            },
          });
        }}
        disabled={isDisabled}
        disableClearable={disableClearable}
        noOptionsText="データがありません"
      />
      <SelectDataDialog
        isOpen={open}
        onClose={handleClose}
        target={target}
        parameter={parameter}
        onSelect={onSelect}
      />
    </>
  );
}
