import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer, IconButton, Divider,
} from '@mui/material';
import {
  Clear as ClearIcon,
} from '@mui/icons-material';
import detailUrl from '../../../../constants/frontUrls';
import DataTableWithCheckbox from '../../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';

export default function CalendarDetailList(props) {
  const {
    searchCondition, onSearch, onClose, open,
  } = props;
  const dbDatas = useSelector((state) => state.opportunityListStore.datas);
  const [datas, setDatas] = useState({});

  useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const headCells = [
    { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
    { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
    { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_name' },
    { id: 'heldDate', label: '開催日', sortId: 'held_date' },
    { id: 'companyName', label: '会社名', sortId: 'company_name' },
    { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
    { id: 'customerEmail', label: 'メールアドレス', sortId: 'customer_email' },
    { id: 'customerPhone', label: '電話番号', sortId: 'customer_phone' },
    { id: 'shopRemarks', label: '店舗向け備考', sortId: 'shop_remarks' },
    { id: 'deliveryAddress', label: '納品先住所', sortId: 'delivery_address' },
  ];

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    onSearch(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    onSearch(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      order,
      orderBy,
    };
    onSearch(tempCondition);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', md: '90%' },
        },
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }}
      open={open}
    >
      <div>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </div>
      <Divider />
      <DataTableWithCheckbox
        url={detailUrl.OPPORTUNITY_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        procName="案件"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isDispFire
        disabled
      />
    </Drawer>
  );
}
