import { createSlice } from '@reduxjs/toolkit';

export const shopNoticeSlice = createSlice({
  name: 'shopNotice',
  initialState: {
    datas: {},
    searchCondition: {},
    isDeleteSuccess: false,
    categories: [],
  },
  reducers: {
    setSearchCondition(state, action) {
      state.searchCondition = action.payload;
    },
    getDatas(state) {
      state.datas = {};
    },
    getDatasSuccess(state, action) {
      state.datas = action.payload;
    },
    getCsvDatas: (state) => {
      state.csvDatas = null;
    },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData(state) {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess(state) {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    getCategories: (state) => {
      state.categories = [];
    },
    getCategoriesSuccess: (state, action) => {
      state.categories = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
  },
});

export const shopNoticeActions = shopNoticeSlice.actions;

export default shopNoticeSlice.reducer;
