import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'category':
      case 'title':
      case 'content':
        return CommonValidation.requiredValue(value);
      case 'startDate':
      case 'endDate':
        return CommonValidation.requiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
