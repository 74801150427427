import { createSlice } from '@reduxjs/toolkit';

export const claimDetailSlice = createSlice({
  name: 'claimDetail',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
    isSaveSuccess: false,
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlgs: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
    },
    getPdfData: () => {},
    getPdfDataSuccess: (state, action) => {
      state.pdfData = action.payload;
    },
  },
});

export const claimDetailActions = claimDetailSlice.actions;

export default claimDetailSlice.reducer;
