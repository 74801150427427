import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';
import ConfirmationFile from '../../report/ConfirmationFile';
import { PdfDownloadButton } from '../../../../components/atoms/Base';
import ComfirmationAffiliationFile from '../../report/ComfirmationAffiliationFile';

export default function ComfimationDownloadButton(props) {
  const {
    id,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isReadyDownload, setReadyDownload] = React.useState(false);
  const formData = useSelector((state) => state.opportunityDetailStore.pdfDatas);
  const isGetData = useSelector((state) => state.opportunityDetailStore.isGetPdfData);
  const [pdfData, setPdfData] = React.useState({ header: {} });
  const [isClickedOnDownload, setClickedOnDownload] = React.useState(false);

  useEffect(() => {
    if (isGetData && isClickedOnDownload) {
      setPdfData(formData);
      setClickedOnDownload(false);
      setLoading(false);
      setReadyDownload(true);
      dispatch(opportunityDetailActions.resetFlg());
    }
  }, [isGetData]);

  const onComfimationDownload = () => {
    dispatch(opportunityDetailActions.getPdfData({ id }));
    setPdfData({ header: {} });
    setLoading(true);
    setReadyDownload(false);
    setClickedOnDownload(true);
  };

  return (
    <PdfDownloadButton
      title="確定書ダウンロード"
      file={pdfData.header && pdfData.header.affiliationId
        ? <ComfirmationAffiliationFile data={pdfData} /> : <ConfirmationFile data={pdfData} />}
      pdfData={pdfData}
      isReadyDownload={isReadyDownload}
      onClick={onComfimationDownload}
      loading={loading}
      onClose={() => { setReadyDownload(false); }}
    />
  );
}
