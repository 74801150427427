import React from 'react';
import { Paper } from '@mui/material';
import detailUrl from '../../../../constants/frontUrls';
import './content.css';

export default function DisplayContent(props) {
  const { datas } = props;
  return (
    <Paper variant="outlined" className="contentBody" style={{ padding: '10px', marginBottom: 16 }}>
      {datas.map((data) => (
        <div key={data.id}>
          {data.contentType === 1 && (
            <a href={data.link} className="c-btn01__link" target="_blank" rel="noreferrer">
              {data.linkText}
            </a>
          )}
          {data.contentType === 2 && (
            <img src={!data.imagePath ? URL.createObjectURL(data.imageFile) : process.env.REACT_APP_AWS_PUBLIC_URL + data.imagePath} alt="画像" />
          )}
          {data.contentType === 3 && (
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          )}
          {data.contentType === 4 && (
            <div className="blog-card">
              <a href={detailUrl.BLOG_DETAIL + data.relateBlogId}>
                <div className="blog-card-thumbnail">
                  <img src={process.env.REACT_APP_AWS_PUBLIC_URL + data.relateBlogImagePath} alt="" width="160" height="90" />
                </div>
                <div className="blog-card-content">
                  <div className="blog-card-title">
                    <p style={{ marginBottom: '25px' }}>{data.relateBlogTitle}</p>
                  </div>
                </div>
              </a>
            </div>
          )}
          {data.contentType === 5 && (
            <div className="no_bullets">
              <p className="toc_title">Contents</p>
              <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
          )}
          {data.contentType === 6 && (
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          )}
        </div>
      ))}
    </Paper>
  );
}
