import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { shopActions } from './shopState';
import { getDatas, getDataById, uploadFiles } from '../../../services/base.service';

function* getShops({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP.SHOP, payload);
    yield put(shopActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.SHOP.SHOP_CSV, payload);
    yield put(shopActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.SHOP.SHOP_ID, payload);
    yield put(shopActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFiles') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
    formData.append('id', new Blob([JSON.stringify(payload.id)], { type: 'application/json' }));

    for (let i = 0; i < payload.imgForm.images.length; i += 1) {
      const tmpData = payload.imgForm.images[i];
      if (tmpData.isNew) {
        const files = tmpData[0];
        formData.append(`imageFiles_${tmpData.id}`, files);
      }
    }

    const result = yield call(uploadFiles, REST_API.SHOP.SHOP.SHOP, formData);
    yield put(shopActions.saveDataSuccess(result));
  } catch (_) { }
}

function* shopSaga() {
  yield takeLatest('shop/getDatas', getShops);
  yield takeLatest('shop/getCsvDatas', getCsvDatas);
  yield takeLatest('shop/getData', getData);
  yield takeLatest('shop/saveData', save);
}

export default shopSaga;
