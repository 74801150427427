import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'productInputTypeCode':
      case 'taxRate':
      case 'serviceTypeCode':
      case 'orderStatusCode':
      case 'opportunityId':
        return CommonValidation.requiredSelect(value);
      case 'displayOrder':
        return CommonValidation.notMinusNumber(value);
      case 'unitPrice':
      case 'quantity':
        return CommonValidation.requiredNotMinusNumber(value);
      case 'driverPickupTime':
        return CommonValidation.nonRequiredTime(value);
      case 'driverPhone':
        return CommonValidation.nonRequiredPhoneNumber(value);
      default:
        return '';
    }
  };
}

export default Validation;
