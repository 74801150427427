import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, useMediaQuery,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import compareDateTime from '../../../utils/common.helper';
import DeleteButton from '../../atoms/DeleteButton';
import { Pagination } from '../../atoms/Base';
import TableHeaderCell from '../../atoms/TableHeaderCell';
import PcTable from './PcTable';
import SmartPhoneTable from './SmartPhoneTable';
import BulkPointGrantDialog from './BulkPointGrantDialog';

export default function DataTableWithCheckbox(props) {
  const [selected, setSelected] = React.useState([]);
  const [openPoint, setOpenPoint] = React.useState(false);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const {
    rows,
    procName,
    headCells,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    url,
    urlTargets,
    onAddOpen,
    isNotAdd,
    isNotDelete,
    isDispFire,
    isBulkPointGrant,
    onBulkPointGrant,
    pointTarget,
    isCreateClaim,
    onCreate,
  } = props;

  const onAdd = () => {
    if (onAddOpen) {
      onAddOpen();
      return;
    }
    navigate(url);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  React.useEffect(() => {
    handleHeaderClose();
  }, [rows]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const onDelete = () => {
    props.onDelete(selected);
    setSelected([]);
  };

  const onPointGrant = (data) => {
    setOpenPoint(false);
    onBulkPointGrant({ ...data, ids: selected });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const addProc = (
    <>
      <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
      {(!isNotAdd) && (
        <Tooltip title={`${procName}の追加`}>
          <Button color="primary" variant="outlined" onClick={onAdd}>{`${procName}の追加`}</Button>
        </Tooltip>
      )}
    </>
  );

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && !isNotDelete ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              {(isBulkPointGrant) && (
                <Tooltip title="ポイント一斉付与">
                  <Button color="primary" onClick={() => setOpenPoint(true)} style={{ marginRight: '10px' }}>ポイント一斉付与</Button>
                </Tooltip>
              )}
              {(isCreateClaim && onCreate) && (
                <Tooltip title="請求作成">
                  <Button color="primary" onClick={() => props.onCreate(selected)} style={{ marginRight: '10px' }}>請求作成</Button>
                </Tooltip>
              )}
              <DeleteButton onDelete={onDelete} procName={procName} />
            </>
          ) : addProc}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!isNotDelete && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0}
                    />
                  </TableCell>
                )}
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {(isPc && isDispFire) && (
                  <TableCell key="table-header-fire" padding="checkbox" />
                )}
                {!isPc && (
                  <TableCell> </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const wordColor = row.fireComplianceDt
                  ? compareDateTime(row.fireComplianceDt) : null;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!isNotDelete && (
                      <TableCell padding="checkbox">
                        {row.deleteable && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                    )}
                    {isPc && (
                      <PcTable
                        row={row}
                        urlTargets={urlTargets}
                        headCells={headCells}
                        wordColor={wordColor}
                        url={url}
                        isDispFire={isDispFire}
                      />
                    )}
                    {!isPc && (
                      <SmartPhoneTable
                        row={row}
                        url={url}
                        headCells={headCells}
                      />
                    )}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    {!isNotDelete && (
                      <TableCell />
                    )}
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
      <BulkPointGrantDialog
        open={openPoint}
        target={pointTarget}
        procName={procName}
        onGrant={onPointGrant}
        onClose={() => setOpenPoint(false)}
      />
    </div>
  );
}
