import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions,
} from '@mui/material';
import { planAttachedActions } from '../../../redux/shop/plan/planAttachedState';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import Validation from './validation';
import { FormErrorText, DialogContent } from '../../../components/atoms/Base';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import NumberInput from '../../../components/atoms/NumberInput';
import SystemInfo from '../../../components/templates/SystemInfo';

const tmpRow = {
  id: '',
  planId: null,
  shopId: null,
  displayOrder: '',
  shopPlanOptionId: '',
};

const tmpErrorMessage = {
  planId: '',
  vendorId: '',
  displayOrder: '',
  shopPlanOptionId: '',
};

export default function OptionDialog(props) {
  const [row, setRow] = React.useState(tmpRow);
  const [errorMessage, setErrorMessage] = React.useState(tmpErrorMessage);
  const isSaveSuccess = useSelector((state) => state.planAttachedStore.isSaveOptionSuccess);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, id, shopId, planId,
  } = props;
  const data = useSelector((state) => state.planAttachedStore.option);

  React.useEffect(() => {
    if (isOpen) {
      setRow(tmpRow);
      setErrorMessage(tmpErrorMessage);
      if (id) {
        setRow(data || tmpRow);
        dispatch(planAttachedActions.getOption({ id }));
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      onClose();
    }
  }, [isSaveSuccess]);

  const beforeSaveCheck = () => {
    let tempMessage = { ...tmpErrorMessage };
    let isError = false;
    Object.keys(row).forEach((key) => {
      const msg = Validation.optionValidate(key, row[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) return;
    const sendRow = { ...row, planId, shopId };
    dispatch(planAttachedActions.saveOption(sendRow));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: value,
    };
    setRow(newRow);
    setErrorMessage({ ...errorMessage, [name]: Validation.optionValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title="オプション" fullWidth maxWidth="sm" onClose={onClose}>
      <DialogContent>
        <DataGrid title="オプション" isEdit isRequired>
          <DataSelectList
            error={!!errorMessage.shopPlanOptionId}
            name="shopPlanOptionId"
            target="options"
            value={row.shopPlanOptionId}
            onChange={onChange}
            parameter={{ shopId }}
            disableClearable
          />
          <FormErrorText>{errorMessage.shopPlanOptionId}</FormErrorText>
        </DataGrid>
        <DataGrid title="表示順" isEdit isRequired>
          <NumberInput value={row.displayOrder} name="displayOrder" fullWidth onChange={onChange} error={!!errorMessage.displayOrder} />
          <FormErrorText>{errorMessage.displayOrder}</FormErrorText>
        </DataGrid>
        {row.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={row} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
