import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { frontNoticeActions } from './frontNoticeState';
import {
  getDatas, deleteDatas, getDataById, saveData,
} from '../../../services/base.service';

function* getNoticeData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.FRONT_NOTICE.FRONT_NOTICE, payload);
    yield put(frontNoticeActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.FRONT_NOTICE.FRONT_NOTICE_CSV, payload);
    yield put(frontNoticeActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.PAGE_SETTING.FRONT_NOTICE.FRONT_NOTICE, payload);
    yield put(frontNoticeActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.FRONT_NOTICE.FRONT_NOTICE_ID, payload);
    yield put(frontNoticeActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.PAGE_SETTING.FRONT_NOTICE.FRONT_NOTICE, payload);
    yield put(frontNoticeActions.saveDataSuccess(result));
  } catch (_) { }
}

function* frontNoticeSaga() {
  yield takeLatest('frontNotice/getDatas', getNoticeData);
  yield takeLatest('frontNotice/getCsvDatas', getCsvDatas);
  yield takeLatest('frontNotice/deleteData', deleteData);
  yield takeLatest('frontNotice/getData', getData);
  yield takeLatest('frontNotice/saveData', save);
}

export default frontNoticeSaga;
