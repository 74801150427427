import React from 'react';
import {
  DialogActions, Box, Button,
} from '@mui/material';
import Dialog from '../../../../../components/atoms/Dialog';
import detailUrl from '../../../../../constants/frontUrls';
import { DialogContent } from '../../../../../components/atoms/Base';

export default function SuccessCreateClaim(props) {
  const {
    isOpen, onClose, saveId,
  } = props;

  const onClick = () => {
    const url = detailUrl.CLAIM_DETAIL + saveId;
    window.open(url, '_blank');
  };

  return (
    <Dialog
      title="請求作成完了"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Box mb={1}>
          作成が完了しました。
        </Box>
        <Box>
          <Button onClick={onClick}>請求データを見る</Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>閉じる</Button>
      </DialogActions>

    </Dialog>
  );
}
