import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { vendorListActions } from './vendorListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getvendors({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.VENDOR.VENDOR, payload);
    yield put(vendorListActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.VENDOR.VENDOR_CSV, payload);
    yield put(vendorListActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SHOP.VENDOR.VENDOR, payload);
    yield put(vendorListActions.deleteDataSuccess());
  } catch (_) { }
}

function* vendorListSaga() {
  yield takeLatest('vendorList/getDatas', getvendors);
  yield takeLatest('vendorList/getCsvDatas', getCsvDatas);
  yield takeLatest('vendorList/deleteData', deleteData);
}

export default vendorListSaga;
