/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField,
} from '@mui/material';
import { Launch as LaunchIcon } from '@mui/icons-material';
import { specialFeatureActions } from '../../../redux/pageSetting/specialFeature/specialFeatureState';
import Tabs from '../../../components/templates/Tabs';
import DataGrid from '../../../components/templates/DataGrid';
import {
  RecordBox, withParams, SuccessSnackbar, TabPanel, DateTimePicker, FormErrorText,
} from '../../../components/atoms/Base';
import Validation from './validation';
import FileUploadDialog from '../../../components/templates/imageList/FileUploadDialog';
import SpecialFeaturePlanList from './attached/SpecialFeaturePlanList';
import SpecialFeatureShopList from './attached/SpecialFeatureShopList';
import SpecialFeatureContentList from './attached/SpecialFeatureContentList';
import SpecialFeatureArea from './attached/SpecialFeatureArea';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import detailUrl from '../../../constants/frontUrls';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import SystemInfo from '../../../components/templates/SystemInfo';
import NumberInput from '../../../components/atoms/NumberInput';
import SingleFileInput from '../../../components/templates/SingleFileInput';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

const DEFAULT_ERROR_MESSAGE = {
  name: '',
  openRange: '',
  displayOrder: '',
  publishStartDate: '',
  publishEndDate: '',
  status: '',
  password: '',
};

class SpecialFeatureDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    const form = {
      id,
      name: '',
      displayOrder: '',
      publishStartDt: '',
      publishEndDt: '',
      specialFeatureStatusCode: '',
      specialFeatureStatusName: '',
      passwordCleartext: '',
      topBannerImagePath: '',
      keyVisualImagePath: '',
      createdAt: '',
      createdByName: '',
      updatedAt: '',
      updatedByName: '',
      externalUrl: '',
    };

    let isSuccessOpen = false;
    if (props.specialFeatureInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(specialFeatureActions.resetFlg());
    }

    this.state = {
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      form,
      inputForm: form,
      isSuccessOpen,
      tabValue: 0,
      isEdit: !form.id,
      errorMessages: DEFAULT_ERROR_MESSAGE,
      isOpenFileUpload: false,
    };
    if (id) {
      props.dispatch(specialFeatureActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.specialFeatureInfo.data !== this.props.specialFeatureInfo.data) {
      if (this.props.specialFeatureInfo.data) {
        this.setData(this.props.specialFeatureInfo.data);
      }
    }
    if (prevProps.specialFeatureInfo.isSaveSuccess
      !== this.props.specialFeatureInfo.isSaveSuccess) {
      if (this.props.specialFeatureInfo.isSaveSuccess) {
        this.setSuccess(this.props.specialFeatureInfo.saveId);
      }
    }
  }

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if (form.id) {
      this.props.dispatch(specialFeatureActions.resetFlg());
    }
    if (!form.id) {
      window.location.href = detailUrl.SPECIAL_FEATURE_DETAIL + searchId;
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(specialFeatureActions.getData({ id: searchId }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    this.props.dispatch(specialFeatureActions.saveData(inputForm));
  };

  onEdit = () => {
    const { form } = this.state;
    this.setState({ isEdit: true, errorMessages: DEFAULT_ERROR_MESSAGE });
    this.props.dispatch(specialFeatureActions.getData({ id: form.id }));
  };

  onEditCancel = () => {
    const { form } = this.state;
    this.setState({ isEdit: false, inputForm: form });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onCloseFileDialog = () => {
    this.setState({ isOpenFileUpload: false, settingFile: '' });
  };

  onSettingFile = (data) => {
    const { inputForm, settingFile } = this.state;
    const newForm = { ...inputForm, [settingFile]: data };
    this.setState({
      inputForm: newForm, settingFile: '', isOpenFileUpload: false,
    });
  };

  static getPath(file, path) {
    if (file) {
      return URL.createObjectURL(file[0]);
    }
    const url = process.env.REACT_APP_AWS_PUBLIC_URL;
    return `${url + path}?${new Date().getTime()}`;
  }

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.status);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  onSetInputForm = (inputForm) => {
    this.setState({ inputForm });
  };

  render() {
    const {
      isSuccessOpen,
      tabValue,
      form,
      inputForm,
      isEdit,
      errorMessages,
      isOpenFileUpload,
      isNotEdit,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={form.id ? 5 : 12}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isNotEdit}
              onEdit={this.onEdit}
            />
            <RecordBox>
              <DataGrid title="特集名" isEdit={isEdit} isRequired value={form.name}>
                <TextField error={!!errorMessages.name} value={inputForm.name} fullWidth name="name" onChange={this.onChange} inputProps={{ maxLength: 255 }} />
                <FormErrorText>{errorMessages.name}</FormErrorText>
              </DataGrid>
              <DataGrid title="表示順" isEdit={isEdit} isRequired value={form.displayOrder}>
                <NumberInput error={!!errorMessages.displayOrder} value={inputForm.displayOrder} fullWidth name="displayOrder" onChange={this.onChange} />
                <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
              </DataGrid>
              <DataGrid title="公開開始日時" value={form.publishStartDt} isEdit={isEdit}>
                <DateTimePicker value={inputForm.publishStartDt} fullWidth name="publishStartDt" onChange={this.onChange} />
                <FormErrorText>{errorMessages.publishStartDt}</FormErrorText>
              </DataGrid>
              <DataGrid title="公開終了日時" value={form.publishEndDt} isEdit={isEdit}>
                <DateTimePicker value={inputForm.publishEndDt} fullWidth name="publishEndDt" onChange={this.onChange} />
                <FormErrorText>{errorMessages.publishEndDt}</FormErrorText>
              </DataGrid>
              <DataGrid title="ステータス" isRequired isEdit={isEdit} value={form.specialFeatureStatusName}>
                <DataSelectList
                  name="specialFeatureStatusCode"
                  target="codes"
                  error={!!errorMessages.specialFeatureStatusCode}
                  parameter={{ codeCategory: 'special_feature_status' }}
                  value={inputForm.specialFeatureStatusCode}
                  onChange={this.onChange}
                  disableClearable
                />
                <FormErrorText>{errorMessages.specialFeatureStatusCode}</FormErrorText>
              </DataGrid>
              {inputForm.specialFeatureStatusCode === 4 && (
                <DataGrid title="パスワード" isEdit={isEdit} value={form.passwordCleartext}>
                  <TextField
                    inputProps={{ maxLength: 100 }}
                    name="passwordCleartext"
                    helperText="パスワード付き特集ページにする場合は入力してください。"
                    fullWidth
                    value={inputForm.passwordCleartext}
                    onChange={this.onChange}
                  />
                </DataGrid>
              )}
              <DataGrid
                title="外部URL"
                isEdit={isEdit}
                value={(
                  <Box display={form.externalUrl ? 'flex' : 'none'}>
                    <a target="_blank" rel="noopener noreferrer" href={form.externalUrl}>{form.externalUrl}</a>
                    <LaunchIcon />
                  </Box>
                )}
              >
                <TextField
                  name="externalUrl"
                  fullWidth
                  inputProps={{ maxLength: 2083 }}
                  value={inputForm.externalUrl}
                  onChange={this.onChange}
                />
              </DataGrid>
              {!isEdit && !inputForm.externalUrl && (
                <DataGrid
                  title="特集ページURL"
                  value={(
                    <Box display="flex">
                      <a target="_blank" rel="noopener noreferrer" href={`https://catering-selection.jp/special-feature/${form.id}`}>{`https://catering-selection.jp/special-feature/${form.id}`}</a>
                      <LaunchIcon />
                    </Box>
                  )}
                />
              )}
              <SingleFileInput
                title="TOPバナー"
                isEdit={isEdit}
                imageFile="topBannerImageFile"
                imagePath="topBannerImagePath"
                form={form}
                isImageOnly
                inputForm={inputForm}
                setInputForm={this.onSetInputForm}
              />
              <SingleFileInput
                title="キービジュアル"
                isEdit={isEdit}
                isImageOnly
                imageFile="keyVisualImageFile"
                imagePath="keyVisualImagePath"
                form={form}
                inputForm={inputForm}
                setInputForm={this.onSetInputForm}
              />
              {(isEdit && !isNotEdit) && (
                <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {form.id && (
                    <Button color="inherit" size="small" variant="contained" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                  )}
                  <Button variant="contained" size="small" onClick={this.onSave}>保存</Button>
                </Box>
              )}
              {!isEdit && form.id && (
                <SystemInfo data={form} />
              )}
            </RecordBox>
          </Grid>
          {form.id && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => this.setState({ tabValue: value })}
              tabs={['コンテンツ', 'プラン', '店舗', 'エリア']}
            />
            <TabPanel value={tabValue} index={0}>
              <SpecialFeatureContentList specialFeatureId={form.id} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SpecialFeaturePlanList specialFeatureId={form.id} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <SpecialFeatureShopList specialFeatureId={form.id} />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <SpecialFeatureArea specialFeatureId={form.id} />
            </TabPanel>
          </Grid>
          )}
        </Grid>
        <FileUploadDialog
          isOpen={isOpenFileUpload}
          onClose={this.onCloseFileDialog}
          onClick={this.onSettingFile}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  specialFeatureInfo: state.specialFeatureStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(SpecialFeatureDetail));
