import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import HolidayDialog from './HolidayDialog';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

const HEAD_CELLS = [
  { id: 'serviceTypeName', label: 'カテゴリ', sortId: 'service_type_name' },
  { id: 'holidayTypeName', label: 'タイプ', sortId: 'holiday_type_name' },
  { id: 'holidayPeriod', label: '休業期間', sortId: 'start_date' },
  { id: 'remarks', label: '備考', sortId: 'remarks' },
];

export default function HolidayDataTable(props) {
  const { shopId, isNotDelete, isNotEdit } = props;
  const dispatch = useDispatch();
  const holidayDatas = useSelector((state) => state.shopDetailStore.holidayDatas);
  const isSaveSuccess = useSelector((state) => state.shopDetailStore.isHolidaySaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.shopDetailStore.isHolidayDeleteSuccess);
  const [holidays, setHolidays] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [holidayId, setHolidayId] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const search = () => {
    dispatch(shopDetailActions.getHolidayData(searchCondition));
  };

  React.useEffect(() => {
    if (shopId) {
      setSearchCondition({ ...searchCondition, shopId });
    }
  }, [shopId]);

  React.useEffect(() => {
    if (searchCondition.shopId) {
      search();
    }
  }, [searchCondition]);

  React.useEffect(() => {
    if (holidayDatas) {
      setHolidays(holidayDatas);
    }
  }, [holidayDatas]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(shopDetailActions.resetFlgs());
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('削除しました');
      dispatch(shopDetailActions.resetFlgs());
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    setSearchCondition({
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    });
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setHolidayId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setHolidayId(openedMenuRowId);
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(shopDetailActions.deleteHolidayData({ ids: [openedMenuRowId] }));
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={holidays.content || []}
        procName="休業"
        headCells={HEAD_CELLS}
        totalElements={holidays.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <HolidayDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        openedMenuRowId={holidayId}
        shopId={shopId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
