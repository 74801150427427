import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions,
} from '@mui/material';
import { specialFeatureAttachedActions } from '../../../../redux/pageSetting/specialFeature/specialFeatureAttachedState';
import Dialog from '../../../../components/atoms/Dialog';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';
import {
  FormTitle, FormSwitch, FormErrorText, DialogContent,
} from '../../../../components/atoms/Base';
import Validation from '../validation';
import NumberInput from '../../../../components/atoms/NumberInput';
import SystemInfo from '../../../../components/templates/SystemInfo';

const baseForm = {
  planId: '',
  displayOrder: '',
  published: false,
  id: null,
  specialFeatureId: null,
};

export default function SpecialFeaturePlanDialog(props) {
  const [form, setForm] = React.useState(baseForm);
  const [errorMessages, setErrorMessages] = React.useState(baseForm);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, specialFeatureId, id,
  } = props;
  const dbData = useSelector((state) => state.specialFeatureAttachedStore.plan);

  React.useEffect(() => {
    if (dbData) {
      setForm(dbData);
    }
  }, [dbData]);
  React.useEffect(() => {
    if (isOpen) {
      setForm(baseForm);
      setErrorMessages(baseForm);
      if (id) {
        dispatch(specialFeatureAttachedActions.getPlan({ id }));
      }
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    return isError;
  };

  const onClickSave = () => {
    const tempForm = { ...form, specialFeatureId };
    if (beforeSaveCheck()) {
      return;
    }
    dispatch(specialFeatureAttachedActions.savePlan(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setForm(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title={`プランの${form.id ? '編集' : '追加'}`} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Box>
          <FormTitle title="プラン名" isRequired isNonFlex />
          <DataSelectList
            name="planId"
            target="plans"
            value={form.planId}
            onChange={onChange}
            error={!!errorMessages.planId}
          />
          <FormErrorText>{errorMessages.planId}</FormErrorText>
        </Box>
        <Box mt={1}>
          <FormTitle title="並び順" isNonFlex isRequired />
          <NumberInput value={form.displayOrder} name="displayOrder" onChange={onChange} error={!!errorMessages.displayOrder} />
          <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
        </Box>
        <Box mt={1}>
          <FormTitle title="公開" isNonFlex />
          <FormSwitch label="公開する" checked={form.published} onChange={onChange} name="published" />
        </Box>
        {form.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
