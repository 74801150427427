import React from 'react';
import {
  Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Grid, Col, Box, Table, TableRow, TableCell, TableDataCell, Page,
} from '../../../components/pdf/Common';
import logo from '../../../asset/cqreeLogo.png';
import syaban from '../../../asset/syaban.png';
import { groupBy } from '../../../utils/common.helper';

const today = new Date();

export default function EstimateEnglishFile(props) {
  const { header, details } = props.data || {};
  const detailMap = details ? groupBy(details, 'shopName') : {};
  let cnt = 0;
  let isNextPage = false;
  const createShopName = (item) => (
    <>
      <Text>{item.shopName}</Text>
      {item.invoiceTargeted && <Text>※Taxable entity</Text>}
    </>

  );
  const detailRowStyle = (index, length) => {
    const base = {
      flex: '1 0 auto',
      display: 'flex',
    };
    return index < length - 1 ? { ...base, borderLeft: 'none', borderTop: 'none' } : { ...base, border: 'none' };
  };
  const detItem = (shopName, detArray, idx) => (
    <React.Fragment key={shopName + idx}>
      <TableRow style={{ display: 'flex', alignItems: 'stretch' }} borderTop={idx > 0}>
        <TableDataCell
          flexBasis="21%"
          style={{
            textAlign: 'center', justifyContent: 'center', flexWrap: 'wrap', display: 'flex', flexDirection: 'column',
          }}
        >
          {createShopName(detailMap[shopName][0])}
        </TableDataCell>
        <Col flexBasis="79%" style={{ display: 'flex', flexDirection: 'column' }}>
          {detArray.map((item, index) => (
            <TableRow
              key={item.id}
              style={detailRowStyle(index, detArray.length)}
            >
              <TableDataCell flexBasis={`${46 / 0.79} %`} left style={{ paddingLeft: '5px', flexWrap: 'wrap' }}>
                <Text>{item.productName}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${9 / 0.79} %`} right>
                <Text>{item.unitPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${6 / 0.79} %`} right>
                <Text>{item.quantity}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${12 / 0.79} %`} right>
                <Text>{item.totalPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis={`${6 / 0.79} %`}>
                <Text>{`${item.taxRate}%`}</Text>
              </TableDataCell>
            </TableRow>
          ))}
        </Col>
      </TableRow>
      <View break={isNextPage} />
    </React.Fragment>
  );
  const detailLine = (shopName) => {
    let shopAllItems = detailMap[shopName];
    const tempCnt = cnt + detailMap[shopName].length;
    const nextPageColumnSize = isNextPage ? 30 : 15;
    if (tempCnt <= nextPageColumnSize) {
      cnt = tempCnt;
      return detItem(shopName, shopAllItems);
    }
    isNextPage = true;
    const result = [];
    const firstChunkSize = 15 - cnt;
    let currentItems = shopAllItems.slice(0, firstChunkSize);
    result.push(currentItems);
    shopAllItems = shopAllItems.slice(firstChunkSize);
    while (shopAllItems.length > 0) {
      currentItems = shopAllItems.slice(0, 30);
      result.push(currentItems);
      shopAllItems = shopAllItems.slice(30);
    }
    cnt = result[result.length - 1].length;
    return (
      result.map((items, index) => (
        detItem(shopName, items, index)
      )));
  };
  return (
    <Document>
      <Page
        size="A4"
        wrap
      >
        <Root>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Image
              src={logo}
              style={{
                width: '20%',
                height: 'auto',
              }}
            />
          </View>
          <View style={{ fontSize: '15px', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center', borderBottom: '1px solid #000', fontFamily: 'NotoSansJP-Bold' }}>{`MESSRS:${header.companyName}`}</Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`issue date：${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`}</Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`Management number：${header.estimateNumber}`}</Text>
          </View>
          <Box style={{
            borderBottom: '3px double #000', marginTop: '15px', marginBottom: '15px', textAlign: 'center',
          }}
          >
            <Text style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'NotoSansJP-Bold' }}>Quotation</Text>
          </Box>
          <Grid>
            <Col flexBasis="50%">
              <Grid>
                <Col
                  flexBasis="30%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>subject</Text></View>
                </Col>
                <Col
                  flexBasis="65%"
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '235px', paddingLeft: '3px', flexDirection: 'row',
                  }}
                >
                  <Col
                    flexBasis="30%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldDateEn}</Text></View>
                  </Col>
                  <Col
                    flexBasis="70%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldCharTime}</Text></View>
                  </Col>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="30%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>total amount</Text></View>
                </Col>
                <Col
                  flexBasis="65%"
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                >
                  <Text>{`¥${header.customerBillPrice}`}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="30%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>Payment terms</Text></View>
                </Col>
                <Col
                  flexBasis="65%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentMethodEnName}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="30%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>destination</Text></View>
                </Col>
                <Col
                  flexBasis="65%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.destination}</Text>
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="50%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
              <Box style={{ position: 'relative', textAlign: 'left' }}>
                <Text>Cqree INC. </Text>
                <Text>4F,Suzunaka Bldg Omori,</Text>
                <Text>6-16-16, Minamioi, Shinagawa-ku,</Text>
                <Text>Tokyo,140-0013,Japan</Text>
                <Text>TEL：03-6447-7971</Text>
                <Text>manager：Yoneda(090-5545-6777)</Text>
                <Text>Registration number：T3-0104-0111-4543</Text>
              </Box>
              <Image
                src={syaban}
                style={{
                  width: '30%', position: 'absolute', bottom: '5px', right: '-5px',
                }}
              />
            </Col>
          </Grid>
          <View style={{ marginTop: '10px' }} />
          <Table style={{ marginTop: '15px' }}>
            <TableRow borderTop subTitle>
              <TableCell
                flexBasis="21%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>restaurant</Text>
              </TableCell>
              <TableCell
                flexBasis="46%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>DESCRIPTION</Text>
              </TableCell>
              <TableCell
                flexBasis="9%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>
                  unit price
                </Text>
                <Text>
                  (tax included)
                </Text>
              </TableCell>
              <TableCell
                flexBasis="6%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>quantity</Text>
              </TableCell>
              <TableCell
                flexBasis="12%"
                style={{
                  alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>amount</Text>
              </TableCell>
              <TableCell
                flexBasis="6%"
                style={{
                  fontSize: '6px', alignItems: 'center', justifyContent: 'center',
                }}
              >
                <Text>tax rate</Text>
              </TableCell>
            </TableRow>
            {Object.keys(detailMap).map((shopName) => (
              (
                detailLine(shopName)
              )

            ))}
          </Table>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              justifyContent: 'center',
            }}
            >
              <Text />
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '6px',
              fontFamily: 'NotoSansJP-Bold',
              justifyContent: 'center',
            }}
            >
              <Text>Store Cost</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>82%</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.totalPrice ? header.totalPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%', fontSize: '8px' }}><View><Text /></View></Col>
                <Col style={{ flexBasis: '17%', marginRight: '5px' }} right><View><Text /></View></Col>
                <Col left style={{ flexBasis: '27%', fontSize: '8px' }}><View><Text /></View></Col>
                <Col style={{ flexBasis: '19%', marginRight: '5px' }} right><View><Text /></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
              justifyContent: 'center',
            }}
            >
              <Text>Arrangement fee</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>18%</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.commissionPrice ? header.commissionPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%' }}><View><Text>⑧：Subject to 8% consumption tax</Text></View></Col>
                <Col style={{ flexBasis: '17%', marginRight: '5px' }} right><View><Text style={{ fontSize: '8px' }}>{`¥${header.eightPercentTotalPrice ? header.eightPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col style={{ flexBasis: '19%', marginRight: '5px' }} right><View><Text>{`¥${header.eightPercentTotalTaxPrice ? header.eightPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '15%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
              justifyContent: 'center',
            }}
            >
              <Text>Amount eligible for discount</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.discountTargetPrice ? header.discountTargetPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%' }}><View><Text>⑩：Subject to 10% consumption tax</Text></View></Col>
                <Col style={{ flexBasis: '17%', marginRight: '5px' }} right><View><Text>{`¥${header.tenPercentTotalPrice ? header.tenPercentTotalPrice : 0}`}</Text></View></Col>
                <Col left style={{ flexBasis: '27%' }}><View><Text>Consumption tax amount </Text></View></Col>
                <Col style={{ flexBasis: '19%', marginRight: '5px' }} right><View><Text>{`¥${header.tenPercentTotalTaxPrice ? header.tenPercentTotalTaxPrice : 0}`}</Text></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '9%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
              justifyContent: 'center',
            }}
            >
              <Text>Discount amount</Text>
            </View>
            <View style={{
              flexBasis: '6%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
              fontSize: '8px',
            }}
            >
              <Text>{`-${header.discountRate}%`}</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.discountPrice ? header.discountPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '67%',
              padding: 4,
              borderRight: '0.5px solid #000',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <View style={{ flexDirection: 'row', fontSize: '8px' }}>
                <Col left style={{ flexBasis: '37%', fontSize: '8px' }}><View><Text /></View></Col>
                <Col right style={{ flexBasis: '17%', marginRight: '5px' }}><View><Text /></View></Col>
                <Col left style={{ flexBasis: '27%', fontSize: '8px' }}><View><Text /></View></Col>
                <Col right style={{ flexBasis: '19%', marginRight: '5px' }}><View><Text /></View></Col>
              </View>
            </View>
            <View style={{
              flexBasis: '15%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '5px',
              fontFamily: 'NotoSansJP-Bold',
              justifyContent: 'center',
            }}
            >
              <Text>total amount(tax included)</Text>
            </View>
            <View style={{
              flexBasis: '12%',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Text>{`¥${header.customerBillPrice}`}</Text>
            </View>
            <View style={{ flexBasis: '6%' }} />
          </Box>
          {/* <Grid>
            <Col flexBasis="20%">
              <View
                flexBasis="20%"
                style={{
                  padding: 6.8,
                  textAlign: 'center',
                  backgroundColor: '#E7E6E6',
                  borderTop: '1 solid #000',
                  borderBottom: '1 solid #000',
                  borderLeft: '1 solid #000',
                  marginTop: '5.4px',
                }}
              >
                <Text>restaurant</Text>
              </View>
              <View
                flexBasis="20%"
                style={{
                  textAlign: 'center',
                  borderBottom: '1 solid #000',
                  borderLeft: '1 solid #000',
                  height: `${String(details.length * 23.5)}px`,
                }}
              >
                <Text>{header.shopName}</Text>
                {header.invoiceTargeted && <Text>※Taxable entity</Text>}
              </View>
            </Col>
            <Col flexBasis="80%">
              <Table style={{ marginTop: '5px' }}>
                <TableRow borderTop subTitle>
                  <TableCell flexBasis="50%"><Text>DESCRIPTION</Text></TableCell>
                  <TableCell flexBasis="24%">
                    <Text style={{ fontSize: '7px' }}>unit price</Text>
                    <Text style={{ fontSize: '7px' }}>(tax included)</Text>
                  </TableCell>
                  <TableCell flexBasis="11%"><Text style
                  ={{ fontSize: '8px' }}>quantity</Text></TableCell>
                  <TableCell flexBasis="24%"><Text>amount</Text></TableCell>
                  <TableCell flexBasis="11%"><Text style
                  ={{ fontSize: '8px' }}>tax rate</Text></TableCell>
                </TableRow>
                {details.map((item) => (
                  <TableRow key={item.id}>
                    <TableDataCell flexBasis="50%" style={{ paddingLeft: '5px', flexWrap
                    : 'wrap', textAlign: 'left' }}><Text>{item.productName}</Text></TableDataCell>
                    <TableDataCell right flexBasis="24%"><Text>{item.unitPrice}
                    </Text></TableDataCell>
                    <TableDataCell right flexBasis="11%"><Text>{item.quantity}
                    </Text></TableDataCell>
                    <TableDataCell right flexBasis="24%"><Text>{item.totalPrice}
                    </Text></TableDataCell>
                    <TableDataCell flexBasis="11%"><Text>{`${item.taxRate}%`}</Text></TableDataCell>
                  </TableRow>
                ))}
              </Table>
            </Col>
          </Grid> */}

          {/* <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '53.2%' }} />
            <View style={{
              flexBasis: '16.2%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'left',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>Store cost</Text>
            </View>
            <View style={{
              flexBasis: '7.3%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>82%</Text>
            </View>
            <View style={{
              flexBasis: '16%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.totalPrice}`}</Text>
            </View>
            <View style={{ flexBasis: '7.3%' }} />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '53.2%' }} />
            <View style={{
              flexBasis: '16.2%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'left',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>Arrangement fee</Text>
            </View>
            <View style={{
              flexBasis: '7.3%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>18%</Text>
            </View>
            <View style={{
              flexBasis: '16%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.commissionPrice}`}</Text>
            </View>
            <View style={{ flexBasis: '7.3%' }} />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53.2%',
            }}
            >
              <View style={{ flexDirection: 'row', marginTop: 8 }}>
                <Col left style={{ flexBasis: '40%' }}><View><Text style={{ fontSize: '7px' }}>
                ⑧：Subject to 8% consumption tax</Text></View></Col>
                <Col right style={{ flexBasis: '17%', marginRight: '3px' }}><View><Text style=
                {{ fontSize: '8px' }}>{`¥${header.eightPercentTotalPrice}`}</Text></View></Col>
                <Col left style={{ flexBasis: '28%' }}><View><Text style={{ fontSize: '7px' }}>
                Consumption tax amount</Text></View></Col>
                <Col right style={{ flexBasis: '15%', marginRight: '3px' }}><View><Text style=
                {{ fontSize: '8px' }}>{`¥${header.eightPercentTotalTaxPrice}`}</Text></View></Col>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 8 }}>
                <Col left style={{ flexBasis: '40%' }}><View><Text style={{ fontSize: '7px' }}>
                ⑩：Subject to 10% consumption tax</Text></View></Col>
                <Col right style={{ flexBasis: '17%', marginRight: '3px' }}><View><Text style=
                {{ fontSize: '8px' }}>{`¥${header.tenPercentTotalPrice}`}</Text></View></Col>
                <Col left style={{ flexBasis: '28%' }}><View><Text style={{ fontSize: '7px' }}>
                Consumption tax amount</Text></View></Col>
                <Col right style={{ flexBasis: '15%', marginRight: '3px' }}><View><Text style=
                {{ fontSize: '8px' }}>{`¥${header.tenPercentTotalTaxPrice}`}</Text></View></Col>
              </View>
            </View>
            <View style={{ flexBasis: '39.5%' }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{
                  flexBasis: '59.5%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'left',
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                  fontSize: '9px',
                }}
                >
                  <Text>Amount eligible for discount</Text>
                </View>
                <View style={{
                  flexBasis: '40.5%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'right',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                }}
                >
                  <Text>{`¥${header.discountTargetPrice}`}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{
                  flexBasis: '41%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'left',
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                }}
                >
                  <Text>Discount amount</Text>
                </View>
                <View style={{
                  flexBasis: '18.5%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'left',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                }}
                >
                  <Text>{`-${header.discountRate}%`}</Text>
                </View>
                <View style={{
                  flexBasis: '40.5%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'right',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                }}
                >
                  <Text>{`¥${header.discountPrice}`}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{
                  flexBasis: '59.5%',
                  backgroundColor: '#FFFFFF',
                  padding: 4,
                  textAlign: 'left',
                  borderLeft: '1px solid #000',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                  fontSize: '9px',
                }}
                >
                  <Text>total amount（tax included）</Text>
                </View>
                <View style={{
                  flexBasis: '40.5%',
                  backgroundColor: '#E7E6E6',
                  padding: 4,
                  textAlign: 'right',
                  borderRight: '1px solid #000',
                  borderBottom: '1px solid #000',
                }}
                >
                  <Text>{`¥${header.customerBillPrice}`}</Text>
                </View>
              </View>
            </View>
            <View style={{ flexBasis: '7.3%' }} />
          </View> */}
          <Box style={{ marginTop: '20px', marginBottom: '5px' }}>
            <View><Text>■SPECIAL NOTES AND INSTRUCTIONS：</Text></View>
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'start',
                minHeight: '40px',
                textAlign: 'left',
              }}
            >
              <Text>
                {header.productRemarks && header.productRemarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
              </Text>
            </Col>
          </Box>
          <Box style={{ marginTop: '5px', marginBottom: '5px' }}>
            <View><Text>■Remarks：</Text></View>
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'start',
                minHeight: '40px',
                textAlign: 'left',
              }}
            >
              <Text>
                {header.customerRemarks && header.customerRemarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
              </Text>
            </Col>
          </Box>
        </Root>
      </Page>
    </Document>
  );
}
