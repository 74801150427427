import React from 'react';
import {
  IconButton, ListItem, ListItemText, ListItemIcon,
} from '@mui/material';
import {
  DragIndicator as DragIndicatorIcon, Delete as DeleteIcon, Undo as UndoIcon,
} from '@mui/icons-material/';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from '../../../../components/atoms/Base';

function Item({
  data, url, onDelete, onUndo, attributeId, index,
}) {
  return (
    <Draggable draggableId={data.id.toString()} index={index}>
      {(provided) => (
        <ListItem
          sx={{ background: data.isDelete || data.isEdit ? '#FCFADE' : '' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <ListItemIcon
            {...provided.dragHandleProps}
            style={{ minWidth: '45px', cursor: 'grab' }}
          >
            <DragIndicatorIcon />
          </ListItemIcon>

          <ListItemText
            primary={(
              <Link
                style={{ textDecoration: data.isDelete ? 'line-through' : '' }}
                to={url + data.planId}
              >
                {data.planName}
              </Link>
          )}
          />
          {!data.isDelete && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => onDelete(e, attributeId, data.planId)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {data.isDelete && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => onUndo(e, attributeId, data.planId)}
            >
              <UndoIcon />
            </IconButton>
          )}
        </ListItem>
      )}
    </Draggable>
  );
}

export default Item;
