import React, { useState } from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Tooltip, Button, useMediaQuery,
  Checkbox,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../../../components/atoms/Base';
import TableHeaderCell from '../../../../../components/atoms/TableHeaderCell';
import PcTableWithMenu from '../../../../../components/templates/dataTableWithMenu/PcTableWithMenu';
import SmartPhoneTableWithMenu from '../../../../../components/templates/dataTableWithMenu/SmartPhoneTableWithMenu';
import DeleteDialog from '../../../../../components/templates/DeleteDialog';
import ConfirmDialog from '../../../../../components/templates/ConfirmDialog';

export default function OrderDataTable(props) {
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState([]);
  const [isConfirmOpen, setConfirmOpen] = React.useState(false);
  const [openedMenuRowId, setOpenedMenuRowId] = useState('');
  const [openedMenuRow, setOpenedMenuRow] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [isCheckDisp, setCheckDisp] = useState(false);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    procName,
    headCells,
    secondFuncText,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    url,
    onAddOpen,
    onEdit,
    isNotEdit,
    isNotDelete,
    onDelete,
    secondFunc,
    isNonSort,
    onCreateClaim,
  } = props;

  const onAdd = () => {
    if (onAddOpen) {
      onAddOpen();
      return;
    }
    navigate(url);
  };

  const onPreDelete = () => {
    onDelete(openedMenuRowId, openedMenuRow);
    setOpenDelete(false);
    setOpenedMenuRowId('');
    setOpenedMenuRow('');
  };

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  const onCreate = () => {
    onCreateClaim(selected);
    setCheckDisp(!isCheckDisp);
    setSelected([]);
    setConfirmOpen(false);
  };

  const colSpan = headCells.length + (!isNotEdit && secondFuncText ? 2 : 1);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {!isNotEdit && (
            <>
              {selected.length > 0 ? (
                <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                  <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  {selected.length}
                  {' '}
                  件選択中
                </Typography>
              ) : <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />}
              { selected.length < 1 && (
              <>
                <Tooltip title={isCheckDisp ? 'キャンセル' : '請求作成'}>
                  <Button color={isCheckDisp ? 'inherit' : 'primary'} variant="outlined" onClick={() => { setCheckDisp(!isCheckDisp); }} style={{ marginRight: '10px' }}>{isCheckDisp ? 'キャンセル' : '請求作成'}</Button>
                </Tooltip>
                { !isCheckDisp && (
                <Tooltip title={`${procName}の追加`}>
                  <Button color="primary" variant="outlined" onClick={onAdd}>{`${procName}の追加`}</Button>
                </Tooltip>
                )}
              </>
              )}
              { selected.length > 0 && (
              <Tooltip title="作成する">
                <Button color="primary" variant="outlined" onClick={() => { setConfirmOpen(true); }}>作成する</Button>
              </Tooltip>
              )}
            </>
          )}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {(isPc && (
                  (onDelete && !isNotDelete) || (onEdit && !isNotEdit)) && isCheckDisp) && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0}
                    />
                  </TableCell>
                )}
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    isNonSort={isNonSort}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {(isPc && !isNotEdit && secondFuncText) && <TableCell />}
                {(isPc && ((onDelete && !isNotDelete)
                || (onEdit && !isNotEdit)) && !isCheckDisp) && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={row.id}
                    sx={{ height: '56px' }}
                    selected={isItemSelected}
                  >
                    {!isNotEdit && isCheckDisp && (
                    <TableCell padding="checkbox">
                      {row.deleteable && (
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                        onClick={(event) => handleClick(event, row.id)}
                      />
                      )}
                    </TableCell>
                    )}
                    {isPc && (
                    <PcTableWithMenu
                      row={row}
                      headCells={headCells}
                      secondFuncText={secondFuncText}
                      secondFunc={secondFunc}
                      isNotEdit={isNotEdit}
                      isNotDelete={isNotDelete}
                      url={url}
                      onDelete={isCheckDisp ? '' : onDelete}
                      onEdit={onEdit}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenedMenuRow={setOpenedMenuRow}
                      setOpenDelete={setOpenDelete}
                    />
                    )}
                    {!isPc && (
                    <SmartPhoneTableWithMenu
                      row={row}
                      headCells={headCells}
                      onDelete={isCheckDisp ? '' : onDelete}
                      onEdit={onEdit}
                      isNotEdit={isNotEdit}
                      url={url}
                      isNotDelete={isNotDelete}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenedMenuRow={setOpenedMenuRow}
                      setOpenDelete={setOpenDelete}
                    />
                    )}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        {!!totalElements && (
          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}

        <DeleteDialog
          open={openDelete}
          onClose={() => {
            setOpenedMenuRowId('');
            setOpenedMenuRow('');
            setOpenDelete(false);
          }}
          procName={procName}
          onDelete={onPreDelete}
        />
        <ConfirmDialog
          open={isConfirmOpen}
          onClose={() => setConfirmOpen(false)}
          title="請求作成"
          content="選択した注文から請求を作成します。"
          actionText="作成する"
          onClick={onCreate}
        />
      </Paper>
    </div>
  );
}
