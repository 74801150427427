import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    datas: {},
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.saveOpportunityId = null;
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
