import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  TextField, Autocomplete, CircularProgress, List,
} from '@mui/material';
import debounce from 'lodash.debounce';
import HttpConnection from '../../../utils/http-connection';
import { REST_URL } from '../../../constants/serverUrls';

const CustomListboxComponent = React.forwardRef((props, ref) => (
  <List
    ref={ref}
    {...props}
    style={{ maxHeight: '300px', overflow: 'auto', position: 'relative' }}
    subheader={<li />}
  >
    {props.children}
  </List>
));

export default function DataSelectListDebounce(props) {
  const {
    error,
    value,
    onChange,
    disableClearable = false,
    isDisabled = false,
    name,
    target,
    parameter,
    exclusions,
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const prevInputValue = useRef(null);
  const prevValue = useRef(null);
  const prevName = useRef(null);
  const prevParameter = useRef(parameter);

  const fetchData = useMemo(() => debounce(async (query) => {
    setLoading(true);
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, {
      ...parameter,
      page: 0,
      records: 50,
      id: value || null,
      name: query || null,
    });
    if (tmpData) {
      const o = tmpData.content;
      setOptions(o);
    } else {
      setOptions([]);
    }
    setLoading(false);
  }, 300), [parameter, target, value]);

  const targetValues = options ? options.filter((row) => row.id === value) : [];
  let [targetValue] = targetValues;

  targetValue = targetValue ?? null;

  const handleInputChange = (event, newValue) => {
    if (!event) {
      if (!inputValue && (prevInputValue.current !== (targetValue ? targetValue.name : null))) {
        setInputValue(newValue);
        prevInputValue.current = newValue;
      }
      return;
    }
    setInputValue(newValue);
    fetchData(newValue);
  };

  const onSelect = (e, row) => {
    if (!options.some((option) => option.id === row.id)) {
      const newOptions = [...options];
      const insertAt = Math.max(0, newOptions.length - 1);
      newOptions.splice(insertAt, 0, row);
      setOptions(newOptions);
    }
    onChange({ target: { name, value: row.id } });
  };

  useEffect(() => {
    if (value !== prevValue.current) {
      fetchData();
      prevValue.current = value;
      if (targetValue) setInputValue(targetValue.name);
    }
  }, [value]);

  useEffect(() => {
    if (name && (!prevName.current || prevName.current !== name)) {
      fetchData();
      prevName.current = name;
    }
  }, [name]);

  useEffect(() => {
    if (prevName.current !== null
      && prevName.current === name && parameter
      && JSON.stringify(prevParameter.current) !== JSON.stringify(parameter)) {
      fetchData();
    }
    prevParameter.current = parameter;
  }, [parameter]);

  useEffect(() => {
    if (exclusions) {
      if (exclusions && Array.isArray(exclusions)) {
        setOptions(options.filter((n) => !exclusions.includes(n.id)));
      }
    }
  }, [exclusions]);

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      size="small"
      options={options}
      ListboxComponent={CustomListboxComponent}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      loading={loading}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          autoComplete="off"
          variant="outlined"
          error={error}
          placeholder="選択してください"
        />
      )}
      value={targetValue}
      onChange={onSelect}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}
