import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, useMediaQuery,
  Button,
} from '@mui/material';
import { Pagination } from '../../../../components/atoms/Base';
import TableHeaderCell from '../../../../components/atoms/TableHeaderCell';
import PcTable from '../../../../components/templates/dataTableWithCheckbox/PcTable';
import SmartPhoneTable from '../../../../components/templates/dataTableWithCheckbox/SmartPhoneTable';

export default function DataTable(props) {
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const headCells = [
    { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
    { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
    { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_name' },
    { id: 'heldDate', label: '開催日', sortId: 'held_date' },
    { id: 'companyName', label: '会社名', sortId: 'company_name' },
    { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
    { id: 'customerEmail', label: 'メールアドレス', sortId: 'customer_email' },
    { id: 'customerPhone', label: '電話番号', sortId: 'customer_phone' },
    { id: 'shopRemarks', label: '店舗向け備考', sortId: 'shop_remarks' },
    { id: 'deliveryAddress', label: '納品先住所', sortId: 'delivery_address' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    onSelect,
  } = props;

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                {isPc && headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {!isPc && (
                  <TableCell> </TableCell>
                )}
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  <TableCell style={{
                    padding: '0px', position: 'sticky', left: '0', minWidth: '80px', background: '#fff',
                  }}
                  >
                    <Button onClick={(e) => { onSelect(e, row); }}>設定する</Button>
                  </TableCell>
                  {isPc && (
                  <PcTable
                    row={row}
                    headCells={headCells}
                  />
                  )}
                  {!isPc && (
                  <SmartPhoneTable
                    row={row}
                    isNotDetail
                    headCells={headCells}
                  />
                  )}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
