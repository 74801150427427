import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { vendorDetailActions } from './vendorDetailState';
import { getDataById, saveData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.VENDOR.VENDOR_ID, payload);
    yield put(vendorDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* updateVendor({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.VENDOR.VENDOR, payload);
    yield put(vendorDetailActions.updateVendorSuccess(result));
  } catch (_) { }
}

function* vendorDetailSaga() {
  yield takeLatest('vendorDetail/getData', getData);
  yield takeLatest('vendorDetail/updateVendor', updateVendor);
}

export default vendorDetailSaga;
