import { createSlice } from '@reduxjs/toolkit';

export const deliveryAgencyUserSlice = createSlice({
  name: 'deliveryAgencyUser',
  initialState: {
    datas: {},
    searchCondition: null,
  },
  reducers: {
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => { },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    insert: (state) => {
      state.isSaveSuccess = false;
    },
    insertSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    update: (state) => {
      state.isSaveSuccess = false;
    },
    updateSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlg: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
    },
  },
});

export const deliveryAgencyUserActions = deliveryAgencyUserSlice.actions;

export default deliveryAgencyUserSlice.reducer;
