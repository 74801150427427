import React from 'react';
import { connect } from 'react-redux';
import {
  TextField, Button, Box, Alert,
} from '@mui/material';
import { loginUserActions } from '../../../redux/systemSetting/loginUser/loginUserState';
import {
  RecordBox, withParams, SuccessSnackbar, FormErrorText, PasswordInput,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';
import LoginUserPasswordDialog from './LoginUserPasswordDialog';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import LogoutDialog from './LogoutDialog';
import SystemInfo from '../../../components/templates/SystemInfo';
import RecordHeader from '../../../components/templates/RecordHeader';

class LoginUserDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id,
      lastName: '',
      firstName: '',
      email: '',
      roleId: '',
      roleName: '',
      userPostCode: '',
      userPostName: '',
      password: '',
      passwordCheck: '',
    };

    let isSuccessOpen = false;
    let successMessage = '';
    if (props.loginUserInfo.isSaveSuccess) {
      isSuccessOpen = true;
      successMessage = '保存しました。';
      props.dispatch(loginUserActions.resetFlg());
    }
    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isMe: `${props.loginUser.userInfo.user.id}` === id,
      isSuccessOpen,
      form,
      inputForm: form,
      errorMessages: {
        lastName: '',
        email: '',
        roleId: '',
        userPostCode: '',
        password: '',
        passwordCheck: '',
      },
      isEdit: !form.id,
      successMessage,
      isPasswordModalOpen: false,
      isLogoutOpen: false,
    };
    // 詳細取得
    if (id) {
      props.dispatch(loginUserActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUserInfo.data !== this.props.loginUserInfo.data) {
      if (this.props.loginUserInfo.data) {
        this.setData(this.props.loginUserInfo.data);
      }
    }
    if (prevProps.loginUserInfo.isSaveSuccess
      !== this.props.loginUserInfo.isSaveSuccess) {
      if (this.props.loginUserInfo.isSaveSuccess) {
        this.setSuccess(this.props.loginUserInfo.saveId);
      }
    }
    if (prevProps.loginUserInfo.isPasswordSuccess
      !== this.props.loginUserInfo.isPasswordSuccess) {
      if (this.props.loginUserInfo.isPasswordSuccess) {
        this.passwordSuccess();
      }
    }
  }

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  setSuccess = (id) => {
    const { form, isMe } = this.state;
    const searchId = id || form.id;
    if (!form.id) {
      window.location.href = detailUrl.LOGIN_USER_DETAIL + searchId;
      return;
    }
    this.props.dispatch(loginUserActions.resetFlg());
    if (isMe) {
      this.setState({ isLogoutOpen: true, isEdit: false });
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true, successMessage: '保存しました。' });
    this.props.dispatch(loginUserActions.getData({ id: searchId }));
  };

  passwordSuccess = () => {
    this.setState({
      isSuccessOpen: true, isEdit: false, successMessage: 'パスワードを変更しました。',
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onChangePassword = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value),
        passwordCheck: Validation.formValidate('passwordCheck', value, inputForm.passwordCheck),
      },
    });
  };

  onChangePasswordCheck = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, inputForm.password, value),
      },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    const tempErrorMessages = {
      customerName: '',
      content: '',
      complianceDeadlineDate: '',
    };
    this.setState({ isEdit: true, errorMessages: tempErrorMessages, inputForm: form });
    this.props.dispatch(loginUserActions.getData({ id: form.id }));
  };

  onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    this.props.dispatch(loginUserActions.saveData(inputForm));
  };

  onPasswordModalOpen = () => {
    this.setState({ isPasswordModalOpen: true });
  };

  onPasswordModalClose = () => {
    this.setState({ isPasswordModalOpen: false });
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      if (key === 'passwordCheck') return;
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!form.id) {
      const msg = Validation.formValidate('passwordCheck', form.password, form.passwordCheck);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        passwordCheck: msg,
      };

      this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    }

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  render() {
    const {
      errorMessages,
      successMessage,
      form,
      isSuccessOpen,
      isEdit,
      isPasswordModalOpen,
      inputForm,
      isDisabled,
      isMe,
      isLogoutOpen,
    } = this.state;

    if (isLogoutOpen) {
      return (
        <LogoutDialog isOpen={isLogoutOpen} />
      );
    }
    return (
      <>
        {(!isEdit && !isDisabled && form.id) && (
          <RecordHeader
            bottomSection={(
              <>
                <Button color="primary" variant="contained" size="small" onClick={this.onPasswordModalOpen}>パスワード変更</Button>
                <Button color="primary" variant="contained" size="small" onClick={this.onEdit}>編集</Button>
              </>
            )}
          />
        )}
        <Box my={1}>
          {(isEdit && isMe) && (
            <Alert severity="warning">ログイン中のユーザーの為、情報が変更された場合、保存後ログアウトします。</Alert>
          )}
        </Box>
        <RecordBox>
          <DataGrid title="姓" isEdit={isEdit} value={form.lastName} isRequired>
            <TextField value={inputForm.lastName} fullWidth name="lastName" onChange={this.onChange} error={!!errorMessages.lastName} inputProps={{ maxLength: 255 }} />
            <FormErrorText>{errorMessages.lastName}</FormErrorText>
          </DataGrid>
          <DataGrid title="名" isEdit={isEdit} value={form.firstName}>
            <TextField value={inputForm.firstName} fullWidth name="firstName" onChange={this.onChange} inputProps={{ maxLength: 255 }} />
          </DataGrid>
          <DataGrid title="メールアドレス" isEdit={isEdit} value={form.email} isRequired>
            <TextField value={inputForm.email} fullWidth name="email" onChange={this.onChange} error={!!errorMessages.email} inputProps={{ maxLength: 255 }} />
            <FormErrorText>{errorMessages.email}</FormErrorText>
          </DataGrid>
          <DataGrid title="役職" isEdit={isEdit} value={form.userPostName} isRequired>
            <DataSelectList
              name="userPostCode"
              target="codes"
              error={!!errorMessages.userPostCode}
              parameter={{ codeCategory: 'user_post' }}
              value={inputForm.userPostCode}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessages.userPostCode}</FormErrorText>
          </DataGrid>
          <DataGrid title="権限" isEdit={isEdit} value={form.roleName} isRequired>
            <DataSelectList
              name="roleId"
              target="roles"
              error={!!errorMessages.roleId}
              value={inputForm.roleId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessages.roleId}</FormErrorText>
          </DataGrid>
          {isEdit && !form.id && (
            <>
              <DataGrid title="パスワード" isRequired isEdit={isEdit} value={form.password}>
                <PasswordInput error={!!errorMessages.password} value={inputForm.password} name="password" onChange={this.onChangePassword} />
                <FormErrorText>{errorMessages.password}</FormErrorText>
              </DataGrid>
              <DataGrid title="確認パスワード" isRequired isEdit={isEdit} value={form.passwordCheck}>
                <PasswordInput error={!!errorMessages.passwordCheck} value={inputForm.passwordCheck} name="passwordCheck" onChange={this.onChangePasswordCheck} />
                <FormErrorText>{errorMessages.passwordCheck}</FormErrorText>
              </DataGrid>
            </>
          )}
          {form.id && (
            <Box mt={2}><SystemInfo data={form} /></Box>
          )}
          {isEdit && (
            <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
              {form.id && (
                <Button color="inherit" variant="contained" size="small" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
              )}
              <Button color="primary" variant="contained" size="small" onClick={this.onSave}>保存</Button>
            </Box>
          )}
        </RecordBox>
        <LoginUserPasswordDialog
          isOpen={isPasswordModalOpen}
          onClose={this.onPasswordModalClose}
          id={form.id}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserInfo: state.loginUserStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(LoginUserDetail));
