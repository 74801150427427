import React from 'react';
import { connect } from 'react-redux';
import {
  Snackbar, IconButton, AlertTitle, Box, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

class ServerErrorProc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.isServerError !== this.props.common.isServerError) {
      if (this.props.common.isServerError) {
        this.setData(this.props.common.errorMessage);
      }
    }
  }

  setData = (errorMessage) => {
    if (window.location.pathname.split('/')[1] === 'login') {
      return;
    }
    this.setState({ open: true, errorMessage });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, errorMessage } = this.state;

    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
          <Alert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity="error"
            action={(
              <IconButton aria-label="delete" size="small" onClick={this.handleClose} color="inherit">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          >
            <AlertTitle>問題が発生しました</AlertTitle>
            {errorMessage ? (
              <div>{errorMessage}</div>
            ) : (<div>予期せぬエラーが発生しました</div>)}
          </Alert>
        </Box>
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(ServerErrorProc);
