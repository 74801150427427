import React from 'react';
import { TextField } from '@mui/material';
import DataGrid from '../templates/DataGrid';
import DataSelectList from './dataList/DataSelectList';

export default function PrefectureInput(props) {
  const {
    inputForm,
    isEdit,
    setForm,
    form,
    addressColumnName,
    addressColumnLabel,
  } = props;

  const onChange = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...inputForm,
      [name]: value,
    };
    setForm(newRow);
  };

  const onChangePre = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...inputForm,
      [name]: value,
      cityId: inputForm[name] === value ? inputForm.cityId : null,
    };
    setForm(newRow);
  };

  return (
    <>
      <DataGrid title="都道府県" isEdit={isEdit} value={form.prefectureName}>
        <DataSelectList
          name="prefectureId"
          target="prefectures"
          value={inputForm.prefectureId}
          onChange={onChangePre}
          fullWidth
        />
      </DataGrid>
      <DataGrid title="市区町村" isEdit={isEdit} value={form.cityName}>
        <DataSelectList
          name="cityId"
          target="citys"
          parameter={{ prefectureId: inputForm.prefectureId }}
          value={inputForm.cityId}
          onChange={onChange}
          fullWidth
        />
      </DataGrid>
      <DataGrid title={addressColumnLabel || 'その他住所'} isEdit={isEdit} value={addressColumnName ? form[addressColumnName] : form.otherAddress}>
        <TextField
          value={addressColumnName ? inputForm[addressColumnName] : inputForm.otherAddress}
          fullWidth
          autoComplete="off"
          inputProps={{ maxLength: 255 }}
          name={addressColumnName || 'otherAddress'}
          onChange={onChange}
        />
      </DataGrid>
    </>
  );
}
