import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { estimateDetailActions } from '../../../../redux/opportunity/estimate/estimateDetailState';
import { isScreenEditDisabled } from '../../../../utils/authCheck.helper';
import HistoryDataTable from './HistoryDetailTable';
import ConfirmDialog from '../../../../components/templates/ConfirmDialog';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';

const detailCells = [
  { id: 'planName', label: '商品名', sortId: 'plan_name' },
  {
    id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
  },
  {
    id: 'quantity', label: '個数', sortId: 'quantity', numeric: true,
  },
  { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
  {
    id: 'totalPrice', label: '合計金額', sortId: 'total_price', numeric: true,
  },
  { id: 'claimed', label: '請求対象', sortId: 'claimed' },
  { id: 'repaid', label: '返金対象', sortId: 'repaid' },
  { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
];

const outputHistoryHeadCells = [
  { id: 'createdAt', label: '出力日', sortId: 'created_at' },
  { id: 'userName', label: '出力者', sortId: 'user_name' },
];

export default function HistoryData(props) {
  const { estimateId } = props;
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [selectedHistoryId, setSelectedHistoryId] = useState(null);
  const [histories, setHistories] = useState({});
  const [details, setDetails] = useState([]);
  const [searchCondition, setSearchCondition] = useState({
    page: 0, records: 50, order: 'desc', orderBy: 'created_at', estimateId,
  });
  const usetData = useSelector((state) => state.loginStore.userInfo);
  const isDisabled = isScreenEditDisabled(usetData);
  const dispatch = useDispatch();
  const dbDatas = useSelector((state) => state.estimateDetailStore.outputHistories);
  const dbDetails = useSelector((state) => state.estimateDetailStore.outputHistoryDetails);
  const isHistoryReload = useSelector((state) => state.estimateDetailStore.isHistoryReload);

  const search = (condition) => {
    dispatch(estimateDetailActions.getOutputHistories(condition));
  };

  useEffect(() => {
    if (estimateId) {
      const tempCondition = {
        ...searchCondition,
        estimateId,
      };
      setSearchCondition(tempCondition);
      search(searchCondition);
    }
  }, [estimateId]);
  useEffect(() => {
    if (dbDatas) {
      setHistories(dbDatas);
    }
  }, [dbDatas]);
  useEffect(() => {
    if (dbDetails) {
      setDetails(dbDetails);
    }
  }, [dbDetails]);
  useEffect(() => {
    if (isHistoryReload) {
      dispatch(estimateDetailActions.resetFlgs());
      const tempCondition = {
        ...searchCondition,
        page: 0,
      };
      setSearchCondition(tempCondition);
      search(tempCondition);
    }
  }, [isHistoryReload]);

  const showDetails = (_, row) => {
    const historyId = row.id;
    setSelectedHistoryId(historyId);
    dispatch(estimateDetailActions.getOutputHistoryDetails({ id: historyId }));
  };

  const onChangeHistoryPage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeHistoryRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleHisotryRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const onConfirmClose = () => {
    setConfirmOpen(false);
  };

  const onMakeThisDetailsCurrent = () => {
    dispatch(estimateDetailActions.bulkData({ outputHisotoryId: selectedHistoryId }));
  };

  return (
    <>
      <DataTableWithMenu
        rows={histories.content || []}
        isShowSelected
        selectedId={selectedHistoryId}
        headCells={outputHistoryHeadCells}
        procName="見積出力履歴"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={histories.totalElements || 0}
        onChangePage={onChangeHistoryPage}
        onChangeRowsPerPage={onChangeHistoryRowsPerPage}
        handleRequestSort={handleHisotryRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        disabled={isDisabled}
        isNotAdd
        secondFunc={showDetails}
        secondFuncText="この見積明細を見る"
      />
      {details.length > 0 && selectedHistoryId && (
        <Box style={{ marginTop: '1em' }}>
          <HistoryDataTable
            rows={details}
            headCells={detailCells}
          />
          {!isDisabled && (
            <Box p={1} textAlign="right" style={{ marginTop: '1em' }}>
              <Button variant="contained" size="small" onClick={onConfirmOpen}>この明細を現在の明細にする</Button>
            </Box>
          )}
        </Box>
      )}

      <ConfirmDialog
        open={isConfirmOpen}
        onClose={onConfirmClose}
        onClick={onMakeThisDetailsCurrent}
        title="明細の上書き"
        content="現在の明細を削除して、選択した明細にします。"
        actionText="上書きする"
      />
    </>
  );
}
