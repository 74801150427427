import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { List } from '@mui/material';
import Item from './Item';

function DraggableList({
  datas, setDatas, detailUrl, onDelete, onUndo, attributeId,
}) {
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return; // ドロップ先が無効な場合は何もしない
    }

    setDatas((prevDatas) => {
      // 並び替え対象のデータを抽出
      const filteredData = prevDatas.filter((data) => data.attributeId === attributeId);

      // 並び替え対象外のデータ
      const otherData = prevDatas.filter((data) => data.attributeId !== attributeId);

      // 並び替え操作
      const reorderedData = Array.from(filteredData);
      const [removed] = reorderedData.splice(source.index, 1);
      reorderedData.splice(destination.index, 0, removed); // 並び替え実行

      // 並び替え後のデータに displayOrder と isEdit を設定
      const updatedReorderedData = reorderedData.map((item, index) => ({
        ...item,
        displayOrder: index + 1, // 新しい順序を設定
        isEdit: item.originalDisplayOrder !== index + 1,
      }));

      // 統合して返す
      return [...otherData, ...updatedReorderedData];
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {datas.map((data, index) => (
              <Item
                key={data.id}
                index={index}
                data={data}
                url={detailUrl}
                onDelete={onDelete}
                onUndo={onUndo}
                attributeId={attributeId}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableList;
