import { createSlice } from '@reduxjs/toolkit';

export const customerPointHistorySlice = createSlice({
  name: 'customerPointHistory',
  initialState: {
    datas: {},
  },
  reducers: {
    getDatasByPointHistory: () => { },
    getDatasByPointHistorySuccess: (state, action) => {
      state.datas = action.payload;
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
    },
    getPointHistoryDataById: (state) => {
      state.data = null;
    },
    getPointHistoryDataByIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    savePoint: (state) => {
      state.isSaveSuccess = false;
    },
    savePointSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteDataById: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataByIdSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlg: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
    },
  },
});

export const customerPointHistoryActions = customerPointHistorySlice.actions;

export default customerPointHistorySlice.reducer;
