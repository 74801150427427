import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { loginUserActions } from '../../../redux/systemSetting/loginUser/loginUserState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SuccessSnackbar } from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  loginUserName: '',
  userPostCodes: [],
  roleIds: [],
  email: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'lastName', label: '姓' },
  { key: 'firstName', label: '名' },
  { key: 'email', label: 'メールアドレス' },
  { key: 'userPostCode', label: '役職コード' },
  { key: 'userPostName', label: '役職名' },
  { key: 'roleId', label: '権限コード' },
  { key: 'roleName', label: '権限名' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class LoginUserList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '名前', sortId: 'name' },
      { id: 'email', label: 'メールアドレス', sortId: 'email' },
      { id: 'userPostName', label: '役職', sortId: 'user_post_name' },
      { id: 'roleName', label: '権限', sortId: 'role_name' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.loginUserInfo.searchCondition) {
      searchCondition = props.loginUserInfo.searchCondition;
    }
    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      download: {
        headerItems: CSV_HEADER,
        fileName: 'ログインユーザー',
        done: false,
        datas: [],
      },
      isSuccessOpen: false,
    };
    this.props.dispatch(loginUserActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUserInfo.datas !== this.props.loginUserInfo.datas) {
      if (this.props.loginUserInfo.datas) {
        this.setDatas(this.props.loginUserInfo.datas);
      }
    }
    if (prevProps.loginUserInfo.csvDatas !== this.props.loginUserInfo.csvDatas) {
      if (this.props.loginUserInfo.csvDatas) {
        this.setCsvDatas(this.props.loginUserInfo.csvDatas);
      }
    }
    if (prevProps.loginUserInfo.isDeleteSuccess
      !== this.props.loginUserInfo.isDeleteSuccess) {
      if (this.props.loginUserInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  search = (searchCondition) => {
    this.props.dispatch(loginUserActions.setSearchCondition(searchCondition));
    this.props.dispatch(loginUserActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(loginUserActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(loginUserActions.deleteData({ ids }));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(loginUserActions.getCsvDatas(searchCondition));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          reSearch={this.reSearch}
          targetScreen="login-user"
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="名前"
          simpleAreaOne={(
            <TextField value={searchCondition.loginUserName} autoComplete="off" fullWidth name="loginUserName" onChange={this.onChange} />
          )}
          simpleAreaTwoText="役職"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="userPostCodes"
              target="codes"
              parameter={{ codeCategory: 'user_post' }}
              values={searchCondition.userPostCodes}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="メールアドレス" />
              <TextField value={searchCondition.email} autoComplete="off" fullWidth name="email" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="権限" />
              <DataMultiSelectList
                name="roleIds"
                target="roles"
                values={searchCondition.roleIds}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.LOGIN_USER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="ログインユーザー"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserInfo: state.loginUserStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(LoginUserList);
