import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, TextField, FormHelperText,
} from '@mui/material';
import SelectStoreComponent from '../../common/selectStoreComponent/SelectStoreComponent';
import CommonValidation from '../../../../utils/common.validation';
import { emptyItemsOtherSelectedStore } from '../../../../utils/common.helper';
import DataGrid from '../../../../components/templates/DataGrid';
import { estimateActions } from '../../../../redux/opportunity/estimate/estimateState';
import Validation from '../validation';
import SelectOpportunityDialog from '../../common/SelectOpportunityDialog/SelectOpportunityDialog';
import detailUrl from '../../../../constants/frontUrls';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';
import NumberInput from '../../../../components/atoms/NumberInput';
import {
  FormErrorText, RecordBox, Link, DatePicker, SearchSelectBox, TextArea,
} from '../../../../components/atoms/Base';
import SystemInfo from '../../../../components/templates/SystemInfo';
import Accordion from '../../../../components/templates/Accordion';
import { RecordHeaderButton } from '../../../../components/templates/RecordHeader';

export default function EstimateDetailInfo(props) {
  const { id, data, isDisabled } = props;

  const dispatch = useDispatch();
  const [estimate, setEstimate] = useState({
    eightPercentTotalPrice: 0,
    eightPercentTotalTaxPrice: 0,
    eightPercentDiscountPrice: 0,
    tenPercentTotalPrice: 0,
    tenPercentTotalTaxPrice: 0,
    tenPercentDiscountPrice: 0,
    totalPrice: 0,
    discountTargetPrice: 0,
    customerBillPrice: 0,
    pointUsagePrice: 0,
    salesFeeBillPrice: 0,
    refundPrice: 0,
    commissionPrice: 0,
  });
  const [inputEstimate, setInputEstimate] = useState({
    id: null,
    shopId: null,
    vendorId: null,
    opportunityId: null,
    deliveryAgencyId: null,
    estimateStatusCode: null,
    pointUsagePrice: 0,
    destination: '',
  });
  const [detailType, setDetailType] = useState(1);
  const [isEdit, setEdit] = useState(true);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [isNewEstimate, setNewEstimate] = useState(true);
  const [isExist, setExist] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const isSaveSuccess = useSelector((state) => state.estimateStore.isSaveSuccess);

  React.useEffect(() => {
    if (id) {
      setEdit(false);
      setNewEstimate(false);
      setExist(true);
    }
  }, [id]);

  React.useEffect(() => {
    if (data.id) {
      setEstimate(data);
      setInputEstimate(data);
      setDetailType(data.shopId ? 1 : 2);
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setEdit(false);
    }
  });

  const onEdit = () => {
    dispatch(estimateActions.getData({ id: estimate.id }));
    setEdit(true);
    setErrorMessages({});
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(inputEstimate).forEach((key) => {
      const msg = Validation.formValidate(key, inputEstimate[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      const result = CommonValidation.storeValidate(inputEstimate, tempMessage, detailType);
      if (result.isError) {
        isError = true;
        tempMessage = result.tempMessage;
      }
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onSave = () => {
    let sendEstimate = inputEstimate;
    if (estimate.affiliationId) {
      sendEstimate = { ...sendEstimate, pointUsagePrice: 0 };
    }
    if (beforeSaveCheck()) {
      return;
    }
    const tempInputEstimate = emptyItemsOtherSelectedStore(sendEstimate, detailType);
    dispatch(estimateActions.saveData(tempInputEstimate));
  };

  const onEditCancel = () => {
    setEdit(false);
    setInputEstimate(estimate);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const updatedEstimate = {
      ...inputEstimate,
      [name]: value,
    };
    const tempErrorMessages = {
      ...errorMessages,
      [name]: Validation.formValidate(name, value),
    };
    setInputEstimate(updatedEstimate);
    setErrorMessages(tempErrorMessages);
  };

  const onSelect = (_, selected) => {
    const updatedEstimate = {
      ...inputEstimate,
      opportunityId: selected.id,
      opportunityNumber: selected.opportunityNumber,
    };
    const tempErrorMessages = {
      ...errorMessages,
      opportunityId: Validation.formValidate('opportunityId', selected.id),
    };
    setSelectOpen(false);
    setInputEstimate(updatedEstimate);
    setErrorMessages(tempErrorMessages);
  };

  return (
    <>
      <RecordHeaderButton
        edit={isEdit}
        disabled={isDisabled}
        onEdit={onEdit}
      />
      <RecordBox>
        <Accordion title="基本情報">
          <DataGrid title="見積番号" value={estimate.estimateNumber} />
          <DataGrid title="ステータス" isRequired isEdit={isEdit} value={estimate.estimateStatusName}>
            <DataSelectList
              error={!!errorMessages.estimateStatusCode}
              name="estimateStatusCode"
              target="codes"
              parameter={{ codeCategory: 'estimate_status' }}
              value={inputEstimate.estimateStatusCode}
              onChange={onChange}
              disableClearable
            />
            <FormErrorText>{errorMessages.estimateStatusCode}</FormErrorText>
          </DataGrid>
          <SelectStoreComponent
            isEdit={isEdit}
            onChange={onChange}
            setDetailType={setDetailType}
            row={inputEstimate}
            detailType={detailType}
            errorMessage={errorMessages}
          />
          <DataGrid
            title="案件番号"
            value={(
              <Link to={detailUrl.OPPORTUNITY_DETAIL + estimate.opportunityId}>
                {estimate.opportunityNumber}
              </Link>
            )}
            isRequired
            isEdit={!isExist}
          >
            <Box>{inputEstimate.opportunityNumber}</Box>
            <Box mt={2}>
              <Button size="small" variant="contained" onClick={() => setSelectOpen(true)}>案件選択</Button>
            </Box>
            <FormErrorText>{errorMessages.opportunityId}</FormErrorText>
          </DataGrid>
          <DataGrid title="有効期限" value={estimate.expireDate} isEdit={isEdit}>
            <DatePicker value={inputEstimate.expireDate} fullWidth name="expireDate" onChange={onChange} />
            <FormErrorText>{errorMessages.expireDate}</FormErrorText>
          </DataGrid>
        </Accordion>
        <Accordion title="金額">
          <DataGrid title="ポイント利用額" isEdit={isEdit} value={estimate.pointUsagePrice}>
            <NumberInput error={!!errorMessages.pointUsagePrice} value={inputEstimate.pointUsagePrice} name="pointUsagePrice" fullWidth onChange={onChange} />
          </DataGrid>
          {estimate.affiliationId && (
            <FormHelperText>提携企業の場合は注文にポイントはご利用できません。</FormHelperText>
          )}
        </Accordion>
        {estimate.id && (
          <>
            <Accordion title="金額明細">
              <DataGrid title="8%小計(税込)" value={estimate.eightPercentTotalPrice} />
              <DataGrid title="8%消費税" value={estimate.eightPercentTotalTaxPrice} />
              <DataGrid title="8%割引額(税込)" value={estimate.eightPercentDiscountPrice} />
              <DataGrid title="10%小計(税込)" value={estimate.tenPercentTotalPrice} />
              <DataGrid title="10%消費税" value={estimate.tenPercentTotalTaxPrice} />
              <DataGrid title="10%割引額(税込)" value={estimate.tenPercentDiscountPrice} />
            </Accordion>
            <Accordion title="合計金額">
              <DataGrid title="合計金額(税込)" value={estimate.totalPrice} />
              <DataGrid title="割引対象額(税抜)" value={estimate.discountTargetPrice} />
              <DataGrid title="顧客請求額(税込)" value={estimate.customerBillPrice} />
              <DataGrid title="販売手数料請求額(税抜)" value={estimate.salesFeeBillPrice} />
              <DataGrid title="返金対象額(税込)" value={estimate.refundPrice} />
              <DataGrid title="手数料(税込)" value={estimate.commissionPrice} />
            </Accordion>
          </>
        )}
        <Accordion title="店舗依頼">
          <DataGrid title="ボール" isEdit={isEdit} value={estimate.ball}>
            <SearchSelectBox
              name="ball"
              options={[{ id: 'cqree', name: 'cqree' }, { id: '店舗', name: '店舗' }]}
              value={inputEstimate.ball}
              onChange={onChange}
            />
          </DataGrid>
          <DataGrid title="店舗への依頼内容" isEdit={isEdit} value={estimate.requestDetail}>
            <TextArea name="requestDetail" value={inputEstimate.requestDetail} onChange={onChange} />
          </DataGrid>
        </Accordion>
        <Accordion title="その他">
          <DataGrid title="英語用見積書送り先" isEdit={isEdit} value={estimate.destination}>
            <TextField name="destination" value={inputEstimate.destination} onChange={onChange} fullWidth inputProps={{ maxLength: 255 }} />
          </DataGrid>
        </Accordion>

        {isEdit && (
          <Box position="sticky" bottom={0} left={0} zIndex={3} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            {!isNewEstimate && <Button color="inherit" variant="contained" size="small" onClick={onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>}
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
        {!isEdit && estimate.id && (
          <Box mt={2}><SystemInfo data={estimate} /></Box>
        )}
      </RecordBox>
      <SelectOpportunityDialog
        isOpen={isSelectOpen}
        onSelect={onSelect}
        onClose={() => setSelectOpen(false)}
      />
    </>
  );
}
