import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Button,
} from '@mui/material';
import { pointRuleActions } from '../../../redux/systemSetting/pointRule/pointRuleState';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import NumberInput from '../../../components/atoms/NumberInput';
import DataGrid from '../../../components/templates/DataGrid';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import detailUrl from '../../../constants/frontUrls';
import SystemInfo from '../../../components/templates/SystemInfo';
import Validation from './validation';
import {
  RecordBox, withParams, SuccessSnackbar, DatePicker,
  FormErrorText, FormSwitch, Link,
} from '../../../components/atoms/Base';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

const DEFAULT_MSG = {
  pointTargetCode: '',
  point: '',
  targetStartDate: '',
  targetEndDate: '',
  periodDays: '',
  planId: '',
  shopId: '',
};

class pointDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id,
      pointTargetCode: '',
      pointTargetName: '',
      evaluationed: false,
      wordCountStart: '',
      wordCountEnd: '',
      imageRegisted: false,
      point: '',
      targetStartDate: '',
      targetEndDate: '',
      periodDays: '',
      opportunityNumber: '',
      shopId: '',
      planId: '',
      createdByName: '',
      createdAt: '',
      updatedByName: '',
      updatedAt: '',
    };

    let isSuccessOpen = false;
    if (props.pointRuleInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(pointRuleActions.resetFlg());
    }

    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isSuccessOpen,
      form,
      inputForm: form,
      isEdit: !form.id,
      errorMessages: DEFAULT_MSG,
    };
    if (id) {
      props.dispatch(pointRuleActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pointRuleInfo.data !== this.props.pointRuleInfo.data) {
      if (this.props.pointRuleInfo.data) {
        this.setDatas(this.props.pointRuleInfo.data);
      }
    }
    if (prevProps.pointRuleInfo.isSaveSuccess !== this.props.pointRuleInfo.isSaveSuccess) {
      if (this.props.pointRuleInfo.isSaveSuccess) {
        this.saveSuccess(this.props.pointRuleInfo.saveId);
      }
    }
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onClickEdit = () => {
    const { form } = this.state;
    this.setState({ isEdit: true, errorMessages: DEFAULT_MSG });
    this.props.dispatch(pointRuleActions.getData({ id: form.id }));
  };

  onClickSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    const tmpForm = {
      ...inputForm,
      planId: inputForm.pointTargetCode === 5 ? inputForm.planId : null,
      shopId: inputForm.pointTargetCode === 4 ? inputForm.shopId : null,
    };
    this.props.dispatch(pointRuleActions.saveData(tmpForm));
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      let msg = Validation.formValidate(key, form[key]);
      if ((form.pointTargetCode !== 4 && key === 'shopId') || (form.pointTargetCode !== 5 && key === 'planId')) msg = '';
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  saveSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if (form.id) {
      this.props.dispatch(pointRuleActions.resetFlg());
    }
    if (!form.id) {
      window.location.href = detailUrl.POINT_RULE_DETAIL + searchId;
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(pointRuleActions.getData({ id: searchId }));
  };

  onEditCancel = () => {
    const { form } = this.state;
    this.setState({ inputForm: form, isEdit: false });
  };

  setDatas = (data) => {
    this.setState({ form: data, inputForm: data });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      form,
      inputForm,
      isSuccessOpen,
      isEdit,
      errorMessages,
      isDisabled,
    } = this.state;

    return (
      <>
        <RecordHeaderButton
          edit={isEdit}
          disabled={isDisabled}
          onEdit={this.onClickEdit}
        />
        <RecordBox>
          <DataGrid title="対象" isEdit={isEdit} value={form.pointTargetName} isRequired>
            <DataSelectList
              name="pointTargetCode"
              target="codes"
              error={!!errorMessages.pointTargetCode}
              parameter={{ codeCategory: 'point_target' }}
              value={inputForm.pointTargetCode}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessages.pointTargetCode}</FormErrorText>
          </DataGrid>
          {inputForm.pointTargetCode === 1 && (
            <>
              <DataGrid title="評価あり" isEdit={isEdit} value={form.evaluationed ? '有の場合のみ' : '無い場合も対象'}>
                <FormSwitch name="evaluationed" checked={inputForm.evaluationed} onChange={this.onChange} label="有の場合のみ" />
              </DataGrid>
              <DataGrid title="文字数開始" isEdit={isEdit} value={form.wordCountStart ? `${form.wordCountStart}文字以上` : ''}>
                <NumberInput name="wordCountStart" value={inputForm.wordCountStart} onChange={this.onChange} endAdornment="文字以上" />
              </DataGrid>
              <DataGrid title="文字数終了" isEdit={isEdit} value={form.wordCountEnd ? `${form.wordCountEnd}文字以下` : ''}>
                <NumberInput name="wordCountEnd" value={inputForm.wordCountEnd} onChange={this.onChange} endAdornment="文字以下" />
              </DataGrid>
              <DataGrid title="画像登録有無" isEdit={isEdit} value={form.imageRegisted ? '有の場合のみ' : '無い場合も対象'}>
                <FormSwitch name="imageRegisted" checked={inputForm.imageRegisted} onChange={this.onChange} label="有の場合のみ" />
              </DataGrid>
            </>
          )}
          {inputForm.pointTargetCode === 4 && (
            <DataGrid
              title="店舗"
              isEdit={isEdit}
              isRequired
              value={(
                <Link to={detailUrl.SHOP_DETAIL + form.shopId}>
                  {form.shopName}
                </Link>
            )}
            >
              <DataSelectList
                name="shopId"
                value={inputForm.shopId}
                target="shops"
                onChange={this.onChange}
                disableClearable
                error={!!errorMessages.shopId}
              />
              <FormErrorText>{errorMessages.shopId}</FormErrorText>
            </DataGrid>
          )}
          {inputForm.pointTargetCode === 5 && (
          <DataGrid
            title="プラン"
            isEdit={isEdit}
            isRequired
            value={(
              <Link to={detailUrl.PLAN_DETAIL + form.planId}>
                {form.planName}
              </Link>
        )}>
            <DataSelectList
              error={!!errorMessages.planId}
              name="planId"
              target="plans"
              value={inputForm.planId}
              onChange={this.onChange}
              disableClearable
            />
            <FormErrorText>{errorMessages.planId}</FormErrorText>
          </DataGrid>
          )}
          <DataGrid title="ポイント" isEdit={isEdit} value={`${form.point}pt`} isRequired>
            <NumberInput name="point" value={inputForm.point} onChange={this.onChange} error={!!errorMessages.point} endAdornment="pt" />
            <FormErrorText>{errorMessages.point}</FormErrorText>
          </DataGrid>
          <DataGrid title="付与対象開始日" isRequired isEdit={isEdit} value={form.targetStartDate}>
            <DatePicker name="targetStartDate" value={inputForm.targetStartDate} onChange={this.onChange} error={!!errorMessages.targetStartDate} />
            <FormErrorText>{errorMessages.targetStartDate}</FormErrorText>
          </DataGrid>
          <DataGrid title="付与対象終了日" isRequired isEdit={isEdit} value={form.targetEndDate}>
            <DatePicker name="targetEndDate" value={inputForm.targetEndDate} onChange={this.onChange} error={!!errorMessages.targetEndDate} />
            <FormErrorText>{errorMessages.targetEndDate}</FormErrorText>
          </DataGrid>
          <DataGrid title="有効期間" isRequired isEdit={isEdit} value={`${form.periodDays}日間`}>
            <Box>
              <NumberInput name="periodDays" onChange={this.onChange} value={inputForm.periodDays} endAdornment="日間" error={!!errorMessages.periodDays} />
              <FormErrorText>{errorMessages.periodDays}</FormErrorText>
            </Box>
          </DataGrid>
          {isEdit && (
            <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
              {form.id && (
                <Button color="inherit" size="small" variant="contained" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
              )}
              <Button variant="contained" size="small" onClick={this.onClickSave}>保存</Button>
            </Box>
          )}
          {!isEdit && form.id && (
            <SystemInfo data={form} />
          )}
        </RecordBox>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pointRuleInfo: state.pointRuleStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(pointDetail));
