import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

export default function SearchSelectBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  const targetValues = options.filter((row) => row.id === value);
  let [targetValue] = targetValues;
  targetValue = targetValue || null;

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      size="small"
      groupBy={(option) => option.category}
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      value={targetValue}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue && selectValue.id,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}
