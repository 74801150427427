import React, { useEffect } from 'react';
import {
  Box, DialogActions, Button, TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../atoms/Dialog';
import Dropzone from '../../atoms/Dropzone';
import RadioButtons from '../../atoms/RadioButtons';
import {
  FormErrorText, FormTitle, Image, TextArea, DialogContent,
} from '../../atoms/Base';

export default function FileUploadDialog(props) {
  const [data, setData] = React.useState({ introduction: '', title: '' });
  const [errorMsg, setErrorMsg] = React.useState('');

  const {
    isOpen, onClose, onClick, newId, isNeedIntroduction, isNeedImageType,
    item, isNoTitle,
  } = props;

  useEffect(() => {
    if (isOpen) {
      setData(item || { introduction: '', title: '', shopImageTypeCode: isNeedImageType ? 1 : '' });
      setErrorMsg('');
    }
  }, [isOpen]);

  const onUpload = (imgData) => {
    setData({ ...data, ...imgData, fileName: imgData[0].name });
    setErrorMsg('');
  };

  return (
    <Dialog title="ファイルアップロード" isOpen={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box>
          {!item && (
            <>
              <Dropzone onChange={onUpload} />
              <FormErrorText>{errorMsg}</FormErrorText>
            </>
          )}
          {item && (
            <Box sx={{ overflowY: 'auto', textAlign: 'center' }}>
              <Image
                src={item.isNew ? URL.createObjectURL(item[0])
                  : process.env.REACT_APP_AWS_PUBLIC_URL + item.imagePath}
                alt={item.title}
                mimeType={item.mimeType}
              />
            </Box>
          )}
        </Box>
        {!isNoTitle && (
          <Box mt={2}>
            <FormTitle title="件名" />
            <TextField
              fullWidth
              name="title"
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
        )}
        {isNeedIntroduction && (
          <Box mt={2}>
            <FormTitle title="説明" />
            <TextArea
              name="introduction"
              value={data.introduction}
              onChange={(e) => setData({ ...data, introduction: e.target.value })}
            />
          </Box>
        )}
        {isNeedImageType && (
          <Box mt={2}>
            <FormTitle title="イメージタイプ" isRequired isNonFlex />
            <RadioButtons
              value={data.shopImageTypeCode}
              onChange={(e) => setData({ ...data, shopImageTypeCode: e.target.value })}
              name="shopImageTypeCode"
              target="codes"
              parameter={{ codeCategory: 'shop_image_type' }}
              defaultValue={1}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={() => {
            if (!item && !data.fileName) {
              setErrorMsg('ファイルをアップロードして下さい');
              return;
            }
            onClick(
              item ? data : {
                ...data, mimeType: data[0].type, id: newId, isNew: true,
              },
            );
          }}
        >
          設定
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
