import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
    isServerError: false,
    errorMessage: '',
  },
  reducers: {
    resetFlg: (state) => {
      state.isSaveFilter = false;
    },
    resetServerError: (state) => {
      state.isServerError = false;
    },
    serverError: (state, action) => {
      state.errorMessage = action.payload.errorMessage;
      state.isServerError = true;
      state.isLoading = false;
    },
    executeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getPointRule: () => { },
    getPointRuleSuccess: (state, action) => {
      state.pointRule = action.payload;
    },
  },
});

export const commonActions = commonSlice.actions;

export default commonSlice.reducer;
