import React from 'react';
import {
  Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, Table, TableRow, TableCell, TableDataCell, Page,
} from '../../../components/pdf/Common';
import logo from '../../../asset/logo.png';
import syaban from '../../../asset/syaban.png';

const today = new Date();

export default function EstimateAffiliationFile(props) {
  const { header, details } = props.data || {};
  return (
    <Document>
      <Page>
        <Root>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Image
              src={logo}
              style={{
                width: '25%',
              }}
            />
          </View>
          <View style={{ fontSize: '15px', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center', borderBottom: '1px solid #000', fontFamily: 'NotoSansJP-Bold' }}>{header.companyName ? `${header.companyName}   御中` : `${header.customerName}   様`}</Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`発行日：${today.getFullYear()}年${today.getMonth() + 1}月${today.getDate()}日`}</Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`管理番号：${header.estimateNumber}`}</Text>
          </View>
          <Box style={{
            borderBottom: '3px double #000', marginTop: '15px', marginBottom: '15px', textAlign: 'center',
          }}
          >
            <Title>見積書</Title>
          </Box>
          <Grid>
            <Col flexBasis="65%">
              <Grid>
                <Col
                  flexBasis="20%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>開催日時</Text></View>
                </Col>
                <Col
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', flexDirection: 'row',
                  }}
                >
                  <Col
                    flexBasis="30%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldDate}</Text></View>
                  </Col>
                  <Col
                    flexBasis="70%"
                    style={{
                      alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                    }}
                  >
                    <View><Text>{header.heldCharTime}</Text></View>
                  </Col>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="20%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>合計金額</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                >
                  <Text>{`¥${header.customerBillPrice}`}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="20%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left', paddingLeft: '3px',
                  }}
                >
                  <View><Text>支払方法</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentMethodName}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col
                  flexBasis="20%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>見積有効期限</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.expireDate}</Text>
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="35%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
              <Box style={{ position: 'relative' }}>
                <Text>ケータリングセレクション</Text>
                <Text>株式会社Cqree</Text>
                <Text>〒140-0013</Text>
                <Text>東京都品川区南大井6-16-16</Text>
                <Text>鈴中ビル大森4F</Text>
                <Text>TEL：0120-262-005</Text>
              </Box>
              <Image
                src={syaban}
                style={{
                  width: '30%', position: 'absolute', bottom: '5px', right: '-5px',
                }}
              />
            </Col>
          </Grid>
          <View style={{ marginTop: '8px', marginBottom: '5px' }}><Text>下記の通り御見積申し上げます。</Text></View>
          <Grid>
            <Col flexBasis="100%">
              <Table>
                <TableRow borderTop subTitle>
                  <Col flexBasis="20%">
                    <View
                      flexBasis="20%"
                      style={{
                        padding: 4,
                        textAlign: 'center',
                        backgroundColor: '#E7E6E6',
                        borderRight: '1 solid #000',
                      }}
                    >
                      <Text>店舗名</Text>
                    </View>
                  </Col>
                  <TableCell flexBasis="60%"><Text>商品名</Text></TableCell>
                  <TableCell flexBasis="16%"><Text>単価(税込)</Text></TableCell>
                  <TableCell flexBasis="11%"><Text>個数</Text></TableCell>
                  <TableCell flexBasis="22%"><Text>合計金額(税込)</Text></TableCell>
                  <TableCell flexBasis="11%"><Text>税区分</Text></TableCell>
                </TableRow>
                {details.map((item, index) => (
                  <TableRow key={item.id} style={{ borderBottom: 'none' }}>
                    <Col flexBasis="20%" style={{ borderBottom: `${details.length - 1 === index ? '1 solid #000' : 'none'}` }}>
                      <View
                        flexBasis="20%"
                        style={{
                          paddingLeft: '5px',
                          textAlign: 'left',
                          justifyContent: 'center',
                        }}
                      >
                        <Text>{index === 0 && header.shopName}</Text>
                      </View>
                    </Col>
                    <TableDataCell
                      flexBasis="60%"
                      style={{
                        paddingLeft: '5px',
                        flexWrap: 'wrap',
                        textAlign: 'left',
                        borderBottom: '1px solid #000',
                        borderLeft: '1 solid #000',
                      }}
                    >
                      <Text>{item.productName}</Text>
                    </TableDataCell>
                    <TableDataCell right flexBasis="16%" style={{ borderBottom: '1 solid #000' }}><Text>{item.unitPrice}</Text></TableDataCell>
                    <TableDataCell right flexBasis="11%" style={{ borderBottom: '1 solid #000' }}><Text>{item.quantity}</Text></TableDataCell>
                    <TableDataCell right flexBasis="22%" style={{ borderBottom: '1 solid #000' }}><Text>{item.totalPrice}</Text></TableDataCell>
                    <TableDataCell flexBasis="11%" style={{ borderBottom: '1 solid #000' }}><Text>{`${item.taxRate}%`}</Text></TableDataCell>
                  </TableRow>
                ))}
              </Table>
            </Col>
          </Grid>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '57%' }} />
            <View style={{
              flexBasis: '19.5%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'left',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>小計</Text>
            </View>
            <View style={{
              flexBasis: '15.7%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.totalPrice}`}</Text>
            </View>
            <View style={{ flexBasis: '7.8%' }} />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '57%' }} />
            <View style={{
              flexBasis: '19.5%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'left',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>割引金額</Text>
            </View>
            <View style={{
              flexBasis: '15.7%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.discountPrice}`}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '57%', marginTop: 8 }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '24%' }}><View><Text>⑧：消費税8% </Text></View></Col>
                <Col left style={{ flexBasis: '22%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '17%', textAlign: 'right' }}><View><Text>{header.eightPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '14%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '15%', textAlign: 'right' }}><View><Text>{header.eightPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '3%', marginRight: '3px' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>
            <View style={{
              flexBasis: '19.5%',
              backgroundColor: '#FFFFFF',
              padding: 4,
              textAlign: 'left',
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
              fontSize: '8.2px',
            }}
            >
              <Text>割引後総合計金額（税込）</Text>
            </View>
            <View style={{
              flexBasis: '15.7%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.customerBillPrice}`}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '57%', marginTop: 8 }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '24%' }}><View><Text>⑩：消費税10%</Text></View></Col>
                <Col left style={{ flexBasis: '22%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '17%', textAlign: 'right' }}><View><Text>{header.tenPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '14%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '15%', textAlign: 'right' }}><View><Text>{header.tenPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '3%', marginRight: '3px' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>

          </View>

          <Box style={{ marginTop: '20px', marginBottom: '5px' }}>
            <View><Text>■商品内容備考：</Text></View>
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'center',
                minHeight: '20px',
                textAlign: 'left',
              }}
            >
              <Text>
                {header.productRemarks && header.productRemarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
              </Text>
            </Col>
          </Box>
          <Box style={{ marginTop: '5px', marginBottom: '5px' }}>
            <View><Text>■納品先：</Text></View>
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'start',
                minHeight: '40px',
                textAlign: 'left',
              }}
            >
              <Text>
                {header.deliveryAddress || ''}
              </Text>
            </Col>
          </Box>
          <Box style={{ marginTop: '5px', marginBottom: '5px' }}>
            <View><Text>■備考：</Text></View>
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'start',
                minHeight: '90px',
                textAlign: 'left',
              }}
            >
              {header.customerRemarks && header.customerRemarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Box>
        </Root>
      </Page>
    </Document>
  );
}
