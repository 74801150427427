import React from 'react';
import { useNavigate } from 'react-router-dom';
import { subMinutes, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, ListItemAvatar, List, ListItemButton,
  Avatar, ListItemText, Paper, ListItem,
} from '@mui/material';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { contactListActions } from '../../redux/opportunity/contact/contactListState';
import { opportunityListActions } from '../../redux/opportunity/opportunity/opportunityListState';
import { homeActions } from '../../redux/home/homeState';
import { reviewActions } from '../../redux/pageSetting/review/reviewState';
import { illustrationActions } from '../../redux/pageSetting/illustration/illustrationState';
import { DEFAULT_SEARCH_CONDITION as contactCondition } from '../opportunity/contact/ContactList';
import { DEFAULT_SEARCH_CONDITION as opportunityCondition } from '../opportunity/opportunity/list/OpportunityList';
import { DEFAULT_SEARCH_CONDITION as reviewCondition } from '../pageSetting/review/ReviewList';
import { DEFAULT_SEARCH_CONDITION as illustrationCondition } from '../pageSetting/illustration/IllustrationList';

export default function Todo() {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const dbData = useSelector((state) => state.homeStore.todos);
  const loginInfo = useSelector((state) => state.loginStore);

  React.useEffect(() => {
    dispatch(homeActions.getTodo());
  }, []);

  React.useEffect(() => {
    if (dbData) {
      setData(dbData);
    }
  }, [dbData]);

  const onClick = (_, target) => {
    if (target === '1') {
      dispatch(contactListActions.setSearchCondition({ ...contactCondition, unassigned: true }));
      navigate('/contact');
    }
    if (target === '2') {
      dispatch(contactListActions.setSearchCondition(
        {
          ...contactCondition,
          salesUsers: [loginInfo.userInfo.user.id],
          statuses: [1, 2],
          complianceDtEnd: format(subMinutes(new Date(), 1), 'yyyy/MM/dd HH:mm'),
        },
      ));
      navigate('/contact');
    }
    if (target === '3') {
      dispatch(opportunityListActions.setSearchCondition({
        ...opportunityCondition, unassigned: true,
      }));
      navigate('/opportunity');
    }
    if (target === '4') {
      dispatch(opportunityListActions.setSearchCondition(
        {
          ...opportunityCondition,
          salesUsers: [loginInfo.userInfo.user.id],
          statuses: [1, 2],
          complianceDtEnd: format(subMinutes(new Date(), 1), 'yyyy/MM/dd HH:mm'),
        },
      ));
      navigate('/opportunity');
    }
    if (target === '5') {
      dispatch(reviewActions.setSearchCondition(
        {
          ...reviewCondition,
          reviewStatuses: [1],
        },
      ));
      navigate('/review');
    }
    if (target === '6') {
      dispatch(illustrationActions.setSearchCondition(
        {
          ...illustrationCondition,
          illustrationStatusCodes: [3],
        },
      ));
      navigate('/illustration');
    }
    if (target === '7') {
      dispatch(illustrationActions.setSearchCondition(
        {
          ...illustrationCondition,
          notConfirmed: true,
        },
      ));
      navigate('/illustration');
    }
  };

  return (
    <Box>
      {data && (
        <Paper elevation={3}>
          <Box display="flex" pt={2} pl={2}>
            <Box sx={{ fontWeight: 'bold' }}>ToDo</Box>
          </Box>
          <List
            sx={{
              bgcolor: 'background.paper',
            }}
          >
            {(!data.unassignedContact && !data.unassignedOpportunity
              && !data.expiredContact && !data.expiredOpportunity) && (
              <ListItem>
                <ListItemText
                  primary="ToDoはありません"
                />
              </ListItem>
            )}
            {data.unassignedContact && (
              <ListItemButton onClick={(e) => onClick(e, '1')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="未割当の問合せがあり" />
              </ListItemButton>
            )}
            {data.expiredContact && (
              <ListItemButton onClick={(e) => onClick(e, '2')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="期限切れの問合せがあり" />
              </ListItemButton>
            )}
            {data.unassignedOpportunity && (
              <ListItemButton onClick={(e) => onClick(e, '3')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="未割当の案件があり" />
              </ListItemButton>
            )}
            {data.expiredOpportunity && (
              <ListItemButton onClick={(e) => onClick(e, '4')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="期限切れの案件があり" />
              </ListItemButton>
            )}
            {data.awaitingReview && (
              <ListItemButton onClick={(e) => onClick(e, '5')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="確認待ちの口コミがあり" />
              </ListItemButton>
            )}
            {data.notEnteredIllustration && (
              <ListItemButton onClick={(e) => onClick(e, '6')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="公開待ちの実例があり" />
              </ListItemButton>
            )}
            {data.notConfirmEnteredIllustration && (
              <ListItemButton onClick={(e) => onClick(e, '7')}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="公開後未確認の実例があり" />
              </ListItemButton>
            )}
          </List>
        </Paper>
      )}
    </Box>
  );
}
