import { createSlice } from '@reduxjs/toolkit';

export const opportunityDetailSlice = createSlice({
  name: 'opportunityDetail',
  initialState: {
    data: {},
  },
  reducers: {
    setCreateSuccess: (state) => {
      state.isCreateSuccess = true;
    },
    resetFlg: (state) => {
      state.isCreateSuccess = false;
      state.isSaveSuccess = false;
      state.isClaimCreateSuccess = false;
      state.isGetPdfData = false;
      state.isCancelSuccess = false;
      state.isChangeStatus = false;
      state.isOrderResearch = false;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    getAffiliations: () => {},
    getAffiliationsSuccess: (state, action) => {
      state.affiliations = action.payload;
    },
    getCustomers: () => {},
    getCustomersSuccess: (state, action) => {
      state.customers = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    getShopSelects: () => {},
    getShopSelectsSuccess: (state, action) => {
      state.storeSelects = action.payload;
    },
    saveCancel: (state) => {
      state.isCancelSuccess = false;
    },
    saveCancelSuccess: (state) => {
      state.isCancelSuccess = true;
    },
    createClaimData: (state) => {
      state.isClaimCreateSuccess = false;
    },
    createClaimDataSuccess: (state, action) => {
      state.isClaimCreateSuccess = true;
      state.createClaimId = action.payload;
    },
    getPdfData: (state) => {
      state.isGetPdfData = false;
    },
    getPdfDataSuccess: (state, action) => {
      state.pdfDatas = action.payload;
      state.isGetPdfData = true;
    },
    getCancelPrice: () => {},
    getCancelPriceSuccess: (state, action) => {
      state.cancelPrice = action.payload;
    },
    setOrderResearch: (state) => {
      state.isOrderResearch = true;
    },
    setChangeStatus: (state) => {
      state.isChangeStatus = true;
    },
  },
});

export const opportunityDetailActions = opportunityDetailSlice.actions;

export default opportunityDetailSlice.reducer;
