import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, Box, Button, IconButton,
  ToggleButtonGroup, ToggleButton, useMediaQuery,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { opportunityListActions } from '../../../../redux/opportunity/opportunity/opportunityListState';
import getCalnedarMonthly from './opportunity-proc';
import CalendarDetailList from './CalendarDetailList';
import HttpConnection from '../../../../utils/http-connection';
import { REST_URL } from '../../../../constants/serverUrls';
import { FireIconTitle } from '../../../../components/atoms/Base';

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

const DataTd = styled('td')(({ disabled, rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
    },
  }),
}));

export default function Calendar(props) {
  const { onSearch, searchCondition } = props;
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [datas, setDatas] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [detailCondition, setCondition] = useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
    heldDate: '',
  });

  const calendarDatas = useSelector((state) => state.opportunityListStore.calendarDatas);
  const loginUser = useSelector((state) => state.loginStore.userInfo.user);

  useEffect(() => {
    const fetchData = async () => {
      const httpConnection = new HttpConnection();
      const tmpData = await httpConnection.get(`${REST_URL}/select/users`, { page: 0, records: 100 });
      const tmpUsers = tmpData.content;
      tmpUsers.unshift({ id: -1, name: '未割当' });
      setSelected([loginUser.id]);
      setUsers(tmpUsers);
    };

    fetchData();
  }, [REST_URL]);

  useEffect(() => {
    if (searchCondition.targetMonth) {
      const calendarMonthly = getCalnedarMonthly(searchCondition.targetMonth);
      setCalendars(calendarMonthly);
    }
  }, [searchCondition.targetMonth]);

  useEffect(() => {
    if (calendarDatas) {
      setDatas(calendarDatas);
    }
  }, [calendarDatas]);

  const onDetailOpen = (_, heldDate, salesUser) => {
    if (searchCondition.heldDate === heldDate) {
      return;
    }
    const salesUserId = salesUser === -1 ? null : salesUser;
    const tmpCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      heldDate,
      salesUsers: [salesUserId],
    };
    setCondition(tmpCondition);
    dispatch(opportunityListActions.getDatas(tmpCondition));
    setDetailOpen(true);
  };

  const onClose = () => {
    setDetailOpen(false);
  };

  const onBeforeMonth = () => {
    const { targetMonth } = searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() - 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    onSearch(newDate);
  };

  const onNextMonth = () => {
    const { targetMonth } = searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() + 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    onSearch(newDate);
  };

  const onSelected = (_, newSelected) => {
    if (!isPc) {
      setSelected(newSelected ? [newSelected] : []);
    } else {
      setSelected(newSelected);
    }
  };
  const onAllSelected = (value) => {
    setSelected(value ? users.map((n) => n.id) : []);
  };

  const onResearch = (tmpConditon) => {
    dispatch(opportunityListActions.getDatas(tmpConditon));
    setCondition(tmpConditon);
  };

  return (
    <>
      <Box style={detailOpen ? { paddingLeft: '0px', marginLeft: '0px', width: '50%' } : null}>
        <Box mb={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton style={{ marginRight: '5px' }} onClick={onBeforeMonth} size="medium">
            <NavigateBeforeIcon />
          </IconButton>
          <Box
            style={{
              fontWeight: 'bold',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            {searchCondition.targetMonth.slice(0, -3)}
          </Box>
          <IconButton style={{ marginLeft: '5px' }} onClick={onNextMonth} size="medium">
            <NavigateNextIcon />
          </IconButton>
        </Box>
        <Box my={2}>
          {isPc && (
            <Box mb={1}>
              <Button
                value={selected.length !== users.length}
                onClick={() => { onAllSelected(selected.length !== users.length); }}
                color={selected.length !== users.length ? 'primary' : 'error'}
                size="small"
                variant="outlined"
              >
                {selected.length !== users.length ? '全選択' : 'クリア'}
              </Button>
            </Box>
          )}
          <ToggleButtonGroup
            size="small"
            value={selected}
            onChange={onSelected}
            exclusive={!isPc}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {users.map((user) => (
              <ToggleButton value={user.id} key={user.id} color="primary">
                {user.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Table
          width="100%"
          sx={{
            border: 'solid 1px #DDD',
            borderCollapse: 'collapse',
            textAlign: 'center',
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
              {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendars.map((weekRow, index) => (
              <tr key={`${weekRow[index].date}month`}>
                {weekRow.map((item) => {
                  const selectedIds = new Set(selected);
                  const dayDatas = datas.filter(
                    (data) => data.targetDate === item.formatDate
                    && selectedIds.has(data.salesUserId),
                  );
                  return (
                    <DataTd
                      key={item.day}
                      disabled={!item.isSomeMonth}
                    >
                      <Box>
                        <div>{item.day}</div>
                        {dayDatas.map((data) => (
                          <Box key={data.id} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box>
                              <Button style={{ padding: 0 }} variant="text" onClick={(e) => onDetailOpen(e, item.formatDate, data.salesUserId)}>{`${data.salesUserName}  ${data.opportunityCount}件`}</Button>
                            </Box>
                            <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
                              <FireIconTitle checkDateTime={data.fireComplianceDt} isSmall />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </DataTd>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
      <CalendarDetailList
        open={detailOpen}
        onClose={onClose}
        onSearch={onResearch}
        searchCondition={detailCondition}
      />
    </>
  );
}
