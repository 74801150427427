import React from 'react';
import { Alert, Snackbar } from '@mui/material';

export default function ErrorAlert({ count, setErrorOpen, open }) {
  const onClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        onClose={onClose}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {`${count}件以上は登録できません`}
      </Alert>
    </Snackbar>
  );
}
