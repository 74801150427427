import React from 'react';
import { connect } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { lpPlanControlActions } from '../../../redux/pageSetting/lpPlanControl/lpPlanControlState';
import { SearchSelectBox, SuccessSnackbar } from '../../../components/atoms/Base';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import PickupList from './attached/PickupList';
import ConciergeList from './attached/ConciergeList';
import AttributeList from './attached/AttributeList';

export const DEFAULT_SEARCH_CONDITION = {
  reviewStatuses: [],
  opportunityNumber: '',
  shopCode: '',
  planName: '',
  imageExisted: false,
  commentExisted: false,
  tasteEvaluationStart: '',
  tasteEvaluationEnd: '',
  volumeEvaluationStart: '',
  volumeEvaluationEnd: '',
  colorfulEvaluationStart: '',
  colorfulEvaluationEnd: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

class LpPlanControlList extends React.Component {
  constructor(props) {
    super(props);
    this.targets = [
      { id: '1', name: 'スクロール箇所のピックアップ' },
      { id: '2', name: '都道府県' },
      { id: '3', name: '予算' },
      { id: '4', name: 'ジャンル' },
      { id: '5', name: '目的・シーン' },
      { id: '6', name: 'コンシェルジュ' },
    ];

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      datas: [],
      target: '1',
      isSuccessOpen: false,
    };
    props.dispatch(lpPlanControlActions.getControlDatas({ target: '1' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lpPlanControlInfo.datas !== this.props.lpPlanControlInfo.datas) {
      if (this.props.lpPlanControlInfo.datas) {
        this.setDatas(this.props.lpPlanControlInfo.datas);
      }
    }
    if (prevProps.lpPlanControlInfo.isSaveSuccess
      !== this.props.lpPlanControlInfo.isSaveSuccess) {
      if (this.props.lpPlanControlInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setSuccess() {
    this.props.dispatch(lpPlanControlActions.getControlDatas({ target: this.state.target }));
    this.setState({ isSuccessOpen: true });
  }

  onChange = (event) => {
    const { value } = event.target;
    this.props.dispatch(lpPlanControlActions.getControlDatas({ target: value }));
    this.setState({ target: value });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      target,
      isSuccessOpen,
      isEditDisabled,
      datas,
    } = this.state;

    return (
      <>
        <Paper sx={{ padding: '10px', marginBottom: '10px' }}>
          <Box display="flex">
            <Box fontWeight="bold" display="flex" alignItems="center" mr={2}>対象</Box>
            <SearchSelectBox
              disableClearable
              name="target"
              options={this.targets}
              value={target}
              minWidth="300px"
              onChange={this.onChange}
            />
          </Box>
        </Paper>
        {target === '1' && (
          <PickupList datas={datas} />
        )}
        {target === '2' && (
          <AttributeList targetName="prefectures" targetId={2} procName="都道府県" datas={datas} />
        )}
        {target === '3' && (
          <AttributeList targetName="budget" targetId={3} procName="予算" datas={datas} />
        )}
        {target === '4' && (
          <AttributeList targetName="genre" targetId={4} procName="ジャンル" datas={datas} />
        )}
        {target === '5' && (
          <AttributeList targetName="scene" targetId={5} procName="目的・シーン" datas={datas} />
        )}
        {target === '6' && (
          <ConciergeList datas={datas} />
        )}
        {isEditDisabled && (<Box>ss</Box>)}
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lpPlanControlInfo: state.lpPlanControlStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(LpPlanControlList);
