export function isCsvDisabled(user) {
  if (!user) {
    return true;
  }
  const segments = window.location.pathname.split('/');
  const url = `/${segments[1]}`;
  const roleScreenList = user ? user.screenList : [];
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i].url === url) {
      return !roleScreenList[i].permissionCsv;
    }
  }
  return true;
}

export function isScreenEditDisabledFromUrl(user, url) {
  if (!user) {
    return true;
  }
  const roleScreenList = user ? user.screenList : [];
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i].url === url) {
      return !roleScreenList[i].edited;
    }
  }
  return true;
}

export function isScreenDeleteDisabledFromUrl(user, url) {
  if (!user) {
    return true;
  }
  const roleScreenList = user ? user.screenList : [];
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i].url === url) {
      return !roleScreenList[i].deleted;
    }
  }
  return true;
}

export function isScreenEditDisabled(user) {
  if (!user) {
    return true;
  }
  const segments = window.location.pathname.split('/');
  const url = `/${segments[1]}`;
  const roleScreenList = user ? user.screenList : [];
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i].url === url) {
      return !roleScreenList[i].edited;
    }
  }
  return true;
}

export function isScreenDeleteDisabled(user) {
  if (!user) {
    return true;
  }
  const segments = window.location.pathname.split('/');
  const url = `/${segments[1]}`;
  const roleScreenList = user ? user.screenList : [];
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i].url === url) {
      return !roleScreenList[i].deleted;
    }
  }
  return true;
}

export function getParentPath() {
  const urls = window.location.pathname.split('/');
  if (urls.length <= 1) {
    return '/';
  }
  const url = `/${urls[1]}`;
  return url;
}
