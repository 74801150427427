import React from 'react';
import {
  Table, TableBody, TableCell, Button,
  TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { Pagination } from '../Base';
import TableHeaderCell from '../TableHeaderCell';
import PcTable from '../../templates/dataTableWithCheckbox/PcTable';

export default function DataTable(props) {
  const headCells = [
    { id: 'name', label: '名前', sortId: 'name' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    onSelect,
  } = props;

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  <TableCell style={{
                    padding: '0px', position: 'sticky', left: '0', minWidth: '80px', background: '#fff',
                  }}
                  >
                    <Button onClick={(e) => { onSelect(e, row); }}>設定する</Button>
                  </TableCell>
                  <PcTable
                    row={row}
                    headCells={headCells}
                  />
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
