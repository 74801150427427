import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField, DialogActions,
  Button, Paper, Toolbar, Typography, Tooltip, Table, TableContainer,
  TableHead, TableRow, TableBody, TableCell, Box, IconButton,
  FormControlLabel, Radio,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import Dropzone from '../../../components/atoms/Dropzone';
import { opportunityFileActions } from '../../../redux/opportunity/opportunityFile/opportunityFileState';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import Validation from './Validation';
import TableHeaderCell from '../../../components/atoms/TableHeaderCell';
import {
  DialogContent, FormErrorText, FormSwitch, LoadingButton, FormRadioGroup,
} from '../../../components/atoms/Base';

const DEFAULT_FORM = {
  id: '',
  fileName: '',
  publicAll: false,
  file: [],
};

const DEFAULT_ERROR = {
  fileName: '',
  file: '',
};

const DEFALUT_SHARE_FORM = {
  id: '',
  name: '',
  value: '',
  detailType: 1,
};

export default function FileUploadDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    opportunityNum,
    opportunityId,
    estimateId,
    defaultShop,
  } = props;
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.opportunityFileStore.data);
  const isSaveSuccess = useSelector((state) => state.opportunityFileStore.isSaveSuccess);
  const [shareTargets, setShareTargets] = React.useState([]);
  const [title, setTitle] = React.useState('');
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR);
  const [headCell] = React.useState({
    id: 'targetName',
    label: '対象者名',
  });
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [shareForm, setShareForm] = React.useState(DEFALUT_SHARE_FORM);

  React.useEffect(() => {
    if (isOpen) {
      setShareTargets([]);
      if (!openedMenuRowId) {
        setTitle('アップロード');
      } else {
        dispatch(opportunityFileActions.getData({ id: openedMenuRowId }));
        setTitle('共有の変更');
      }
      setForm({
        ...form,
        opportunityNum,
        opportunityId,
        estimateId,
      });
      setErrorMessages(DEFAULT_ERROR);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (fileData && fileData.opportunityId && openedMenuRowId) {
      setForm(fileData);
      setShareTargets(fileData.shareTargets || []);
    }
  }, [fileData]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setForm(DEFAULT_FORM);
    }
  }, [isSaveSuccess]);

  const onFileChange = (value) => {
    setForm({
      ...form,
      file: value[0],
    });
    const msg = Validation.formValidate('file', value[0]);
    setErrorMessages({ ...errorMessages, file: msg });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    const msg = Validation.formValidate([name], value);
    setErrorMessages({ ...errorMessages, [name]: msg });
  };

  const onSave = () => {
    let isError = false;
    let tempMessage = errorMessages;
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    if (isError) return;

    const shopShareTargets = shareTargets
      .filter((target) => target.detailType === 1)
      .map((target) => target.value);
    const agencyShareTargets = shareTargets
      .filter((target) => target.detailType === 2)
      .map((target) => target.value);

    const tempForm = {
      ...form,
      shopShareTargets,
      agencyShareTargets,
      opportunityNum,
      opportunityId,
      estimateId,
    };
    dispatch(opportunityFileActions.upsertData(tempForm));
  };

  const onAddChange = (event) => {
    const { label, value, name } = event.target;
    const tempShareForm = name === 'detailType'
      ? { ...DEFALUT_SHARE_FORM, [name]: value }
      : { ...shareForm, [name]: value, name: label };
    setShareForm(tempShareForm);
  };

  const onAddShare = () => {
    setAddOpen(true);
    setShareForm(DEFALUT_SHARE_FORM);
  };

  const onAddClose = () => {
    setAddOpen(false);
  };

  const onAddSave = () => {
    setShareTargets([
      ...shareTargets,
      shareForm,
    ]);
    setShareForm(DEFALUT_SHARE_FORM);
    setAddOpen(false);
  };

  const onAddShop = () => {
    setShareTargets([
      ...shareTargets,
      defaultShop,
    ]);
    setShareForm(DEFALUT_SHARE_FORM);
  };

  const deleteShareTarget = (row) => {
    const newShareTargets = shareTargets.filter(
      (obj) => !(obj.value === row.value && obj.detailType === row.detailType),
    );
    setShareTargets(newShareTargets);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent>
        {!openedMenuRowId && (
          <>
            <Box mb={3}>
              <Dropzone multiple={false} onChange={onFileChange} />
            </Box>
            <FormErrorText>{errorMessages.file}</FormErrorText>
          </>
        )}
        <DataGrid title="件名" isEdit isRequired>
          <TextField value={form.fileName} name="fileName" onChange={onChange} error={!!errorMessages.fileName} fullWidth inputProps={{ maxLength: 100 }} />
          <FormErrorText>{errorMessages.fileName}</FormErrorText>
        </DataGrid>
        {!estimateId && (<FormSwitch checked={form.publicAll} name="publicAll" onChange={onChange} label="全員に公開する" />)}
        <div>
          <Paper>
            <Toolbar style={{ paddingLeft: 0 }}>
              <>
                <Typography style={{ flex: '1 1 30% ' }} color="inherit" variant="subtitle1" component="div" />
                {!estimateId && (
                  <Tooltip title="共有者の追加">
                    <Button color="primary" variant="outlined" onClick={onAddShare} disabled={form.publicAll}>共有者の追加</Button>
                  </Tooltip>
                )}
                {(estimateId && shareTargets.length === 0) && (
                  <Tooltip title="店舗を共有に追加">
                    <Button color="primary" variant="outlined" onClick={onAddShop} disabled={form.publicAll}>店舗を共有に追加</Button>
                  </Tooltip>
                )}
              </>
            </Toolbar>

            <TableContainer sx={{ maxHeight: 500 }}>
              <Table
                size="medium"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableHeaderCell
                      headCell={headCell}
                    />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shareTargets.map((row) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${row.detailType}${row.value}`}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell style={{
                        padding: '0px',
                        position: 'sticky',
                        right: '0',
                        backgroundColor: 'white',
                      }}
                      >
                        <Box
                          mr={1}
                          style={{
                            minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
                          }}
                        >
                          <IconButton onClick={() => deleteShareTarget(row)} size="small">
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {
                    shareTargets.length < 1 && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                          データがありません
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setShareTargets([]); setForm(DEFAULT_FORM); onClose(); }} color="inherit">キャンセル</Button>
        <LoadingButton onClick={onSave}>保存</LoadingButton>
      </DialogActions>
      <Dialog
        isOpen={isAddOpen}
        onClose={onAddClose}
        maxWidth="sm"
        fullWidth
        title="共有者の追加"
      >
        <DialogContent>
          <DataGrid title="共有先" isEdit>
            <FormRadioGroup row value={shareForm.detailType} name="detailType" onChange={onAddChange}>
              <FormControlLabel value="1" control={<Radio />} label="店舗" />
              {!estimateId && (<FormControlLabel value="2" control={<Radio />} label="配送会社" />)}
            </FormRadioGroup>
          </DataGrid>
          {shareForm.detailType === 1 && (
          <DataGrid title="店舗" isEdit>
            <DataSelectList
              name="value"
              parameter={{ opportunityId }}
              target="opportunity-shops"
              value={shareForm.value}
              onChange={onAddChange}
            />
          </DataGrid>
          )}
          {shareForm.detailType === 2 && (
          <DataGrid title="配送会社" isEdit>
            <DataSelectList
              name="value"
              parameter={{ opportunityId }}
              target="opportunity-delivery-agencies"
              value={shareForm.value}
              onChange={onAddChange}
            />
          </DataGrid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddClose} color="inherit">キャンセル</Button>
          <LoadingButton disabled={!shareForm.value} onClick={onAddSave}>追加</LoadingButton>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}
