import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, TextField,
  DialogActions, FormControlLabel, Radio,
} from '@mui/material';
import { format } from 'date-fns';
import { customerPointHistoryActions } from '../../../redux/opportunity/customer/customerPointHistoryState';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import Validation from './validation';
import NumberInput from '../../../components/atoms/NumberInput';
import SystemInfo from '../../../components/templates/SystemInfo';
import {
  DialogContent, FormRadioGroup, DatePicker, FormErrorText,
} from '../../../components/atoms/Base';

const selectedData = [
  { id: 1, name: '付与' },
  { id: 2, name: '消化' },
];

const tmpRow = {
  id: null,
  pointGrantTypeCode: 1,
  targetDate: '',
  reason: '',
  point: '',
  expirationDate: '',
  updatedAt: '',
};

export default function AddPointDialog(props) {
  const [row, setRow] = React.useState(tmpRow);
  const [errorMessages, setErrorMessages] = React.useState({
    pointGrantTypeCode: '',
    targetDate: '',
    reason: '',
    point: '',
    expirationDate: '',
  });
  const dispatch = useDispatch();
  const {
    isOpen, onClose, point, customerId,
  } = props;
  const data = useSelector((state) => state.customerPointHistoryStore.data);

  React.useEffect(() => {
    if (isOpen) {
      setRow(tmpRow);
      setErrorMessages({
        pointGrantTypeCode: '',
        targetDate: '',
        reason: '',
        point: '',
        expirationDate: '',
      });

      if (point.id) {
        dispatch(customerPointHistoryActions.getPointHistoryDataById({
          id: point.id,
          pointGrantTypeCode: point.pointGrantTypeCode,
        }));
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  const onChange = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: value,
    };
    setRow(newRow);
    setErrorMessages({
      ...errorMessages,
      [name]: Validation.formValidate(name, value),
    });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(row).forEach((key) => {
      let msg = Validation.formValidate(key, row[key]);

      if (row.pointGrantTypeCode === 2 && key === 'expirationDate') return;

      if (!msg && row.pointGrantTypeCode === 2 && key === 'targetDate') {
        const currentDate = new Date();
        const formatValue = format(currentDate, 'yyyy/MM/dd');
        if (formatValue < row.targetDate) {
          msg = '今日以前を設定してください。';
        }
      }

      if (!msg && row.pointGrantTypeCode === 1 && key === 'expirationDate') {
        if (row.expirationDate < row.targetDate) {
          msg = '対象日より未来を設定してください。';
        }
      }

      if (msg) {
        isError = true;
      }

      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) {
      return;
    }

    dispatch(customerPointHistoryActions.savePoint({
      ...row,
      id: point.id,
      customerId,
    }));
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} title={row.id ? 'ポイントの編集' : 'ポイントの追加'} onClose={onClose}>
      <DialogContent>
        <DataGrid title="区分" isRequired isEdit>
          <FormRadioGroup value={row.pointGrantTypeCode} onChange={onChange} name="pointGrantTypeCode">
            <Box>
              {selectedData && selectedData.map((e) => (
                <FormControlLabel
                  key={e.id}
                  value={e.id}
                  control={<Radio />}
                  label={e.name}
                  disabled={!!row.id}
                />
              ))}
            </Box>
          </FormRadioGroup>
          <FormErrorText>{errorMessages.pointGrantTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="対象日" isRequired isEdit>
          <DatePicker error={!!errorMessages.targetDate} value={row.targetDate} name="targetDate" onChange={onChange} />
          <FormErrorText>{errorMessages.targetDate}</FormErrorText>
        </DataGrid>
        <DataGrid title="名目" isRequired isEdit>
          <TextField fullWidth error={!!errorMessages.reason} name="reason" value={row.reason} onChange={onChange} inputProps={{ maxLength: 100 }} />
          <FormErrorText>{errorMessages.reason}</FormErrorText>
        </DataGrid>
        <DataGrid title="ポイント" isRequired isEdit>
          <NumberInput fullWidth name="point" onChange={onChange} value={row.point} error={!!errorMessages.point} endAdornment="pt" />
          <FormErrorText>{errorMessages.point}</FormErrorText>
        </DataGrid>
        {row.pointGrantTypeCode === 1 && (
          <Box item xs={12}>
            <DataGrid title="有効期限" isRequired isEdit>
              <DatePicker error={!!errorMessages.expirationDate} value={row.expirationDate} name="expirationDate" onChange={onChange} />
              <FormErrorText>{errorMessages.expirationDate}</FormErrorText>
            </DataGrid>
          </Box>
        )}
        {row.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={row} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
