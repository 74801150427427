import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { lpPlanControlActions } from './lpPlanControlState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getControlDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.LP_PLAN_CONTROL.LP_PLAN_CONTROL, payload);
    yield put(lpPlanControlActions.getControlDatasSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.PAGE_SETTING.LP_PLAN_CONTROL.LP_PLAN_CONTROL, payload);
    yield put(lpPlanControlActions.saveDataSuccess(result));
  } catch (_) { }
}

function* lpPlanControlSaga() {
  yield takeLatest('lpPlanControl/getControlDatas', getControlDatas);
  yield takeLatest('lpPlanControl/saveData', save);
}

export default lpPlanControlSaga;
