import React from 'react';
import {
  Table, TableBody, TableCell, Paper,
  TableContainer, TableHead, TableRow,
} from '@mui/material';

export default function MemberDataTable(props) {
  const { rows } = props;

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.name}
                >
                  <TableCell>{row.name}</TableCell>
                </TableRow>
              ))}
              {rows.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    データがありません
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
