import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ListItem, ListItemText, ListItemButton,
} from '@mui/material';

export default function HeaderMenuItem(props) {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.loginStore.userInfo);
  const location = useLocation();
  const {
    url, menuName, onClose,
  } = props;

  const onClick = (_, link) => {
    onClose();
    navigate(link);
  };

  const roleScreenList = userInfo ? userInfo.screenList : [];

  const isShow = roleScreenList.filter((row) => row.url === url).length > 0;
  const path = `/${location.pathname.split('/')[1]}`;

  return (
    <ListItem
      sx={{ padding: 0 }}
      onClick={(event) => onClick(event, url)}
    >
      <ListItemButton selected={url === path} style={{ display: isShow ? '' : 'none', paddingLeft: '25px' }}>
        <ListItemText primary={menuName} />
      </ListItemButton>
    </ListItem>
  );
}
