import React from 'react';
import { connect } from 'react-redux';
import { TextField, Box } from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { illustrationActions } from '../../../redux/pageSetting/illustration/illustrationState';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import { FormTitle, FormSwitch, SuccessSnackbar } from '../../../components/atoms/Base';

export const DEFAULT_SEARCH_CONDITION = {
  shopName: '',
  illustrationStatusCodes: [],
  imageUploadCompleted: false,
  notConfirmed: false,
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'opportunityId', label: '案件ID' },
  { key: 'opportunityNumber', label: '案件番号' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'title', label: 'タイトル' },
  { key: 'planName', label: 'プラン名' },
  { key: 'budgetPrice', label: '予算' },
  { key: 'joinNumber', label: '人数' },
  { key: 'prefectureId', label: '都道府県ID' },
  { key: 'prefectureName', label: '都道府県名' },
  { key: 'cityId', label: '市区町村ID' },
  { key: 'cityName', label: '市区町村名' },
  { key: 'sceneAttributeItemId', label: '利用シーン属性ID' },
  { key: 'sceneAttributeItemName', label: '利用シーン属性名' },
  { key: 'serviceTypeCode', label: 'サービスタイプコード' },
  { key: 'serviceTypeName', label: 'サービスタイプ名' },
  { key: 'eventDate', label: '開催日' },
  { key: 'overview', label: '概要' },
  { key: 'menuDetail', label: 'メニュー詳細' },
  { key: 'pickup', label: 'ピックアップフラグ' },
  { key: 'illustrationStatusCode', label: 'ステータスコード' },
  { key: 'illustrationStatusName', label: 'ステータス名' },
  { key: 'confirmed', label: '公開後Cqree確認済' },
  { key: 'imageUploadCompleted', label: '画像アップロード完了フラグ' },
  { key: 'postDate', label: '投稿日' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedBy', label: '更新者' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdBy', label: '作成者' },
];

class IllustrationList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'shopName', label: '店舗名', sortId: 'shop_name' },
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'illustrationStatusName', label: 'ステータス名', sortId: 'illustration_status_name' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.illustrationInfo.searchCondition) {
      searchCondition = props.illustrationInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '店舗実例',
        done: false,
        datas: [],
      },
    };
    props.dispatch(illustrationActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.illustrationInfo.datas !== this.props.illustrationInfo.datas) {
      if (this.props.illustrationInfo.datas) {
        this.setDatas(this.props.illustrationInfo.datas);
      }
    }
    if (prevProps.illustrationInfo.isDeleteSuccess
        !== this.props.illustrationInfo.isDeleteSuccess) {
      if (this.props.illustrationInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.illustrationInfo.csvDatas !== this.props.illustrationInfo.csvDatas) {
      if (this.props.illustrationInfo.csvDatas) {
        this.setCsvDatas(this.props.illustrationInfo.csvDatas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  setSuccess = () => {
    this.setState({
      isSuccessOpen: true,
    });
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(illustrationActions.setSearchCondition(searchCondition));
    this.props.dispatch(illustrationActions.getDatas(searchCondition));
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(illustrationActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(illustrationActions.deleteDatas({ ids }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(illustrationActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      isEditDisabled,
      isDeleteDisabled,
      datas,
      searchCondition,
      isSuccessOpen,
      download,
    } = this.state;
    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          targetScreen="shop-illustration"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onClear={this.onClear}
          simpleAreaOneText="店舗名"
          simpleAreaOne={(
            <TextField value={searchCondition.shopName} fullWidth name="shopName" onChange={this.onChange} />
          )}
          simpleAreaTwoText="ステータス"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="illustrationStatusCodes"
              values={searchCondition.illustrationStatusCodes}
              target="codes"
              parameter={{ codeCategory: 'illustration_status' }}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Box display="flex">
            <Box>
              <FormTitle title="ピックアップ" />
              <FormSwitch checked={searchCondition.pickup} label="ピックアップのみ" name="pickup" onChange={this.onChange} />
            </Box>
            <Box>
              <FormTitle title="公開後未確認" />
              <FormSwitch checked={searchCondition.notConfirmed} label="公開後未確認のみ" name="notConfirmed" onChange={this.onChange} />
            </Box>
          </Box>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.ILLUSTRATION_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="実例"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          onDelete={this.onDelete}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="削除しました。"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  illustrationInfo: state.illustrationStore,
});

export default connect(mapStateToProps)(IllustrationList);
