import React from 'react';
import { useTheme } from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap';

export default function EditorMenuControls() {
  const theme = useTheme();

  return (
    <MenuControlsContainer>
      <MenuSelectHeading
        tooltipTitle="文字スタイル"
        labels={{
          empty: '変更する',
          paragraph: '標準テキスト',
          heading2: 'タイトル',
          heading3: 'サブタイトル',
        }}
      />

      <MenuDivider />

      <MenuSelectFontSize
        tooltipTitle="フォントサイズ"
        unsetOptionLabel="リセット"
      />

      <MenuDivider />

      <MenuButtonBold tooltipLabel="太字" />

      <MenuButtonItalic tooltipLabel="斜体" />

      <MenuButtonUnderline tooltipLabel="下線" />

      <MenuButtonStrikethrough tooltipLabel="取消線" />

      <MenuDivider />

      <MenuButtonTextColor
        defaultTextColor={theme.palette.text.primary}
        tooltipLabel="テキストカラー"
        swatchColors={[
          { value: '#000000', label: 'Black' },
          { value: '#ffffff', label: 'White' },
          { value: '#888888', label: 'Grey' },
          { value: '#ff0000', label: 'Red' },
          { value: '#ff9900', label: 'Orange' },
          { value: '#ffff00', label: 'Yellow' },
          { value: '#00d000', label: 'Green' },
          { value: '#0000ff', label: 'Blue' },
        ]}
        PopperProps={{
          sx: { zIndex: (them) => `${them.zIndex.tooltip} !important` },
        }}
      />

      <MenuButtonHighlightColor
        tooltipLabel="ハイライト"
        swatchColors={[
          { value: '#595959', label: 'Dark grey' },
          { value: '#dddddd', label: 'Light grey' },
          { value: '#ffa6a6', label: 'Light red' },
          { value: '#ffd699', label: 'Light orange' },
          { value: '#ffff00', label: 'Yellow' },
          { value: '#99cc99', label: 'Light green' },
          { value: '#90c6ff', label: 'Light blue' },
          { value: '#8085e9', label: 'Light purple' },
        ]}
        PopperProps={{
          sx: { zIndex: (them) => `${them.zIndex.tooltip} !important` },
        }}
      />

      <MenuDivider />

      <MenuButtonEditLink tooltipLabel="リンク" />

      <MenuDivider />

      <MenuSelectTextAlign tooltipLabel="テキスト位置" />

      <MenuDivider />

      <MenuButtonOrderedList tooltipLabel="順序付きリスト" />

      <MenuButtonBulletedList tooltipLabel="箇条書き" />

      {isTouchDevice() && (
        <>
          <MenuButtonIndent />

          <MenuButtonUnindent />
        </>
      )}

      <MenuDivider />

      <MenuButtonBlockquote tooltipLabel="引用タグ" />

      <MenuDivider />

      <MenuButtonCodeBlock tooltipLabel="コードブロック" />

      <MenuDivider />

      <MenuButtonHorizontalRule tooltipLabel="線" />

      <MenuButtonAddTable tooltipLabel="テーブル" />

      <MenuDivider />

      <MenuButtonRemoveFormatting tooltipLabel="スタイル削除" />

      <MenuDivider />

      <MenuButtonUndo tooltipLabel="戻る" />
      <MenuButtonRedo tooltipLabel="進む" />
    </MenuControlsContainer>
  );
}
