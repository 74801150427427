import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import {
  getDatas, getDataById, uploadFiles, deleteDatas,
} from '../../../services/base.service';
import { specialFeatureActions } from './specialFeatureState';

function* getDataList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE.SPECIAL_FEATURE, payload);
    yield put(specialFeatureActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE.SPECIAL_FEATURE_CSV, payload);
    yield put(specialFeatureActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.PAGE_SETTING.SPECIAL_FEATURE.SPECIAL_FEATURE, payload);
    yield put(specialFeatureActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.SPECIAL_FEATURE.SPECIAL_FEATURE_ID, payload);
    yield put(specialFeatureActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'topBannerImageFile' && key !== 'keyVisualImageFile') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    if (payload.topBannerImageFile) {
      formData.append('topBannerImageFile', payload.topBannerImageFile);
    }
    if (payload.keyVisualImageFile) {
      formData.append('keyVisualImageFile', payload.keyVisualImageFile);
    }

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.SPECIAL_FEATURE.SPECIAL_FEATURE, formData);
    yield put(specialFeatureActions.saveDataSuccess(result));
  } catch (_) { }
}

function* specialFeatureSaga() {
  yield takeLatest('specialFeature/getDatas', getDataList);
  yield takeLatest('specialFeature/getCsvDatas', getCsvDatas);
  yield takeLatest('specialFeature/deleteData', deleteData);
  yield takeLatest('specialFeature/getData', getData);
  yield takeLatest('specialFeature/saveData', save);
}

export default specialFeatureSaga;
