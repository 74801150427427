import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { blogContentActions } from './blogContentState';
import {
  getDatas, uploadFiles,
} from '../../../services/base.service';

function* getBlogContents({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT, payload);
    yield put(blogContentActions.getDatasSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();
    const { datas } = payload;

    const requestData = datas.map((item) => {
      const { id, imageFile, ...otherData } = item;
      return { id, ...otherData };
    });
    formData.append(
      'request',
      new Blob([JSON.stringify(requestData)], { type: 'application/json' }),
    );
    formData.append(
      'blogId',
      new Blob([JSON.stringify(payload.blogId)], { type: 'application/json' }),
    );

    // 画像を追加
    datas.forEach((item) => {
      if (item.imageFile) {
        formData.append(`imageFiles_${item.id}`, item.imageFile);
      }
    });

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.BLOG_CONTENT.BLOG_CONTENT, formData);

    yield put(blogContentActions.saveDataSuccess(result));
  } catch (_) {}
}

function* blogContentSaga() {
  yield takeLatest('blogContent/getDatas', getBlogContents);
  yield takeLatest('blogContent/saveData', save);
}

export default blogContentSaga;
