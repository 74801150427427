import React from 'react';
import {
  Button, DialogActions, Box,
} from '@mui/material';
import Dialog from '../../../components/atoms/Dialog';
import { DialogContent } from '../../../components/atoms/Base';

export default function RequestConfirmDialog(props) {
  const {
    isOpen, onClose, onClickSave, data,
  } = props;

  return (
    <Dialog maxWidth="md" isError={!data.salesUserLastName} fullWidth isOpen={isOpen} title="店舗へ確認依頼" onClose={onClose}>
      <DialogContent>
        {!data.salesUserLastName && (<Box>案件で営業担当を設定してください。</Box>) }
        {data.salesUserLastName && (
          <Box>
            <Box>店舗へ確認依頼をします。</Box>
            <Box>店舗が可と回答すると、即座に顧客店舗双方に確定書を送付します。</Box>
            <Box>よろしいですか？</Box>
          </Box>
        ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose(); }} color="inherit">{!data.salesUserLastName ? '閉じる' : 'キャンセル'}</Button>
        {data.salesUserLastName && (<Button onClick={() => { onClickSave(); }}>依頼する</Button>)}
      </DialogActions>
    </Dialog>
  );
}
