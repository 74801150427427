import { createSlice } from '@reduxjs/toolkit';

export const deliveryAgencySlice = createSlice({
  name: 'deliveryAgency',
  initialState: {
    datas: {},
    searchCondition: null,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: (state) => {
      state.csvDatas = null;
    },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const deliveryAgencyActions = deliveryAgencySlice.actions;

export default deliveryAgencySlice.reducer;
