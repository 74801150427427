import * as React from 'react';
import {
  List, Box, Tabs, Tab, IconButton,
  Paper, Tooltip, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import detailUrl from '../../../../constants/frontUrls';
import SelectDialog from './SelectPlanDialog';
import DraggableList from './DraggableList';
import { TabPanel } from '../../../../components/atoms/Base';
import { lpPlanControlActions } from '../../../../redux/pageSetting/lpPlanControl/lpPlanControlState';
import SelectAttributeDialog from './SelectAttributeDialog';
import ErrorAlert from './ErrorAlert';

export default function AttributeList(props) {
  const [datas, setDatas] = React.useState([]);
  const [attrOpen, setAttrOpen] = React.useState(false);
  const [attributeMap, setAttributeMap] = React.useState(new Map());
  const [tabValue, setTabValue] = React.useState(0);
  const [selectAttrId, setSelectAttrId] = React.useState(null);
  const [attributesArray, setAttributesArray] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const { targetName, targetId, procName } = props;

  React.useEffect(() => {
    setDatas(props.datas);
    const uniqueAttributesMap = new Map();
    let firstAttr = 0;
    props.datas.forEach((item, index) => {
      if (!uniqueAttributesMap.has(item.attributeId)) {
        uniqueAttributesMap.set(item.attributeId, item.attributeName);
      }
      if (index === 0) {
        firstAttr = item.attributeId;
      }
    });
    setAttributesArray(Array.from(uniqueAttributesMap));
    setAttributeMap(uniqueAttributesMap);
    if (!uniqueAttributesMap.has(selectAttrId) || !selectAttrId) {
      setTabValue(0);
      setSelectAttrId(firstAttr);
    }
  }, [props.datas]);

  const onAddOpen = () => {
    setOpen(true);
  };

  const onAdd = (_, t) => {
    const data = {
      id: datas.length > 0 ? Math.max(...datas.map((item) => item.id || 0)) + 1 : 1,
      planId: t.value,
      planName: t.label,
      attributeId: selectAttrId,
      isEdit: true,
    };

    const exists = datas.some((item) => item.attributeId === data.attributeId
    && item.planId === data.planId);

    if (!exists) {
      const newDatas = [...datas, data];
      setDatas(newDatas);
    }
    setOpen(false);
  };

  const onAttrAdd = (_, t) => {
    if (!attributeMap.has(t.value)) {
      attributeMap.set(t.value, t.label);
    }
    const updatedAttributesArray = Array.from(attributeMap);
    setAttributeMap(attributeMap);

    const addedAttrIndex = updatedAttributesArray.findIndex(
      ([key]) => key === t.value,
    );
    setTabValue(addedAttrIndex);
    setSelectAttrId(t.value);
    setAttrOpen(false);
  };

  const onDelete = (_, attributeId, planId) => {
    const newDatas = datas.map((item) => {
      if (item.planId === planId && selectAttrId === attributeId) {
        return { ...item, isDelete: true };
      }
      return item;
    });
    setDatas(newDatas);
  };

  const onUndo = (_, attributeId, planId) => {
    const countNotDeleted = datas.filter(
      (item) => item.attributeId === attributeId
      && item.planId !== planId && !item.isDelete,
    ).length;

    if (countNotDeleted >= 3) {
      setErrorOpen(true);
      return;
    }

    const newDatas = datas.map((item) => {
      if (item.attributeId === attributeId && item.planId === planId) {
        return { ...item, isDelete: false };
      }
      return item;
    });
    setDatas(newDatas);
  };

  const onSave = () => {
    const planIds = [];
    datas.forEach((data) => {
      if (!data.isDelete && data.attributeId === selectAttrId) {
        planIds.push(data.planId);
      }
    });
    dispatch(lpPlanControlActions.saveData({
      target: targetId, planIds, attributeId: selectAttrId,
    }));
  };

  const onTabChange = (_, newValue) => {
    setTabValue(newValue);
    const selectedAttribute = attributesArray[newValue]; // 選択したタブのデータを取得
    if (selectedAttribute) {
      const [attributeId] = selectedAttribute;
      setSelectAttrId(attributeId);
    }
  };

  return (
    <>
      <Paper sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Box display="flex">
          <Tooltip title={`${procName}追加`} placement="top">
            <IconButton onClick={() => setAttrOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {Array.from(attributeMap).map(([attributeId, attributeName]) => (
              <Tab key={attributeId} label={attributeName} />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={tabValue}>
          <Box display="flex" my={1}>
            <Button
              color="primary"
              sx={{ marginRight: '10px' }}
              variant="outlined"
              size="small"
              onClick={onAddOpen}
              disabled={datas.filter((data) => data.attributeId === selectAttrId
                && !data.isDelete).length >= 3}
            >
              プランの追加
            </Button>
            <LoadingButton loading={isLoading} variant="contained" size="small" disabled={datas.filter((e) => e.isDelete || e.isEdit).length === 0} onClick={onSave}>{`${attributeMap.get(selectAttrId)}を保存する`}</LoadingButton>
          </Box>
          <List>
            <DraggableList
              datas={datas.filter((data) => data.attributeId === selectAttrId)}
              setDatas={setDatas}
              detailUrl={detailUrl.PLAN_DETAIL}
              onDelete={onDelete}
              onUndo={onUndo}
              attributeId={selectAttrId}
            />
          </List>
        </TabPanel>

      </Paper>
      <SelectDialog open={open} onClose={() => setOpen(false)} onClick={onAdd} target="plan" />
      <SelectAttributeDialog
        open={attrOpen}
        onClose={() => setAttrOpen(false)}
        onClick={onAttrAdd}
        target={targetName}
        title={procName}
      />
      <ErrorAlert open={errorOpen} setErrorOpen={setErrorOpen} count={3} />
    </>
  );
}
