import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { customerViewFavoriteActions } from './customerViewFavoriteState';
import { getDatas } from '../../../services/base.service';

function* getDatasByFavorite({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CUSTOMER.VIEW_FAVORITE.FAVORITE, payload);
    yield put(customerViewFavoriteActions.getDatasByFavoriteSuccess(result));
  } catch (_) { }
}

function* getDatasByViewHistory({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CUSTOMER.VIEW_FAVORITE.VIEW_HISTORY, payload);
    yield put(customerViewFavoriteActions.getDatasByViewHistorySuccess(result));
  } catch (_) { }
}

function* customerViewFavoriteSaga() {
  yield takeLatest('customerViewFavorite/getDatasByFavorite', getDatasByFavorite);
  yield takeLatest('customerViewFavorite/getDatasByViewHistory', getDatasByViewHistory);
}

export default customerViewFavoriteSaga;
