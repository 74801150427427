import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions,
} from '@mui/material';
import { orderDetailActions } from '../../../redux/opportunity/order/orderDetailState';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import NumberInput from '../../../components/atoms/NumberInput';
import {
  FormErrorText, DialogContent, TaxRate, FormSwitch,
} from '../../../components/atoms/Base';
import ProductInputType from '../../../components/atoms/ProductInputType';
import Validation from './validation';
import CommonValidation from '../../../utils/common.validation';
import { emptyItemsOtherSelectedProduct } from '../../../utils/common.helper';
import SystemInfo from '../../../components/templates/SystemInfo';

const DEFAULT_ROW = {
  id: '',
  orderId: '',
  productInputTypeCode: 1,
  planId: '',
  freeProductName: '',
  shopPlanOptionId: '',
  quantity: '',
  unitPrice: '',
  taxRate: 8,
  totalPrice: '',
  burdenPrice: '',
  commissionRate: '',
  purchasingPrice: '',
  displayOrder: '',
  claimTypeId: '',
  claimed: false,
  repaid: false,
  reportHidden: false,
  updatedAt: '',
  updatedByName: '',
  createdAt: '',
  createdByName: '',
};

const DEFAULT_ERROR_MESSAGE = {
  productInputTypeCode: '',
  planId: '',
  freeProductName: '',
  shopPlanOptionId: '',
  unitPrice: '',
  purchasingPrice: '',
  quantity: '',
  taxRate: '',
  displayOrder: '',
};

export default function OrderDetailDialog(props) {
  const [row, setRow] = React.useState(DEFAULT_ROW);
  const [storeType, setStoreType] = React.useState(1);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, id, orderId, shopId,
  } = props;
  const data = useSelector((state) => state.orderDetailStore.data);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGE);

  React.useEffect(() => {
    if (isOpen) {
      let tempRow = DEFAULT_ROW;
      if (id) {
        dispatch(orderDetailActions.getData({ id }));
      }
      if (!shopId) {
        setStoreType(2);
        tempRow = { ...tempRow, productInputTypeCode: 2 };
      }
      setRow(tempRow);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setRow(data);
    }
  }, [data]);

  const beforeSaveCheck = (newRow) => {
    let tempMessages = errorMessages;
    let isError = false;

    Object.keys(newRow).forEach((key) => {
      const msg = Validation.formValidate(key, newRow[key]);
      if (msg) {
        isError = true;
      }
      tempMessages = {
        ...tempMessages,
        [key]: msg,
      };
    });
    const result = CommonValidation.productValidate(newRow, tempMessages);
    if (result.isError) {
      isError = true;
      tempMessages = result.tempMessage;
    }
    setErrorMessages(tempMessages);
    return (isError);
  };

  const onClickSave = () => {
    let newRow = row;
    if (!row.orderId) {
      newRow.orderId = orderId;
    }
    if (beforeSaveCheck(newRow)) {
      return;
    }
    newRow = emptyItemsOtherSelectedProduct(newRow);
    dispatch(orderDetailActions.saveData({ newRow }));
  };

  const onChangeProductInputType = (event) => {
    const { name, value } = event.target;
    const newRow = {
      ...row,
      [name]: value,
    };
    setRow(newRow);
  };

  const onChange = (event) => {
    const {
      name,
      value,
      unitPrice,
      taxRate,
    } = event.target;
    let newRow = row;
    if (unitPrice) {
      newRow = {
        ...newRow,
        unitPrice,
        taxRate,
      };
    }
    newRow = {
      ...newRow,
      [name]: value,
    };
    setRow(newRow);
    setErrorMessages({
      ...errorMessages,
      [name]: Validation.formValidate(name, value),
    });
  };

  const onChangeProduct = (event) => {
    const {
      name,
      value,
      unitPrice,
      taxRate,
    } = event.target;
    let newRow = row;

    let unitPriceCheck = errorMessages.unitPrice;
    let taxRateCheck = errorMessages.taxRate;
    if (unitPrice) {
      newRow = {
        ...newRow,
        unitPrice,
        taxRate,
      };
      unitPriceCheck = Validation.formValidate('unitPrice', unitPrice);
      taxRateCheck = Validation.formValidate('taxRate', taxRate);
    }
    newRow = {
      ...newRow,
      [name]: value,
    };
    setRow(newRow);
    const { tempMessage } = CommonValidation.productValidate(newRow, errorMessages);
    const message = {
      ...tempMessage,
      unitPrice: unitPriceCheck,
      taxRate: taxRateCheck,
    };
    setErrorMessages(message);
  };

  return (
    <Dialog isOpen={isOpen} title="注文明細" onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <ProductInputType
          errorMessages={errorMessages}
          onChange={onChangeProduct}
          onChangeProductInputType={onChangeProductInputType}
          shopId={shopId}
          row={row}
          storeType={storeType}
        />
        <DataGrid title="税込単価" isRequired isEdit>
          <NumberInput
            error={!!errorMessages.unitPrice}
            name="unitPrice"
            fullWidth
            value={row.unitPrice}
            onChange={(e) => onChange(e, row.unitPrice)}
          />
          <FormErrorText>{errorMessages.unitPrice}</FormErrorText>
        </DataGrid>
        {storeType === 2 && (
        <DataGrid title="仕入原価(税込)" isEdit>
          <NumberInput
            error={!!errorMessages.purchasingPrice}
            name="purchasingPrice"
            fullWidth
            value={row.purchasingPrice}
            onChange={(e) => onChange(e, row.purchasingPrice)}
          />
          <FormErrorText>{errorMessages.purchasingPrice}</FormErrorText>
        </DataGrid>
        )}
        <DataGrid title="個数" isRequired isEdit>
          <NumberInput error={!!errorMessages.quantity} name="quantity" value={row.quantity} onChange={onChange} />
          <FormErrorText>{errorMessages.quantity}</FormErrorText>
        </DataGrid>
        <DataGrid title="税率" isRequired isEdit>
          <TaxRate error={!!errorMessages.taxRate} value={row.taxRate} onChange={onChange} name="taxRate" />
          <FormErrorText>{errorMessages.taxRate}</FormErrorText>
        </DataGrid>
        <DataGrid title="Cqree負担額(税込)" isEdit>
          <NumberInput value={row.burdenPrice} name="burdenPrice" fullWidth onChange={onChange} />
        </DataGrid>
        <DataGrid taxRate title="表示順" isEdit>
          <NumberInput error={!!errorMessages.displayOrder} name="displayOrder" value={row.displayOrder} onChange={onChange} />
          <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
        </DataGrid>
        {storeType === 1 && (
        <>
          <DataGrid title="イレギュラー販売手数料率" isEdit>
            <NumberInput error={!!errorMessages.commissionRate} helperText="店舗の販売手数料率を使用しない場合のみ入力してください" isDecimal name="commissionRate" value={row.commissionRate} onChange={onChange} />
            <FormErrorText>{errorMessages.commissionRate}</FormErrorText>
          </DataGrid>
          <DataGrid title="請求対象" isEdit>
            <FormSwitch checked={row.claimed} onChange={onChange} label="請求対象にする" name="claimed" />
          </DataGrid>
          <DataGrid title="返金対象" isEdit>
            <FormSwitch checked={row.repaid} onChange={onChange} label="返金対象にする" name="repaid" />
          </DataGrid>
        </>
        )}
        <DataGrid title="帳票非表示" isEdit>
          <FormSwitch checked={row.reportHidden} onChange={onChange} label="帳票非表示にする" name="reportHidden" />
        </DataGrid>
        {row.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={row} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setErrorMessages(DEFAULT_ERROR_MESSAGE); onClose(); }} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
