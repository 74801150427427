import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import {
  LinkBubbleMenu,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
} from 'mui-tiptap';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';

export default function Editor(props) {
  const {
    onChange, content, title, name,
  } = props;

  const [editorContent, setEditorContent] = useState(content || '');
  const [submittedContent, setSubmittedContent] = useState('');
  const [isPreview, setPreview] = useState(false);

  const extensions = useExtensions({
    placeholder: `${title}詳細を入力...`,
  });

  const rteRef = useRef(null);

  useEffect(() => {
    if (rteRef.current?.editor) {
      rteRef.current.editor.commands.setContent(content || '');
    }
  }, [content]);

  const handleEditorChange = () => {
    const updatedContent = rteRef.current?.editor?.getHTML() || '';
    setEditorContent(updatedContent);

    // onChangeで最新の状態を親コンポーネントに伝える
    onChange({
      target: {
        name,
        value: updatedContent,
      },
    });
  };

  const handleBlur = () => {
    handleEditorChange(); // onBlur時に最新の状態を強制更新
  };

  return (
    <>
      {!isPreview && (
        <Box
          sx={{
            '& .ProseMirror': {
              '& h1, & h2, & h3, & h4, & h5, & h6': {
                scrollMarginTop: 50,
              },
            },
          }}
        >
          <RichTextEditor
            ref={rteRef}
            onBlur={handleBlur}
            extensions={extensions}
            content={editorContent} // 常に最新の状態を反映
            editable
            renderControls={() => <EditorMenuControls />}
            RichTextFieldProps={{
              variant: 'outlined',
              MenuBarProps: {
                hide: false,
              },
              footer: (
                <Box
                  spacing={2}
                  sx={{
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    borderTopColor: (theme) => theme.palette.divider,
                    py: 1,
                    px: 1.5,
                    textAlign: 'right',
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleEditorChange(); // ボタンを押した時点で最新の状態に更新
                      setSubmittedContent(editorContent); // 最新の内容をプレビューに設定
                      setPreview(true);
                    }}
                  >
                    プレビュー
                  </Button>
                </Box>
              ),
            }}
          >
            {() => (
              <>
                <LinkBubbleMenu />
                <TableBubbleMenu />
              </>
            )}
          </RichTextEditor>
        </Box>
      )}

      {isPreview && (
        <Box
          mt={3}
          sx={{
            border: 'rgba(0, 0, 0, 0.12) 1px solid',
            borderRadius: '4px',
          }}
        >
          <Box p={3}>
            <RichTextReadOnly
              content={submittedContent}
              extensions={extensions}
            />
          </Box>
          <Box
            spacing={2}
            sx={{
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              borderTopColor: (theme) => theme.palette.divider,
              py: 1,
              px: 1.5,
              textAlign: 'right',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setPreview(false);
              }}
            >
              編集
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
