import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../../../constants/frontUrls';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { illustrationActions } from '../../../../../redux/pageSetting/illustration/illustrationState';
import { isScreenDeleteDisabledFromUrl } from '../../../../../utils/authCheck.helper';
import { SuccessSnackbar } from '../../../../../components/atoms/Base';

const DEFAULT_SEARCH_CONDITION = {
  opportunityId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  { id: 'title', label: 'タイトル', sortId: 'title' },
  { id: 'planName', label: 'プラン名', sortId: 'plan_name' },
  { id: 'shopName', label: '店舗名', sortId: 'shop_name' },
  { id: 'illustrationStatusName', label: 'ステータス', sortId: 'illustration_status_name' },
];

export default function IllustrationList(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const [successOpen, setSuccessOpen] = useState(false);
  const illustrationDatas = useSelector((state) => state.illustrationStore.datas);
  const isDeleteSuccess = useSelector((state) => state.illustrationStore.isDeleteSuccess);
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/illustration'));

  const search = (tmpCondition) => {
    dispatch(illustrationActions.getDatas(tmpCondition));
  };

  React.useEffect(() => {
    if (illustrationDatas) {
      setDatas(illustrationDatas);
    }
  }, [illustrationDatas]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessOpen(true);
      search(searchCondition);
      dispatch(illustrationActions.resetFlg());
    }
  }, [isDeleteSuccess]);

  React.useEffect(() => {
    if (id) {
      const tmpCondition = { ...DEFAULT_SEARCH_CONDITION, opportunityId: id };
      setSearchCondition(tmpCondition);
      search(tmpCondition);
    }
  }, []);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onDelete = (deleteId) => {
    dispatch(illustrationActions.deleteDatas({ ids: [deleteId] }));
  };

  return (
    <>
      <DataTableWithMenu
        url={detailUrl.ILLUSTRATION_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        procName="実例"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onDelete={onDelete}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
        isNotAdd
      />
      <SuccessSnackbar
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        message="削除しました"
      />
    </>
  );
}
