import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { commonActions } from './commonState';
import {
  getDatas,
} from '../../../services/base.service';

function* getPointRule({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.POINT_RULE, payload);
    yield put(commonActions.getPointRuleSuccess(result));
  } catch (_) { }
}

function* commonSaga() {
  yield takeLatest('common/getPointRule', getPointRule);
}

export default commonSaga;
