import { createSlice } from '@reduxjs/toolkit';

export const opportunityFileSlice = createSlice({
  name: 'opportunityFile',
  initialState: {
    isDeleteSuccess: false,
    data: {},
    datas: [],
  },
  reducers: {
    resetFlg: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
      state.url = null;
      state.isReload = false;
    },
    getDatas: (state) => {
      state.datas = [];
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    getUrl: () => {},
    getUrlSuccess: (state, action) => {
      state.url = action.payload;
    },
    upsertData: (state) => {
      state.isSaveSuccess = false;
    },
    upsertDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    onReload: (state) => {
      state.isReload = true;
    },
  },
});

export const opportunityFileActions = opportunityFileSlice.actions;

export default opportunityFileSlice.reducer;
