import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Dialog from '../../../../components/atoms/Dialog';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';

export default function SelectPlanDialog(props) {
  const [value, setValue] = React.useState({});
  const {
    open, onClose, onClick, target,
  } = props;
  React.useEffect(() => {
    if (open) {
      setValue({});
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="xs" isOpen={open} onClose={onClose} title={`${target === 'shop' ? '店舗' : 'プラン'}の追加`}>
      <DialogContent dividers>
        {target === 'shop' && (
          <DataSelectList
            name="value"
            target="shops"
            value={value.value}
            onChange={(e) => setValue(e.target)}
          />
        )}
        {target === 'plan' && (
          <DataSelectList
            name="value"
            target="plans"
            value={value.value}
            onChange={(e) => setValue(e.target)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button
          onClick={(e) => onClick(e, value)}
          color="primary"
        >
          設定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
