import { createSlice } from '@reduxjs/toolkit';

export const contactListSlice = createSlice({
  name: 'contactList',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const contactListActions = contactListSlice.actions;

export default contactListSlice.reducer;
