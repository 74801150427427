import React from 'react';
import { Grid, Container } from '@mui/material';
import Todo from './Todo';
import OpportunityStatus from './OpportunityStatus';

export default function Home() {
  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Todo />
        </Grid>
        <Grid item xs={12} md={6}>
          <OpportunityStatus />
        </Grid>
      </Grid>
    </Container>
  );
}
