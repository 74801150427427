import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityListActions } from '../../../redux/opportunity/opportunity/opportunityListState';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import detailUrl from '../../../constants/frontUrls';
import { isScreenDeleteDisabledFromUrl } from '../../../utils/authCheck.helper';
import { SuccessSnackbar } from '../../../components/atoms/Base';

const opportunityHeadCells = [
  { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
  { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_code' },
  { id: 'heldDate', label: '開催日', sortId: 'held_date' },
  { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
];

export default function OpportunityDataTable(props) {
  const { customerId, isOpen } = props;
  const [opportunities, setOpportunities] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    customerId: customerId || null,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const dispatch = useDispatch();
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/opportunity'));
  const datas = useSelector((state) => state.opportunityListStore.datas);
  const isDeleteSuccess = useSelector((state) => state.opportunityListStore.isDeleteSuccess);

  const search = (sc) => {
    dispatch(opportunityListActions.getDatas(sc));
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(opportunityListActions.deleteData({ ids: [openedMenuRowId] }));
  };

  const onSearch = () => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  React.useEffect(() => {
    if (isOpen) {
      onSearch();
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (datas && customerId) {
      setOpportunities(datas);
    }
  }, [datas]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(opportunityListActions.resetFlg());
      onSearch();
      setSuccessOpen(true);
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <>
      <DataTableWithMenu
        url={detailUrl.OPPORTUNITY_DETAIL}
        rows={opportunities.content || []}
        procName="案件"
        headCells={opportunityHeadCells}
        totalElements={opportunities.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        isNotAdd
        onDelete={onDelete}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setSuccessOpen(false)}
        message="削除しました。"
      />
    </>
  );
}
