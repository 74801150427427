import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { accountUserActions } from './accountUserState';
import {
  getDatas, getDataById, deleteDatas, saveData, updateData,
} from '../../../services/base.service';

function* getAccountUsers({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.ACCOUNT_USER.ACCOUNT_USER, payload);
    yield put(accountUserActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.ACCOUNT_USER.ACCOUNT_USER_ID, payload);
    yield put(accountUserActions.getDataSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.ACCOUNT_USER.ACCOUNT_USER, payload);
    yield put(accountUserActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* insert({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.ACCOUNT_USER.ACCOUNT_USER, payload);
    yield put(accountUserActions.insertSuccess(result));
  } catch (_) { }
}

function* update({ payload }) {
  try {
    const result = yield call(updateData, REST_API.SHOP.ACCOUNT_USER.ACCOUNT_USER_ID, payload);
    yield put(accountUserActions.updateSuccess(result));
  } catch (_) { }
}

function* accountUserSaga() {
  yield takeLatest('accountUser/getDatas', getAccountUsers);
  yield takeLatest('accountUser/getData', getData);
  yield takeLatest('accountUser/deleteData', deleteData);
  yield takeLatest('accountUser/insert', insert);
  yield takeLatest('accountUser/update', update);
}

export default accountUserSaga;
