import React, { useEffect, useState } from 'react';
import {
  Button, RadioGroup, Radio, FormControlLabel, TableContainer, Paper,
  DialogActions, Box, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { DialogContent, FormSwitch } from '../../../../components/atoms/Base';
import NumberInput from '../../../../components/atoms/NumberInput';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';

export default function OpportunityCancelDialog(props) {
  const {
    isOpen, onClose, id,
  } = props;
  const [datas, setDatas] = useState([]);
  const [type, setType] = useState('1');
  const [createClaim, setCreateClaim] = useState(false);
  const dispatch = useDispatch();
  const cancelPrice = useSelector((state) => state.opportunityDetailStore.cancelPrice);

  useEffect(() => {
    if (isOpen) {
      dispatch(opportunityDetailActions.getCancelPrice({ id }));
      setType('1');
      setCreateClaim(false);
    }
  }, [isOpen]);
  useEffect(() => {
    if (cancelPrice) {
      setDatas(cancelPrice.data);
      setCreateClaim(cancelPrice.createClaim);
    }
  }, [cancelPrice]);

  const onClickSave = () => {
    dispatch(opportunityDetailActions.saveCancel({
      id, datas, type, createClaim,
    }));
    onClose();
  };

  const onChange = (e, shopId) => {
    const { value, name } = e.target;

    setDatas((prevDatas) => prevDatas.map((data) => (data.shopId === shopId
      ? { ...data, [name]: value }
      : data)));
  };

  return (
    <Dialog maxWidth="md" fullWidth isOpen={isOpen} title="案件キャンセル" onClose={onClose}>
      <DialogContent>
        <Box mb={1}>案件のステータスをキャンセルに更新します。</Box>
        {datas.length > 0 && (
          <RadioGroup row value={type} onChange={(e) => setType(e.target.value)} defaultValue="10">
            <FormControlLabel value="1" control={<Radio />} label="キャンセル料を適用しない" />
            <FormControlLabel value="2" control={<Radio />} label="キャンセル料を適用する" />
          </RadioGroup>
        )}
        {type === '2' && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>店舗名</TableCell>
                    <TableCell>8%キャンセル料</TableCell>
                    <TableCell>10%キャンセル料</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((row) => (
                    <TableRow key={row.shopId}>
                      <TableCell>{row.shopName}</TableCell>
                      <TableCell>
                        <NumberInput
                          name="eightPercentTotalPrice"
                          value={row.eightPercentTotalPrice}
                          onChange={(e) => onChange(e, row.shopId)}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberInput
                          name="tenPercentTotalPrice"
                          value={row.tenPercentTotalPrice}
                          onChange={(e) => onChange(e, row.shopId)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2}>
              <FormSwitch checked={createClaim} name="createClaim" onChange={(e) => setCreateClaim(e.target.value)} label="キャンセル料分の請求を作成する" />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">閉じる</Button>
        <Button onClick={onClickSave} color="secondary">キャンセルにする</Button>
      </DialogActions>
    </Dialog>
  );
}
