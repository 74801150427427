import React from 'react';
import {
  Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, Page,
} from '../../../components/pdf/Common';
import logo from '../../../asset/logo.png';
import syaban from '../../../asset/syaban.png';

export default function ReceiptFile(props) {
  const { header } = props.data || {};
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1);
  const dd = String(today.getDate());
  const formattedDate = `${yyyy}年${mm}月${dd}日`;

  return (
    <Document>
      <Page
        size="A4"
        wrap
      >
        <Root>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '50%', fontSize: '15px', flexDirection: 'row', fontFamily: 'NotoSansJP-Bold',
            }}
            >
              <Title>領収書</Title>
            </View>
            <View style={{ flexBasis: '50%', justifyContent: 'flex-end', flexDirection: 'row' }}>
              <Image
                src={logo}
                style={{
                  width: '50%',
                  height: '70%',
                }}
              />
            </View>
          </Box>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>
              {`発行日：${formattedDate}`}
            </Text>
          </View>
          <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
            <Text>{header.receiptAddress ? `${header.receiptAddress}   御中` : `${header.customerName}   様`}</Text>
          </View>
          <Box />
          <Grid style={{ marginTop: '35px' }}>
            <Col flexBasis="65%" />
            <Col flexBasis="35%">
              <Box style={{ position: 'relative', textAlign: 'left' }}>
                <Text>ケータリングセレクション</Text>
                <Text>株式会社Cqree</Text>
                <Text>〒140-0013</Text>
                <Text>東京都品川区南大井6-16-16</Text>
                <Text>鈴中ビル大森4F</Text>
                <Text>TEL：0120-262-005</Text>
                <Text>登録番号：T3-0104-0111-4543</Text>
              </Box>
              <Image
                src={syaban}
                style={{
                  width: '30%', position: 'absolute', bottom: '5px', right: '-5px',
                }}
              />
            </Col>
          </Grid>
          <Box style={{ height: '100px' }} />
          <Box style={{
            borderBottom: '1px double #000', marginTop: '10px', marginBottom: '15px', textAlign: 'center',
          }}
          >
            <Text style={{
              textAlign: 'center',
              fontSize: '36px',
            }}
            >
              {`¥${header.customerBillPrice || 0}`}
            </Text>
          </Box>
          <View style={{ marginTop: '5px', fontSize: '13px' }}><Text>{`但：${header.proviso}`}</Text></View>
          <View style={{ marginTop: '10px', fontSize: '11px' }}><Text>{`${header.paymentDate}上記正に領収いたしました。`}</Text></View>
          <Box style={{ flexDirection: 'row' }}>
            <Col flexBasis="15%" />
            <Col flexBasis="85%">
              <Text>
                {header.credit ? 'クレジットカードにて決済' : ''}
              </Text>
            </Col>
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53%',
              paddingTop: 10,
              textAlign: 'left',
              marginTop: '30px',
            }}
            >
              <Text style={{ textAlignVertical: 'bottom' }}>（適格請求書発行事業者ご利用分）</Text>
            </View>
            <View style={{ flexBasis: '47%' }} />
          </Box>

          <Box style={{ flexDirection: 'row', marginTop: 5 }}>
            <View style={{ flexBasis: '76%' }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑧：消費税8% </Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationEightPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationEightPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑩：消費税10%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationTenPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationTenPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>
            <View style={{ flexBasis: '24%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '76%', marginTop: 5 }}>
              <Text>（非適格請求書発行事業者ご利用分）</Text>
            </View>
            <View style={{
              flexBasis: '24%',
            }}
            />
          </Box>
          <Box style={{ flexDirection: 'row', marginTop: 5 }}>
            <View style={{ flexBasis: '76%' }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑧：消費税8%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionEightPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionEightPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑩：消費税10%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionTenPercentTotalPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionTenPercentTotalTaxPrice}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>
            <View style={{ flexBasis: '24%' }} />
          </Box>
          <Box style={{ marginTop: '30px' }}>
            <View><Text>■備考：</Text></View>
            <Col borderTop borderRight borderBottom borderLeft left style={{ paddingLeft: '4px', justifyContent: 'start', minHeight: '40px' }}>
              {header.receiptRemarks && header.receiptRemarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Box>
        </Root>
      </Page>
    </Document>
  );
}
