import React, { useState } from 'react';
import {
  TableCell, Button, IconButton, Menu, MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '../../atoms/Base';

const CustomButton = styled(Button)(() => ({
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'rgba(30, 144, 255, 0)',
  },
}));

export default function PcTableWithMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    row, urlTargets, headCells, secondFuncText, secondFunc, isNotEdit, url,
    onDelete, onEdit, openedMenuRowId, isNotDelete,
    setOpenedMenuRowId, setOpenedMenuRow, setOpenDelete, onShare,
    buttonFunc, buttonTargets,
  } = props;

  const handleClick = (event, rowId, targetRow) => {
    setAnchorEl(event.currentTarget);
    setOpenedMenuRowId(rowId);
    setOpenedMenuRow(targetRow);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedMenuRowId(null);
    setOpenedMenuRow(null);
  };

  const clickFuncButton = (rowData) => {
    buttonFunc(rowData);
  };

  // Generate a unique key
  const generateUniqueKey = (rowId, headCellId) => `${rowId}-${headCellId}`;

  return (
    <>
      {headCells.map((headCell) => {
        const key = generateUniqueKey(row.id, headCell.id);
        let value = row[headCell.id];
        let cmp = (
          <TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={key}>
            {value}
          </TableCell>
        );

        if (headCell.id === headCells[0].id && url) {
          cmp = (
            <TableCell key={key}>
              <Link to={`${url}${row.id}`}>{value}</Link>
            </TableCell>
          );
        }

        if (urlTargets) {
          urlTargets.forEach((e) => {
            if (e.label === headCell.id) {
              cmp = (
                <TableCell key={key}>
                  <Link to={`${e.url}${row[e.id]}`}>{value}</Link>
                </TableCell>
              );
            }
          });
        }

        if (buttonTargets) {
          buttonTargets.forEach((e) => {
            if (e.label === headCell.id) {
              cmp = (
                <TableCell key={key}>
                  <CustomButton onClick={() => clickFuncButton(row)}>
                    {value}
                  </CustomButton>
                </TableCell>
              );
            }
          });
        }

        if (headCell.numeric) {
          if (value && typeof value === 'number' && !Number.isNaN(value)) {
            value = value.toLocaleString();
          }
          cmp = (
            <TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap' }} key={key}>
              {value}
            </TableCell>
          );
        }

        return cmp;
      })}
      {(secondFuncText && !isNotEdit) && (
        <TableCell
          style={{
            padding: '0px', position: 'sticky', right: '0', background: '#fff',
          }}
        >
          <Button style={{ border: 'none', padding: '7.75px 8px' }} onClick={(e) => secondFunc(e, row)}>
            {secondFuncText}
          </Button>
        </TableCell>
      )}
      {((onDelete && !isNotDelete) || (!isNotEdit && (onEdit || onShare))) && (
        <TableCell style={{
          padding: '0px',
          position: 'sticky',
          right: '0',
          backgroundColor: 'white',
        }}
        >
          {((onDelete && row.deleteable && !isNotDelete)
          || (!isNotEdit && (onEdit || onShare))) && (
            <IconButton onClick={(event) => handleClick(event, row.id, row)}>
              <ExpandMoreIcon size="small" />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && openedMenuRowId === row.id}
            onClose={handleClose}
          >
            {(onEdit && !isNotEdit) && (
              <MenuItem
                onClick={(e) => {
                  onEdit(e, openedMenuRowId, row);
                  handleClose();
                }}
              >
                編集
              </MenuItem>
            )}
            {(onShare && !isNotEdit) && (
              <MenuItem
                onClick={(e) => {
                  onShare(e, openedMenuRowId, row);
                  handleClose();
                }}
              >
                共有の変更
              </MenuItem>
            )}
            {(onDelete && row.deleteable && !isNotDelete) && (
              <MenuItem
                onClick={() => setOpenDelete(true)}
              >
                削除
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      )}
    </>
  );
}
