import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Link, Drawer, CssBaseline, AppBar as MuiAppBar, Toolbar, List,
  Typography, Divider, IconButton, Button, Menu, MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Menu as MenuIcon, ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { urls } from '../../constants/frontUrls';
import RouterBreadcrumbs from './RouterBreadcrumbs';
import CollapseList from './CollapseList';
import HeaderMenuItem from './HeaderMenuItem';
import Loading from './Loading';
import AlertButton from './AlertButton';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    marginTop: 90,
    paddingTop: 10,
    paddingBotton: 5,
    paddingLeft: 15,
    paddingRight: 15,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Layout(props) {
  const [open, setOpen] = React.useState(false);
  const [openOpportunity, setOpenOpportunity] = React.useState(false);
  const [openShop, setOpenShop] = React.useState(false);
  const [openPageSetting, setOpenPageSetting] = React.useState(false);
  const [openSystemSetting, setOpenSystemSetting] = React.useState(false);

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [closeAnchorEl, setCloseAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onCloseAnchorClose = () => {
    setCloseAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const onClickOpportunity = () => {
    setOpenOpportunity(!openOpportunity);
  };
  const onClickShop = () => {
    setOpenShop(!openShop);
  };
  const onClickPageSetting = () => {
    setOpenPageSetting(!openPageSetting);
  };
  const onClickSystemSetting = () => {
    setOpenSystemSetting(!openSystemSetting);
  };

  const onClickLogout = () => {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  };

  const onClickPassword = () => {
    navigate(urls.PROFILE.PASSWORD.url);
    setProfileAnchorEl(null);
  };

  const usetData = useSelector((state) => state.loginStore.userInfo);
  const userInfo = usetData ? usetData.user : {};
  const screens = usetData ? usetData.screenList : [];

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <Link href="/" underline="none" color="white">catering selection</Link>
          </Typography>
          <Menu
            anchorEl={closeAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(closeAnchorEl)}
            onClose={onCloseAnchorClose}
          />
          <AlertButton />
          <div>
            <Button
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Typography variant="h6" noWrap>
                {userInfo && userInfo.name}
              </Typography>
            </Button>
            <Menu
              anchorEl={profileAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={onClickPassword}>
                パスワード変更
              </MenuItem>
              <Divider />
              <MenuItem onClick={onClickLogout}>
                ログアウト
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <RouterBreadcrumbs />
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />

        <CollapseList
          isShow={screens.filter((row) => row.categoryNo === 0).length > 0}
          onClick={onClickOpportunity}
          menuTitle="案件管理"
          isOpen={openOpportunity}
        >
          <List>
            {urls.OPPORTUNITY.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.categoryNo === 1).length > 0}
          onClick={onClickShop}
          menuTitle="店舗管理"
          isOpen={openShop}
        >
          <List>
            {urls.SHOP.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>
        <CollapseList
          isShow={screens.filter((row) => row.categoryNo === 2).length > 0}
          onClick={onClickPageSetting}
          menuTitle="ページ設定"
          isOpen={openPageSetting}
        >
          <List>
            {urls.PAGE_SETTING.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>
        <CollapseList
          isShow={screens.filter((row) => row.categoryNo === 3).length > 0}
          onClick={onClickSystemSetting}
          menuTitle="システム設定"
          isOpen={openSystemSetting}
        >
          <List>
            {urls.SYSTEM_SETTING.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>
      </Drawer>
      <Loading />
      <Main open={open}>
        {props.children}
      </Main>
    </Box>
  );
}
