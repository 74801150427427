import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import HttpConnection from '../../../utils/http-connection';
import { REST_URL } from '../../../constants/serverUrls';

export default function DataMultiSelectList(props) {
  const {
    error,
    values,
    onChange,
    disableClearable,
    isDisabled,
    name,
    target,
    parameter,
    fullWidth,
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, { ...parameter, page: 0, records: 50 });
    setOptions(tmpData.content);
    setLoading(false);
  };

  useEffect(() => {
    if (name) {
      fetchData();
    }
  }, [name]);

  const targetValue = [];
  options.forEach((c) => {
    if (values) {
      values.forEach((v) => {
        if (v === c.id) {
          targetValue.push(c);
        }
      });
    }
  });

  return (
    <Autocomplete
      size="small"
      multiple
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          autoComplete="off"
          variant="outlined"
          error={error}
          placeholder="選択してください"
        />
      )}
      value={targetValue}
      onChange={(_, newValue) => {
        onChange({
          target: {
            name,
            value: newValue.map((n) => n.id),
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
      fullWidth={fullWidth}
    />
  );
}
