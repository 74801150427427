import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customerViewFavoriteActions } from '../../../redux/opportunity/customer/customerViewFavoriteState';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';

const headerCells = [
  { id: 'target', label: '対象', sortId: 'target' },
  { id: 'targetName', label: '対象名', sortId: 'target_name' },
  { id: 'createdAt', label: '登録日', sortId: 'created_at' },
];

export default function ViewFavoriteDataTable(props) {
  const { customerId, target } = props;
  const [data, setData] = React.useState({});
  const [searchCondition, setSearchCondition] = React.useState({
    customerId: customerId || null,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: 'id',
  });
  const dispatch = useDispatch();
  const dbFavoriteData = useSelector((state) => state.customerViewFavoriteStore.favorites);
  const dbViewData = useSelector((state) => state.customerViewFavoriteStore.viewHistories);

  const search = (sc) => {
    if (target === 'favorite') {
      dispatch(customerViewFavoriteActions.getDatasByFavorite(sc));
    }
    if (target === 'view') {
      dispatch(customerViewFavoriteActions.getDatasByViewHistory(sc));
    }
  };

  const onSearch = () => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  React.useEffect(() => {
    onSearch();
  }, []);
  React.useEffect(() => {
    if (dbFavoriteData && target === 'favorite' && customerId) {
      setData(dbFavoriteData);
    }
  }, [dbFavoriteData]);
  React.useEffect(() => {
    if (dbViewData && target === 'view' && customerId) {
      setData(dbViewData);
    }
  }, [dbViewData]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <DataTableWithMenu
      rows={data.content || []}
      headCells={headerCells}
      totalElements={data.totalElements}
      rowsPerPage={searchCondition.records}
      page={searchCondition.page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleRequestSort={handleRequestSort}
      isNotAdd
      order={searchCondition.order}
      orderBy={searchCondition.orderBy}
      disabled
    />
  );
}
