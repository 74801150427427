import React from 'react';
import { connect } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { accountActions } from '../../../redux/shop/account/accountState';
import AccountUserDataTable from '../accountUser/AccountUserDataTable';
import { RecordBox, withParams, TabPanel } from '../../../components/atoms/Base';
import Tabs from '../../../components/templates/Tabs';
import DataGrid from '../../../components/templates/DataGrid';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import ShopList from './ShopList';

class AccountDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;

    props.dispatch(accountActions.getData({ id }));
    this.state = {
      tabValue: 0,
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
      data: {
        id: '',
        accountNumber: '',
        name: '',
        phone: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountInfo.data !== this.props.accountInfo.data) {
      if (this.props.accountInfo.data) {
        this.setAccount(this.props.accountInfo.data);
      }
    }
  }

  setAccount = (data) => {
    this.setState({ data });
  };

  render() {
    const {
      data, isNotDelete, isNotEdit, tabValue,
    } = this.state;

    return (
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{ display: { xs: 'none', md: 'block' } }}
            style={{ minHeight: '50px' }}
          />
          <RecordBox>
            <DataGrid title="契約先コード" value={data.accountNumber} />
            <DataGrid title="企業名" value={data.name} />
            <DataGrid title="電話番号" value={data.phone} />
          </RecordBox>
        </Grid>
        <Grid item xs={12} md={8}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => this.setState({ tabValue: value })}
            tabs={['店舗', 'ログイン情報']}
          />
          <TabPanel value={tabValue} index={0}>
            <ShopList accountId={data.id} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AccountUserDataTable
              accountId={data.id}
              isNotDelete={isNotDelete}
              isNotEdit={isNotEdit}
            />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(AccountDetail));
