import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTableWithMenuNoSort from '../../../../components/templates/DataTableWithMenuNoSort';
import { specialFeatureAttachedActions } from '../../../../redux/pageSetting/specialFeature/specialFeatureAttachedState';
import SpecialFeaturePlanDialog from './SpecialFeaturePlanDialog';
import { SuccessSnackbar } from '../../../../components/atoms/Base';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../../utils/authCheck.helper';
import detailUrl from '../../../../constants/frontUrls';

const headCells = [
  { id: 'publishedStr', label: '公開', sortId: 'published' },
  { id: 'planName', label: 'プラン', sortId: 'plan_name' },
];

export default function SpecialFeaturePlanList(props) {
  const { specialFeatureId } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const dbDatas = useSelector((state) => state.specialFeatureAttachedStore.plans);
  const isSaveSuccess = useSelector(
    (state) => state.specialFeatureAttachedStore.isSavePlanSuccess,
  );
  const isDeleteSuccess = useSelector(
    (state) => state.specialFeatureAttachedStore.isPlanDeleteSuccess,
  );
  const usetData = useSelector((state) => state.loginStore.userInfo);
  const isNotEdit = isScreenEditDisabled(usetData);
  const isNotDelete = isScreenDeleteDisabled(usetData);

  React.useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const search = () => {
    dispatch(specialFeatureAttachedActions.getPlans({ specialFeatureId }));
  };

  React.useEffect(() => {
    if (specialFeatureId) {
      search();
    }
  }, [specialFeatureId]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setSuccessOpen(true);
      setSuccessMessage('保存しました。');
      dispatch(specialFeatureAttachedActions.resetFlg());
      search();
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessOpen(true);
      setSuccessMessage('削除しました。');
      dispatch(specialFeatureAttachedActions.resetFlg());
      search();
    }
  }, [isDeleteSuccess]);

  const onAdd = () => {
    setSuccessOpen(false);
    setRowId(null);
    setOpen(true);
  };

  const onClose = () => {
    setRowId(null);
    setOpen(false);
  };

  const onCloseSnackbar = () => {
    setSuccessOpen(false);
  };

  const onDelete = (deleteId) => {
    setSuccessOpen(false);
    dispatch(specialFeatureAttachedActions.deletePlan({ ids: [deleteId] }));
  };

  const onEdit = (_, selectId) => {
    setRowId(selectId);
    setOpen(true);
    setSuccessOpen(false);
  };

  return (
    <>
      <DataTableWithMenuNoSort
        rows={datas || []}
        headCells={headCells}
        onAddOpen={onAdd}
        procName="プラン"
        onDelete={onDelete}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
        urlTargets={[{ url: detailUrl.PLAN_DETAIL, label: 'planName', id: 'planId' }]}
        onEdit={onEdit}
      />
      <SpecialFeaturePlanDialog
        specialFeatureId={specialFeatureId}
        isOpen={open}
        onClose={onClose}
        id={rowId}
      />
      <SuccessSnackbar
        open={successOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}
