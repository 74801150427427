import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'claimStatusCode':
      case 'opportunityId':
        return CommonValidation.requiredSelect(value);
      case 'paymentDate':
      case 'paymentDueDate':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };

  static detailFormValidate = (type, value) => {
    switch (type) {
      case 'taxRate':
        return CommonValidation.requiredSelect(value);
      case 'unitPrice':
      case 'quantity':
        return CommonValidation.requiredNotMinusNumber(value);
      case 'displayOrder':
        return CommonValidation.notMinusNumber(value);
      case 'freeProductName':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };

  static relationValidate = (type, value) => {
    switch (type) {
      case 'orderId':
        return CommonValidation.requiredSelect(value);
      default:
        return '';
    }
  };
}

export default Validation;
