import React from 'react';
import {
  TextField, DialogActions, Button, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DataGrid from '../../../../components/templates/DataGrid';
import {
  FormSwitch, DateTimePicker, TaxRate, FormErrorText, TextArea, DialogContent,
} from '../../../../components/atoms/Base';
import Validation from '../validation';
import OptionTypeInput from './OptionTypeInput';
import NumberInput from '../../../../components/atoms/NumberInput';
import SingleFileInput from '../../../../components/templates/SingleFileInput';
import RadioButtons from '../../../../components/atoms/RadioButtons';
import SystemInfo from '../../../../components/templates/SystemInfo';

const DEFAULT_FORM = {
  id: '',
  optionTypeCode: 1,
  name: '',
  introduction: '',
  price: '',
  taxRate: 8,
  imagePath: '',
  imageName: '',
  orderStartDt: '',
  orderEndDt: '',
  publishDt: '',
  minOptionAssignTypeCode: 0,
  maxOptionAssignTypeCode: 0,
  minOptionAssignValue: '',
  maxOptionAssignValue: '',
  isDelete: false,
  cqreeProvided: false,
  published: false,
};

const DEFAULT_ERROR = {
  optionTypeCode: '',
  name: '',
  introduction: '',
  price: '',
  taxRate: '',
  imagePath: '',
  imageName: '',
  orderStartDt: '',
  orderEndDt: '',
  publishDt: '',
  minOptionAssignTypeCode: '',
  maxOptionAssignTypeCode: '',
  minOptionAssignValue: '',
  maxOptionAssignValue: '',
};

export default function OptionDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    shopId,
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR);
  const option = useSelector((state) => state.shopDetailStore.optionData);

  React.useEffect(() => {
    if (isOpen && openedMenuRowId) {
      setErrorMessages(DEFAULT_ERROR);
      dispatch(shopDetailActions.getOptionDataById({ id: openedMenuRowId }));
    }
    if (isOpen && !openedMenuRowId) {
      setErrorMessages(DEFAULT_ERROR);
      setForm(DEFAULT_FORM);
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (option) {
      setForm(option);
    }
  }, [option]);

  const inputCheck = (name, value) => {
    let msg = Validation.optionFormValidate(name, value);
    if (msg || !(name === 'orderStartDt' || name === 'orderEndDt') || !value) return msg;
    if (name === 'orderStartDt' && form.orderEndDt && form.orderEndDt < value) {
      msg = '終了日より過去を入力してください';
    }
    if (name === 'orderEndDt' && form.orderStartDt && form.orderStartDt > value) {
      msg = '開始日より未来を入力してください';
    }
    return msg;
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrorMessages({ ...errorMessages, [name]: inputCheck(name, value) });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;
    let tempForm = form;
    if (tempForm.minOptionAssignTypeCode !== 2) {
      tempForm = {
        ...tempForm,
        minOptionAssignValue: '',
      };
      tempMessage = {
        minOptionAssignValue: '',
      };
    }
    if (form.maxOptionAssignTypeCode !== 2) {
      tempForm = {
        ...tempForm,
        maxOptionAssignValue: '',
      };
      tempMessage = {
        maxOptionAssignValue: '',
      };
    }
    Object.keys(tempForm).forEach((key) => {
      const msg = inputCheck(key, form[key]);
      if (key === 'minOptionAssignValue' || key === 'maxOptionAssignValue') return;
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (form.minOptionAssignTypeCode === 2) {
      let nameError = '';
      if (!form.minOptionAssignValue) {
        nameError = '入力して下さい';
        isError = true;
      }
      if (form.maxOptionAssignValue && form.minOptionAssignValue
        && form.minOptionAssignValue > form.maxOptionAssignValue) {
        nameError = '最大数より小さい値を入力して下さい';
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        minOptionAssignValue: nameError,
      };
    }
    if (form.maxOptionAssignTypeCode === 2) {
      let nameError = '';
      if (!form.maxOptionAssignValue) {
        nameError = '入力して下さい';
        isError = true;
      }
      if (form.maxOptionAssignValue && form.minOptionAssignValue
        && form.minOptionAssignValue > form.maxOptionAssignValue) {
        nameError = '最低数より大きい値を入力して下さい';
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        maxOptionAssignValue: nameError,
      };
    }

    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) return;
    dispatch(shopDetailActions.saveOptionData({
      ...form,
      shopId,
    }));
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      title={`オプションの${form.id ? '編集' : '追加'}`}
    >
      <DialogContent>
        <DataGrid title="オプションタイプ" isEdit isRequired>
          <RadioButtons
            name="optionTypeCode"
            target="codes"
            parameter={{ codeCategory: 'option_type' }}
            value={form.optionTypeCode}
            error={!!errorMessages.optionTypeCode}
            onChange={onChange}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.optionTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="オプション名" isEdit isRequired>
          <TextField value={form.name} fullWidth name="name" onChange={onChange} error={!!errorMessages.name} inputProps={{ maxLength: 50 }} />
          <FormErrorText>{errorMessages.name}</FormErrorText>
        </DataGrid>
        <DataGrid title="説明" isEdit>
          <TextArea value={form.introduction} name="introduction" onChange={onChange} />
        </DataGrid>
        <DataGrid title="税込価格" isEdit isRequired>
          <NumberInput value={form.price} fullWidth name="price" onChange={onChange} error={!!errorMessages.price} />
          <FormErrorText>{errorMessages.price}</FormErrorText>
        </DataGrid>
        <DataGrid title="税率" isRequired isEdit>
          <TaxRate error={!!errorMessages.taxRate} value={form.taxRate} onChange={onChange} name="taxRate" />
          <FormErrorText>{errorMessages.taxRate}</FormErrorText>
        </DataGrid>
        <SingleFileInput
          title="イメージ"
          imagePath="imagePath"
          imageFile="imageFile"
          isEdit
          form={form}
          inputForm={form}
          setInputForm={setForm}
        />
        <DataGrid title="提供開始日" isEdit>
          <DateTimePicker
            value={form.orderStartDt}
            onChange={onChange}
            name="orderStartDt"
            error={!!errorMessages.orderStartDt}
          />
          <FormErrorText>{errorMessages.orderStartDt}</FormErrorText>
        </DataGrid>
        <DataGrid title="提供終了日" isEdit>
          <DateTimePicker
            value={form.orderEndDt}
            onChange={onChange}
            name="orderEndDt"
            error={!!errorMessages.orderEndDt}
          />
          <FormErrorText>{errorMessages.orderEndDt}</FormErrorText>
        </DataGrid>
        <OptionTypeInput onChange={onChange} row={form} errorMessages={errorMessages} />
        <DataGrid title="提供者" isEdit>
          <FormSwitch checked={form.cqreeProvided} onChange={onChange} label="Cqreeから提供する" name="cqreeProvided" />
        </DataGrid>
        <DataGrid title="公開状況" isEdit>
          <FormSwitch checked={form.published} onChange={onChange} label="公開する" name="published" />
        </DataGrid>
        <DataGrid title=" 公開日時" isEdit>
          <DateTimePicker
            value={form.publishDt}
            onChange={onChange}
            name="publishDt"
            error={!!errorMessages.publishDt}
          />
          <FormErrorText>{errorMessages.publishDt}</FormErrorText>
        </DataGrid>
        {form.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
