import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reviewActions } from '../../../redux/pageSetting/review/reviewState';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import detailUrl from '../../../constants/frontUrls';
import { isScreenDeleteDisabledFromUrl } from '../../../utils/authCheck.helper';
import { SuccessSnackbar } from '../../../components/atoms/Base';

const reviewHeadCells = [
  { id: 'title', label: 'タイトル', sortId: 'title' },
  { id: 'opportunityNumber', label: '案件', sortId: 'opportunity_number' },
  { id: 'shopName', label: '店舗', sortId: 'shop_name' },
  { id: 'averageEvaluation', label: '評価', sortId: 'average_evaluation' },
  { id: 'commentExisted', label: 'コメント', sortId: 'comment_existed' },
  { id: 'imageExisted', label: '画像', sortId: 'image_existed' },
  { id: 'reviewStatusName', label: 'ステータス', sortId: 'review_status_name' },
];

export default function OpportunityDataTable(props) {
  const { customerId, isOpen } = props;
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [reviews, setReviews] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    customerId: customerId || null,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const dispatch = useDispatch();
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/review'));
  const datas = useSelector((state) => state.reviewStore.datas);
  const isDeleteSuccess = useSelector((state) => state.reviewStore.isDeleteSuccess);

  const search = (sc) => {
    dispatch(reviewActions.getDatas(sc));
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(reviewActions.deleteData({ ids: [openedMenuRowId] }));
  };

  const onSearch = () => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  React.useEffect(() => {
    if (isOpen) {
      onSearch();
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (datas && customerId) {
      setReviews(datas);
    }
  }, [datas]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(reviewActions.resetFlg());
      onSearch();
      setSuccessOpen(true);
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <>
      <DataTableWithMenu
        url={detailUrl.REVIEW_DETAIL}
        rows={reviews.content || []}
        procName="口コミ"
        headCells={reviewHeadCells}
        urlTargets={[{ url: detailUrl.OPPORTUNITY_DETAIL, label: 'opportunityNumber', id: 'opportunityId' }]}
        totalElements={reviews.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        isNotAdd
        onDelete={onDelete}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={() => setSuccessOpen(false)}
        message="削除しました。"
      />
    </>
  );
}
