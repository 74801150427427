import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value, passwordCheck) => {
    switch (type) {
      case 'affiliationTypeCode':
      case 'pointGrantTypeCode':
        return CommonValidation.requiredSelect(value);
      case 'nameKana':
      case 'name':
      case 'reason':
        return CommonValidation.requiredValue(value);
      case 'point':
        return CommonValidation.requiredNumber(value);
      case 'email':
        return CommonValidation.requiredEmail(value);
      case 'targetDate':
      case 'expirationDate':
        return CommonValidation.requiredDate(value);
      case 'password':
        return CommonValidation.requiredPassword(value);
      case 'passwordCheck':
        return CommonValidation.requiredPasswordCheck(value, passwordCheck);
      case 'fixedPhone':
      case 'mobilePhone':
        return CommonValidation.nonRequiredPhoneNumber(value);
      default:
        return '';
    }
  };
}

export default Validation;
