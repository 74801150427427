import React from 'react';
import { connect } from 'react-redux';
import { Grid, Box, TextField } from '@mui/material';
import { specialFeatureActions } from '../../../redux/pageSetting/specialFeature/specialFeatureState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SuccessSnackbar, DateTimePicker } from '../../../components/atoms/Base';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  name: '',
  publishStartDate: '',
  publishEndDate: '',
  specialFeatureStatusCodes: [],
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: '特集名' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'publishStartDt', label: '公開開始日時' },
  { key: 'publishEndDt', label: '公開終了日時' },
  { key: 'specialFeatureStatusCode', label: 'ステータスコード' },
  { key: 'specialFeatureStatusName', label: 'ステータス名' },
  { key: 'passwordCleartext', label: 'パスワード' },
  { key: 'externalUrl', label: '外部URL' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },

];

class SpecialFeatureList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '特集名', sortId: 'name' },
      { id: 'specialFeatureStatusName', label: 'ステータス', sortId: 'special_feature_status_name' },
      { id: 'publishDt', label: '公開期間', sortId: 'publish_dt' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;
    if (props.specialFeatureInfo.searchCondition) {
      searchCondition = props.specialFeatureInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '特集',
        done: false,
        datas: [],
      },
    };
    this.props.dispatch(specialFeatureActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.specialFeatureInfo.datas !== this.props.specialFeatureInfo.datas) {
      if (this.props.specialFeatureInfo.datas) {
        this.setDatas(this.props.specialFeatureInfo.datas);
      }
    }
    if (prevProps.specialFeatureInfo.csvDatas !== this.props.specialFeatureInfo.csvDatas) {
      if (this.props.specialFeatureInfo.csvDatas) {
        this.setCsvDatas(this.props.specialFeatureInfo.csvDatas);
      }
    }
    if (prevProps.specialFeatureInfo.isDeleteSuccess
      !== this.props.specialFeatureInfo.isDeleteSuccess) {
      if (this.props.specialFeatureInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(specialFeatureActions.setSearchCondition(searchCondition));
    this.props.dispatch(specialFeatureActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(specialFeatureActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(specialFeatureActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isDeleteDisabled,
      isEditDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="special-feature"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="特集名"
          simpleAreaOne={(
            <TextField value={searchCondition.name} fullWidth name="name" onChange={this.onChange} />
          )}
          simpleAreaTwoText="ステータス"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="specialFeatureStatusCodes"
              target="codes"
              parameter={{ codeCategory: 'special_feature_status' }}
              values={searchCondition.specialFeatureStatusCodes}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="公開期間" />
              <Box display="flex" alignItems="center">
                <Box>
                  <DateTimePicker value={searchCondition.publishStartDate} name="publishStartDate" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <DateTimePicker value={searchCondition.publishEndDate} name="publishEndDate" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.SPECIAL_FEATURE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="特集"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  specialFeatureInfo: state.specialFeatureStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(SpecialFeatureList);
