import React, { useState } from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Tooltip, Button, useMediaQuery,
} from '@mui/material';
import { Pagination } from '../atoms/Base';
import DeleteDialog from './DeleteDialog';
import PcTableWithMenu from './dataTableWithMenu/PcTableWithMenu';
import SmartPhoneTableWithMenu from './dataTableWithMenu/SmartPhoneTableWithMenu';

export default function DataTableWithMenuNoSort(props) {
  const [openedMenuRowId, setOpenedMenuRowId] = useState(null);
  const [openedMenuRow, setOpenedMenuRow] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const {
    rows,
    procName,
    headCells,
    secondFuncText,
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    isNotEdit,
    isNotDelete,
    url,
    urlTargets,
    onAddOpen,
    onEdit,
    isNotAdd,
    onDelete,
    secondFunc,
    onShare,
    buttonFunc,
    buttonTargets,
    isShowSelected,
    selectedId,
  } = props;

  const onAdd = () => {
    onAddOpen();
  };

  const onPreDelete = () => {
    onDelete(openedMenuRowId, openedMenuRow);
    setOpenDelete(false);
    setOpenedMenuRowId(null);
    setOpenedMenuRow(null);
  };

  const colSpan = headCells.length + (!isNotEdit && secondFuncText ? 2 : 1);

  return (
    <div>
      <Paper>
        <Toolbar style={{ paddingLeft: 0 }}>
          <>
            <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
            {(!isNotEdit && !isNotAdd) && (
              <Tooltip title={`${procName}の追加`}>
                <Button color="primary" variant="outlined" onClick={onAdd}>{`${procName}の追加`}</Button>
              </Tooltip>
            )}
          </>
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {isPc && headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    {headCell.label}
                  </TableCell>
                ))}
                {(isPc && !isNotEdit && secondFuncText) && <TableCell />}
                {(isPc && ((onDelete && !isNotDelete) || (onEdit && !isNotEdit))) && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  selected={isShowSelected && selectedId === row.id}
                  key={`row-${row.id}`}
                  sx={{ height: '56px' }}
                >
                  {isPc && (
                    <PcTableWithMenu
                      row={row}
                      urlTargets={urlTargets}
                      headCells={headCells}
                      secondFuncText={secondFuncText}
                      secondFunc={secondFunc}
                      isNotEdit={isNotEdit}
                      isNotDelete={isNotDelete}
                      url={url}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onShare={onShare}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenedMenuRow={setOpenedMenuRow}
                      setOpenDelete={setOpenDelete}
                      buttonFunc={buttonFunc}
                      buttonTargets={buttonTargets}
                    />
                  )}
                  {!isPc && (
                    <SmartPhoneTableWithMenu
                      row={row}
                      headCells={headCells}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onShare={onShare}
                      isNotEdit={isNotEdit}
                      isNotDelete={isNotDelete}
                      secondFuncText={secondFuncText}
                      secondFunc={secondFunc}
                      openedMenuRowId={openedMenuRowId}
                      setOpenedMenuRowId={setOpenedMenuRowId}
                      setOpenedMenuRow={setOpenedMenuRow}
                      setOpenDelete={setOpenDelete}
                    />
                  )}
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        {!!totalElements && (
          <Pagination
            totalElements={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}

        <DeleteDialog
          open={openDelete}
          onClose={() => {
            setOpenedMenuRowId(null);
            setOpenedMenuRow(null);
            setOpenDelete(false);
          }}
          procName={procName}
          onDelete={onPreDelete}
        />
      </Paper>
    </div>
  );
}
