import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { contactDetailActions } from './contactDetailState';
import { getDataById, saveData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.CONTACT.CONTACT_ID, payload);
    yield put(contactDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.CONTACT.CONTACT, payload);
    yield put(contactDetailActions.saveDataSuccess(result));
  } catch (_) { }
}

function* createOpportunity({ payload }) {
  const result = yield call(saveData, REST_API.OPPORTUNITY.CONTACT.CONTACT_OPPORTUNITY, payload);
  yield put(contactDetailActions.createOpportunitySuccess(result));
}

function* contactDetailSaga() {
  yield takeLatest('contactDetail/getData', getData);
  yield takeLatest('contactDetail/saveData', save);
  yield takeLatest('contactDetail/createOpportunity', createOpportunity);
}

export default contactDetailSaga;
