import { createSlice } from '@reduxjs/toolkit';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => { },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    bulkPointGrant: (state) => {
      state.isBulkPointGrantSuccess = false;
    },
    bulkPointGrantSuccess: (state) => {
      state.isBulkPointGrantSuccess = true;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => { },
    getDataSuccess: (state, action) => {
      state.datas = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.saveId = action.payload;
      state.isSaveSuccess = true;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const customerActions = customerSlice.actions;

export default customerSlice.reducer;
