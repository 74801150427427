import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField, DialogActions, Button, Box,
} from '@mui/material';
import DataGrid from '../../../../../components/templates/DataGrid';
import Dialog from '../../../../../components/atoms/Dialog';
import Dropzone from '../../../../../components/atoms/Dropzone';
import { opportunityAttachActions } from '../../../../../redux/opportunity/opportunity/opportunityAttachState';
import Validation from '../validation';
import { FormErrorText, DialogContent, LoadingButton } from '../../../../../components/atoms/Base';

const DEFAULT_FORM = {
  id: '',
  file: [],
  title: '',
};

const DEFAULT_ERROR = {
  title: '',
  file: '',
};

export default function FileUploadDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    opportunityNum,
    opportunityId,
  } = props;
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.opportunityAttachStore.deliveryData);
  const isSaveSuccess = useSelector((state) => state.opportunityAttachStore.isSaveDeliverySuccess);
  const [title, setTitle] = React.useState('');
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR);

  React.useEffect(() => {
    if (isOpen) {
      if (!openedMenuRowId) {
        setTitle('アップロード');
      } else {
        dispatch(opportunityAttachActions.getDeliveryData({ id: openedMenuRowId }));
        setTitle('件名の変更');
      }
      setForm({
        ...DEFAULT_FORM,
        opportunityNum,
        opportunityId,
      });
      setErrorMessages(DEFAULT_ERROR);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (fileData && fileData.opportunityId && openedMenuRowId) {
      setForm(fileData);
    }
  }, [fileData]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setForm(DEFAULT_FORM);
    }
  }, [isSaveSuccess]);

  const onFileChange = (value) => {
    setForm({
      ...form,
      file: value[0],
    });
    const msg = Validation.fileValidate('file', value[0]);
    setErrorMessages({ ...errorMessages, file: msg });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    const msg = Validation.fileValidate([name], value);
    setErrorMessages({ ...errorMessages, [name]: msg });
  };

  const onSave = () => {
    let isError = false;
    let tempMessage = errorMessages;
    Object.keys(form).forEach((key) => {
      const msg = Validation.fileValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    if (isError) return;

    const tempForm = {
      ...form,
      opportunityNum,
      opportunityId,
    };
    dispatch(opportunityAttachActions.saveDeliveryData(tempForm));
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent>
        {!openedMenuRowId && (
          <>
            <Box mb={3}>
              <Dropzone multiple={false} onChange={onFileChange} />
            </Box>
            <FormErrorText>{errorMessages.file}</FormErrorText>
          </>
        )}
        <DataGrid title="件名" isEdit isRequired>
          <TextField value={form.title} name="title" onChange={onChange} error={!!errorMessages.title} fullWidth inputProps={{ maxLength: 100 }} />
          <FormErrorText>{errorMessages.title}</FormErrorText>
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setForm(DEFAULT_FORM); onClose(); }} color="inherit">キャンセル</Button>
        <LoadingButton onClick={onSave}>保存</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
