import { createSlice } from '@reduxjs/toolkit';

export const opportunityListSlice = createSlice({
  name: 'opportunityList',
  initialState: {
    searchCondition: null,
    isGrantSuccess: false,
    datas: {},
    csvDatas: [],
    calendarDatas: [],
    isDeleteSuccess: false,
  },

  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => {},
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    getCalendarData: () => {},
    getCalendarDataSuccess: (state, action) => {
      state.calendarDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    grantPoint: (state) => {
      state.isGrantSuccess = false;
    },
    grantPointSuccess: (state) => {
      state.isGrantSuccess = true;
    },
    createClaim: (state) => {
      state.saveClaimId = null;
    },
    createClaimSuccess: (state, action) => {
      state.saveClaimId = action.payload;
    },
    resetFlg: (state) => {
      state.isDeleteSuccess = false;
    },
  },
});

export const opportunityListActions = opportunityListSlice.actions;

export default opportunityListSlice.reducer;
