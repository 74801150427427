import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Grid, Button, Box,
  Paper, Alert, Container,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails,
} from '@mui/material';
import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  RecordBox, withParams, FormTitle, SuccessSnackbar, TabPanel, FormSwitch,
} from '../../../components/atoms/Base';
import { roleDetailActions } from '../../../redux/systemSetting/role/roleDetailState';
import RoleDetailDialog from './RoleDetailDialog';
import {
  getIndex, getIncludes, getEdit, getFormatScreens,
} from './role-detail-proc';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import MemberDataTable from './MemberDataTable';
import Tabs from '../../../components/templates/Tabs';
import ConfirmDialog from '../../../components/templates/ConfirmDialog';
import RecordHeader, { RecordHeaderButton } from '../../../components/templates/RecordHeader';
import detailUrl from '../../../constants/frontUrls';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters defaultExpanded elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.roleDetail.isSaveSuccess) {
      successSnackOpen = true;
      successSnackMessage = '登録しました。';
    }
    if (props.roleDetail.isCopySuccess) {
      successSnackOpen = true;
      successSnackMessage = 'コピーしました。';
    }
    props.dispatch(roleDetailActions.resetFlg());

    this.state = {
      screens: new Map(),
      cateogories: [],
      roleScreens: [],
      members: [],
      role: {
        id: null,
        name: '',
      },
      tabValue: 0,
      updateModalOpen: false,
      successSnackOpen,
      successSnackMessage,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
    };

    props.dispatch(roleDetailActions.getScreens());
    props.dispatch(roleDetailActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roleDetail.screens !== this.props.roleDetail.screens) {
      if (this.props.roleDetail.screens) {
        this.setScreens(this.props.roleDetail.screens);
      }
    }
    if (prevProps.roleDetail.data !== this.props.roleDetail.data) {
      if (this.props.roleDetail.data) {
        this.setData(this.props.roleDetail.data);
      }
    }
    if (prevProps.roleDetail.isSaveSuccess !== this.props.roleDetail.isSaveSuccess) {
      if (this.props.roleDetail.isSaveSuccess) {
        this.setUpdateSuccess();
      }
    }
    if (prevProps.roleDetail.isCopySuccess !== this.props.roleDetail.isCopySuccess) {
      if (this.props.roleDetail.isCopySuccess) {
        window.location.href = detailUrl.ROLE_DETAIL + this.props.roleDetail.copyId;
      }
    }
    if (prevProps.roleDetail.isUpdateScreen !== this.props.roleDetail.isUpdateScreen) {
      if (this.props.roleDetail.isUpdateScreen) {
        this.setScreenUpdateSuccess();
      }
    }
  }

  setScreens(tempScreens) {
    const value = getFormatScreens(tempScreens);
    this.setState({
      screens: value.values,
      cateogories: value.category,
    });
  }

  setData(roleDetail) {
    this.setState({
      roleScreens: roleDetail.roleScreens,
      role: roleDetail.role,
      members: roleDetail.members,
    });
  }

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onUpdate = () => {
    this.setState({ updateModalOpen: true, successSnackOpen: false });
  };

  handleClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');
    let newSelected = [];

    if (selectedIndex === -1) {
      const newRoleScreen = { id: screenId, edit: false };
      newSelected = newSelected.concat(roleScreens, newRoleScreen);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(roleScreens.slice(1));
    } else if (selectedIndex === roleScreens.length - 1) {
      newSelected = newSelected.concat(roleScreens.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        roleScreens.slice(0, selectedIndex),
        roleScreens.slice(selectedIndex + 1),
      );
    }

    this.setState({ roleScreens: newSelected });
  };

  handleEditClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');

    const newSelected = roleScreens.map((screen) => {
      if (screen.id === screenId) {
        return { ...screen, edit: !screen.edit };
      }
      return screen;
    });

    if (selectedIndex === -1) {
      newSelected.push({ id: screenId, edit: true });
    }

    this.setState({ roleScreens: newSelected });
  };

  handleDeleteClick = (e, screenId) => {
    const { roleScreens } = this.state;
    const isChecked = e.target.value;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');

    const newSelected = roleScreens.map((screen) => {
      if (screen.id === screenId) {
        return { ...screen, deleted: isChecked };
      }
      return screen;
    });

    if (selectedIndex === -1) {
      newSelected.push({ id: screenId, deleted: isChecked });
    }

    this.setState({ roleScreens: newSelected });
  };

  updateRoleScreen = () => {
    this.setState({ successSnackOpen: false });
    const { roleScreens, role } = this.state;
    this.props.dispatch(roleDetailActions.updateScreens({ id: role.id, screenIds: roleScreens }));
  };

  setScreenUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '使用可能画面の保存をしました。',
    });
    this.props.dispatch(roleDetailActions.getData({ id: this.state.role.id }));
  };

  handleDetailClose = () => {
    this.setState({ updateModalOpen: false });
  };

  setUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '権限の変更をしました。',
      updateModalOpen: false,
    });
    this.props.dispatch(roleDetailActions.resetFlg());
    this.props.dispatch(roleDetailActions.getData({ id: this.state.role.id }));
  };

  onSaveClose = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '権限の変更をしました。',
      updateModalOpen: false,
    });
    this.props.dispatch(roleDetailActions.getData({ id: this.state.role.id }));
  };

  onSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  };

  onConfirmClose = () => {
    this.setState({ isCopyConfirmOpen: false });
  };

  onCopyConfirm = () => {
    this.setState({ isCopyConfirmOpen: true });
  };

  onCopy = () => {
    this.props.dispatch(roleDetailActions.copy({ id: this.state.role.id }));
  };

  isSelected = (screenId) => getIncludes(screenId, this.state.roleScreens, 'id');

  isEditSelected = (screenId) => getEdit(screenId, this.state.roleScreens, 'id', 'edit');

  isDeletedSelected = (screenId) => getEdit(screenId, this.state.roleScreens, 'id', 'deleted');

  render() {
    const {
      screens, updateModalOpen, role, cateogories, tabValue, isCopyConfirmOpen,
      successSnackOpen, successSnackMessage, isDisabled, members,
    } = this.state;

    return (
      <Container maxWidth={false}>
        {!isDisabled && (<RecordHeader bottomSection={(<Button color="primary" variant="contained" size="small" onClick={this.onCopyConfirm}>コピーする</Button>)} />)}
        <Grid container spacing={2} mb={2}>
          <Grid item sm={4} xs={12}>
            <RecordHeaderButton
              disabled={isDisabled}
              onEdit={this.onUpdate}
            />
            <RecordBox>
              <Box>
                <FormTitle title="権限名" />
                <Box>{role.name}</Box>
              </Box>
              <Box mt={2}>
                <FormTitle title="CSVダウンロード" />
                <Box>{role.permissionCsv ? '有' : '無'}</Box>
              </Box>
            </RecordBox>
          </Grid>

          <Grid item sm={8} xs={12}>
            <Tabs
              value={tabValue}
              onChange={this.onTabChange}
              tabs={['画面', '付与者']}
            />
            <TabPanel value={tabValue} index={0}>
              <Paper>
                <Box p={2}>
                  <Alert severity="info">編集にチェックをしない場合は参照のみとなります</Alert>
                </Box>
                <Box style={{ overflowY: 'scroll', maxHeight: '600px' }}>
                  {cateogories.map((text) => (
                    <Accordion key={`category${text}`}>
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Box>{text}</Box>
                      </AccordionSummary>
                      {screens.get(text).map((screen) => {
                        const isSelected = this.isSelected(screen.id);
                        const isEditSelected = this.isEditSelected(screen.id);
                        const isDeletedSelected = this.isDeletedSelected(screen.id);
                        return (
                          <AccordionDetails key={screen.id}>
                            <FormSwitch onFocus={(event) => event.stopPropagation()} sx={{ minWidth: '210px' }} disabled={isDisabled} label={screen.name} checked={isSelected} onChange={(event) => this.handleClick(event, screen.id)} />
                            <FormSwitch
                              label="編集"
                              disabled={isDisabled}
                              checked={isEditSelected}
                              onChange={(event) => this.handleEditClick(event, screen.id)}
                            />
                            {(screen.url !== '/trash' && screen.url !== '/account' && screen.url !== '/shop' && screen.url !== '/delivery-agency') && (
                              <FormSwitch
                                label="削除"
                                disabled={isDisabled}
                                checked={isDeletedSelected}
                                onChange={(event) => this.handleDeleteClick(event, screen.id)}
                              />
                            )}
                            {(screen.url === '/account' || screen.url === '/shop' || screen.url === '/delivery-agency') && (
                              <FormSwitch
                                label="ログインユーザー削除"
                                disabled={isDisabled}
                                checked={isDeletedSelected}
                                onChange={(event) => this.handleDeleteClick(event, screen.id)}
                              />
                            )}
                          </AccordionDetails>
                        );
                      })}
                    </Accordion>
                  ))}
                </Box>
                <Box textAlign="right" p={1}>
                  {!isDisabled && (
                    <LoadingButton
                      onClick={this.updateRoleScreen}
                    >
                      保存
                    </LoadingButton>
                  )}
                </Box>
              </Paper>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <MemberDataTable rows={members} />
            </TabPanel>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={successSnackOpen}
          onClose={this.onSnackbarClose}
          message={successSnackMessage}
        />

        <RoleDetailDialog
          onSaveClose={this.onSaveClose}
          open={updateModalOpen}
          onClose={this.handleDetailClose}
          id={role.id}
          role={role}
        />

        <ConfirmDialog
          open={isCopyConfirmOpen}
          onClose={this.onConfirmClose}
          onClick={this.onCopy}
          title="権限のコピー"
          content="権限をコピーします。"
          actionText="コピーする"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  roleDetail: state.roleDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(RoleDetail));
