import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField,
  RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import { customerActions } from '../../../redux/opportunity/customer/customerState';
import { selectActions } from '../../../redux/common/select/selectState';
import {
  withParams, RecordBox, FormErrorText, SuccessSnackbar, TabPanel,
  PasswordInput, FormSwitch,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import EditPointDialog from './EditPointDialog';
import OpportunityDataTable from './OpportunityDataTable';
import ReviewDataTable from './ReviewDataTable';
import PointDataTable from './PointDataTable';
import ViewFavoriteDataTable from './ViewFavoriteDataTable';
import Validation from './validation';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import SystemInfo from '../../../components/templates/SystemInfo';
import Tabs from '../../../components/templates/Tabs';
import { RecordHeaderButton } from '../../../components/templates/RecordHeader';

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    const form = {
      id,
      customerNumber: '',
      affiliationTypeCode: 1,
      nameKana: '',
      name: '',
      nickname: '',
      totalRemainPoint: 0,
      companyName: '',
      branchName: '',
      fixedPhone: '',
      mobilePhone: '',
      pointGranted: false,
      email: '',
      password: '',
      passwordCheck: '',
      createdByName: '',
      createdAt: '',
      updatedByName: '',
      updatedAt: '',
    };

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.customerInfo.isSaveSuccess) {
      props.dispatch(customerActions.resetFlg());
      successMessage = '保存しました。';
      isSuccessOpen = true;
    }

    if (form.id) {
      props.dispatch(customerActions.getData({ id }));
    }
    props.dispatch(selectActions.getCodes({ codeCategory: 'affiliation_type' }));
    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isEdit: !id,
      form,
      inputForm: { ...form },
      isOpen: false,
      affiliationTypes: [{ id: 1, name: '' }],
      openedMenuRowId: '',
      isSuccessOpen,
      successMessage,
      tabValue: 0,
      errorMessages: {
        nameKana: '',
        name: '',
        email: '',
        password: '',
        passwordCheck: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectInfo.codes !== this.props.selectInfo.codes) {
      if (this.props.selectInfo.codes) {
        this.setSelectDatas(this.props.selectInfo.codes);
      }
    }
    if (prevProps.customerInfo.datas !== this.props.customerInfo.datas) {
      if (this.props.customerInfo.datas) {
        this.setDatas(this.props.customerInfo.datas);
      }
    }
    if (prevProps.customerInfo.isSaveSuccess !== this.props.customerInfo.isSaveSuccess) {
      if (this.props.customerInfo.isSaveSuccess) {
        this.setSuccess(this.props.customerInfo.saveId);
      }
    }
    if (prevProps.pointInfo.isSaveSuccess !== this.props.pointInfo.isSaveSuccess) {
      if (this.props.pointInfo.isSaveSuccess) {
        this.setPointSuccess();
      }
    }
    if (prevProps.pointInfo.isDeleteSuccess !== this.props.pointInfo.isDeleteSuccess) {
      if (this.props.pointInfo.isDeleteSuccess) {
        this.setPointSuccess();
      }
    }
  }

  setSelectDatas = (select) => {
    this.setState({
      affiliationTypes: select.content,
    });
  };

  setDatas = (form) => {
    this.setState({
      form,
      inputForm: form,
    });
  };

  setPointSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(customerActions.getData({ id: form.id }));
  };

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.id;
    if (form.id) {
      this.props.dispatch(customerActions.resetFlg());
    }
    if (!form.id) {
      window.location.href = detailUrl.CUSTOMER_DETAIL + searchId;
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true, successMessage: '保存しました。' });
    this.props.dispatch(customerActions.getData({ id: searchId }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        [name]: value,
      },
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onChangePassword = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value),
        passwordCheck: Validation.formValidate('passwordCheck', value, inputForm.passwordCheck),
      },
    });
  };

  onChangePasswordCheck = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, inputForm.password, value),
      },
    });
  };

  onClickEditCustomer = () => {
    const tempErrorMessages = {
      nameKana: '',
      name: '',
      email: '',
    };
    const { form } = this.state;
    this.setState({ isEdit: true, errorMessages: tempErrorMessages });
    this.props.dispatch(customerActions.getData({ id: form.id }));
  };

  onClickCancel = () => {
    const { form } = this.state;
    this.setState({ inputForm: form, isEdit: false });
  };

  onClickSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    if (inputForm.affiliationTypeCode === '2' && inputForm.companyName === '') {
      this.setState({ errorMessages: { ...errorMessages, companyName: '所属タイプが法人の場合、入力してください' } });
      return;
    }

    this.props.dispatch(customerActions.saveData(inputForm));
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      if (key === 'passwordCheck') return;
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!form.id) {
      const msg = Validation.formValidate('passwordCheck', form.password, form.passwordCheck);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        passwordCheck: msg,
      };

      this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    }
    return isError;
  };

  onSnackbarClose = () => {
    this.setState({
      isSuccessOpen: false,
    });
  };

  render() {
    const {
      isDisabled,
      isEdit,
      form,
      inputForm,
      affiliationTypes,
      isSuccessOpen,
      successMessage,
      openedMenuRowId,
      tabValue,
      isOpen,
      errorMessages,
    } = this.state;

    return (
      <>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={form.id ? 4 : 12}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isDisabled}
              onEdit={this.onClickEditCustomer}
            />
            <RecordBox>
              <DataGrid title="会員番号" value={form.customerNumber} />
              <DataGrid title="所属タイプ" isRequired isEdit={isEdit} value={form.affiliationTypeCode && affiliationTypes.find((e) => e.id === form.affiliationTypeCode).name}>
                <RadioGroup row value={inputForm.affiliationTypeCode} name="affiliationTypeCode" onChange={this.onChange}>
                  {affiliationTypes.map((e) => (
                    <FormControlLabel key={e.id} value={e.id} control={<Radio />} label={e.name} />
                  ))}
                </RadioGroup>
                <FormErrorText>{errorMessages.affiliationTypeCode}</FormErrorText>
              </DataGrid>
              <DataGrid title="フリガナ" isRequired isEdit={isEdit} value={form.nameKana}>
                <TextField error={!!errorMessages.nameKana} value={inputForm.nameKana} fullWidth name="nameKana" onChange={this.onChange} inputProps={{ maxLength: 200 }} />
                <FormErrorText>{errorMessages.nameKana}</FormErrorText>
              </DataGrid>
              <DataGrid title="名前" isRequired isEdit={isEdit} value={form.name}>
                <TextField error={!!errorMessages.name} value={inputForm.name} fullWidth name="name" onChange={this.onChange} inputProps={{ maxLength: 200 }} />
                <FormErrorText>{errorMessages.name}</FormErrorText>
              </DataGrid>
              <DataGrid title="ニックネーム" isEdit={isEdit} value={form.nickname}>
                <TextField value={inputForm.nickname} fullWidth name="nickname" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
              </DataGrid>
              {form.id && (
                <DataGrid title="合計ポイント" value={`${form.totalRemainPoint ? form.totalRemainPoint : 0} pt`} />
              )}
              <DataGrid title="所属会社" isEdit={isEdit} value={form.companyName}>
                <TextField value={inputForm.companyName} fullWidth name="companyName" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
                <FormErrorText>{errorMessages.companyName}</FormErrorText>
              </DataGrid>
              <DataGrid title="部署名" isEdit={isEdit} value={form.branchName}>
                <TextField value={inputForm.branchName} fullWidth name="branchName" onChange={this.onChange} inputProps={{ maxLength: 255 }} />
              </DataGrid>
              <DataGrid title="固定電話番号" isEdit={isEdit} value={form.fixedPhone}>
                <TextField value={inputForm.fixedPhone} fullWidth name="fixedPhone" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
                <FormErrorText>{errorMessages.fixedPhone}</FormErrorText>
              </DataGrid>
              <DataGrid title="携帯電話番号" isEdit={isEdit} value={form.mobilePhone}>
                <TextField value={inputForm.mobilePhone} fullWidth name="mobilePhone" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
                <FormErrorText>{errorMessages.mobilePhone}</FormErrorText>
              </DataGrid>
              <DataGrid title="メールアドレス" isRequired isEdit={isEdit} value={form.email}>
                <TextField error={!!errorMessages.email} value={inputForm.email} fullWidth name="email" onChange={this.onChange} inputProps={{ maxLength: 255 }} />
                <FormErrorText>{errorMessages.email}</FormErrorText>
              </DataGrid>
              <DataGrid title="ポイント付与済" isEdit={isEdit} value={form.pointGranted ? '済' : ''}>
                <FormSwitch checked={inputForm.pointGranted} label="ポイント付与済にする" name="pointGranted" onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="StripeID" value={form.stripeId} />
              {(isEdit && !form.id) && (
                <>
                  <DataGrid title="パスワード" isRequired isEdit={isEdit} value={form.password}>
                    <PasswordInput error={!!errorMessages.password} value={inputForm.password} name="password" onChange={this.onChangePassword} inputProps={{ maxLength: 100 }} />
                    <FormErrorText>{errorMessages.password}</FormErrorText>
                  </DataGrid>
                  <DataGrid title="確認パスワード" isRequired isEdit={isEdit} value={form.passwordCheck}>
                    <PasswordInput error={!!errorMessages.passwordCheck} value={inputForm.passwordCheck} name="passwordCheck" onChange={this.onChangePasswordCheck} inputProps={{ maxLength: 100 }} />
                    <FormErrorText>{errorMessages.passwordCheck}</FormErrorText>
                  </DataGrid>
                </>
              )}
              {!isEdit && form.id && (
                <SystemInfo data={form} />
              )}
              {isEdit && (
                <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {form.id && (
                    <Button color="inherit" variant="contained" size="small" onClick={this.onClickCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                  )}
                  <Button variant="contained" size="small" onClick={this.onClickSave}>保存</Button>
                </Box>
              )}
            </RecordBox>
          </Grid>

          {form.id && (
            <Grid item xs={12} md={8}>
              <Tabs
                value={tabValue}
                onChange={(e, value) => this.setState({ tabValue: value })}
                tabs={['案件', '口コミ', 'ポイント履歴', '閲覧履歴', 'お気に入り']}
              />
              <TabPanel value={tabValue} index={0}>
                <OpportunityDataTable customerId={form.id} isOpen />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ReviewDataTable customerId={form.id} isOpen />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <PointDataTable customerId={form.id} isOpen />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <ViewFavoriteDataTable customerId={form.id} target="view" />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <ViewFavoriteDataTable customerId={form.id} target="favorite" />
              </TabPanel>
            </Grid>
          )}
        </Grid>
        <EditPointDialog
          isOpen={isOpen}
          onClose={this.onClose}
          customerId={openedMenuRowId}
          onSave={this.onSavePoint}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  customerInfo: state.customerStore,
  selectInfo: state.selectStore,
  pointInfo: state.customerPointHistoryStore,
});

export default withParams(connect(mapStateToProps)(CustomerDetail));
