import React, { useState } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import DataGrid from '../../../../components/templates/DataGrid';
import DataSelectList from '../../../../components/atoms/dataList/DataSelectList';
import { FormErrorText, FormRadioGroup, Link } from '../../../../components/atoms/Base';
import detailUrl from '../../../../constants/frontUrls';

export default function SelectStoreComponent(props) {
  const {
    onChange,
    setDetailType,
    row,
    detailType,
    errorMessage,
    isEdit,
  } = props;

  const [detailTypeName, setDetailTypeName] = useState('店舗');

  React.useEffect(() => {
    if (detailType) {
      let name = '';
      switch (detailType) {
        case 1:
          name = '店舗';
          break;
        case 2:
          name = '業者';
          break;
        default:
          name = '';
      }
      setDetailTypeName(name);
    }
  }, [detailType]);

  const onChangeType = (event) => {
    const { value } = event.target;
    setDetailType(value);
  };

  return (
    <>
      <DataGrid title="タイプ" isEdit={isEdit} value={detailTypeName}>
        <FormRadioGroup row value={detailType} name="detailType" onChange={onChangeType} defaultValue="1">
          <FormControlLabel value="1" control={<Radio />} label="店舗" />
          <FormControlLabel value="2" control={<Radio />} label="業者" />
        </FormRadioGroup>
      </DataGrid>
      {detailType === 1 && (
      <DataGrid
        title="店舗"
        isEdit={isEdit}
        isRequired
        value={(
          <Link to={detailUrl.SHOP_DETAIL + row.shopId}>
            {row.shopName}
          </Link>
        )}
      >
        <DataSelectList
          name="shopId"
          value={row.shopId}
          target="shops"
          onChange={onChange}
          disableClearable
          error={!!errorMessage.shopId}
        />
        <FormErrorText>{errorMessage.shopId}</FormErrorText>
      </DataGrid>
      )}
      {detailType === 2 && (
      <DataGrid
        title="業者"
        isEdit={isEdit}
        isRequired
        value={(
          <Link to={detailUrl.VENDOR_DETAIL + row.vendorId}>
            {row.vendorName}
          </Link>
        )}
      >
        <DataSelectList
          name="vendorId"
          value={row.vendorId}
          target="vendors"
          onChange={onChange}
          disableClearable
          error={!!errorMessage.vendorId}
        />
        <FormErrorText>{errorMessage.vendorId}</FormErrorText>
      </DataGrid>
      )}
    </>
  );
}
