import React, { useEffect } from 'react';
import {
  Box, DialogActions, InputBase, Paper, Dialog,
  Button, Divider, IconButton, Tooltip, TextField,
  List, ListItem, ListItemButton, ListItemText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { conditionActions } from '../../../redux/common/condition/conditionState';
import DeleteDialog from '../DeleteDialog';
import { SuccessSnackbar, Pagination, DialogContent } from '../../atoms/Base';

export default function ConditionListDialog(props) {
  const {
    isOpen, onClose, targetScreen, selectRow,
  } = props;

  const DEFAULT_SEARCH_CONDITION = {
    targetScreen,
    condition: '',
    page: 0,
    records: 50,
  };

  const [successProc, setSuccessProc] = React.useState('');
  const [changeValue, setChangeValue] = React.useState('');
  const [searchCondition, setSearchCondition] = React.useState(DEFAULT_SEARCH_CONDITION);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [datas, setDatas] = React.useState({ content: [] });
  const [selectedRow, setSelectedRow] = React.useState({});
  const dispatch = useDispatch();
  const conditionDatas = useSelector((state) => state.conditionStore.conditionDatas);
  const isDeleteSuccess = useSelector((state) => state.conditionStore.isDeleteSuccess);
  const isUpdateSuccess = useSelector((state) => state.conditionStore.isUpdateSuccess);

  useEffect(() => {
    if (isUpdateSuccess) {
      setSuccessProc('変更');
      setEditOpen(false);
      setLoading(false);
      dispatch(conditionActions.resetFlg());
      dispatch(conditionActions.getDatas(searchCondition));
    }
  }, [isUpdateSuccess]);
  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessProc('削除');
      setDeleteOpen(false);
      dispatch(conditionActions.resetFlg());
      dispatch(conditionActions.getDatas(searchCondition));
    }
  }, [isDeleteSuccess]);
  useEffect(() => {
    if (conditionDatas) {
      setDatas(conditionDatas);
    }
  }, [conditionDatas]);
  useEffect(() => {
    if (isOpen) {
      dispatch(conditionActions.getDatas(searchCondition));
    }
  }, [isOpen]);

  const onLocalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose(event);
    }
  };

  const onChange = (e) => {
    setSearchCondition({ ...searchCondition, condition: e.target.value });
  };

  const onDelete = () => {
    dispatch(conditionActions.delete({ ids: [selectedRow.id] }));
  };

  const onSave = () => {
    setLoading(true);
    dispatch(conditionActions.update({ id: selectedRow.id, name: changeValue }));
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = { ...searchCondition, page: newPage };
    setSearchCondition(tempCondition);
    dispatch(conditionActions.getDatas(tempCondition));
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = { ...searchCondition, records: rowsPerPage, page: 0 };
    setSearchCondition(tempCondition);
    dispatch(conditionActions.getDatas(tempCondition));
  };

  const onSearch = () => {
    dispatch(conditionActions.getDatas(searchCondition));
  };

  const onClear = () => {
    const tempCondition = { ...searchCondition, condition: '' };
    setSearchCondition(tempCondition);
    dispatch(conditionActions.getDatas(tempCondition));
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onLocalClose} maxWidth="sm" fullWidth scroll="paper">
        <DialogContent sx={{ padding: 0 }}>
          <Box
            m={1}
            sx={{
              position: 'sticky', top: '8px', background: '#fff', zIndex: 99,
            }}
          >
            <Paper
              component="form"
              sx={{
                p: '2px 4px', display: 'flex', alignItems: 'center',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="条件を検索"
                value={searchCondition.condition}
                onChange={onChange}
              />
              <Button onClick={onSearch}>検索</Button>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton sx={{ p: '10px' }} onClick={onClear}>
                <CloseIcon />
              </IconButton>
            </Paper>
          </Box>
          <Box m={1}>
            <List component="nav" disablePadding>
              {datas.content.map((d, index) => {
                const divid = index !== 0 ? (<Divider />) : null;
                let cmp = (
                  <React.Fragment key={d.id}>
                    {divid}
                    <ListItem component="div" disablePadding>
                      <ListItemButton onClick={() => selectRow(d.searchCondition)}>
                        <ListItemText primary={d.name} />
                      </ListItemButton>
                      <Tooltip title="条件名の変更">
                        <IconButton
                          size="small"
                          onClick={() => { setSelectedRow(d); setEditOpen(true); setSuccessProc(''); setChangeValue(d.name); }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <Tooltip title="削除">
                        <IconButton
                          size="small"
                          onClick={() => { setSelectedRow(d); setDeleteOpen(true); setSuccessProc(''); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  </React.Fragment>
                );
                if (editOpen && d.id === selectedRow.id) {
                  cmp = (
                    <Box key={d.id}>
                      {divid}
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          value={changeValue}
                          onChange={(e) => setChangeValue(e.target.value)}
                        />
                      </Box>
                      <Box textAlign="center" my={1}>
                        <Button size="small" color="inherit" variant="contained" sx={{ marginRight: '10px' }} onClick={() => { setEditOpen(false); setLoading(false); }}>キャンセル</Button>
                        <LoadingButton size="small" variant="contained" onClick={onSave} loading={loading}>保存</LoadingButton>
                      </Box>
                    </Box>
                  );
                }
                return (
                  divid,
                  cmp
                );
              })}
            </List>
            <Pagination
              totalElements={datas.totalElements}
              page={searchCondition.page}
              rowsPerPage={searchCondition.records}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">閉じる</Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={onDelete}
        procName={selectedRow.name}
      />
      <SuccessSnackbar
        open={Boolean(successProc)}
        onClose={() => setSuccessProc('')}
        message={`${successProc}しました。`}
      />
    </>
  );
}
