import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import FullScreenDialog from '../FullScreenDialog';
import Mailer from './Mailer';
import CommonValidation from '../../../utils/common.validation';

const DEFAULT_MAILER_DATA = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function FullScreenMailer(props) {
  const {
    isOpen, onClose, onClickAction, data, title, objectData, loading, leftArea, isPushPaymentLink,
  } = props;
  const [mailerData, setMailerData] = useState(DEFAULT_MAILER_DATA);
  const [errorMessages, setErrorMessages] = useState({});
  useEffect(() => {
    if (isOpen && data) {
      setMailerData(data);
    }
  }, [isOpen, data]);

  const dataBeforeCheck = (d) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(d).forEach((key) => {
      const msg = CommonValidation.mailerDatasValidate(key, d[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    return isError;
  };

  const onChangeMailer = (event) => {
    const { name, value } = event.target;
    setMailerData({
      ...mailerData,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages, [name]: CommonValidation.mailerDatasValidate(name, value),
    });
  };
  const onSelectMailTemplate = (d) => {
    const tmpMailerData = {
      ...mailerData,
      cc: d.cc,
      subject: d.subject,
      body: d.body,
    };
    setMailerData(tmpMailerData);
    dataBeforeCheck(tmpMailerData);
  };

  const onClickSend = () => {
    if (dataBeforeCheck(mailerData)) return;
    onClickAction(mailerData);
  };

  return (
    <FullScreenDialog isOpen={isOpen} title={title} onClose={() => { setMailerData(DEFAULT_MAILER_DATA); setErrorMessages({}); onClose(); }} onClickAction={onClickSend} actionName="送信" loading={loading}>
      {!loading && (
        <Grid container spacing={1}>
          <Grid item xs={leftArea ? 12 : 0} md={leftArea ? 6 : 0}>
            {leftArea}
          </Grid>
          <Grid item xs={leftArea ? 12 : 0} md={leftArea ? 6 : 12}>
            <Mailer
              data={mailerData}
              onChange={onChangeMailer}
              onSelectMailTemplate={onSelectMailTemplate}
              objectData={objectData}
              errorMessages={errorMessages}
            />
            {isPushPaymentLink && objectData.paymentLink && (
              <Box>{`支払リンク：${objectData.paymentLink}`}</Box>
            )}
          </Grid>
        </Grid>
      )}
    </FullScreenDialog>
  );
}
