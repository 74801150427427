import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { upload } from '../../../../utils/common.helper';
import { REST_API } from '../../../../constants/serverUrls';
import FullScreenMailer from '../../../../components/templates/mailer/FullScreenMailer';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';
import ConfirmationFile from '../../report/ConfirmationFile';
import ComfirmationAffiliationFile from '../../report/ComfirmationAffiliationFile';

const defaultMailerData = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function ConfirmFileDialog(props) {
  const {
    isOpen, onClose, form, sendSuccess,
  } = props;
  const dispatch = useDispatch();
  const dbPdfData = useSelector((state) => state.opportunityDetailStore.pdfDatas);
  const [mailerData, setMailerData] = useState(defaultMailerData);
  const [pdfData, setPdfData] = useState({ header: {}, details: [] });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (dbPdfData) {
      setPdfData(dbPdfData);
      setLoading(false);
    }
  }, [dbPdfData]);

  useEffect(() => {
    if (isOpen) {
      setMailerData({
        ...defaultMailerData,
        to: form.customerEmail,
      });
      setLoading(true);
      dispatch(opportunityDetailActions.getPdfData({ id: form.id }));
    }
  }, [isOpen]);

  const fileData = () => {
    if (!pdfData.header) return '';
    const returnData = !pdfData.header.affiliationId
      ? <ConfirmationFile data={pdfData} /> : <ComfirmationAffiliationFile data={pdfData} />;
    return returnData;
  };

  const onSend = async (mailForm) => {
    const sendMailForm = {
      ...mailForm,
      id: form.id,
    };
    setLoading(true);
    const result = await upload(
      fileData(),
      sendMailForm,
      REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_SEND_ORDER,
      pdfData.header.fileName,
    );
    setLoading(false);
    if (result == null) return;
    sendSuccess();
  };

  return (
    <FullScreenMailer
      isOpen={isOpen}
      title="確定書送付"
      onClose={onClose}
      onClickAction={onSend}
      data={mailerData}
      objectData={form || {}}
      loading={loading}
      leftArea={(
        <PDFViewer style={{ width: '100%', height: '85vh' }} showToolbar={false}>
          {fileData()}
        </PDFViewer>
      )}
    />
  );
}
