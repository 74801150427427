import React from 'react';
import { connect } from 'react-redux';
import {
  TextField, Grid,
} from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { shopNoticeActions } from '../../../redux/shop/shopNotice/shopNoticeState';
import {
  FormTitle, FormSwitch, DatePicker, SuccessSnackbar, SearchMultipleSelectBox,
} from '../../../components/atoms/Base';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  shopCode: '',
  shopName: '',
  allTargeted: false,
  startDate: '',
  endDate: '',
  categories: [],
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'category', label: 'カテゴリ' },
  { key: 'title', label: 'タイトル' },
  { key: 'content', label: '内容' },
  { key: 'startDate', label: '公開開始日' },
  { key: 'endDate', label: '公開終了日' },
  { key: 'allTargeted', label: '全店対象' },
  { key: 'shopName', label: '店舗名' },
  { key: 'reviewId', label: '口コミID' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class ShopNoticeList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'shopName', label: 'お知らせ店舗名', sortId: 'shop_name' },
      { id: 'publicDatePeriod', label: '公開期間', sortId: 'public_date_period' },
      { id: 'category', label: 'カテゴリ', sortId: 'category' },
    ];

    const searchCondition = DEFAULT_SEARCH_CONDITION;

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '店舗お知らせ',
        done: false,
        datas: [],
      },
      categories: [],
    };
    props.dispatch(shopNoticeActions.getDatas(searchCondition));
    props.dispatch(shopNoticeActions.getCategories());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopNoticeInfo.datas !== this.props.shopNoticeInfo.datas) {
      if (this.props.shopNoticeInfo.datas) {
        this.setDatas(this.props.shopNoticeInfo.datas);
      }
    }
    if (prevProps.shopNoticeInfo.isDeleteSuccess !== this.props.shopNoticeInfo.isDeleteSuccess) {
      if (this.props.shopNoticeInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.shopNoticeInfo.csvDatas !== this.props.shopNoticeInfo.csvDatas) {
      if (this.props.shopNoticeInfo.csvDatas) {
        this.setCsvDatas(this.props.shopNoticeInfo.csvDatas);
      }
    }
    if (prevProps.shopNoticeInfo.categories !== this.props.shopNoticeInfo.categories) {
      if (this.props.shopNoticeInfo.categories) {
        this.setCategories(this.props.shopNoticeInfo.categories);
      }
    }
  }

  setCategories = (categories) => {
    this.setState({ categories });
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  setSuccess = () => {
    this.setState({
      isSuccessOpen: true,
    });
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(shopNoticeActions.setSearchCondition(searchCondition));
    this.props.dispatch(shopNoticeActions.getDatas(searchCondition));
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(shopNoticeActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(shopNoticeActions.deleteData({ ids }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(shopNoticeActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      isEditDisabled,
      isDeleteDisabled,
      datas,
      searchCondition,
      isSuccessOpen,
      download,
      categories,
    } = this.state;
    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          targetScreen="shop-notice"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onClear={this.onClear}
          simpleAreaOneText="公開開始日"
          simpleAreaOne={(
            <DatePicker
              value={searchCondition.startDate}
              name="startDate"
              onChange={this.onChange}
            />
          )}
          simpleAreaTwoText="公開終了日"
          simpleAreaTwo={(
            <DatePicker
              value={searchCondition.endDate}
              name="endDate"
              onChange={this.onChange}
            />
          )}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="お知らせ先店舗コード" />
              <TextField fullWidth size="small" value={searchCondition.shopCode} name="shopCode" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="お知らせ先店舗名" />
              <TextField fullWidth size="small" value={searchCondition.shopName} name="shopName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="全店舗にお知らせ" />
              <FormSwitch
                name="allTargeted"
                checked={searchCondition.allTargeted}
                onChange={this.onChange}
                label="全店舗にお知らせしたもののみ"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="カテゴリ" />
              <SearchMultipleSelectBox
                name="categories"
                options={categories}
                values={searchCondition.categories}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
          <FormTitle title="フリーワード" attention="タイトル、内容" />
          <TextField fullWidth name="keyword" onChange={this.onChange} value={searchCondition.keyword} />
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.SHOP_NOTICE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="店舗お知らせ"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          onDelete={this.onDelete}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="削除をしました。"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  shopNoticeInfo: state.shopNoticeStore,
});

export default connect(mapStateToProps)(ShopNoticeList);
