/* eslint-disable max-len */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectActions } from '../../redux/common/select/selectState';
import { SearchSelectBox } from '../atoms/Base';

export default function AreaInput(props) {
  const { datas, onChange, isEdit } = props;
  const [areas, setAreas] = useState([{ prefectureGroups: [{ cities: [] }] }]);
  const [prefectures, setPrefectures] = useState([{}]);
  const [regions, setRegions] = useState([{}]);
  const [region, setRegion] = useState('');
  const [selected, setSelected] = useState([]);
  const [prefecture, setPrefecture] = useState('');
  const dbDatas = useSelector((state) => state.selectStore.areas);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectActions.getAreas());
  }, []);

  useEffect(() => {
    if (isEdit) {
      const allCities = datas.reduce((acc, area) => {
        const citiesInArea = area.prefectureGroups.reduce((groupAcc, group) => {
          const citiesWithPrefectureId = group.cities.map((city) => ({
            ...city,
            prefectureId: group.id,
          }));
          return groupAcc.concat(citiesWithPrefectureId);
        }, []);
        return acc.concat(citiesInArea);
      }, []);
      setSelected(allCities);
    }
  }, [isEdit, datas]);

  useEffect(() => {
    if (dbDatas) {
      setAreas(dbDatas);
      const p = dbDatas.reduce((acc, area) => {
        const groupsWithRegion = area.prefectureGroups?.map((group) => ({
          ...group,
          regionEnglish: area.regionEnglish,
        })) || [];
        return acc.concat(groupsWithRegion);
      }, []);
      setPrefectures(p);
      const simplifiedRegions = dbDatas.map((d) => ({
        name: d.regionName,
        id: d.regionEnglish,
      }));
      setRegions(simplifiedRegions);
    }
  }, [dbDatas]);

  const updateSelected = useCallback((newSelected) => {
    setSelected(newSelected);
    onChange(newSelected);
  }, [onChange]);

  const onCityChange = useCallback((e, city) => {
    setSelected((prevSelected) => {
      let newSelected;
      if (e.target.checked) {
        newSelected = [...prevSelected, city];
      } else {
        newSelected = prevSelected.filter((item) => item.cityId !== city.cityId);
      }
      updateSelected(newSelected);
      return newSelected;
    });
  }, [updateSelected]);

  const onPrefectureChange = useCallback((e, prefectureId) => {
    setSelected((prevSelected) => {
      let newSelected;
      if (e.target.checked) {
        const citiesToAdd = [];
        areas.forEach((area) => {
          area.prefectureGroups.forEach((v) => {
            if (v.id === prefectureId) {
              v.cities.forEach((city) => {
                if (!prevSelected.some((item) => item.cityId === city.cityId)) {
                  citiesToAdd.push({ ...city, prefectureId });
                }
              });
            }
          });
        });
        newSelected = [...prevSelected, ...citiesToAdd];
      } else {
        newSelected = prevSelected.filter((item) => item.prefectureId !== prefectureId);
      }
      updateSelected(newSelected);
      return newSelected;
    });
  }, [areas, updateSelected]);

  const filteredPrefectures = useMemo(() => prefectures.filter((row) => !region || row.regionEnglish === region), [prefectures, region]);

  const filteredAreas = useMemo(() => areas.filter((row) => !region || row.regionEnglish === region), [areas, region]);
  const filteredDataAreas = useMemo(() => datas.filter((row) => !region || row.regionEnglish === region), [datas, region]);

  return (
    <>
      <Box display="flex" mb={2}>
        <Box mr={2}>
          <SearchSelectBox
            name="region"
            options={regions}
            value={region}
            placeholder="エリア"
            onChange={(e) => {
              setRegion(e.target.value);
              setPrefecture('');
            }}
          />
        </Box>
        <Box>
          <SearchSelectBox
            name="prefecture"
            placeholder="都道府県"
            options={filteredPrefectures}
            value={prefecture}
            onChange={(e) => setPrefecture(e.target.value)}
          />
        </Box>
      </Box>
      {!isEdit && (
        <Box>
          {filteredDataAreas.map((area) => (
            <Box key={`dregion${area.regionEnglish}`}>
              <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>{area.regionName}</Box>
              {area.prefectureGroups
                .filter((row) => !prefecture || row.id === prefecture)
                .map((p) => (
                  <div key={`dprefecture${p.id}`}>
                    <Box sx={{ fontWeight: 'bold' }}>
                      {p.name}
                    </Box>
                    <Box display="flex" flexWrap="wrap" sx={{ maxWidth: '100%' }}>
                      {p.cities.map((city) => (
                        <Box sx={{ margin: '5px', whiteSpace: 'nowrap' }} key={`dcity${city.cityId}`}>
                          {city.cityName}
                        </Box>
                      ))}
                    </Box>
                  </div>
                ))}
            </Box>
          ))}
        </Box>
      )}
      {isEdit && (
        <>
          {filteredAreas.map((area) => (
            <Box key={`region${area.regionEnglish}`}>
              <Box sx={{ fontWeight: 'bold', fontSize: '20px' }}>{area.regionName}</Box>
              {area.prefectureGroups
                .filter((row) => !prefecture || row.id === prefecture)
                .map((p) => (
                  <div key={`prefecture${p.id}`}>
                    <Box sx={{ fontWeight: 'bold' }}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            onChange={(e) => onPrefectureChange(e, p.id)}
                            checked={selected.some((item) => item.prefectureId === p.id)}
                          />
                        )}
                        label={p.name}
                      />
                    </Box>
                    <Box display="flex" flexWrap="wrap" sx={{ maxWidth: '100%' }}>
                      {p.cities.map((city) => (
                        <Box sx={{ margin: '5px', whiteSpace: 'nowrap' }} key={`city${city.cityId}`}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={selected.some((item) => item.cityId === city.cityId)}
                                onChange={(e) => onCityChange(e, city)}
                              />
                            )}
                            label={city.cityName}
                          />
                        </Box>
                      ))}
                    </Box>
                  </div>
                ))}
            </Box>
          ))}
        </>
      )}
    </>
  );
}
