import React from 'react';
import {
  View, StyleSheet, Font, Page as CustomPage, Text,
} from '@react-pdf/renderer';
import fontNomal from '../../asset/NotoSansJP-Regular.ttf';
import fontBold from '../../asset/NotoSansJP-Bold.ttf';

export function Page(props) {
  const styles = StyleSheet.create({
    page: {
      paddingBottom: 50,
      paddingTop: 10,
      ...props.style,
    },
  });
  return (
    <CustomPage
      size="A4"
      wrap
      style={styles.page}
    >
      {props.children}
    </CustomPage>
  );
}

export function Root(props) {
  Font.register({
    family: 'NotoSansJP-Regular',
    src: fontNomal,
  });
  Font.register({
    family: 'NotoSansJP-Bold',
    src: fontBold,
  });
  Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, '']));
  const styles = StyleSheet.create({
    root: {
      fontFamily: 'NotoSansJP-Regular',
      padding: '15px',
      fontSize: '10px',
    },
  });
  return (
    <View style={styles.root}>
      {props.children}
    </View>
  );
}

export function Title(props) {
  const styles = StyleSheet.create({
    title: {
      textAlign: 'center',
      fontSize: '25px',
      fontFamily: 'NotoSansJP-Bold',
      letterSpacing: '8px',
    },
  });
  return (
    <View style={styles.title}><Text size="large">{props.children}</Text></View>
  );
}

export function Grid(props) {
  const styles = StyleSheet.create({
    gird: {
      flexDirection: 'row',
      ...props.style,
    },
  });
  return (
    <View style={styles.gird}>{props.children}</View>
  );
}

export function Col(props) {
  let align = props.right ? 'right' : 'center';
  if (props.left) align = 'left';
  const styles = StyleSheet.create({
    col: {
      flexGrow: 0,
      flexBasis: props.flexBasis,
      backgroundColor: props.subTitle ? '#E7E6E6' : '#ffffff',
      border: props.border ? '1px solid #000' : null,
      borderLeft: props.borderLeft ? '1px solid #000' : null,
      borderRight: props.borderRight ? '1px solid #000' : null,
      borderTop: props.borderTop ? '1px solid #000' : null,
      borderBottom: props.borderBottom ? '1px solid #000' : null,
      textAlign: align,
      display: 'flex',
      justifyContent: 'center',
      ...props.style,
    },
  });
  return (
    <View style={styles.col}>{props.children}</View>
  );
}

export function Table(props) {
  const styles = StyleSheet.create({
    col: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      ...props.style,
    },
  });
  return (
    <View style={styles.col}>{props.children}</View>
  );
}

export function TableRow(props) {
  const styles = StyleSheet.create({
    col: {
      flexDirection: 'row',
      backgroundColor: props.subTitle ? '#E7E6E6' : null,
      borderTop: props.borderTop ? '1 solid #000' : null,
      borderBottom: '1 solid #000',
      borderLeft: '1 solid #000',
      ...props.style,
    },
  });
  return (
    <View style={styles.col} wrap={false}>{props.children}</View>
  );
}

export function TableCell(props) {
  const styles = StyleSheet.create({
    col: {
      flex: 1,
      borderRightWidth: 1,
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      padding: 4,
      textAlign: 'center',
      flexBasis: props.flexBasis,
      display: 'flex',
      justifyContent: 'center',
      ...props.style,
    },
  });
  return (
    <View style={styles.col}>{props.children}</View>
  );
}

export function TableDataCell(props) {
  let align = props.right ? 'right' : 'center';
  if (props.left) align = 'left';
  const styles = StyleSheet.create({
    col: {
      flex: 1,
      borderRightWidth: 1,
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      padding: 4,
      textAlign: align,
      flexBasis: props.flexBasis,
      justifyContent: 'center',
      ...props.style,
    },
  });
  return (
    <View style={styles.col}>{props.children}</View>
  );
}

export function Box(props) {
  const styles = StyleSheet.create({
    box: {
      ...props.style,
      display: 'block',
    },
  });
  return (
    <View style={styles.box} wrap={false}>{props.children}</View>
  );
}

export function NumberCol(props) {
  const styles = StyleSheet.create({
    box: {
      flexGrow: 0,
      flexBasis: props.flexBasis,
      backgroundColor: props.subTitle ? '#E4E4E4' : '#ffffff',
      borderLeft: props.borderLeft ? '1px solid #E4E4E4' : null,
      borderRight: props.borderRight ? '1px solid #E4E4E4' : null,
      borderTop: props.borderTop ? '1px solid #E4E4E4' : null,
      borderBottom: props.borderBottom ? '1px solid #E4E4E4' : null,
      textAlign: 'right',
      paddingRight: '5px',
      justifyContent: 'center',
      ...props.style,
    },
  });
  return (
    <View style={styles.box}>{props.children}</View>
  );
}
