export function getIndex(value, arr, prop) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1;
}

export function getIncludes(value, arr, prop) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][prop] === value) {
      return true;
    }
  }
  return false;
}

export function getEdit(value, arr, screen, key) {
  const item = arr.find((obj) => obj[screen] === value);
  return item ? item[key] : false;
}

export function getFormatScreens(screen) {
  const map = new Map();
  const category = [];
  screen.forEach((c) => {
    let lists = [];
    if (map.get(c.categoryName)) {
      lists = map.get(c.categoryName);
    } else {
      category.push(c.categoryName);
    }
    lists.push(c);
    map.set(c.categoryName, lists);
  });
  return {
    values: map,
    category,
  };
}
