import { createSlice } from '@reduxjs/toolkit';

export const opportunityAttachSlice = createSlice({
  name: 'opportunityAttach',
  initialState: {
    isCancelDeleteSuccess: false,
  },
  reducers: {
    resetFlg: (state) => {
      state.isSaveCancelSuccess = false;
      state.isCancelDeleteSuccess = false;
      state.isDeleteDeliverySuccess = false;
      state.isSaveDeliverySuccess = false;
    },
    getCancelDatas: () => {},
    getCancelDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getStatusHistory: () => {},
    getStatusHistorySuccess: (state, action) => {
      state.statusHistories = action.payload;
    },
    getCancelData: () => {},
    getCancelDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveCancelData: (state) => {
      state.isSaveCancelSuccess = false;
    },
    saveCancelDataSuccess: (state) => {
      state.isSaveCancelSuccess = true;
    },
    deleteCancelData: (state) => {
      state.isCancelDeleteSuccess = false;
    },
    deleteCancelDataSuccess: (state) => {
      state.isCancelDeleteSuccess = true;
    },
    getDeliveryDatas: () => {},
    getDeliveryDatasSuccess: (state, action) => {
      state.deliveryDatas = action.payload;
    },
    getDeliveryData: () => {},
    getDeliveryDataSuccess: (state, action) => {
      state.deliveryData = action.payload;
    },
    saveDeliveryData: (state) => {
      state.isSaveDeliverySuccess = false;
    },
    saveDeliveryDataSuccess: (state) => {
      state.isSaveDeliverySuccess = true;
    },
    deleteDeliveryData: (state) => {
      state.isDeleteDeliverySuccess = false;
    },
    deleteDeliveryDataSuccess: (state) => {
      state.isDeleteDeliverySuccess = true;
    },
    getUrl: () => {},
    getUrlSuccess: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const opportunityAttachActions = opportunityAttachSlice.actions;

export default opportunityAttachSlice.reducer;
