import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { specialFeatureAttachedActions } from '../../../../redux/pageSetting/specialFeature/specialFeatureAttachedState';
import Dialog from '../../../../components/atoms/Dialog';
import FileUploadDialog from '../../../../components/templates/imageList/FileUploadDialog';
import {
  FormTitle, FormErrorText, FormSwitch, DialogContent, TextArea,
} from '../../../../components/atoms/Base';
import Validation from '../validation';
import NumberInput from '../../../../components/atoms/NumberInput';
import SystemInfo from '../../../../components/templates/SystemInfo';
import SingleFileInput from '../../../../components/templates/SingleFileInput';

const baseForm = {
  title: '',
  content: '',
  displayOrder: '',
  imagePath: '',
  id: null,
  specialFeatureId: null,
  published: false,
};

export default function SpecialFeatureContentDialog(props) {
  const [form, setForm] = React.useState(baseForm);
  const [errorMessages, setErrorMessages] = React.useState(baseForm);
  const [openFile, setOpenFile] = React.useState(false);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, specialFeatureId, id,
  } = props;
  const dbData = useSelector((state) => state.specialFeatureAttachedStore.content);
  const loading = useSelector((state) => state.commonStore.isLoading);

  React.useEffect(() => {
    if (dbData) {
      setForm(dbData);
    }
  }, [dbData]);
  React.useEffect(() => {
    if (isOpen) {
      setForm(baseForm);
      setErrorMessages(baseForm);
      if (id) {
        dispatch(specialFeatureAttachedActions.getContent({ id }));
      }
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    return isError;
  };

  const onClickSave = () => {
    const tempForm = { ...form, specialFeatureId };
    if (beforeSaveCheck()) {
      return;
    }
    dispatch(specialFeatureAttachedActions.saveContent(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setForm(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  const onSettingFile = (data) => {
    setOpenFile(false);
    const newForm = { ...form, imagePath: data };
    setForm(newForm);
  };

  return (
    <>
      <Dialog isOpen={isOpen} title={`コンテンツの${form.id ? '編集' : '追加'}`} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
          <Box>
            <FormTitle title="タイトル" isRequired isNonFlex />
            <TextField value={form.title} fullWidth name="title" onChange={onChange} error={!!errorMessages.title} inputProps={{ maxLength: 255 }} />
            <FormErrorText>{errorMessages.title}</FormErrorText>
          </Box>
          <Box mt={1}>
            <FormTitle title="内容" isNonFlex />
            <TextArea value={form.content} name="content" onChange={onChange} />
          </Box>
          <Box mt={1}>
            <FormTitle title="並び順" isNonFlex isRequired />
            <NumberInput value={form.displayOrder} name="displayOrder" onChange={onChange} error={!!errorMessages.displayOrder} />
            <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
          </Box>
          <Box mt={1}>
            <FormTitle title="公開" />
            <FormSwitch checked={form.published} name="published" onChange={onChange} label="公開する" />
          </Box>
          <Box mt={1}>
            <SingleFileInput
              title="TOPバナー"
              isEdit
              imageFile="imageFile"
              imagePath="imagePath"
              form={form}
              inputForm={form}
              setInputForm={setForm}
            />
          </Box>
          {form.id && (
            <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">キャンセル</Button>
          <LoadingButton onClick={onClickSave} loading={loading}>保存</LoadingButton>
        </DialogActions>
      </Dialog>
      <FileUploadDialog
        isOpen={openFile}
        onClose={() => setOpenFile(false)}
        onClick={onSettingFile}
      />
    </>
  );
}
