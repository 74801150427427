import React from 'react';
import {
  Button, RadioGroup, FormControlLabel, Radio, Box, DialogActions, FormGroup, Alert,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';
import {
  FormTitle, FormSwitch, FormErrorText, DatePicker, DialogContent,
} from '../../../components/atoms/Base';
import NumberInput from '../../../components/atoms/NumberInput';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';

const reviewStatusCodes = [
  { value: 2, label: '公開する' },
  { value: 3, label: '非公開にする' },
];

const pupblicContents = [
  { value: 0, label: '評価を公開', name: 'evaluationPublished' },
  { value: 1, label: 'コメントを公開', name: 'commentPublished' },
  { value: 2, label: '画像を公開', name: 'imagePublished' },
];

const tmpData = {
  reviewStatusCode: '',
  evaluationPublished: false,
  commentPublished: false,
  imagePublished: false,
};

const tmpPointData = {
  grantedPoint: '',
  periodDays: '',
  grantedDate: '',
};

const tmpErrorMessages = {
  reviewStatusCode: '',
};

const tmpPointErrorMessages = {
  point: '',
  periodDays: '',
  grantedDate: '',
};

export default function StatusDialog(props) {
  const [data, setData] = React.useState(tmpData);
  const [pointData, setPointData] = React.useState(tmpPointData);
  const [errorMessages, setErrorMessages] = React.useState(tmpErrorMessages);
  const [pointErrorMessages, setPointErrorMessages] = React.useState(tmpPointErrorMessages);
  const [isGrantPoint, setGrantPoint] = React.useState('0');
  const dbData = useSelector((st) => st.reviewStore.data);
  const {
    isOpen, onClickSave, onClose,
  } = props;

  React.useEffect(() => {
    if (isOpen && dbData) {
      setData(dbData.data);
      setPointData(dbData.pointRule);
    }
  }, [isOpen, dbData]);

  React.useEffect(() => {
    if (!isOpen) {
      setData(tmpData);
      setPointData(tmpPointData);
      setErrorMessages(tmpErrorMessages);
      setPointErrorMessages(tmpPointErrorMessages);
    }
  }, [isOpen]);

  const updateData = (e) => {
    const { name, value } = e.target;
    if (value) {
      setErrorMessages({ ...errorMessages, reviewStatusCode: '' });
    }
    setData((prevData) => ({ ...prevData, [name]: Number(value) }));
  };

  const beforeSaveCheck = () => {
    let isError = false;
    if (data.reviewStatusCode === 2
      && !data.commentPublished
      && !data.evaluationPublished
      && !data.imagePublished
    ) {
      setErrorMessages({ ...errorMessages, reviewStatusCode: '1つ以上選択して下さい' });
      isError = true;
    }

    let tempMessage = pointErrorMessages;

    if (data.customerId && isGrantPoint === '0') {
      Object.keys(pointData).forEach((key) => {
        const msg = Validation.formValidate(key, pointData[key]);
        if (msg) {
          isError = true;
        }
        tempMessage = {
          ...tempMessage,
          [key]: msg,
        };
      });
      setPointErrorMessages(tempMessage);
    }

    return isError;
  };

  const onSave = () => {
    if (data.reviewStatusCode === 2 && beforeSaveCheck()) return;
    onClickSave(data, pointData, isGrantPoint);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setPointData({ ...pointData, [name]: value });
    setPointErrorMessages({ ...pointErrorMessages, [name]: Validation.formValidate(name, value) });
  };

  const onChangeGrantPoint = (event) => {
    const { value } = event.target;
    setGrantPoint(value);
  };

  return (
    <Dialog isOpen={isOpen} title="ステータス変更" onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        {(!data.planId || !data.shopId) && (
          <Box mb={2}>
            <Alert severity="warning">店舗またはプランが設定されていない為、公開してもサイトには表示されません。</Alert>
          </Box>
        )}

        <Box>
          <FormTitle title="公開タイプ" />
          <RadioGroup row value={data.reviewStatusCode} name="reviewStatusCode" onChange={updateData}>
            {reviewStatusCodes.map((e) => (
              <FormControlLabel
                key={e.value}
                value={e.value}
                control={<Radio />}
                label={e.label}
              />
            ))}
          </RadioGroup>
        </Box>
        {data.reviewStatusCode === 2 && (
          <>
            <Box mb={2} sx={{ width: 'fit-content' }}>
              <FormGroup>
                {pupblicContents.map((option) => (
                  <FormSwitch
                    onChange={updateData}
                    name={option.name}
                    key={option.value}
                    label={option.label}
                    checked={!!data[option.name]}
                  />
                ))}
              </FormGroup>
              <FormErrorText>{errorMessages.reviewStatusCode}</FormErrorText>
            </Box>
            {data.customerId && (
            <>
              <RadioGroup row value={isGrantPoint} name="isGrantPoint" onChange={onChangeGrantPoint}>
                <FormControlLabel
                  key={0}
                  value="0"
                  control={<Radio />}
                  label="ポイントを付与する"
                />
                <FormControlLabel
                  key={1}
                  value="1"
                  control={<Radio />}
                  label="ポイントを付与しない"
                />
              </RadioGroup>
              {isGrantPoint === '0' && (
                <>
                  <DataGrid title="ポイント" isEdit value={`${pointData.grantedPoint}pt`} isRequired>
                    <NumberInput name="grantedPoint" value={pointData.grantedPoint} onChange={onChange} error={!!pointErrorMessages.grantedPoint} endAdornment="pt" />
                    <FormErrorText>{pointErrorMessages.grantedPoint}</FormErrorText>
                  </DataGrid>
                  <DataGrid title="有効期間" isRequired isEdit value={`${pointData.periodDays}日間`}>
                    <Box>
                      <NumberInput name="periodDays" onChange={onChange} value={pointData.periodDays} endAdornment="日間" error={!!pointErrorMessages.periodDays} />
                      <FormErrorText>{pointErrorMessages.periodDays}</FormErrorText>
                    </Box>
                  </DataGrid>
                  <DataGrid title="付与日" isRequired isEdit value={pointData.grantedDate}>
                    <DatePicker name="grantedDate" value={pointData.grantedDate} onChange={onChange} error={!!pointErrorMessages.grantedDate} />
                    <FormErrorText>{pointErrorMessages.grantedDate}</FormErrorText>
                  </DataGrid>
                </>
              )}
            </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setData(tmpData); setErrorMessages(tmpErrorMessages); setPointErrorMessages(tmpPointErrorMessages); onClose(); }} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
