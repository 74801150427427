import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (name, value) => {
    switch (name) {
      case 'lastName':
      case 'subject':
      case 'body':
        return CommonValidation.requiredValue(value);
      case 'email':
        return CommonValidation.requiredEmail(value);
      case 'to':
        return CommonValidation.requiredEmailList(value);
      case 'cc':
        return CommonValidation.nonRequiredEmailList(value);
      default:
        return '';
    }
  };
}

export default Validation;
