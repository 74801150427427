import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, DialogActions,
} from '@mui/material';
import Dialog from '../../../../../components/atoms/Dialog';
import Validation from '../../../estimate/validation';
import SelectStoreComponent from '../../../common/selectStoreComponent/SelectStoreComponent';
import DataGrid from '../../../../../components/templates/DataGrid';
import DataSelectList from '../../../../../components/atoms/dataList/DataSelectList';
import CommonValidation from '../../../../../utils/common.validation';
import { estimateActions } from '../../../../../redux/opportunity/estimate/estimateState';
import { DialogContent, FormErrorText } from '../../../../../components/atoms/Base';

const baseForm = {
  shopId: '',
  vendorId: '',
  estimateStatusCode: '',
  opportunityId: '',
};

const DEFALUT_ERROR_MESSAGE = { status: '' };

export default function AddEstimateDialog(props) {
  const [form, setform] = React.useState(baseForm);
  const [detailType, setDetailType] = React.useState(1);
  const [errorMessages, setErrorMessages] = React.useState(DEFALUT_ERROR_MESSAGE);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, opportunityId, opportunityNumber,
  } = props;

  React.useEffect(() => {
    if (isOpen) {
      setform({ ...baseForm, opportunityId, opportunityNumber });
      setDetailType(1);
      setErrorMessages(DEFALUT_ERROR_MESSAGE);
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
      const result = CommonValidation.storeValidate(form, tempMessage, detailType);
      if (result.isError) {
        isError = true;
        tempMessage = result.tempMessage;
      }
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) {
      return;
    }
    const tempForm = { ...form, opportunityId };
    dispatch(estimateActions.saveData(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setform(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title="見積の追加" onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <SelectStoreComponent
          detailType={detailType}
          row={form}
          onChange={onChange}
          errorMessage={errorMessages}
          setDetailType={setDetailType}
          isEdit
        />
        <DataGrid title="ステータス" isRequired isEdit>
          <DataSelectList
            error={!!errorMessages.estimateStatusCode}
            name="estimateStatusCode"
            target="codes"
            parameter={{ codeCategory: 'estimate_status' }}
            value={form.estimateStatusCode}
            onChange={onChange}
            disableClearable
          />
          <FormErrorText>{errorMessages.estimateStatusCode}</FormErrorText>
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
