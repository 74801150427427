import React from 'react';
import {
  Grid,
} from '@mui/material';
import { FormTitle } from '../atoms/Base';

export default function SystemInfo(props) {
  const {
    createdByName, createdAt, updatedByName, updatedAt,
  } = props.data;
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      style={{ marginTop: 10, marginLeft: 0, width: '100%' }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Grid item xs={12} md={6} style={{ padding: 0 }}>
        <FormTitle title="作成情報" />
        {`${createdByName || ''}, ${createdAt}` }
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: 0 }}>
        <FormTitle title="更新情報" />
        {`${updatedByName || ''}, ${updatedAt}` }
      </Grid>
    </Grid>
  );
}
