import React from 'react';
import DataGrid from '../../../../components/templates/DataGrid';
import RadioButtons from '../../../../components/atoms/RadioButtons';
import { FormErrorText } from '../../../../components/atoms/Base';
import NumberInput from '../../../../components/atoms/NumberInput';

export default function OptionTypeInput(props) {
  const {
    errorMessages,
    onChange,
    row,
  } = props;

  return (
    <>
      <DataGrid title="オプション最低数" isRequired isEdit>
        <RadioButtons
          value={row.minOptionAssignTypeCode}
          onChange={onChange}
          name="minOptionAssignTypeCode"
          target="codes"
          parameter={{ codeCategory: 'option_assign_type' }}
          defaultValue={1}
          error={!!errorMessages.minOptionAssignTypeCode}
        />
        <FormErrorText>{errorMessages.minOptionAssignTypeCode}</FormErrorText>
      </DataGrid>
      {row.minOptionAssignTypeCode === 2 && (
      <DataGrid title="最低数" isEdit isRequired>
        <NumberInput value={row.minOptionAssignValue} fullWidth name="minOptionAssignValue" onChange={onChange} error={!!errorMessages.minOptionAssignValue} />
        <FormErrorText>{errorMessages.minOptionAssignValue}</FormErrorText>
      </DataGrid>
      )}
      <DataGrid title="オプション最大数" isRequired isEdit>
        <RadioButtons
          value={row.maxOptionAssignTypeCode}
          onChange={onChange}
          name="maxOptionAssignTypeCode"
          target="codes"
          parameter={{ codeCategory: 'option_assign_type' }}
          defaultValue={1}
          error={!!errorMessages.maxOptionAssignTypeCode}
        />
        <FormErrorText>{errorMessages.minOptionAssignTypeCode}</FormErrorText>
      </DataGrid>
      {row.maxOptionAssignTypeCode === 2 && (
      <DataGrid title="最大数" isEdit isRequired>
        <NumberInput value={row.maxOptionAssignValue} fullWidth name="maxOptionAssignValue" onChange={onChange} error={!!errorMessages.maxOptionAssignValue} />
        <FormErrorText>{errorMessages.maxOptionAssignValue}</FormErrorText>
      </DataGrid>
      )}
    </>
  );
}
