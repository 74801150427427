import HttpConnection from '../utils/http-connection';

export function getDataNoLoading(url, param) {
  const httpConnection = new HttpConnection(true);
  return httpConnection.get(url, param);
}

export function getDatas(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.get(url, param);
}

export function getDataById(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.getById(url, param);
}

export function saveData(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.post(url, param);
}

export function updateData(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.put(url, param, param);
}

export function deleteDatas(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.deletes(url, param);
}

export function uploadFiles(url, param) {
  const httpConnection = new HttpConnection();
  return httpConnection.upload(url, param);
}
