import React, { useState } from 'react';
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material';
import Validation from '../validation';
import DataGrid from '../../../../components/templates/DataGrid';
import Dialog from '../../../../components/atoms/Dialog';
import {
  FormTitle, DialogContent, FormErrorText,
} from '../../../../components/atoms/Base';
import SelectBlogDialog from '../relatedBlogDialog/SelectBlogDialog';
import Editor from '../../../../components/templates/wysiwyg/Editor';
import SingleFileInput from '../../../../components/templates/SingleFileInput';

const DEFAULT_FORM = {
  id: '',
  blogId: '',
  contentType: 1,
  link: '',
  linkText: '',
  imagePath: '',
  content: '',
  relateBlogId: '',
  displayOrder: '',
};

const DEFAULT_ERROR_MESSAGE = {
  linkText: '',
  link: '',
  imageFile: '',
  content: '',
  relateBlogId: '',
};

export default function BlogContentDialog(props) {
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const {
    isOpen, onClose, onSave, content, contentType,
  } = props;
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGE);

  React.useEffect(() => {
    if (isOpen) {
      setForm({ ...DEFAULT_FORM, contentType, ...content });
      setErrorMessages(DEFAULT_ERROR_MESSAGE);
    }
  }, [isOpen]);

  const beforeSaveCheck = (newRow) => {
    let tempMessages = errorMessages;
    let isError = false;

    Object.keys(newRow).forEach((key) => {
      const msg = Validation.contentValidate(key, newRow[key], newRow.contentType);
      if (msg) {
        isError = true;
      }
      let tmpKey = key;
      if (key === 'imagePath') {
        tmpKey = 'imageFile';
      }
      tempMessages = {
        ...tempMessages,
        [tmpKey]: msg,
      };
    });
    if (!isError && !newRow.imageFile && !newRow.imagePath && newRow.contentType === 2) {
      isError = true;
      tempMessages = {
        ...tempMessages,
        imageFile: '設定してください。',
      };
    }
    setErrorMessages(tempMessages);
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck(form)) return;
    onSave(form);
  };

  const onChange = (event) => {
    const {
      name,
      value,
    } = event.target;
    let newRow = form;
    let newMessage = errorMessages;
    newRow = {
      ...newRow,
      [name]: value,
    };
    newMessage = {
      ...newMessage,
      [name]: Validation.formValidate(name, value),
    };
    setForm(newRow);
    setErrorMessages(newMessage);
  };

  const onSelect = (_, selected) => {
    setForm({ ...form, relateBlogId: selected.id, relateBlogTitle: selected.title });
    setErrorMessages({ ...errorMessages, relateBlogId: '' });
    setSelectOpen(false);
  };

  return (
    <>
      <Dialog isOpen={isOpen} title="コンテンツ" fullWidth maxWidth="md" onClose={onClose}>
        <DialogContent>
          <Box>
            {form.contentType === 1 && (
              <>
                <DataGrid title="リンク" isEdit value={form.link}>
                  <TextField autoComplete="off" value={form.link} fullWidth name="link" onChange={onChange} error={!!errorMessages.link} />
                  <FormErrorText>{errorMessages.link}</FormErrorText>
                </DataGrid>
                <DataGrid title="リンクテキスト" isEdit value={form.linkText}>
                  <TextField autoComplete="off" value={form.linkText} fullWidth name="linkText" onChange={onChange} error={!!errorMessages.linkText} />
                  <FormErrorText>{errorMessages.linkText}</FormErrorText>
                </DataGrid>
              </>
            )}
            {form.contentType === 2 && (
              <>
                <SingleFileInput
                  title="イメージ"
                  isEdit
                  imagePath="imagePath"
                  imageFile="imageFile"
                  isImageOnly
                  form={form}
                  inputForm={form}
                  setInputForm={setForm}
                />
                <FormErrorText>{errorMessages.imageFile}</FormErrorText>
              </>
            )}
            {(form.contentType === 3 || form.contentType === 5) && (
            <Box mb={2} mt={1}>
              <FormTitle title="テキスト" isNonFlex />
              <Editor title="テキスト" name="content" onChange={onChange} content={form.content} />
              <FormErrorText>{errorMessages.content}</FormErrorText>
            </Box>
            )}
            {form.contentType === 4 && (
            <DataGrid title="関連幹事のコツ" isEdit>
              <Box>
                {form.relateBlogTitle}
              </Box>
              <Box mt={1}>
                <Button size="small" variant="contained" onClick={() => setSelectOpen(true)}>関連幹事のコツ選択</Button>
              </Box>
              <FormErrorText>{errorMessages.relateBlogId}</FormErrorText>
            </DataGrid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">キャンセル</Button>
          <Button onClick={onClickSave}>追加</Button>
        </DialogActions>
      </Dialog>
      <SelectBlogDialog
        isOpen={isSelectOpen}
        onSelect={onSelect}
        onClose={() => setSelectOpen(false)}
      />
    </>

  );
}
