import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PdfDownloadButton } from '../../../../components/atoms/Base';
import { claimActions } from '../../../../redux/opportunity/claim/claimState';
import ClaimAffiliationFile from '../../report/ClaimAffiliationFile';
import ClaimEnglishFile from '../../report/ClaimEnglishFile';
import ClaimFile from '../../report/ClaimFile';

export default function ClaimFileDownloadButton(props) {
  const {
    id,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isReadyDownload, setReadyDownload] = React.useState(false);
  const formData = useSelector((state) => state.claimStore.pdfDatas);
  const isGetData = useSelector((state) => state.claimStore.isGetPdfData);
  const [pdfData, setPdfData] = React.useState({ header: {} });
  const [isClickedOnDownload, setClickedOnDownload] = React.useState(false);

  const fileData = () => {
    if (!pdfData.header) return '';
    let returnData = pdfData.header.affiliationId
      ? <ClaimAffiliationFile data={pdfData} /> : <ClaimFile data={pdfData} />;
    if (pdfData.header.englishReport)returnData = <ClaimEnglishFile data={pdfData} />;
    return returnData;
  };

  useEffect(() => {
    if (isGetData && isClickedOnDownload) {
      setPdfData(formData);
      setClickedOnDownload(false);
      setLoading(false);
      setReadyDownload(true);
      dispatch(claimActions.resetFlgs());
    }
  }, [isGetData]);

  const onComfimationDownload = () => {
    dispatch(claimActions.getPdfData({ id }));
    setPdfData({ header: {} });
    setLoading(true);
    setReadyDownload(false);
    setClickedOnDownload(true);
  };

  return (
    <PdfDownloadButton
      title="請求書ダウンロード"
      file={fileData()}
      pdfData={pdfData}
      isReadyDownload={isReadyDownload}
      onClick={onComfimationDownload}
      loading={loading}
      onClose={() => { setReadyDownload(false); }}
    />
  );
}
