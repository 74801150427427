import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../constants/frontUrls';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { shopActions } from '../../../redux/shop/shop/shopState';

const DEFAULT_SEARCH_CONDITION = {
  accountId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  { id: 'shopCode', label: '店舗コード', sortId: 'shop_code' },
  { id: 'name', label: '店舗名', sortId: 'name' },
  { id: 'prefectureName', label: '都道府県', sortId: 'prefecture_name' },
  { id: 'cityName', label: '市区町村', sortId: 'city_name' },
  { id: 'phone', label: '電話番号', sortId: 'phone' },
  { id: 'email', label: 'メール', sortId: 'email' },
  { id: 'published', label: '公開状況', sortId: 'published' },
];

export default function ShopList(props) {
  const { accountId } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const shopDatas = useSelector((state) => state.shopStore.datas);

  React.useEffect(() => {
    if (accountId && shopDatas) {
      setDatas(shopDatas);
    }
  }, [shopDatas]);

  const search = (tempCondition) => {
    dispatch(shopActions.getDatas(tempCondition));
  };

  React.useEffect(() => {
    if (accountId) {
      search({ ...DEFAULT_SEARCH_CONDITION, accountId });
    }
  }, [accountId]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <DataTableWithMenu
      url={detailUrl.SHOP_DETAIL}
      rows={datas.content || []}
      headCells={headCells}
      procName="店舗"
      page={searchCondition.page}
      rowsPerPage={searchCondition.records}
      totalElements={datas.totalElements}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleRequestSort={handleRequestSort}
      order={searchCondition.order}
      orderBy={searchCondition.orderBy}
      isNotDelete
      isNotAdd
    />
  );
}
