import React from 'react';
import {
  DialogActions, Box, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';
import { DialogContent } from '../../../components/atoms/Base';
import { trashActions } from '../../../redux/systemSetting/trash/trashState';
import detailUrl from '../../../constants/frontUrls';

export default function TrashRestorDialog(props) {
  const {
    isOpen, onClose, row, searchCondition,
  } = props;
  const urlMapping = {
    contact: detailUrl.CONTACT_DETAIL,
    opportunity: detailUrl.OPPORTUNITY_DETAIL,
    estimate: detailUrl.ESTIMATE_DETAIL,
    estimateDetail: detailUrl.ESTIMATE_DETAIL,
    order: detailUrl.ORDER_DETAIL,
    orderDetail: detailUrl.ORDER_DETAIL,
    claim: detailUrl.CLAIM_DETAIL,
    claimDetail: detailUrl.CLAIM_DETAIL,
    customer: detailUrl.CUSTOMER_DETAIL,
    vendor: detailUrl.VENDOR_DETAIL,
    deliveryAgency: detailUrl.DELIVERY_AGENCY_DETAIL,
    account: detailUrl.ACCOUNT_DETAIL,
    shop: detailUrl.SHOP_DETAIL,
    shopOption: detailUrl.SHOP_DETAIL,
    shopDelivery: detailUrl.SHOP_DETAIL,
    shopHoliday: detailUrl.SHOP_DETAIL,
    shopCancelRate: detailUrl.SHOP_DETAIL,
    plan: detailUrl.PLAN_DETAIL,
    planOption: detailUrl.PLAN_DETAIL,
    shopNotice: detailUrl.SHOP_NOTICE_DETAIL,
    review: detailUrl.REVIEW_DETAIL,
    role: detailUrl.ROLE_DETAIL,
    loginUser: detailUrl.LOGIN_USER_DETAIL,
    specialFeature: detailUrl.SPECIAL_FEATURE_DETAIL,
    specialFeatureContent: detailUrl.SPECIAL_FEATURE_DETAIL,
    specialFeaturePlan: detailUrl.SPECIAL_FEATURE_DETAIL,
    specialFeatureShop: detailUrl.SPECIAL_FEATURE_DETAIL,
    specialFeatureArea: detailUrl.SPECIAL_FEATURE_DETAIL,
    pointRule: detailUrl.POINT_RULE_DETAIL,
    mailTemplate: detailUrl.MAIL_TEMPLATE_DETAIL,
    illustration: detailUrl.ILLUSTRATION_DETAIL,
    blog: detailUrl.BLOG_DETAIL,
  };
  const [isReshopd, setIsReshopd] = React.useState(false);
  const [targetId, setTargetId] = React.useState('');
  const dispatch = useDispatch();
  const isRestorSuccess = useSelector(
    (state) => state.trashStore.isRestorSuccess,
  );
  const restorData = useSelector(
    (state) => state.trashStore.restorData,
  );

  React.useEffect(() => {
    if (isRestorSuccess) {
      setIsReshopd(true);
      setTargetId(restorData);
      dispatch(trashActions.resetFlg());
      dispatch(trashActions.getDatas(searchCondition));
    }
  }, [isRestorSuccess]);

  React.useEffect(() => {
    if (isOpen) {
      setIsReshopd(false);
    }
  }, [isOpen]);

  const onClickRestorDetail = () => {
    const url = urlMapping[searchCondition.target] + targetId;
    window.open(url, '_blank');
  };
  const onRestorData = () => {
    dispatch(trashActions.restorData({ id: row.id, target: searchCondition.target }));
  };

  return (
    <Dialog
      title="ごみ箱"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        {!isReshopd && (
          <Box>
            {row.name}
            を復元します。よろしいですか？
          </Box>
        )}
        {isReshopd && (
          <Box>
            <Box mb={1}>
              復元しました。
            </Box>
            <Box>
              <Button onClick={onClickRestorDetail}>復元データを見る</Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>{isReshopd ? '閉じる' : 'キャンセル'}</Button>
        {!isReshopd && (
          <Button onClick={onRestorData} color="primary">
            復元する
          </Button>
        )}

      </DialogActions>

    </Dialog>
  );
}
