import React, { useEffect } from 'react';
import {
  Box, Button, IconButton, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataGrid from './DataGrid';
import FileUploadDialog from './imageList/FileUploadDialog';
import { FormErrorText, Image } from '../atoms/Base';

export default function SingleFileInput(props) {
  const {
    title, setInputForm, isEdit, form, inputForm,
    imagePath, imageFile, isImageOnly,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    setError(false);
  }, [isEdit]);

  const addimage = (value) => {
    setError(false);
    if (isImageOnly && value[0].type && value[0].type.startsWith('video')) {
      setError(true);
      return;
    }
    const changeTempForm = {
      ...inputForm,
      [imageFile]: value[0],
      mimeType: value[0].type,
    };
    setInputForm(changeTempForm);
  };

  return (
    <>
      <DataGrid
        title={title}
        isEdit={isEdit}
        value={form[imagePath] && (
          <Box style={{
            display: 'flex', justifyContent: 'start', alignItems: 'center',
          }}
          >
            <Image src={process.env.REACT_APP_AWS_PUBLIC_URL + form[imagePath]} alt="" mimeType={form.mimeType ? form.mimeType : 'image'} />
          </Box>
        )}
      >
        {error && (<FormErrorText>追加できるのは画像のみです。</FormErrorText>)}
        {(inputForm[imagePath] || inputForm[imageFile]) && (
          <Box style={{
            display: 'flex', justifyContent: 'start', alignItems: 'center',
          }}
          >
            <Image src={inputForm[imageFile] ? URL.createObjectURL(inputForm[imageFile]) : process.env.REACT_APP_AWS_PUBLIC_URL + inputForm[imagePath]} alt="" mimeType={inputForm.mimeType ? inputForm.mimeType : 'image'} />
            <Tooltip title="削除する">
              <IconButton onClick={() => {
                setInputForm({
                  ...inputForm, [imagePath]: '', [imageFile]: null,
                });
              }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {!(inputForm[imagePath] || inputForm[imageFile]) && (
          <Button onClick={() => { setOpen(true); }}>
            追加する
          </Button>
        )}
      </DataGrid>
      <FileUploadDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        onClick={(data) => { setOpen(false); addimage(data); }}
      />
    </>
  );
}
