import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Typography, Box, TextField, Button, CircularProgress,
  Backdrop, Paper, Divider, Link,
} from '@mui/material';
import { loginActions } from '../../redux/login/loginState';
import Validation from './validation';
import { FormErrorText } from '../../components/atoms/Base';

class ResetPasswordToken extends React.Component {
  constructor(props) {
    super(props);

    const form = {
      email: '',
    };
    this.state = {
      form,
      isResetPasswordTokenSuccess: false,
      loading: false,
      errorMessages: {
        email: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginInfo.isResetPasswordTokenSuccess
      !== this.props.loginInfo.isResetPasswordTokenSuccess) {
      if (this.props.loginInfo.isResetPasswordTokenSuccess) {
        this.setResetPasswordTokenSuccess();
      }
    }
  }

  setResetPasswordTokenSuccess = () => {
    this.setState({ loading: false, isResetPasswordTokenSuccess: true });
  };

  handleBtnLogin = (event) => {
    event.preventDefault();
    const { form, errorMessages } = this.state;

    const errorMessage = Validation.formValidate('email', form.email);

    if (errorMessage) {
      this.setState({
        errorMessages: { ...errorMessages, email: errorMessage },
      });
      return;
    }

    this.setState({ loading: true });
    this.props.dispatch(
      loginActions.resetPasswordToken({
        email: form.email,
      }),
    );
  };

  onChange = (event) => {
    const key = event.target.name;
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: value,
      },
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  render() {
    const {
      form, loading, isResetPasswordTokenSuccess, errorMessages,
    } = this.state;

    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={15} style={{ padding: '30px' }}>
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mb={2}
          >
            <Typography component="h1" variant="h5">
              パスワードリセット
            </Typography>
            {!isResetPasswordTokenSuccess && (
              <>
                <Box mt={2}>登録されているメールアドレスを入力してください。</Box>
                <Box mt={4} sx={{ width: '100%' }}>
                  <Box>メールアドレス</Box>
                  <TextField error={!!errorMessages.email} value={form.email} fullWidth name="email" onChange={this.onChange} />
                  <FormErrorText>{errorMessages.email}</FormErrorText>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={this.handleBtnLogin}
                    disabled={!form.email || !!errorMessages.email}
                  >
                    送信
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '13px',
                  }}
                >
                  <Link href="/login">
                    ログイン画面へ戻る
                  </Link>
                </Box>
              </>
            )}
            {isResetPasswordTokenSuccess && (
              <>
                <Box mt={2}>登録されているメールアドレスにメールを送信しました。</Box>
                <Box>パスワードリセットのリンクは24時間有効です。</Box>
                <Box
                  mt={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '13px',
                  }}
                >
                  <Link href="/login">
                    ログイン画面へ戻る
                  </Link>
                </Box>
              </>
            )}
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.cqree.jp/corporate/" target="_blank">
                Cqree INC.
              </Link>
              {' '}
              {new Date().getFullYear()}
              .
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
});

export default connect(mapStateToProps)(ResetPasswordToken);
