import { createSlice } from '@reduxjs/toolkit';

export const trashSlice = createSlice({
  name: 'trash',
  initialState: {
    isRestorSuccess: false,
    restorData: null,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    restorData: (state) => {
      state.isRestorSuccess = false;
      state.restorData = null;
    },
    restorDataSuccess: (state, action) => {
      state.isRestorSuccess = true;
      state.restorData = action.payload;
    },
    resetFlg: (state) => {
      state.isRestorSuccess = false;
      state.restorData = null;
    },

  },
});

export const trashActions = trashSlice.actions;

export default trashSlice.reducer;
