import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, TextField, Chip,
} from '@mui/material';
import { shopNoticeActions } from '../../../redux/shop/shopNotice/shopNoticeState';
import {
  FormTitle, SuccessSnackbar, Link, RecordBox, withParams,
  DatePicker, FormErrorText, FormSwitch, FreeSoloBox,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import Editor from '../../../components/templates/wysiwyg/Editor';
import RichTextReadOnly from '../../../components/templates/wysiwyg/RichTextReadOnly';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import SystemInfo from '../../../components/templates/SystemInfo';
import Validation from './validation';
import SelectShopDialog from './SelectShopDialog';
import RecordHeader from '../../../components/templates/RecordHeader';

const ERROR_MESSAGES = {
  startDate: '',
  endDate: '',
  category: '',
  title: '',
  content: '',
};

class ShopNoticeDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      data: {
        id, startDate: '', endDate: '', category: '', title: '', content: '', allTargeted: false,
      },
      targets: [],
    };

    let isSuccessOpen = false;
    if (props.shopNoticeInfo.isSaveSuccess) {
      isSuccessOpen = true;
      props.dispatch(shopNoticeActions.resetFlg());
    }

    this.state = {
      isSuccessOpen,
      form,
      inputForm: form,
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isEdit: !form.data.id,
      errorMessages: ERROR_MESSAGES,
      isSelectOpen: false,
      categories: [],
    };
    props.dispatch(shopNoticeActions.getCategories());
    if (id) {
      props.dispatch(shopNoticeActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopNoticeInfo.data !== this.props.shopNoticeInfo.data) {
      if (this.props.shopNoticeInfo.data) {
        this.setData(this.props.shopNoticeInfo.data);
      }
    }
    if (prevProps.shopNoticeInfo.isSaveSuccess !== this.props.shopNoticeInfo.isSaveSuccess) {
      if (this.props.shopNoticeInfo.isSaveSuccess) {
        this.setSuccess(this.props.shopNoticeInfo.saveId);
      }
    }
    if (prevProps.shopNoticeInfo.categories !== this.props.shopNoticeInfo.categories) {
      if (this.props.shopNoticeInfo.categories) {
        this.setCategories(this.props.shopNoticeInfo.categories);
      }
    }
  }

  setCategories = (categories) => {
    this.setState({ categories });
  };

  setSuccess = (id) => {
    const { form } = this.state;
    const searchId = id || form.data.id;
    if ((form.data.id && form.data.id !== id) || !form.data.id) {
      window.location.href = detailUrl.SHOP_NOTICE_DETAIL + id;
      return;
    }
    this.props.dispatch(shopNoticeActions.resetFlg());
    this.setState({ isEdit: false, isSuccessOpen: true });
    this.props.dispatch(shopNoticeActions.getData({ id: searchId }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      data: {
        ...inputForm.data,
        [name]: value,
      },
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    this.setState({
      isEdit: true,
      errorMessages: ERROR_MESSAGES,
    });
    this.props.dispatch(shopNoticeActions.getData({ id: form.data.id }));
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(inputForm.data).forEach((key) => {
      const msg = Validation.formValidate(key, inputForm.data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (!inputForm.allTargeted && inputForm.targets.length <= 0) {
      tempMessage = {
        ...tempMessage,
        targets: '全店にお知らせしない場合、お知らせ店舗は選択必須です',
      };
    }
    if (isError) {
      this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
      return;
    }
    const data = {
      ...inputForm.data,
      shopNoticeTargets: inputForm.targets.map((n) => n.shopId),
    };
    this.props.dispatch(shopNoticeActions.saveData(data));
  };

  onClickCancel = () => {
    const { form } = this.state;
    this.setState({ isEdit: false, inputForm: form });
  };

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onDelete = (_, data) => {
    const { inputForm } = this.state;
    const newTargets = inputForm.targets.filter((target) => target.shopId !== data.shopId);
    this.setState({
      inputForm: {
        ...inputForm,
        targets: newTargets,
      },
    });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSelect = (selected) => {
    const { inputForm } = this.state;

    const newTargets = selected.reduce((acc, item) => {
      const exists = inputForm.targets.some((target) => target.shopId === item.id);
      if (!exists) {
        acc.push({
          shopId: item.id,
          name: item.name,
        });
      }
      return acc;
    }, []);

    let updatedInputForm = inputForm;
    if (newTargets.length > 0) {
      updatedInputForm = {
        ...inputForm,
        targets: [...inputForm.targets, ...newTargets],
      };
    }
    this.setState({ isSelectOpen: false, inputForm: updatedInputForm });
  };

  onCopy = () => {
    const { form } = this.state;
    this.setState({
      inputForm: { ...form, data: { ...form.data, id: null } },
      isEdit: true,
      errorMessages: ERROR_MESSAGES,
    });
  };

  render() {
    const {
      form,
      inputForm,
      isSuccessOpen,
      isDisabled,
      isEdit,
      errorMessages,
      isSelectOpen,
      categories,
    } = this.state;

    return (
      <>
        {(!isEdit && !isDisabled) && (
          <RecordHeader
            bottomSection={(
              <>
                <Button color="primary" variant="contained" size="small" onClick={this.onCopy}>コピーする</Button>
                <Button color="primary" variant="contained" size="small" onClick={this.onEdit}>編集</Button>
              </>
            )}
          />
        )}

        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={12}>
            <RecordBox>
              <DataGrid
                title="お知らせ店舗"
                isEdit={isEdit}
                value={(
                  <Box display="flex">
                    {form.targets && form.targets.map((p) => (
                      <Box key={p.shopId} mr={2}>
                        <Link to={detailUrl.SHOP_DETAIL + p.shopId}>
                          {p.name}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                )}
              >
                {inputForm.targets.map((s) => (
                  <Chip key={s.shopId} disabled={inputForm.data.allTargeted} label={s.name} onDelete={(e) => this.onDelete(e, s)} sx={{ marginRight: '10px' }} />
                ))}
                <Box mt={2}>
                  <Button disabled={inputForm.data.allTargeted} size="small" variant="contained" onClick={() => this.setState({ isSelectOpen: true })}>店舗選択</Button>
                </Box>
                <FormErrorText>{errorMessages.targets}</FormErrorText>
              </DataGrid>
              <DataGrid title="全店舗へのお知らせ" isEdit={isEdit} value={form.data.allTargeted ? 'する' : ''}>
                <FormSwitch checked={inputForm.data.allTargeted} name="allTargeted" label="する" onChange={this.onChange} />
              </DataGrid>
              <DataGrid title="公開開始日" isRequired isEdit={isEdit} value={form.data.startDate}>
                <DatePicker error={!!errorMessages.startDate} name="startDate" value={inputForm.data.startDate} onChange={this.onChange} />
                <FormErrorText>{errorMessages.startDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="公開終了日" isRequired isEdit={isEdit} value={form.data.endDate}>
                <DatePicker error={!!errorMessages.endDate} name="endDate" value={inputForm.data.endDate} onChange={this.onChange} />
                <FormErrorText>{errorMessages.endDate}</FormErrorText>
              </DataGrid>
              <DataGrid title="カテゴリ" isEdit={isEdit} isRequired value={form.data.category}>
                <FreeSoloBox
                  name="category"
                  options={categories}
                  value={inputForm.data.category}
                  onChange={this.onChange}
                  disableClearable
                  error={!!errorMessages.category}
                />
                <FormErrorText>{errorMessages.category}</FormErrorText>
              </DataGrid>
              <DataGrid title="件名" isRequired isEdit={isEdit} value={form.data.title}>
                <TextField error={!!errorMessages.title} name="title" value={inputForm.data.title} onChange={this.onChange} fullWidth inputProps={{ maxLength: 200 }} />
                <FormErrorText>{errorMessages.title}</FormErrorText>
              </DataGrid>
              <FormTitle title="内容" isRequired={isEdit} isNonFlex />
              {!isEdit && <RichTextReadOnly content={form.data.content} />}
              {isEdit && <Editor title="お知らせの" name="content" onChange={this.onChange} content={inputForm.data.content} />}

              {isEdit && (
                <Box position="sticky" bottom={0} left={0} zIndex={3} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {form.data.id && (<Button color="inherit" variant="contained" size="small" onClick={this.onClickCancel} style={{ marginRight: '10px' }}>キャンセル</Button>)}
                  <Button variant="contained" size="small" onClick={this.onSave}>保存</Button>
                </Box>
              )}
              {!isEdit && form.data.id && (
                <Box mt={2}><SystemInfo data={form.data} /></Box>
              )}
            </RecordBox>
          </Grid>
        </Grid>
        <SelectShopDialog
          isOpen={isSelectOpen}
          onSelect={this.onSelect}
          onClose={() => this.setState({ isSelectOpen: false })}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shopNoticeInfo: state.shopNoticeStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ShopNoticeDetail));
