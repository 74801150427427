import React from 'react';
import {
  DialogActions, Button, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DataGrid from '../../../../components/templates/DataGrid';
import Validation from '../validation';
import {
  FormErrorText, TextArea, DatePicker, DialogContent,
} from '../../../../components/atoms/Base';
import RadioButtons from '../../../../components/atoms/RadioButtons';
import SystemInfo from '../../../../components/templates/SystemInfo';

const DEFAULT_FORM = {
  id: '',
  serviceTypeCode: 1,
  holidayTypeCode: 1,
  startDate: '',
  endDate: '',
  remarks: '',
  isDelete: false,
};
const DEFAULT_ERROR = {
  serviceTypeCode: '',
  holidayTypeCode: '',
  startDate: '',
  endDate: '',
};

export default function HolidayDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    shopId,
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const holiday = useSelector((state) => state.shopDetailStore.holiday);
  const [title, setTitle] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR);

  React.useEffect(() => {
    setErrorMessages(DEFAULT_ERROR);
    if (isOpen && openedMenuRowId) {
      setTitle('休業の編集');
      dispatch(shopDetailActions.getHolidayDataById({ id: openedMenuRowId }));
    }
    if (isOpen && !openedMenuRowId) {
      setTitle('休業の追加');
      setForm(DEFAULT_FORM);
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (holiday) {
      setForm(holiday);
    }
  }, [holiday]);

  const inputCheck = (name, value) => {
    let msg = Validation.holidayFormValidate(name, value);
    if (msg || !(name === 'startDate' || name === 'endDate') || !value) return msg;
    if (name === 'startDate' && form.endDate && form.endDate < value) {
      msg = '終了日より過去を入力してください';
    }
    if (name === 'endDate' && form.startDate && form.startDate > value) {
      msg = '開始日より未来を入力してください';
    }
    return msg;
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });

    setErrorMessages({
      ...errorMessages,
      [name]: inputCheck(name, value),
    });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const msg = inputCheck(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) return;
    dispatch(shopDetailActions.saveHolidayData({
      ...form,
      shopId,
    }));
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent>
        <DataGrid title="サービスタイプ" isEdit isRequired>
          <RadioButtons
            name="serviceTypeCode"
            target="codes"
            parameter={{ codeCategory: 'service_type' }}
            value={form.serviceTypeCode}
            error={!!errorMessages.serviceTypeCode}
            onChange={onChange}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="休日タイプ" isEdit isRequired>
          <RadioButtons
            name="holidayTypeCode"
            target="codes"
            parameter={{ codeCategory: 'holiday_type' }}
            value={form.holidayTypeCode}
            error={!!errorMessages.holidayTypeCode}
            onChange={onChange}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.holidayTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="休業開始日" isEdit isRequired>
          <DatePicker
            value={form.startDate}
            onChange={onChange}
            name="startDate"
            error={!!errorMessages.startDate}
          />
          <FormErrorText>{errorMessages.startDate}</FormErrorText>
        </DataGrid>
        <DataGrid title="休業終了日" isEdit isRequired>
          <DatePicker
            value={form.endDate}
            onChange={onChange}
            name="endDate"
            error={!!errorMessages.endDate}
          />
          <FormErrorText>{errorMessages.endDate}</FormErrorText>
        </DataGrid>
        <DataGrid title="メモ" isEdit>
          <TextArea value={form.remarks} fullWidth name="remarks" onChange={onChange} />
        </DataGrid>
        {form.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
