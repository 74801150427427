import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { claimActions } from './claimState';
import {
  getDatas as callGetData, deleteDatas as callDeleteDatas, saveData as callSaveData, getDataById,
} from '../../../services/base.service';

function* getDatas({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM.CLAIM, payload);
    yield put(claimActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM.CLAIM_CSV, payload);
    yield put(claimActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteDatas({ payload }) {
  try {
    const result = yield call(callDeleteDatas, REST_API.OPPORTUNITY.CLAIM.CLAIM, payload);
    yield put(claimActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* saveData({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.CLAIM.CLAIM, payload);
    yield put(claimActions.saveDataSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.CLAIM.CLAIM_ID, payload);
    yield put(claimActions.getDataSuccess(result));
  } catch (_) {}
}

function* createPaymentLink({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM.CLAIM_PAYMENT_LINK, payload);
    yield put(claimActions.createPaymentLinkSuccess(result));
  } catch (_) {}
}
function* sendText({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.CLAIM.CLAIM_SEND_TEXT, payload);
    yield put(claimActions.sendTextSuccess(result));
  } catch (_) { }
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.REPORT.CLAIM, payload);
    yield put(claimActions.getPdfDataSuccess(result));
  } catch (_) { }
}

function* getReceiptData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.REPORT.RECEIPT, payload);
    yield put(claimActions.getReceiptDataSuccess(result));
  } catch (_) { }
}

function* claimSaga() {
  yield takeLatest('claim/getDatas', getDatas);
  yield takeLatest('claim/getData', getData);
  yield takeLatest('claim/getCsvDatas', getCsvDatas);
  yield takeLatest('claim/saveData', saveData);
  yield takeLatest('claim/deleteData', deleteDatas);
  yield takeLatest('claim/createPaymentLink', createPaymentLink);
  yield takeLatest('claim/sendText', sendText);
  yield takeLatest('claim/getPdfData', getPdfData);
  yield takeLatest('claim/getReceiptData', getReceiptData);
}

export default claimSaga;
