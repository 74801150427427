import * as React from 'react';
import {
  Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon,
  Checkbox, Button, Divider, TextField, Switch, FormControlLabel, Box,
} from '@mui/material';

function not(a, b) {
  const target = [];
  a.forEach((avalue) => {
    const existTarget = b.filter((bvalue) => bvalue.key === avalue.key);
    if (existTarget.length === 0) {
      target.push(avalue);
    }
  });
  return target;
}

function intersection(a, b) {
  let target = [];
  a.forEach((avalue) => {
    const existTarget = b.filter((bvalue) => bvalue.key === avalue.key);
    if (existTarget.length > 0) {
      target = target.concat(existTarget);
    }
  });
  return target;
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function isChecked(targets, item) {
  return targets.some((target) => target.key === item.key);
}

export default function TransferList(props) {
  const [checked, setChecked] = React.useState([]);
  const [leftSearchTerm, setLeftSearchTerm] = React.useState('');
  const [rightSearchTerm, setRightSearchTerm] = React.useState('');
  const [showDetails, setShowDetails] = React.useState(false);
  const [left, setLeft] = React.useState(props.left);
  const [right, setRight] = React.useState(props.right);

  React.useEffect(() => {
    setLeft(props.left);
    setRight(props.right);
  }, [props.left, props.right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (item) => () => {
    const isExist = checked.some((target) => target.key === item.key);
    let newChecked = [...checked];

    if (!isExist) {
      newChecked.push(item);
    } else {
      newChecked = checked.filter((target) => target.key !== item.key);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const tempRight = right.concat(leftChecked);
    const tempLeft = not(left, leftChecked);
    props.onSetValue(tempRight, tempLeft, showDetails);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const tempLeft = left.concat(rightChecked);
    const tempRight = not(right, rightChecked);
    props.onSetValue(tempRight, tempLeft, showDetails);
    setChecked(not(checked, rightChecked));
  };

  const filteredLeft = left.filter(
    (item) => item.label.toLowerCase().includes(leftSearchTerm.toLowerCase()),
  );

  const filteredRight = right.filter(
    (item) => item.label.toLowerCase().includes(rightSearchTerm.toLowerCase()),
  );

  const handleSwitchChange = (event) => {
    const newShowDetails = event.target.checked;
    const newLeft = newShowDetails
      ? [...left, ...props.detailCsvItem] : not(left, props.detailCsvItem);
    const newRight = newShowDetails
      ? not(right, props.detailCsvItem) : [...right, ...props.detailCsvItem];

    setShowDetails(newShowDetails);
    setLeft(newLeft);
    setRight(newRight);

    props.onSetValue(newRight, newLeft, newShowDetails);
  };

  const customList = (title, items, searchTerm, setSearchTerm) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={(
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length
              && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} 選択中`}
      />
      <Divider />
      <TextField
        fullWidth
        margin="dense"
        autoComplete="off"
        placeholder={`${title}を検索する`}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <List
        sx={{
          width: 340,
          height: 500,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((item) => {
          const labelId = `transfer-list-all-item-${item.key}-label`;

          return (
            <ListItem
              key={item.key}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isChecked(checked, item)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        {customList(props.leftTitle, filteredLeft, leftSearchTerm, setLeftSearchTerm)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList(props.rightTitle, filteredRight, rightSearchTerm, setRightSearchTerm)}
        {props.detailCsvItem && (
          <Box mt={2}>
            <FormControlLabel
              control={<Switch checked={showDetails} onChange={handleSwitchChange} />}
              label="明細を表示"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
