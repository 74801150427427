import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import DeleteDialog from '../templates/DeleteDialog';

export default function DeleteButton(props) {
  const [open, setOpen] = useState(false);
  const {
    procName,
    onDelete,
    disabled,
  } = props;

  const onPreDelete = () => {
    setOpen(true);
    onDelete();
  };

  return (
    <>
      <Tooltip title={`${procName}の削除`}>
        <Button color="secondary" onClick={() => setOpen(true)} disabled={disabled}>{`${procName}の削除`}</Button>
      </Tooltip>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        procName={procName}
        onDelete={onPreDelete}
      />
    </>
  );
}
