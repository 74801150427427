import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { orderDetailActions } from './orderDetailState';
import {
  getDatas,
  getDataById,
  saveData,
  deleteDatas,
} from '../../../services/base.service';

function* getOrderDetailDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ORDER.ORDER_DETAIL, payload);
    yield put(orderDetailActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ORDER.ORDER_DETAIL_ID, payload);
    yield put(orderDetailActions.getDataSuccess(result));
  } catch (_) { }
}

function* saveOrderDetailData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ORDER.ORDER_DETAIL, payload.newRow);
    yield put(orderDetailActions.saveDataSuccess(result));
  } catch (_) { }
}

function* deleteOrderDetailDatas({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.ORDER.ORDER_DETAIL, payload);
    yield put(orderDetailActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* orderDetailSaga() {
  yield takeLatest('orderDetail/getDatas', getOrderDetailDatas);
  yield takeLatest('orderDetail/getData', getData);
  yield takeLatest('orderDetail/saveData', saveOrderDetailData);
  yield takeLatest('orderDetail/deleteData', deleteOrderDetailDatas);
}

export default orderDetailSaga;
