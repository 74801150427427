import React from 'react';
import {
  TableCell,
} from '@mui/material';
import { Link, FireIconTitle } from '../../atoms/Base';

export default function PcTable(props) {
  const {
    row, urlTargets, headCells, wordColor, url, isDispFire,
  } = props;

  return (
    <>
      {headCells.map((headCell, idx) => {
        let cmp = (
          <TableCell style={{ color: wordColor, whiteSpace: 'nowrap' }} key={headCell.id}>
            {row[headCell.id] && row[headCell.id].length > 50
              ? `${row[headCell.id].substring(0, 50)}...`
              : row[headCell.id]}
          </TableCell>
        );
        if (idx === 0 && url) {
          cmp = (
            <TableCell key={headCell.id}>
              <Link to={url + row.id}>
                {row[headCell.id] && row[headCell.id].length > 50
                  ? `${row[headCell.id].substring(0, 50)}...`
                  : row[headCell.id]}
              </Link>
            </TableCell>
          );
        }
        if (urlTargets) {
          urlTargets.forEach((e) => {
            if (e.label === headCell.id) {
              cmp = (
                <TableCell key={headCell.id}>
                  <Link to={e.url + row[e.id]}>
                    {row[e.label] && row[e.label].length > 50
                      ? `${row[e.label].substring(0, 50)}...`
                      : row[e.label]}
                  </Link>
                </TableCell>
              );
            }
          });
        }
        if (headCell.numeric) {
          let value = row[headCell.id];
          if (value && typeof value === 'number' && !Number.isNaN(value)) {
            value = value.toLocaleString();
          }
          cmp = (<TableCell style={{ padding: '0px 10px', whiteSpace: 'nowrap', textAlign: 'right' }} key={headCell.id}>{value}</TableCell>);
        }
        return (
          cmp
        );
      })}
      {isDispFire && (
        <TableCell style={{ display: 'table-cell', alignItems: 'center' }}>
          <FireIconTitle checkDateTime={row.fireComplianceDt} />
        </TableCell>
      )}
    </>
  );
}
