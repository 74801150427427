import { createSlice } from '@reduxjs/toolkit';

export const estimateDetailSlice = createSlice({
  name: 'estimateDetail',
  initialState: {
    datas: [],
    data: {},
    outputHistories: [],
    outputHistoryDetails: [],
    isDeleteSuccess: false,
    isSaveSuccess: false,
    isBulkDataSuccess: false,
    isHistoryReload: false,
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getOutputHistories: () => {},
    getOutputHistoriesSuccess: (state, action) => {
      state.outputHistories = action.payload;
    },
    getOutputHistoryDetails: () => {},
    getOutputHistoryDetailsSuccess: (state, action) => {
      state.outputHistoryDetails = action.payload;
    },
    bulkData: (state) => {
      state.isBulkDataSuccess = false;
    },
    bulkDataSuccess: (state) => {
      state.isBulkDataSuccess = true;
    },
    resetFlgs: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
      state.isHistoryReload = false;
    },
    historyReload: (state) => {
      state.isHistoryReload = true;
    },
  },
});

export const estimateDetailActions = estimateDetailSlice.actions;

export default estimateDetailSlice.reducer;
