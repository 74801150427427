import { createSlice } from '@reduxjs/toolkit';

export const lpPlanControlSlice = createSlice({
  name: 'lpPlanControl',
  initialState: {
    datas: [],
  },
  reducers: {
    getControlDatas: () => {},
    getControlDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
  },
});

export const lpPlanControlActions = lpPlanControlSlice.actions;

export default lpPlanControlSlice.reducer;
