import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Button, Grid,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { PDFViewer } from '@react-pdf/renderer';
import {
  collection, addDoc, serverTimestamp,
} from 'firebase/firestore';
import db from '../../../firebase';
import OrderDetailInfo from './OrderDetailInfo';
import OrderDetailDialog from './OrderDetailDialog';
import PurchaseOrderFile from '../report/PurchaseOrderFile';
import { replace, upload } from '../../../utils/common.helper';
import detailUrl from '../../../constants/frontUrls';
import { REST_API } from '../../../constants/serverUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import { withParams, SuccessSnackbar, TabPanel } from '../../../components/atoms/Base';
import Tabs from '../../../components/templates/Tabs';
import DetailTable from '../common/detailTable/DetailTable';
import FullScreenMailer from '../../../components/templates/mailer/FullScreenMailer';
import { orderActions } from '../../../redux/opportunity/order/orderListState';
import { orderDetailActions } from '../../../redux/opportunity/order/orderDetailState';
import { estimateActions } from '../../../redux/opportunity/estimate/estimateState';
import PurchaseOrderDownloadButton from './PurchaseOrderDownloadButton';
import RequestConfirmDialog from './RequestConfirmDialog';
import RecordHeader from '../../../components/templates/RecordHeader';

const DEFAULT_MAILER_DATA = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.orderDetailCells = [
      { id: 'planName', label: '商品名', sortId: 'plan_name' },
      {
        id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
      },
      { id: 'quantity', label: '個数', sortId: 'quantity' },
      { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
      { id: 'burdenPrice', label: 'Cqree負担税込額', sortId: 'burden_price' },
      {
        id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
      },
      { id: 'commissionRate', label: '販売手数料', sortId: 'commission_rate' },
      { id: 'claimed', label: '請求対象', sortId: 'claimed' },
      { id: 'repaid', label: '返金対象', sortId: 'repaid' },
      { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
    ];

    const searchCondition = {
      order: 'asc',
      orderBy: 'display_order',
      orderId: id,
    };

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.orderInfo.isSaveSuccess) {
      props.dispatch(orderActions.resetFlgs());
      successMessage = '保存しました。';
      isSuccessOpen = true;
    }

    if (props.estimateInfo.isCreateOrderSuccess) {
      props.dispatch(estimateActions.resetFlgs());
      successMessage = '作成しました。';
      isSuccessOpen = true;
    }

    this.state = {
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
      isDetailDialogOpen: false,
      isSuccessOpen,
      successMessage,
      isMailerOpen: false,
      isRequestOpen: false,
      order: {
        id,
        shopId: null,
        vendorId: null,
        opportunityId: null,
        orderStatusCode: null,
        serviceTypeCode: 1,
      },
      datas: [],
      detailId: null,
      fileMailerData: DEFAULT_MAILER_DATA,
      requestMailerData: DEFAULT_MAILER_DATA,
      pdfData: {
        header: {},
        details: {},
      },
      searchCondition,
      loading: false,
    };
    this.detailHeader(1);

    if (id) {
      props.dispatch(orderDetailActions.getDatas(searchCondition));
      props.dispatch(orderActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderDetailInfo.datas !== this.props.orderDetailInfo.datas) {
      if (this.props.orderDetailInfo.datas) {
        this.setDatas(this.props.orderDetailInfo.datas);
      }
    }
    if (prevProps.orderInfo.data !== this.props.orderInfo.data) {
      if (this.props.orderInfo.data) {
        this.setData(this.props.orderInfo.data);
      }
    }
    if (prevProps.orderInfo.pdfDatas !== this.props.orderInfo.pdfDatas) {
      if (this.props.orderInfo.pdfDatas) {
        this.setPdfDatas(this.props.orderInfo.pdfDatas);
      }
    }
    if (prevProps.orderInfo.isSaveSuccess !== this.props.orderInfo.isSaveSuccess) {
      if (this.props.orderInfo.isSaveSuccess) {
        this.setSuccess(this.props.orderInfo.id);
      }
    }
    if (prevProps.orderInfo.isSendTextSuccess !== this.props.orderInfo.isSendTextSuccess) {
      if (this.props.orderInfo.isSendTextSuccess) {
        this.sendSuccess();
      }
    }
    if (prevProps.orderDetailInfo.isSaveSuccess !== this.props.orderDetailInfo.isSaveSuccess) {
      if (this.props.orderDetailInfo.isSaveSuccess) {
        this.saveDetailSuccess();
      }
    }
    if (prevProps.orderDetailInfo.isDeleteSuccess !== this.props.orderDetailInfo.isDeleteSuccess) {
      if (this.props.orderDetailInfo.isDeleteSuccess) {
        this.deleteSuccess();
      }
    }
    if (prevProps.commonInfo.isServerError !== this.props.commonInfo.isServerError) {
      if (this.props.commonInfo.isServerError) {
        this.resetLoading();
      }
    }
  }

  detailHeader = (newStoreType) => {
    const { storeType } = this.state;
    if (this.orderDetailCells && storeType === newStoreType) return;
    this.orderDetailCells = newStoreType === 1 ? [
      { id: 'planName', label: '商品名', sortId: 'plan_name' },
      {
        id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
      },
      {
        id: 'quantity', label: '個数', sortId: 'quantity', numeric: true,
      },
      { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
      { id: 'burdenPrice', label: 'Cqree負担税込額', sortId: 'burden_price' },
      {
        id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
      },
      { id: 'commissionRate', label: '販売手数料', sortId: 'commission_rate' },
      { id: 'claimed', label: '請求対象', sortId: 'claimed' },
      { id: 'repaid', label: '返金対象', sortId: 'repaid' },
      { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
    ] : [
      { id: 'planName', label: '商品名', sortId: 'plan_name' },
      {
        id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
      },
      { id: 'quantity', label: '個数', sortId: 'quantity' },
      { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
      { id: 'burdenPrice', label: 'Cqree負担税込額', sortId: 'burden_price' },
      {
        id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
      },
      {
        id: 'purchasingPrice', label: '仕入原価', sortId: 'purchasing_price', numeric: true,
      },
      { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
    ];
  };

  resetLoading = () => {
    const { order } = this.state;
    this.setState({
      loading: false,
      requestMailerData: DEFAULT_MAILER_DATA,
      isRequestOpen: false,
    });
    this.props.dispatch(orderActions.getData({ id: order.id }));
  };

  onDelete = (deleteId) => {
    this.props.dispatch(orderDetailActions.deleteData({ ids: [deleteId] }));
  };

  setSuccess = (id) => {
    const { order } = this.state;
    const searchId = id || order.id;
    if (order.id) {
      this.props.dispatch(orderActions.resetFlgs());
    }
    if (!order.id) {
      window.location.href = detailUrl.ORDER_DETAIL + searchId;
      return;
    }
    this.setState({ isSuccessOpen: true, successMessage: '保存しました。' });
    this.props.dispatch(orderActions.getData({ id: searchId }));
  };

  saveDetailSuccess = () => {
    const { order } = this.state;

    this.props.dispatch(orderActions.getData({ id: order.id }));
    this.props.dispatch(orderDetailActions.getDatas({ orderId: order.id }));

    this.setState({
      isSuccessOpen: true, successMessage: '保存しました。', isDetailDialogOpen: false, detailId: '',
    });
  };

  deleteSuccess = () => {
    const { order } = this.state;
    this.props.dispatch(orderActions.getData({ id: order.id }));
    this.props.dispatch(orderDetailActions.getDatas({ orderId: order.id }));
    this.setState({
      isSuccessOpen: true, successMessage: '削除しました。', isDetailDialogOpen: false, detailId: '',
    });
  };

  sendSuccess = () => {
    const { order, isRequestOpen } = this.state;
    if (isRequestOpen) this.addMessageToFirestore(order);
    this.props.dispatch(orderActions.getData({ id: order.id }));
    this.setState({
      isSuccessOpen: true, successMessage: '送信しました。', isMailerOpen: false, isRequestOpen: false, loading: false,
    });
  };

  onAddOrderDetail = () => {
    this.setState({ isDetailDialogOpen: true });
  };

  onEditDetail = (_, id) => {
    this.setState({ detailId: id, isDetailDialogOpen: true });
  };

  onSendOrder = () => {
    const { order } = this.state;
    this.props.dispatch(orderActions.getPdfDatas({ id: order.id }));
    this.setState({ isMailerOpen: true, loading: true });
  };

  setData = (data) => {
    const { fileMailerData } = this.state;
    const storeType = data.data.shopId ? 1 : 2;
    this.detailHeader(storeType);
    this.setState({
      order: data.data,
      requestMailerData: { ...data.mailTemplate, to: data.data.shopEmail },
      fileMailerData: {
        ...fileMailerData,
        to: data.data.shopEmail,
        cc: data.data.deliveryAgencyEmail,
      },
      storeType,
    });
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setPdfDatas = (pdfData) => {
    if (pdfData.header) {
      this.setState({ pdfData, loading: false });
    }
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(orderDetailActions.getDatas(searchCondition));
  };

  onOpenRequest = () => {
    const { requestMailerData, order } = this.state;
    const tempMailData = {
      ...order,
      ...requestMailerData,
      subject: replace(order, requestMailerData.subject),
      body: replace(order, requestMailerData.content),
    };
    this.setState({ requestMailerData: tempMailData, isRequestOpen: true, loading: false });
  };

  onSendMail = () => {
    this.setState({ loading: true });
    const { order, requestMailerData } = this.state;
    const sendMailerData = {
      ...requestMailerData,
      id: order.id,
      serviceTypeCode: order.serviceTypeCode,
    };
    this.props.dispatch(orderActions.sendText(sendMailerData));
  };

  addMessageToFirestore = async (order) => {
    try {
      const dt = new Date();
      const dateString = `${dt.getFullYear()}年${dt.getMonth() + 1}月${dt.getDate()}日 ${dt.getHours()}時${dt.getMinutes()}分`;
      await addDoc(collection(db, 'messagesToShop'), {
        read: false,
        orderNumber: order.orderNumber,
        orderId: order.id,
        shopId: order.shopId,
        accountId: order.accountId,
        date: serverTimestamp(),
        dateString,

      });
      this.setState({
        isRequestOpen: false,
      });
    } catch (_) {}
  };

  onSendFile = (data) => {
    this.setState({ loading: true });
    const { order, pdfData } = this.state;
    const sendMeilerData = {
      ...data,
      id: order.id,
    };
    upload(
      <PurchaseOrderFile data={pdfData} />,
      sendMeilerData,
      REST_API.OPPORTUNITY.ORDER.ORDER_SEND_PURCHASE_ORDER,
      pdfData.header.fileName,
    )
      .then((result) => {
        if (result == null) {
          this.setState({
            loading: false,
          });
          return;
        }
        this.sendSuccess();
      })
      .catch(() => {});
  };

  render() {
    const {
      isSuccessOpen,
      successMessage,
      isNotEdit,
      isNotDelete,
      datas,
      isDetailDialogOpen,
      isMailerOpen,
      isRequestOpen,
      detailId,
      order,
      pdfData,
      fileMailerData,
      requestMailerData,
      searchCondition,
      loading,
    } = this.state;

    return (
      <>
        <RecordHeader
          bottomSection={(
            <>
              {!isNotEdit && datas.length > 0 && order.serviceTypeCode === 1 && order.shopId && (
                <Button variant="contained" size="small" onClick={this.onOpenRequest}>店舗へ確認依頼</Button>
              )}
              {datas.length > 0 && order.shopId && (
                <PurchaseOrderDownloadButton id={order.id} />
              )}
              {datas.length > 0 && order.shopId && (
                <Button variant="contained" size="small" startIcon={<SendIcon />} onClick={this.onSendOrder}>発注書送付</Button>
              )}
            </>
          )}
        />
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={order.id ? 5 : 12}>
            <OrderDetailInfo
              id={order.id}
              data={order}
              isDisabled={isNotEdit}
            />
          </Grid>
          {order.id && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={0}
              tabs={['注文明細']}
            />
            <TabPanel value={0} index={0}>
              <DetailTable
                rows={datas}
                headCells={this.orderDetailCells}
                onAddOpen={this.onAddOrderDetail}
                procName="注文明細"
                onDelete={this.onDelete}
                handleRequestSort={this.handleRequestSort}
                order={searchCondition.order}
                orderBy={searchCondition.orderBy}
                isNotDelete={isNotDelete}
                isNotEdit={isNotEdit}
                onEdit={this.onEditDetail}
              />

            </TabPanel>
          </Grid>
          )}
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <OrderDetailDialog
          isOpen={isDetailDialogOpen}
          onClose={() => this.setState({ isDetailDialogOpen: false, detailId: '' })}
          id={detailId}
          orderId={order.id}
          shopId={order.shopId}
        />
        <FullScreenMailer
          isOpen={isMailerOpen}
          loading={loading}
          onClose={() => this.setState({
            isMailerOpen: false,
            detailId: '',
          })}
          onClickAction={this.onSendFile}
          data={fileMailerData}
          title="発注書送付"
          objectData={order}
          leftArea={(
            <Box style={{ overflowY: 'auto' }}>
              <PDFViewer style={{ width: '100%', height: '85vh' }} showToolbar={false}>
                <PurchaseOrderFile data={pdfData} />
              </PDFViewer>
            </Box>
          )}
        />
        <RequestConfirmDialog
          isOpen={isRequestOpen}
          onClose={() => this.setState({
            isRequestOpen: false,
          })}
          onClickSave={this.onSendMail}
          data={requestMailerData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderInfo: state.orderStore,
  orderDetailInfo: state.orderDetailStore,
  loginUser: state.loginStore,
  estimateInfo: state.estimateStore,
  commonInfo: state.commonStore,
});

export default withParams(connect(mapStateToProps)(OrderDetail));
