import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { estimateActions } from '../../../../redux/opportunity/estimate/estimateState';
import EstimateFile from '../../report/EstimateFile';
import EstimateAffiliationFile from '../../report/EstimateAffiliationFile';
import EstimateEnglishFile from '../../report/EstimateEnglishFile';
import { PdfDownloadButton } from '../../../../components/atoms/Base';
import { estimateDetailActions } from '../../../../redux/opportunity/estimate/estimateDetailState';

export default function EstimateFileDownloadButton(props) {
  const {
    id, englishReport, affiliationId,
  } = props;
  const dispatch = useDispatch();
  const dbPdfData = useSelector((state) => state.estimateStore.pdfData);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPdfData] = useState({ header: {}, details: [] });
  const isGetPdfData = useSelector((state) => state.estimateStore.isGetPdfDataSuccess);
  const [isReadyDownload, setReadyDownload] = useState(false);
  const [isClickedOnDownload, setClickedOnDownload] = useState(false);

  useEffect(() => {
    if (isGetPdfData && isClickedOnDownload) {
      setPdfData(dbPdfData);
      setLoading(false);
      setReadyDownload(true);
      dispatch(estimateActions.resetFlgs());
      setClickedOnDownload(false);
      dispatch(estimateDetailActions.historyReload());
    }
  }, [isGetPdfData]);

  const onReceiptDownload = () => {
    setClickedOnDownload(true);
    dispatch(estimateActions.getPdfData({ id }));
    setLoading(true);
    setReadyDownload(false);
  };

  return (
    <PdfDownloadButton
      title="見積書ダウンロード"
      file={
        (englishReport && <EstimateEnglishFile data={pdfData} />)
        || (affiliationId && <EstimateAffiliationFile data={pdfData} />)
        || <EstimateFile data={pdfData} />
      }
      pdfData={pdfData}
      isReadyDownload={isReadyDownload}
      onClick={onReceiptDownload}
      loading={loading}
      onClose={() => { setReadyDownload(false); }}
    />
  );
}
