import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { illustrationActions } from './illustrationState';
import {
  getDatas, deleteDatas, getDataById, uploadFiles,
} from '../../../services/base.service';

function* getIllustrations({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.ILLUSTRATION.ILLUSTRATION, payload);
    yield put(illustrationActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.ILLUSTRATION.ILLUSTRATION_CSV, payload);
    yield put(illustrationActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.ILLUSTRATION.ILLUSTRATION_ID, payload);
    yield put(illustrationActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFiles') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
    formData.append('id', new Blob([JSON.stringify(payload.id)], { type: 'application/json' }));

    for (let i = 0; i < payload.imgForm.images.length; i += 1) {
      const tmpData = payload.imgForm.images[i];
      if (tmpData.isNew) {
        const files = tmpData[0];
        formData.append(`imageFiles_${tmpData.id}`, files);
      }
    }

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.ILLUSTRATION.ILLUSTRATION, formData);
    yield put(illustrationActions.saveDataSuccess(result));
  } catch (_) { }
}
function* deleteIllustrations({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.PAGE_SETTING.ILLUSTRATION.ILLUSTRATION, payload);
    yield put(illustrationActions.deleteDatasSuccess(result));
  } catch (_) { }
}

function* illustrationSaga() {
  yield takeLatest('illustration/getDatas', getIllustrations);
  yield takeLatest('illustration/getCsvDatas', getCsvDatas);
  yield takeLatest('illustration/getData', getData);
  yield takeLatest('illustration/saveData', save);
  yield takeLatest('illustration/deleteDatas', deleteIllustrations);
}

export default illustrationSaga;
