import { createSlice } from '@reduxjs/toolkit';

export const contactDetailSlice = createSlice({
  name: 'contactDetail',
  initialState: {
    data: {},
    isSaveSuccess: false,
    isCreateOpportunitySuccess: false,
    saveId: null,
    saveOpportunityId: null,
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    createOpportunity: (state) => {
      state.isCreateOpportunitySuccess = false;
      state.saveOpportunityId = null;
    },
    createOpportunitySuccess: (state, action) => {
      state.isCreateOpportunitySuccess = true;
      state.saveOpportunityId = action.payload;
    },
    resetFlg: (state) => {
      state.isCreateOpportunitySuccess = false;
      state.isSaveSuccess = false;
      state.saveOpportunityId = null;
    },
  },
});

export const contactDetailActions = contactDetailSlice.actions;

export default contactDetailSlice.reducer;
