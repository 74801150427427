import React from 'react';
import {
  TextField, DialogActions, Button, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DataGrid from '../../../../components/templates/DataGrid';
import AreaInput from '../../../../components/templates/AreaInput';
import Validation from '../validation';
import NumberInput from '../../../../components/atoms/NumberInput';
import RadioButtons from '../../../../components/atoms/RadioButtons';
import {
  FormTitle, DialogContent, FormErrorText, TextArea,
} from '../../../../components/atoms/Base';
import SystemInfo from '../../../../components/templates/SystemInfo';
import Accordion from '../../../../components/templates/Accordion';

const DEFAULT_FORM = {
  id: '',
  serviceTypeCode: 1,
  name: '',
  minPrice: '',
  isDelete: false,
};

const DEFAULT_ERROR = {
  serviceTypeCode: '',
  name: '',
  minPrice: '',
  area: '',
};

export default function DeliveryDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    shopId,
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [areaForm, setAreaForm] = React.useState([]);
  const [areaData, setAreaData] = React.useState([{ prefectureGroups: [] }]);
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR);
  const delivery = useSelector((state) => state.shopDetailStore.delivery);

  React.useEffect(() => {
    if (isOpen) {
      setForm(DEFAULT_FORM);
      setErrorMessages(DEFAULT_ERROR);
    }
    if (isOpen && openedMenuRowId) {
      dispatch(shopDetailActions.getDeliveryDataById({ id: openedMenuRowId }));
    }
    if (isOpen && !openedMenuRowId) {
      setForm(DEFAULT_FORM);
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (delivery) {
      setForm(delivery.region);
      setAreaData(delivery.areas);
      const allCities = delivery.areas.flatMap(
        (area) => area.prefectureGroups.flatMap((prefectureGroup) => prefectureGroup.cities),
      );
      setAreaForm(allCities);
    }
  }, [delivery]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: Validation.deliveryFormValidate(name, value),
    });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const msg = Validation.deliveryFormValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (areaForm.length < 1) {
      isError = true;
      tempMessage = {
        ...tempMessage,
        area: '１つ以上エリアを選択して下さい',
      };
    }

    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) return;
    dispatch(shopDetailActions.saveDeliveryData(
      {
        region: {
          ...form,
          shopId,
        },
        areas: areaForm,
      },
    ));
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      title={`配達の${form.id ? '編集' : '追加'}`}
    >
      <DialogContent>
        <DataGrid title="サービスタイプ" isEdit isRequired>
          <RadioButtons
            name="serviceTypeCode"
            target="codes"
            parameter={{ codeCategory: 'service_type' }}
            value={form.serviceTypeCode}
            error={!!errorMessages.serviceTypeCode}
            onChange={onChange}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="条件名" isEdit isRequired>
          <TextField value={form.name} fullWidth name="name" onChange={onChange} error={!!errorMessages.name} inputProps={{ maxLength: 50 }} />
          <FormErrorText>{errorMessages.name}</FormErrorText>
        </DataGrid>
        <DataGrid title="配達可能最低金額" isEdit isRequired>
          <NumberInput value={form.minPrice} fullWidth name="minPrice" onChange={onChange} error={!!errorMessages.minPrice} />
          <FormErrorText>{errorMessages.minPrice}</FormErrorText>
        </DataGrid>
        <DataGrid title="備考" isEdit>
          <TextArea value={form.remarks} fullWidth name="remarks" onChange={onChange} />
        </DataGrid>
        <FormTitle title="エリア" isRequired isNonFlex />
        <FormErrorText>{errorMessages.area}</FormErrorText>
        <Accordion>
          <AreaInput
            datas={areaData}
            isEdit
            onChange={(e) => setAreaForm(e)}
          />
        </Accordion>
        {form.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
