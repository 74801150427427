import axios from 'axios';
import UrlHelper from './url.helper';
import { commonActions } from '../redux/common/common/commonState';
import rootStore from '../redux/rootStore';

export default class HttpConnection {
  constructor(isNotLoading) {
    this.isNotLoading = isNotLoading;
    if (!isNotLoading) {
      rootStore.dispatch(commonActions.executeLoading(true));
    }
    rootStore.dispatch(commonActions.resetServerError());
    this.header = HttpConnection.headerOptions();
    this.uploadHeader = HttpConnection.uploadHeaderOptions();
    axios.defaults.withCredentials = true;
  }

  static headerOptions() {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  static uploadHeaderOptions() {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  handleSuccessResponse(response) {
    if (!this.isNotLoading) {
      rootStore.dispatch(commonActions.executeLoading(false));
    }
    if (response.status === 200) {
      return response.data;
    }
    rootStore.dispatch(commonActions.serverError({
      errorMessage: response.errors,
    }));
    throw new Error();
  }

  static handleErrorResponse(error) {
    rootStore.dispatch(commonActions.executeLoading(false));
    const { response } = error;
    if (response.status && response.status === 404) {
      window.location.href = '/not-found';
      return;
    }
    if (response.status && response.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/login';
      return;
    }
    if (response.status && response.status !== 400 && response.status !== 401) {
      window.location.href = '/error';
      return;
    }
    rootStore.dispatch(commonActions.serverError({
      errorMessage: error.response.data.message,
    }));
    throw error;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async get(url, parameter) {
    const getUrl = encodeURI(UrlHelper.convertQueryUrl(url, parameter));
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async getById(url, parameter) {
    const getUrl = UrlHelper.convertQueryUrl(UrlHelper.convertPathUrl(url, parameter), parameter, ['id']);
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async post(url, parameter) {
    const requestOptions = {
      method: 'POST',
      headers: this.header,
      data: parameter,
      url,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Put
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async put(url, queryParameter, parameter) {
    const putUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'PUT',
      headers: this.header,
      data: parameter,
      url: putUrl,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Deletes
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async deletes(url, parameter) {
    const requestOptions = {
      method: 'DELETE',
      headers: this.header,
      url,
      data: parameter,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post(create)
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async upload(url, parameter) {
    const requestOptions = {
      method: 'POST',
      headers: this.uploadHeader,
      data: parameter,
      url,
    };
    const result = await axios(requestOptions)
      .then((response) => this.handleSuccessResponse(response))
      .catch((error) => {
        HttpConnection.handleErrorResponse(error);
      });
    return result;
  }
}
