import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { mailTemplateActions } from './mailTemplateState';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getFolders({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.FOLDERS, payload);

    yield put(mailTemplateActions.getFoldersSuccess(result));
  } catch (_) { }
}

function* getMailDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.MAIL_TEMPLATE, payload);

    yield put(mailTemplateActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.MAIL_TEMPLATE_CSV, payload);
    yield put(mailTemplateActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.MAIL_TEMPLATE, payload);
    yield put(mailTemplateActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData(param) {
  try {
    const data = param.payload;
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.MAIL_TEMPLATE_ID, data);
    yield put(mailTemplateActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SYSTEM_SETTING.MAIL_TEMPLATE.MAIL_TEMPLATE, payload);
    yield put(mailTemplateActions.saveDataSuccess(result));
  } catch (_) { }
}

function* mailTemplateSaga() {
  yield takeLatest('mailTemplate/getFolders', getFolders);
  yield takeLatest('mailTemplate/getDatas', getMailDatas);
  yield takeLatest('mailTemplate/getCsvDatas', getCsvDatas);
  yield takeLatest('mailTemplate/deleteData', deleteData);
  yield takeLatest('mailTemplate/getData', getData);
  yield takeLatest('mailTemplate/saveData', save);
}
export default mailTemplateSaga;
