import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { estimateActions } from './estimateState';
import {
  getDatas,
  deleteDatas,
  getDataById,
  saveData,
  updateData,
} from '../../../services/base.service';

function* getDataList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_CSV, payload);
    yield put(estimateActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.deleteDataSuccess());
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_ID, payload);
    yield put(estimateActions.getDataSuccess(result));
  } catch (_) { }
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.REPORT.ESTIMATE, payload);
    yield put(estimateActions.getPdfDataSuccess(result));
  } catch (_) { }
}

function* saveEstimateData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.saveDataSuccess(result));
  } catch (_) { }
}

function* createOrder({ payload }) {
  try {
    const result = yield call(updateData, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_ORDER, payload);
    yield put(estimateActions.createOrderSuccess(result));
  } catch (_) { }
}

function* estimateSaga() {
  yield takeLatest('estimate/getDatas', getDataList);
  yield takeLatest('estimate/getCsvDatas', getCsvDatas);
  yield takeLatest('estimate/deleteData', deleteData);
  yield takeLatest('estimate/getData', getData);
  yield takeLatest('estimate/getPdfData', getPdfData);
  yield takeLatest('estimate/saveData', saveEstimateData);
  yield takeLatest('estimate/createOrder', createOrder);
}

export default estimateSaga;
