import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { deliveryAgencyUserActions } from './deliveryAgencyUserState';
import {
  getDatas, getDataById, deleteDatas, saveData, updateData,
} from '../../../services/base.service';

function* getDeliveryAgencyUsers({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.DELIVERY_AGENCY_USER.DELIVERY_AGENCY_USER, payload);
    yield put(deliveryAgencyUserActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.DELIVERY_AGENCY_USER.DELIVERY_AGENCY_USER_ID, payload);
    yield put(deliveryAgencyUserActions.getDataSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.SHOP.DELIVERY_AGENCY_USER.DELIVERY_AGENCY_USER, payload);
    yield put(deliveryAgencyUserActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* insert({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SHOP.DELIVERY_AGENCY_USER.DELIVERY_AGENCY_USER, payload);
    yield put(deliveryAgencyUserActions.insertSuccess(result));
  } catch (_) { }
}

function* update({ payload }) {
  try {
    const result = yield call(updateData, REST_API.SHOP.DELIVERY_AGENCY_USER.DELIVERY_AGENCY_USER_ID, payload);
    yield put(deliveryAgencyUserActions.updateSuccess(result));
  } catch (_) { }
}

function* deliveryAgencyUserSaga() {
  yield takeLatest('deliveryAgencyUser/getDatas', getDeliveryAgencyUsers);
  yield takeLatest('deliveryAgencyUser/getData', getData);
  yield takeLatest('deliveryAgencyUser/deleteData', deleteData);
  yield takeLatest('deliveryAgencyUser/insert', insert);
  yield takeLatest('deliveryAgencyUser/update', update);
}

export default deliveryAgencyUserSaga;
