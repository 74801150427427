import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { mailTemplateActions } from '../../../redux/systemSetting/mailTemplate/mailTemplateState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SearchMultipleSelectBox, SuccessSnackbar } from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  folders: [],
  emailUsedTypeCodes: [],
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'emailUsedTypeCode', label: '使用場所コード' },
  { key: 'emailUsedTypeName', label: '使用場所名' },
  { key: 'folder', label: 'フォルダ' },
  { key: 'title', label: 'タイトル' },
  { key: 'defaultCc', label: 'デフォルトCC' },
  { key: 'subject', label: '件名' },
  { key: 'content', label: '本文' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class MailTemplateList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'folder', label: 'フォルダ', sortId: 'folder' },
      { id: 'emailUsedType', label: '使用場所', sortId: 'email_used_type' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.mailTemplateInfo.searchCondition) {
      searchCondition = props.mailTemplateInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      folders: [],
      download: {
        headerItems: CSV_HEADER,
        done: false,
        fileName: 'メールテンプレート',
        datas: [],
      },
    };

    this.props.dispatch(mailTemplateActions.getDatas(searchCondition));
    this.props.dispatch(mailTemplateActions.getFolders());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mailTemplateInfo.datas !== this.props.mailTemplateInfo.datas) {
      if (this.props.mailTemplateInfo.datas) {
        this.setDatas(this.props.mailTemplateInfo.datas);
      }
    }
    if (prevProps.mailTemplateInfo.folders !== this.props.mailTemplateInfo.folders) {
      if (this.props.mailTemplateInfo.folders) {
        this.setFolders(this.props.mailTemplateInfo.folders);
      }
    }
    if (prevProps.mailTemplateInfo.isDeleteSuccess
      !== this.props.mailTemplateInfo.isDeleteSuccess) {
      if (this.props.mailTemplateInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.mailTemplateInfo.csvDatas !== this.props.mailTemplateInfo.csvDatas) {
      if (this.props.mailTemplateInfo.csvDatas) {
        this.setCsvDatas(this.props.mailTemplateInfo.csvDatas);
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  search = (searchCondition) => {
    this.props.dispatch(mailTemplateActions.setSearchCondition(searchCondition));
    this.props.dispatch(mailTemplateActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setFolders = (folders) => {
    this.setState({ folders });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(mailTemplateActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(mailTemplateActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      folders,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="mail-template"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="フォルダ"
          simpleAreaOne={(
            <SearchMultipleSelectBox
              name="folders"
              options={folders}
              values={searchCondition.folders}
              onChange={this.onChange}
            />
          )}
          simpleAreaTwoText="使用場所"
          simpleAreaTwo={(
            <DataMultiSelectList
              name="emailUsedTypeCodes"
              target="codes"
              parameter={{ codeCategory: 'email_used_type' }}
              values={searchCondition.emailUsedTypeCodes}
              onChange={this.onChange}
              fullWidth
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid container spacing={1} my={1}>
              <Grid item xs={12} md={12}>
                <FormTitle title="フリーワード" attention="件名、本文" />
                <TextField value={searchCondition.keyword} autoComplete="off" fullWidth name="keyword" onChange={this.onChange} />
              </Grid>
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.MAIL_TEMPLATE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="メールテンプレート"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mailTemplateInfo: state.mailTemplateStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(MailTemplateList);
