import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddCancelDialog from './AddCancelDialog';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

const headCells = [
  { id: 'chargeDay', label: 'チャージ日', sortId: 'charge_day' },
  { id: 'chargeTime', label: 'チャージ時間', sortId: 'charge_time' },
  { id: 'chargeRate', label: 'キャンセル率', sortId: 'charge_rate' },
  { id: 'serviceTypeName', label: 'サービスタイプ', sortId: 'service_type_name' },
];

export default function CancelRateList(props) {
  const {
    id, isNotDelete, isNotEdit,
  } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const cancelDatas = useSelector((state) => state.shopDetailStore.cancelDatas);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchCondition, setSearchCondition] = React.useState({
    shopId: id,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const isSaveSuccess = useSelector(
    (state) => state.shopDetailStore.isCancelSaveSuccess,
  );
  const isDeleteSuccess = useSelector(
    (state) => state.shopDetailStore.isCancelDeleteSuccess,
  );
  const [isOpen, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  React.useEffect(() => {
    if (id && cancelDatas) {
      setData(cancelDatas);
    }
  }, [cancelDatas]);

  const search = (sc) => {
    dispatch(shopDetailActions.getCancelDatas(sc));
  };

  React.useEffect(() => {
    if (id) {
      const tempCondition = {
        ...searchCondition,
        shopId: id,
      };
      setSearchCondition(tempCondition);
      search(tempCondition);
    }
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setIsSuccessOpen(true);
      dispatch(shopDetailActions.resetFlgs());
      search(searchCondition);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isSaveSuccess) {
      setSuccessMessage('保存しました');
      setIsSuccessOpen(true);
      dispatch(shopDetailActions.resetFlgs());
      search(searchCondition);
    }
  }, [isSaveSuccess]);

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const onEdit = (_, selectId) => {
    setOpen(true);
    setRowId(selectId);
  };

  const onAdd = () => {
    setOpen(true);
  };

  const onDelete = (deleteId) => {
    dispatch(shopDetailActions.deleteCancelData({ ids: [deleteId] }));
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <>
      <DataTableWithMenu
        rows={data.content || []}
        procName="キャンセル規定"
        headCells={headCells}
        totalElements={data.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        onDelete={onDelete}
        onEdit={onEdit}
        onAddOpen={onAdd}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
      <AddCancelDialog
        isOpen={isOpen}
        onClose={() => { setOpen(false); setRowId(''); }}
        id={rowId}
        shopId={id}
      />
    </>
  );
}
