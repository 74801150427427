import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import OptionDialog from './OptionDialog';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

const HEAD_CELLS = [
  { id: 'optionTypeName', label: 'オプションタイプ名', sortId: 'option_type_name' },
  { id: 'name', label: 'オプション名', sortId: 'name' },
  { id: 'introduction', label: '説明', sortId: 'introduction' },
  {
    id: 'price', label: '税込金額', sortId: 'price', numeric: true,
  },
  { id: 'minOptionAssignType', label: 'MINオプション指定値', sortId: 'min_option_assign_type_code' },
  { id: 'maxOptionAssignType', label: 'MAXオプション指定値', sortId: 'max_option_assign_type_code' },
  { id: 'provider', label: '提供者', sortId: 'is_cqree_provided' },
  { id: 'orderDt', label: '提供期間', sortId: 'order_start_dt' },
  { id: 'published', label: '公開状況', sortId: 'is_published' },
];

const DEFAULT_SEARCH_CONDITION = {
  records: 50,
  page: 0,
  order: 'asc',
  orderBy: '',
};

export default function OptionDataTable(props) {
  const { shopId, isNotEdit, isNotDelete } = props;
  const dispatch = useDispatch();
  const optionDatas = useSelector((state) => state.shopDetailStore.options);
  const isSaveSuccess = useSelector((state) => state.shopDetailStore.isOptionSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.shopDetailStore.isOptionDeleteSuccess);
  const [options, setOptions] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState(DEFAULT_SEARCH_CONDITION);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [optionId, setOptionId] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const search = () => {
    dispatch(shopDetailActions.getOptionData(searchCondition));
  };

  React.useEffect(() => {
    if (shopId) {
      setSearchCondition({ ...DEFAULT_SEARCH_CONDITION, shopId });
    }
  }, [shopId]);

  React.useEffect(() => {
    if (searchCondition.shopId) {
      search();
    }
  }, [searchCondition]);

  React.useEffect(() => {
    if (optionDatas) {
      setOptions(optionDatas);
    }
  }, [optionDatas]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(shopDetailActions.resetFlgs());
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      search();
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessOpen(true);
      setSuccessMessage('削除しました');
      dispatch(shopDetailActions.resetFlgs());
      search();
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    setSearchCondition({
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    });
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setOptionId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setOptionId(openedMenuRowId);
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(shopDetailActions.deleteOption({ ids: [openedMenuRowId] }));
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={options.content || []}
        procName="オプション"
        headCells={HEAD_CELLS}
        totalElements={options.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <OptionDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        openedMenuRowId={optionId}
        shopId={shopId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
