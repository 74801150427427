import React from 'react';
import {
  DialogActions, Box, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';
import { DialogContent } from '../../../components/atoms/Base';
import detailUrl from '../../../constants/frontUrls';
import { contactDetailActions } from '../../../redux/opportunity/contact/contactDetailState';
import { opportunityDetailActions } from '../../../redux/opportunity/opportunity/opportunityDetailState';

export default function CreateOpportunityDialog(props) {
  const { isOpen, onClose, form } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreateOpportunitySuccess = useSelector(
    (state) => state.contactDetailStore.isCreateOpportunitySuccess,
  );
  const saveOpportunityId = useSelector(
    (state) => state.contactDetailStore.saveOpportunityId,
  );

  React.useEffect(() => {
    if (isCreateOpportunitySuccess && saveOpportunityId) {
      dispatch(contactDetailActions.resetFlg());
      dispatch(opportunityDetailActions.setCreateSuccess());
      navigate(detailUrl.OPPORTUNITY_DETAIL + saveOpportunityId);
    }
  }, [isCreateOpportunitySuccess]);

  const onCreateOpportunity = () => {
    const opportunityForm = form;
    dispatch(contactDetailActions.createOpportunity(opportunityForm));
  };

  return (
    <Dialog
      title="案件作成"
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Box>この問合せから案件作成します。よろしいですか？</Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>キャンセル</Button>
        <Button onClick={onCreateOpportunity} color="primary">
          作成
        </Button>
      </DialogActions>

    </Dialog>
  );
}
