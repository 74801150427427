import React from 'react';
import { Box, Grid } from '@mui/material';
import { FormTitle } from '../atoms/Base';

export default function DataGrid(props) {
  const {
    title, isRequired, isEdit, children, value, isNonFlex,
  } = props;

  const formatNumber = (number) => {
    if (typeof number === 'number') {
      return number.toLocaleString();
    }
    return number;
  };

  return (
    <Grid
      container
      paddingBottom="0.25rem"
      borderBottom={isEdit ? '' : '1px solid #d4d4d4'}
      spacing={1}
      mt={1}
      style={{
        wordBreak: 'break-all', width: '100%', marginLeft: 0,
      }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex', alignItems: 'flex-start', paddingRight: 1,
        }}
      >
        <FormTitle title={title} isRequired={isEdit && isRequired} isNonFlex={isNonFlex} />
      </Grid>
      <Grid item xs={12} md={8} sx={{ paddingRight: 1 }}>
        <Box display={isEdit ? '' : 'none'}>{children}</Box>
        <Box display={isEdit ? 'none' : ''} style={{ whiteSpace: 'pre-line' }}>
          {value && typeof value === 'string' ? (
            value.split('\n').map((t, i) => (
              <Box key={`${t}-${i + 1}`}>
                {t === '' ? <br /> : t}
              </Box>
            ))
          ) : (
            <Box>{formatNumber(value)}</Box>
          )}
        </Box>

      </Grid>
    </Grid>
  );
}
