import React from 'react';
import { connect } from 'react-redux';
import { urls } from '../../constants/frontUrls';
import { loginActions } from '../../redux/login/loginState';

class Auth extends React.Component {
  static reset() {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  }

  constructor(props) {
    super(props);
    this.props.dispatch(loginActions.executeGetLoginInfo());
    this.exclusionList = [
      urls.COMMON.HOME.url, urls.COMMON.SYSTEM_ERROR.url,
      urls.PROFILE.PASSWORD.url,
    ];
    this.check();
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginInfo !== prevProps.loginInfo) {
      if (this.props.loginInfo.isLoginInfoError) {
        Auth.reset();
      }
    }
  }

  check() {
    const url = window.location.pathname;
    const isExclusion = this.exclusionList.includes(url);
    if (isExclusion) {
      return;
    }
    const secondUrl = window.location.pathname.split('/')[1];
    const { userInfo } = this.props.loginInfo;
    const roleScreenList = userInfo ? userInfo.screenList : [];
    const result = roleScreenList.some((c) => c.url.indexOf(secondUrl) >= 0);
    if (!result || roleScreenList.length === 0) {
      window.location.href = '/';
    }
  }

  render() {
    this.check();
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
});
export default connect(mapStateToProps)(Auth);
