import React from 'react';
import {
  TextField,
} from '@mui/material';
import DataGrid from '../templates/DataGrid';
import DataSelectList from './dataList/DataSelectList';
import { FormErrorText } from './Base';
import RadioButtons from './RadioButtons';

export default function ProductInputType(props) {
  const {
    errorMessages,
    onChange,
    shopId,
    row,
    onChangeProductInputType,
    storeType,
  } = props;

  return (
    <>
      {storeType === 1 && (
      <DataGrid title="商品タイプ" isRequired isEdit>
        <RadioButtons
          error={errorMessages.productInputTypeCode}
          value={row.productInputTypeCode}
          onChange={onChangeProductInputType}
          name="productInputTypeCode"
          target="codes"
          parameter={{ codeCategory: 'product_input_type' }}
          defaultValue={1}
        />
      </DataGrid>
      )}
      {storeType === 1 && row.productInputTypeCode === 1 && (
      <DataGrid title="プラン" isEdit isRequired>
        <DataSelectList
          error={!!errorMessages.planId}
          name="planId"
          target="plans"
          value={row.planId}
          onChange={onChange}
          parameter={shopId ? { shopId } : null}
          disableClearable
        />
        <FormErrorText>{errorMessages.planId}</FormErrorText>
      </DataGrid>
      )}
      {row.productInputTypeCode === 2 && (
      <DataGrid title="商品自由入力" isEdit isRequired>
        <TextField error={!!errorMessages.freeProductName} inputProps={{ maxLength: 255 }} name="freeProductName" value={row.freeProductName || ''} onChange={onChange} fullWidth />
        <FormErrorText>{errorMessages.freeProductName}</FormErrorText>
      </DataGrid>
      )}
      {storeType === 1 && row.productInputTypeCode === 3 && (
      <DataGrid title="オプション" isEdit isRequired>
        <DataSelectList
          error={!!errorMessages.shopPlanOptionId}
          name="shopPlanOptionId"
          target="options"
          value={row.shopPlanOptionId}
          onChange={onChange}
          parameter={shopId ? { shopId } : null}
          disableClearable
        />
        <FormErrorText>{errorMessages.shopPlanOptionId}</FormErrorText>
      </DataGrid>
      )}
    </>
  );
}
