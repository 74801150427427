import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Box,
  ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import Calendar from './Calendar';
import { opportunityListActions } from '../../../../redux/opportunity/opportunity/opportunityListState';
import {
  SuccessSnackbar, FormTitle, DateTimePicker, DatePicker, FormSwitch,
} from '../../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../../constants/frontUrls';
import SearchBox from '../../../../components/templates/searchBox/SearchBox';
import DataMultiSelectList from '../../../../components/atoms/dataList/DataMultiSelectList';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../../utils/authCheck.helper';
import ConfirmDialog from '../../../../components/templates/ConfirmDialog';
import SuccessCreateClaim from '../detail/attached/SuccessCreateClaim';

export const DEFAULT_SEARCH_CONDITION = {
  customerName: '',
  companyName: '',
  customerEmail: '',
  customerPhone: '',
  opportunityNumber: '',
  heldDate: '',
  salesUsers: [],
  statuses: [],
  complianceDtStart: '',
  complianceDtEnd: '',
  notPointGranted: false,
  unassigned: false,
  keyword: '',
  page: 0,
  records: 50,
  order: '',
  orderBy: '',
  targetMonth: format(new Date(), 'yyyy/MM/dd'),
};

const CSV_HEADER = [
  { label: 'ID', key: 'id' },
  { label: '案件番号', key: 'opportunityNumber' },
  { label: '営業担当ID', key: 'salesUserId' },
  { label: '営業担当名', key: 'salesUserName' },
  { label: '受付日', key: 'receptionDate' },
  { label: '対応期日時', key: 'complianceDt' },
  { label: '案件概要', key: 'overview' },
  { label: '参加人数', key: 'joinNumber' },
  { label: '総合計金額(税込)', key: 'totalPrice' },
  { label: '開催日', key: 'heldDate' },
  { label: '開催時間', key: 'heldCharTime' },
  { label: '準備開始可能時間', key: 'cateringPreparationrTime' },
  { label: 'イベント終宴時間', key: 'cateringEventEndTime' },
  { label: 'イベント撤収完了時間', key: 'cateringCleanUpTime' },
  { label: '受注経路コード', key: 'orderRouteCode' },
  { label: '受注経路名', key: 'orderRouteName' },
  { label: '支払方法ID', key: 'paymentMethodId' },
  { label: '支払方法名', key: 'paymentMethodName' },
  { label: '領収書', key: 'receiptRequired' },
  { label: '納品先都道府県ID', key: 'prefectureId' },
  { label: '納品先都道府県名', key: 'prefectureName' },
  { label: '納品先市区町村ID', key: 'cityId' },
  { label: '納品先市区町村名', key: 'cityName' },
  { label: '納品先その他住所', key: 'otherAddress' },
  { label: '搬入経路テキスト', key: 'deliveryRouteText' },
  { label: '領収書宛名', key: 'receiptAddress' },
  { label: '但書注意', key: 'proviso' },
  { label: 'お客様に確定メール送付済', key: 'customerSend' },
  { label: '結膳手配有無', key: 'yuizenArranged' },
  { label: '提携企業ID', key: 'affiliationId' },
  { label: '提携企業名', key: 'affiliationName' },
  { label: '提携企業確認済', key: 'affiliationChecked' },
  { label: '所属先会社', key: 'companyName' },
  { label: '支店営業所', key: 'branchName' },
  { label: 'お客様名', key: 'customerName' },
  { label: '電話番号', key: 'customerPhone' },
  { label: 'メール', key: 'customerEmail' },
  { label: '会員ID', key: 'customerId' },
  { label: '会員確認済', key: 'customerChecked' },
  { label: 'PO番号', key: 'poNumber' },
  { label: '注文状態詳細', key: 'orderRemarks' },
  { label: '社内用備考', key: 'insideRemarks' },
  { label: '店舗向け備考', key: 'shopRemarks' },
  { label: '商品内容備考', key: 'productRemarks' },
  { label: 'ユーザー向け備考', key: 'customerRemarks' },
  { label: '顧客リクエスト', key: 'customerRequest' },
  { label: 'ステータスコード', key: 'opportunityStatusCode' },
  { label: 'ステータス名', key: 'opportunityStatusName' },
  { label: 'ポイント付与済', key: 'pointGranted' },
  { label: '付与ポイント数', key: 'grantedPoint' },
  { label: '口コミ受付', key: 'reviewTargeted' },
  { label: '店舗へ実例依頼対象', key: 'illustrationTargeted' },
  { label: '作成日時', key: 'createdAt' },
  { label: '作成者', key: 'createdByName' },
  { label: '更新日時', key: 'updatedAt' },
  { label: '更新者', key: 'updatedByName' },

];

class OpportunityList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'contactDate', label: '問合せ日時', sortId: 'contact_date' },
      { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
      { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_name' },
      { id: 'heldDate', label: '開催日', sortId: 'held_date' },
      { id: 'companyName', label: '会社名', sortId: 'company_name' },
      { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
      { id: 'customerEmail', label: 'メールアドレス', sortId: 'customer_email' },
      { id: 'customerPhone', label: '電話番号', sortId: 'customer_phone' },
      { id: 'shopRemarks', label: '店舗向け備考', sortId: 'shop_remarks' },
      { id: 'deliveryAddress', label: '納品先住所', sortId: 'delivery_address' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.opportunityInfo.searchCondition) {
      searchCondition = props.opportunityInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      displayType: 'table',
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      isSuccessClaim: false,
      openClaim: false,
      successMessage: '',
      download: {
        headerItems: CSV_HEADER,
        done: false,
        fileName: '案件',
        datas: [],
      },
    };

    props.dispatch(opportunityListActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.datas !== this.props.opportunityInfo.datas) {
      if (this.props.opportunityInfo.datas) {
        this.setDatas(this.props.opportunityInfo.datas);
      }
    }
    if (prevProps.opportunityInfo.isDeleteSuccess
      !== this.props.opportunityInfo.isDeleteSuccess) {
      if (this.props.opportunityInfo.isDeleteSuccess) {
        this.setSuccess('削除しました。');
      }
    }
    if (prevProps.opportunityInfo.isGrantSuccess
      !== this.props.opportunityInfo.isGrantSuccess) {
      if (this.props.opportunityInfo.isGrantSuccess) {
        this.setSuccess('付与しました。');
      }
    }
    if (prevProps.opportunityInfo.saveClaimId
      !== this.props.opportunityInfo.saveClaimId) {
      if (this.props.opportunityInfo.saveClaimId) {
        this.createClaimSuccess(this.props.opportunityInfo.saveClaimId);
      }
    }
    if (prevProps.opportunityInfo.csvDatas !== this.props.opportunityInfo.csvDatas) {
      if (this.props.opportunityInfo.csvDatas) {
        this.setCsvDatas(this.props.opportunityInfo.csvDatas);
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  createClaimSuccess = (createClaimId) => {
    this.setState({ createClaimId, isSuccessClaim: true });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    const { displayType } = this.state;
    this.props.dispatch(opportunityListActions.setSearchCondition(searchCondition));
    if (displayType === 'calendar') {
      this.props.dispatch(opportunityListActions.getCalendarData(searchCondition));
    } else {
      this.props.dispatch(opportunityListActions.getDatas(searchCondition));
    }
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onDelete = (ids) => {
    this.props.dispatch(opportunityListActions.deleteData({ ids }));
  };

  onDisplayChange = (_, displayType) => {
    if (!displayType) {
      return;
    }
    const { searchCondition } = this.state;
    if (displayType === 'calendar') {
      this.props.dispatch(opportunityListActions.getCalendarData(searchCondition));
    } else {
      this.props.dispatch(opportunityListActions.getDatas(searchCondition));
    }
    this.setState({ displayType });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(opportunityListActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onCalendarResearch = (targetMonth) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      targetMonth,
    };
    this.setState({ searchCondition: tempCondition });
    this.props.dispatch(opportunityListActions.getCalendarData(tempCondition));
  };

  onBulkPointGrant = (param) => {
    this.props.dispatch(opportunityListActions.grantPoint(param));
  };

  confirmCreateClaim = (selected) => {
    this.setState({ selected: { ids: selected }, openClaim: true });
  };

  createClaim = () => {
    const { selected } = this.state;
    this.props.dispatch(opportunityListActions.createClaim(selected));
    this.setState({ openClaim: false });
  };

  render() {
    const {
      displayType,
      datas,
      searchCondition,
      isSuccessOpen,
      isDeleteDisabled,
      isEditDisabled,
      download,
      successMessage,
      openClaim,
      isSuccessClaim,
      createClaimId,
    } = this.state;

    return (
      <Box>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="opportunity"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="お客様名"
          simpleAreaOne={(
            <TextField value={searchCondition.customerName} autoComplete="off" fullWidth name="customerName" onChange={this.onChange} />
          )}
          simpleAreaTwoText="開催日"
          simpleAreaTwo={(
            <DatePicker value={searchCondition.heldDate} fullWidth name="heldDate" onChange={this.onChange} />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="担当者" />
              <DataMultiSelectList
                name="salesUsers"
                target="users"
                values={searchCondition.salesUsers}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="ステータス" />
              <DataMultiSelectList
                name="statuses"
                target="codes"
                parameter={{ codeCategory: 'opportunity_status' }}
                values={searchCondition.statuses}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="会社名" />
              <TextField autoComplete="off" value={searchCondition.companyName} fullWidth name="companyName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormTitle title="対応期限日時" />
              <Box display="flex" alignItems="center">
                <Box>
                  <DateTimePicker value={searchCondition.complianceDtStart} fullWidth name="complianceDtStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <DateTimePicker value={searchCondition.complianceDtEnd} fullWidth name="complianceDtEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormTitle title="案件番号" />
              <TextField autoComplete="off" value={searchCondition.opportunityNumber} fullWidth name="opportunityNumber" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="お客様メールアドレス" />
              <TextField autoComplete="off" value={searchCondition.customerEmail} fullWidth name="customerEmail" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="お客様電話番号" />
              <TextField autoComplete="off" value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="ポイント未付与" />
              <FormSwitch checked={searchCondition.notPointGranted} label="ポイント未付与のみ" name="notPointGranted" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="担当者未割当のみ" />
              <FormSwitch checked={searchCondition.unassigned} label="未割当のみ" name="unassigned" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormTitle title="フリーワード" attention="社内用備考、店舗向け備考、商品内容備考、ユーザー向け備考" />
              <TextField autoComplete="off" value={searchCondition.keyword} fullWidth name="keyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <Box sx={{ textAlign: 'right' }} my={1}>
          <ToggleButtonGroup
            size="small"
            value={displayType}
            exclusive
            onChange={this.onDisplayChange}
            aria-label="text alignment"
          >
            <ToggleButton value="table">
              <TableViewIcon />
            </ToggleButton>
            <ToggleButton value="calendar">
              <CalendarMonthIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box style={{ marginBottom: '10px' }}>
          {displayType === 'table' && (
            <>
              <DataTableWithCheckbox
                url={detailUrl.OPPORTUNITY_DETAIL}
                rows={datas.content || []}
                headCells={this.headCells}
                onAddOpen={this.onAdd}
                procName="案件"
                page={searchCondition.page}
                rowsPerPage={searchCondition.records}
                totalElements={datas.totalElements}
                onChangePage={this.onChangePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                onDelete={this.onDelete}
                handleRequestSort={this.handleRequestSort}
                order={searchCondition.order}
                orderBy={searchCondition.orderBy}
                isNotAdd={isEditDisabled}
                isNotDelete={isDeleteDisabled}
                isBulkPointGrant
                isDispFire
                onBulkPointGrant={this.onBulkPointGrant}
                pointTarget={2}
                isCreateClaim
                onCreate={this.confirmCreateClaim}
              />
              <ConfirmDialog
                open={openClaim}
                onClose={() => this.setState({ openClaim: false })}
                title="請求作成"
                content="選択した案件から請求を作成します。"
                actionText="作成する"
                onClick={this.createClaim}
              />
              <SuccessCreateClaim
                isOpen={isSuccessClaim}
                onClose={() => this.setState({ isSuccessClaim: false })}
                saveId={createClaimId}
              />
            </>
          )}
          {displayType === 'calendar' && (<Calendar searchCondition={searchCondition} onSearch={this.onCalendarResearch} />)}
          <SuccessSnackbar
            open={isSuccessOpen}
            onClose={this.onSnackbarClose}
            message={successMessage}
          />
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(OpportunityList);
