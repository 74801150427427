import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../../../constants/frontUrls';
import { orderActions } from '../../../../../redux/opportunity/order/orderListState';
import AddOrderDialog from './AddOrderDialog';
import { SuccessSnackbar } from '../../../../../components/atoms/Base';
import { opportunityDetailActions } from '../../../../../redux/opportunity/opportunity/opportunityDetailState';
import OrderDataTable from './OrderDataTable';
import { isScreenDeleteDisabledFromUrl, isScreenEditDisabledFromUrl } from '../../../../../utils/authCheck.helper';
import SuccessCreateClaim from './SuccessCreateClaim';

const DEFAULT_SEARCH_CONDITION = {
  opportunityId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  { id: 'orderNumber', label: '注文番号', sortId: 'order_number' },
  { id: 'status', label: 'ステータス', sortId: 'status' },
  { id: 'shopVendorName', label: '店舗/業者', sortId: 'shop_vendor_name' },
  {
    id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
  },
  {
    id: 'customerBillPrice', label: '顧客税込請求額', sortId: 'customer_bill_price', numeric: true,
  },
];

export default function OrderList(props) {
  const {
    id, isOpen, opportunityNumber,
  } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isSuccessClaim, setSuccessClaim] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const orderDatas = useSelector((state) => state.orderStore.datas);
  const isSaveSuccess = useSelector((state) => state.orderStore.isSaveSuccess);
  const isClaimCreateSuccess = useSelector(
    (state) => state.opportunityDetailStore.isClaimCreateSuccess,
  );
  const createClaimId = useSelector(
    (state) => state.opportunityDetailStore.createClaimId,
  );
  const isDeleteSuccess = useSelector((state) => state.orderStore.isDeleteSuccess);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/order'));
  const isNotEdit = useSelector((state) => isScreenEditDisabledFromUrl(state.loginStore.userInfo, '/order'));
  const isOrderResearch = useSelector(
    (state) => state.opportunityDetailStore.isOrderResearch,
  );
  React.useEffect(() => {
    if (id && orderDatas) {
      setDatas(orderDatas);
    }
  }, [orderDatas]);

  const search = () => {
    dispatch(orderActions.getDatas(searchCondition));
  };

  React.useEffect(() => {
    if (id && isOpen) {
      const tempSearchCondition = { ...DEFAULT_SEARCH_CONDITION, opportunityId: id };
      setSearchCondition(tempSearchCondition);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (searchCondition.opportunityId) {
      search();
    }
  }, [searchCondition]);
  React.useEffect(() => {
    if (isOrderResearch) {
      dispatch(opportunityDetailActions.resetFlg());
      search();
    }
  }, [isOrderResearch]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setIsSuccessOpen(true);
      setSuccessMessage('保存しました。');
      dispatch(orderActions.resetFlgs());
      search();
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (isClaimCreateSuccess) {
      setSuccessClaim(true);
      dispatch(opportunityDetailActions.resetFlg());
    }
  }, [isClaimCreateSuccess]);

  React.useEffect(() => {
    if (isDeleteSuccess) {
      setIsSuccessOpen(true);
      setSuccessMessage('削除しました。');
      dispatch(orderActions.resetFlgs());
      search();
    }
  }, [isDeleteSuccess]);

  const onAdd = () => {
    setIsAddOpen(true);
  };

  const onClose = () => {
    setIsAddOpen(false);
  };

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
  };

  const onDelete = (deleteId) => {
    dispatch(orderActions.deleteData({ ids: [deleteId] }));
  };

  const onCreateClaim = (ids) => {
    dispatch(opportunityDetailActions.createClaimData({ ids, opportunityId: id }));
  };

  return (
    <>
      <OrderDataTable
        url={detailUrl.ORDER_DETAIL}
        rows={datas.content || []}
        headCells={headCells}
        onAddOpen={onAdd}
        procName="注文"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={datas.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onDelete={onDelete}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotDelete={isNotDelete}
        isNotEdit={isNotEdit}
        onCreateClaim={onCreateClaim}
      />
      <AddOrderDialog
        opportunityId={id}
        opportunityNumber={opportunityNumber}
        isOpen={isAddOpen}
        onClose={onClose}
      />
      <SuccessCreateClaim
        isOpen={isSuccessClaim}
        onClose={() => setSuccessClaim(false)}
        saveId={createClaimId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
    </>
  );
}
