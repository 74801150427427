import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { contactListActions } from './contactListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CONTACT.CONTACT, payload);
    yield put(contactListActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CONTACT.CONTACT_CSV, payload);
    yield put(contactListActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OPPORTUNITY.CONTACT.CONTACT, payload);
    yield put(contactListActions.deleteDataSuccess());
  } catch (_) { }
}

function* contactListSaga() {
  yield takeLatest('contactList/getDatas', getData);
  yield takeLatest('contactList/getCsvDatas', getCsvDatas);
  yield takeLatest('contactList/deleteData', deleteData);
}

export default contactListSaga;
