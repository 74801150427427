import React, { useState } from 'react';
import {
  DialogActions, Button, Box, Checkbox, FormControlLabel,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { opportunityDetailActions } from '../../../../redux/opportunity/opportunity/opportunityDetailState';
import DataTableWithMenuNoSort from '../../../../components/templates/DataTableWithMenuNoSort';
import { DialogContent } from '../../../../components/atoms/Base';

const headCells = [
  { id: 'name', label: '名前' },
  { id: 'fixedPhone', label: '固定電話番号' },
  { id: 'mobilePhone', label: '携帯電話番号' },
  { id: 'email', label: 'メールアドレス' },
];

export default function CustomerConfirmDialog(props) {
  const {
    isOpen, onClose, data,
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = useState({ id: '', customerId: '', customerChecked: false });
  const [datas, setDatas] = useState([]);
  const dbCustomers = useSelector((state) => state.opportunityDetailStore.customers);
  const opportunityData = useSelector((state) => state.opportunityListStore.data);

  React.useEffect(() => {
    if (isOpen) {
      setForm(data);
      dispatch(opportunityDetailActions.getCustomers({ id: data.id }));
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data && data.id && dbCustomers) {
      setDatas(dbCustomers);
    }
  }, [dbCustomers]);

  React.useEffect(() => {
    if (data && data.id && opportunityData && opportunityData.form) {
      setForm(opportunityData.form);
    }
  }, [opportunityData]);

  const onSettingAccount = (_, row) => {
    const isNotApplicable = row.id === -1;
    const tempForm = {
      ...form,
      customerId: isNotApplicable ? null : row.id,
      customerChecked: isNotApplicable,
    };
    setForm(tempForm);
    dispatch(opportunityDetailActions.saveData(tempForm));
    onClose();
  };

  const onCkeckAccount = (e) => {
    const tempForm = {
      ...form,
      customerChecked: e.target.checked,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      title="会員"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogContent>
        <Box style={{ marginTop: '20px' }}>
          <Box sx={{ fontWeight: 'bold' }}>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={(e) => onCkeckAccount(e)}
                  checked={form.customerChecked}
                />
                        )}
              label="会員ではありません"
            />
          </Box>
          <DataTableWithMenuNoSort
            rows={datas || []}
            headCells={headCells}
            procName="会員"
            isNotAdd
            secondFuncText="設定する"
            secondFunc={onSettingAccount}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>閉じる</Button>
        {form.customerChecked && (
          <Button
            color="primary"
            onClick={() => {
              dispatch(opportunityDetailActions.saveData(form));
              onClose();
            }}
          >
            保存する
          </Button>
        )}
      </DialogActions>

    </Dialog>
  );
}
