import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { vendorListActions } from '../../../redux/shop/vendor/vendorListState';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  name: '',
  phone: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};
const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: '業者名' },
  { key: 'reportName', label: '帳票表示名' },
  { key: 'phone', label: '電話番号' },
  { key: 'invoiceTargeted', label: 'インボイス対象' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class VendorList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '業者名', sortId: 'name' },
      { id: 'reportName', label: '帳票表示名', sortId: 'report_name' },
      { id: 'phone', label: '電話番号', sortId: 'phone' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.vendorListInfo.searchCondition) {
      searchCondition = props.vendorListInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: '業者',
        done: false,
        datas: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vendorListInfo.datas !== this.props.vendorListInfo.datas) {
      if (this.props.vendorListInfo.datas) {
        this.setDatas(this.props.vendorListInfo.datas);
      }
    }
    if (prevProps.vendorListInfo.csvDatas !== this.props.vendorListInfo.csvDatas) {
      if (this.props.vendorListInfo.csvDatas) {
        this.setCsvDatas(this.props.vendorListInfo.csvDatas);
      }
    }
    if (prevProps.vendorListInfo.isDeleteSuccess
      !== this.props.vendorListInfo.isDeleteSuccess) {
      if (this.props.vendorListInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  search = (searchCondition) => {
    this.props.dispatch(vendorListActions.setSearchCondition(searchCondition));
    this.props.dispatch(vendorListActions.getDatas(searchCondition));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(vendorListActions.deleteData({ ids }));
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(vendorListActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(vendorListActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="vendor"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="業者名"
          simpleAreaOne={(
            <TextField fullWidth size="small" value={searchCondition.name} name="name" onChange={this.onChange} />
          )}
          simpleAreaTwoText="電話番号"
          simpleAreaTwo={(
            <TextField fullWidth value={searchCondition.phone} name="phone" onChange={this.onChange} />
          )}
        />
        <DataTableWithCheckbox
          url={detailUrl.VENDOR_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="業者"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          onDelete={this.onDelete}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  vendorListInfo: state.vendorListStore,
});

export default connect(mapStateToProps)(VendorList);
