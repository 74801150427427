import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (name, value) => {
    switch (name) {
      case 'opportunityId':
      case 'shopId':
      case 'illustrationStatusCode':
        return CommonValidation.requiredSelect(value);
      case 'eventDate':
      case 'postDate':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
