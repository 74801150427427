import { all } from '@redux-saga/core/effects';

import commonSaga from './common/common/commonSaga';
import conditionSaga from './common/condition/conditionSaga';
import selectSaga from './common/select/selectSaga';
import loginSaga from './login/loginSaga';
import homeSaga from './home/homeSaga';

import contactListSaga from './opportunity/contact/contactListSaga';
import contactDetailSaga from './opportunity/contact/contactDetailSaga';
import opportunityListSaga from './opportunity/opportunity/opportunityListSaga';
import opportunityDetailSaga from './opportunity/opportunity/opportunityDetailSaga';
import opportunityAttachSaga from './opportunity/opportunity/opportunityAttachSaga';
import opportunityFileSaga from './opportunity/opportunityFile/opportunityFileSaga';
import estimateSaga from './opportunity/estimate/estimateSaga';
import estimateDetailSaga from './opportunity/estimate/estimateDetailSaga';
import orderSaga from './opportunity/order/orderListSaga';
import orderDetailSaga from './opportunity/order/orderDetailSaga';
import claimSaga from './opportunity/claim/claimSaga';
import claimDetailSaga from './opportunity/claim/claimDetailSaga';
import claimAttachSaga from './opportunity/claim/claimAttachSaga';
import customerSaga from './opportunity/customer/customerSaga';
import customerPointHistorySaga from './opportunity/customer/customerPointHistorySaga';
import customerViewFavoriteSaga from './opportunity/customer/customerViewFavoriteSaga';

import accountSaga from './shop/account/accountSaga';
import accountUserSaga from './shop/accountUser/accountUserSaga';
import shopSaga from './shop/shop/shopSaga';
import shopDetailSaga from './shop/shop/shopDetailSaga';
import shopNoticeSaga from './shop/shopNotice/shopNoticeSaga';
import planSaga from './shop/plan/planSaga';
import planAttachedSaga from './shop/plan/planAttachedSaga';
import vendorListSaga from './shop/vendor/vendorListSaga';
import vendorDetailSaga from './shop/vendor/vendorDetailSaga';
import deliveryAgencySaga from './shop/deliveryAgency/deliveryAgencySaga';
import deliveryAgencyUserSaga from './shop/deliveryAgencyUser/deliveryAgencyUserSaga';

import reviewSaga from './pageSetting/review/reviewSaga';
import illustrationSaga from './pageSetting/illustration/illustrationSaga';
import specialFeatureSaga from './pageSetting/specialFeature/specialFeatureSaga';
import specialFeatureAttachedSaga from './pageSetting/specialFeature/specialFeatureAttachedSaga';
import blogSaga from './pageSetting/blog/blogSaga';
import blogContentSaga from './pageSetting/blog/blogContentSaga';
import lpPlanControlSaga from './pageSetting/lpPlanControl/lpPlanControlSaga';

import roleListSaga from './systemSetting/role/roleListSaga';
import roleDetailSaga from './systemSetting/role/roleDetailSaga';
import loginUserSaga from './systemSetting/loginUser/loginUserSaga';
import pointRuleSaga from './systemSetting/pointRule/pointRuleSaga';
import mailTemplateSaga from './systemSetting/mailTemplate/mailTemplateSaga';
import trashSaga from './systemSetting/trash/trashSaga';
import frontNoticeSaga from './pageSetting/frontNotice/frontNoticeSaga';

export default function* rootSaga() {
  yield all([
    commonSaga(),
    conditionSaga(),
    selectSaga(),
    loginSaga(),
    homeSaga(),

    contactListSaga(),
    contactDetailSaga(),
    opportunityListSaga(),
    opportunityDetailSaga(),
    opportunityAttachSaga(),
    opportunityFileSaga(),
    estimateSaga(),
    estimateDetailSaga(),
    orderSaga(),
    orderDetailSaga(),
    claimSaga(),
    claimDetailSaga(),
    claimAttachSaga(),
    customerSaga(),
    customerPointHistorySaga(),
    customerViewFavoriteSaga(),

    accountSaga(),
    accountUserSaga(),
    shopSaga(),
    shopDetailSaga(),
    shopNoticeSaga(),
    planSaga(),
    planAttachedSaga(),
    vendorListSaga(),
    vendorDetailSaga(),
    deliveryAgencySaga(),
    deliveryAgencyUserSaga(),

    reviewSaga(),
    illustrationSaga(),
    specialFeatureSaga(),
    specialFeatureAttachedSaga(),
    blogSaga(),
    blogContentSaga(),
    frontNoticeSaga(),
    lpPlanControlSaga(),

    roleListSaga(),
    roleDetailSaga(),
    loginUserSaga(),
    pointRuleSaga(),
    mailTemplateSaga(),
    trashSaga(),
  ]);
}
