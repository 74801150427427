import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Dialog as MuiDialog, Slide, IconButton, Typography, AppBar, Toolbar, Button,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent } from '../atoms/Base';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FullScreenDialog(props) {
  const {
    children,
    isOpen,
    onClose,
    title,
    actionName,
    onClickAction,
    isNoPadding,
    loading,
  } = props;
  const theme = useTheme();
  const onLocalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose(event);
    }
  };

  return (
    <MuiDialog
      fullScreen
      TransitionComponent={Transition}
      onClose={onLocalClose}
      open={isOpen}
      disableEscapeKeyDown
      sx={{
        '& .MuiDialogContent-root': {
          padding: isNoPadding && '0px',
        },
      }}
    >
      <AppBar
        sx={{ position: 'relative' }}
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {actionName && (
            <Button style={{ fontSize: '1.1rem' }} autoFocus color="inherit" onClick={onClickAction} disabled={loading}>
              {actionName}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {loading && (<LinearProgress color="secondary" />)}
      <DialogContent>
        {children}
      </DialogContent>
    </MuiDialog>
  );
}
