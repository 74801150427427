import { createSlice } from '@reduxjs/toolkit';

export const vendorDetailSlice = createSlice({
  name: 'vendorDetail',
  initialState: {
    data: {},
    isSaveSuccess: false,
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateVendor: () => { },
    updateVendorSuccess: (state, action) => {
      state.saveId = action.payload;
      state.isSaveSuccess = true;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
    },
  },
});

export const vendorDetailActions = vendorDetailSlice.actions;

export default vendorDetailSlice.reducer;
