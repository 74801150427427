import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { customerPointHistoryActions } from './customerPointHistoryState';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getDatasByPointHistory({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.CUSTOMER.POINT.POINT_HISTORY, payload);
    yield put(customerPointHistoryActions.getDatasByPointHistorySuccess(result));
  } catch (_) { }
}

function* getPointHistoryDataById({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.CUSTOMER.POINT.POINT_HISTORY_ID, payload);
    yield put(customerPointHistoryActions.getPointHistoryDataByIdSuccess(result));
  } catch (_) { }
}

function* savePoint({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.CUSTOMER.POINT.POINT_HISTORY, payload);
    yield put(customerPointHistoryActions.savePointSuccess(result));
  } catch (_) { }
}

function* deleteDataById({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.CUSTOMER.POINT.POINT_HISTORY, payload);
    yield put(customerPointHistoryActions.deleteDataByIdSuccess(result));
  } catch (_) { }
}

function* customerPointHistorySaga() {
  yield takeLatest('customerPointHistory/getDatasByPointHistory', getDatasByPointHistory);
  yield takeLatest('customerPointHistory/getPointHistoryDataById', getPointHistoryDataById);
  yield takeLatest('customerPointHistory/savePoint', savePoint);
  yield takeLatest('customerPointHistory/deleteDataById', deleteDataById);
}

export default customerPointHistorySaga;
