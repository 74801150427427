/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const selectSlice = createSlice({
  name: 'select',
  initialState: {
    datas: [],
    areas: [],
  },
  reducers: {
    getCodes: () => {},
    getCodesSuccess: (state, action) => {
      state.codes = action.payload;
    },
    getAreas: () => { },
    getAreasSuccess: (state, action) => {
      state.areas = action.payload;
    },
  },
});

export const selectActions = selectSlice.actions;

export default selectSlice.reducer;
