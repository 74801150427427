import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { accountActions } from './accountState';
import { getDatas, getDataById } from '../../../services/base.service';

function* getAccounts({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.ACCOUNT.ACCOUNT, payload);
    yield put(accountActions.getDatasSuccess(result));
  } catch (_) {}
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.ACCOUNT.ACCOUNT_CSV, payload);
    yield put(accountActions.getCsvDatasSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.ACCOUNT.ACCOUNT_ID, payload);
    yield put(accountActions.getDataSuccess(result));
  } catch (_) {}
}

function* accountSaga() {
  yield takeLatest('account/getDatas', getAccounts);
  yield takeLatest('account/getCsvDatas', getCsvDatas);
  yield takeLatest('account/getData', getData);
}

export default accountSaga;
