import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Paper, Button } from '@mui/material';

export default function EditOrderContent({ initialDatas, onClose }) {
  const [datas, setDatas] = useState(initialDatas);

  // ドラッグ完了時の処理
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // ドロップ先が無効なら終了

    const items = Array.from(datas);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setDatas(items);
  };

  const stripHtmlTags = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  };

  const onLocalClose = () => {
    onClose(datas);
  };

  return (
    <>
      <Box position="sticky" top={90} left={0} zIndex={99} mb={1} textAlign="right" width="100%" px={1}>
        <Box textAlign="right">
          <Button color="primary" style={{ marginRight: '10px' }} size="small" variant="contained" onClick={onLocalClose}>並び替え終了</Button>
        </Box>
      </Box>
      <Paper variant="outlined" className="contentBody" style={{ padding: '10px', marginBottom: 16 }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-area">
            {(provided) => (
              <Box
                variant="outlined"
                className="contentBody"
                style={{ padding: '10px', marginBottom: 16 }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {datas.map((data, index) => (
                  <Draggable key={data.id} draggableId={data.id.toString()} index={index}>
                    {(provid) => (
                      <div
                        ref={provid.innerRef}
                        {...provid.draggableProps}
                        {...provid.dragHandleProps}
                      >
                        {data.contentType === 1 && (
                          <Paper className="hover-elevation">
                            {data.linkText}
                          </Paper>
                        )}
                        {data.contentType === 2 && (
                          <Paper className="hover-elevation">
                            <img src={!data.imagePath ? URL.createObjectURL(data.imageFile) : process.env.REACT_APP_AWS_PUBLIC_URL + data.imagePath} style={{ width: '100px' }} alt="画像" />
                          </Paper>
                        )}
                        {data.contentType === 3 && (
                          <Paper className="hover-elevation">
                            {stripHtmlTags(data.content).slice(0, 50)}
                            {stripHtmlTags(data.content).length > 50 && '...'}
                          </Paper>
                        )}
                        {data.contentType === 4 && (
                          <Paper className="hover-elevation">
                            <Box>関連記事</Box>
                            <Box>{data.relateBlogTitle}</Box>
                          </Paper>
                        )}
                        {data.contentType === 5 && (
                          <Paper className="hover-elevation">目次</Paper>
                        )}
                        {data.contentType === 6 && (
                          <Paper className="hover-elevation">スペース</Paper>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
    </>
  );
}
