import React from 'react';
import { connect } from 'react-redux';
import {
  TextField,
} from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { frontNoticeActions } from '../../../redux/pageSetting/frontNotice/frontNoticeState';
import {
  FormTitle, DatePicker, SuccessSnackbar,
} from '../../../components/atoms/Base';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  startDate: '',
  endDate: '',
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'title', label: 'タイトル' },
  { key: 'content', label: '内容' },
  { key: 'publishStartDate', label: '公開開始日' },
  { key: 'publishEndDate', label: '公開終了日' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class frontNoticeList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'publishStartDate', label: '公開開始日', sortId: 'publish_start_date' },
      { id: 'publishEndDate', label: '公開終了日', sortId: 'publish_end_date' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.frontNoticeInfo.searchCondition) {
      searchCondition = props.frontNoticeInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: 'フロントお知らせ',
        done: false,
        datas: [],
      },
    };
    props.dispatch(frontNoticeActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.frontNoticeInfo.datas !== this.props.frontNoticeInfo.datas) {
      if (this.props.frontNoticeInfo.datas) {
        this.setDatas(this.props.frontNoticeInfo.datas);
      }
    }
    if (prevProps.frontNoticeInfo.isDeleteSuccess !== this.props.frontNoticeInfo.isDeleteSuccess) {
      if (this.props.frontNoticeInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.frontNoticeInfo.csvDatas !== this.props.frontNoticeInfo.csvDatas) {
      if (this.props.frontNoticeInfo.csvDatas) {
        this.setCsvDatas(this.props.frontNoticeInfo.csvDatas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  setSuccess = () => {
    this.setState({
      isSuccessOpen: true,
    });
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(frontNoticeActions.setSearchCondition(searchCondition));
    this.props.dispatch(frontNoticeActions.getDatas(searchCondition));
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(frontNoticeActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(frontNoticeActions.deleteData({ ids }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(frontNoticeActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      isEditDisabled,
      isDeleteDisabled,
      datas,
      searchCondition,
      isSuccessOpen,
      download,
    } = this.state;
    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          targetScreen="front-notice"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onClear={this.onClear}
          simpleAreaOneText="公開開始日"
          simpleAreaOne={(
            <DatePicker
              value={searchCondition.startDate}
              name="startDate"
              onChange={this.onChange}
            />
          )}
          simpleAreaTwoText="公開終了日"
          simpleAreaTwo={(
            <DatePicker
              value={searchCondition.endDate}
              name="endDate"
              onChange={this.onChange}
            />
          )}
        >
          <FormTitle title="フリーワード" attention="タイトル、内容" />
          <TextField fullWidth name="keyword" onChange={this.onChange} value={searchCondition.keyword} />
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.FRONT_NOTICE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="フロントお知らせ"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          onDelete={this.onDelete}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message="削除をしました。"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  frontNoticeInfo: state.frontNoticeStore,
});

export default connect(mapStateToProps)(frontNoticeList);
