import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTableWithMenu from '../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { accountUserActions } from '../../../redux/shop/accountUser/accountUserState';
import AccountUserDialog from './AccountUserDialog';
import { SuccessSnackbar } from '../../../components/atoms/Base';

const HEAD_CELLS = [
  { id: 'name', label: '担当者名', sortId: 'name' },
  { id: 'shopName', label: '所属店舗', sortId: 'shop_name' },
  { id: 'email', label: 'メールアドレス', sortId: 'email' },
];

export default function AccountUserDataTable(props) {
  const {
    accountId, shopId, isNotEdit, isNotDelete,
  } = props;
  const dispatch = useDispatch();
  const accountUsers = useSelector((state) => state.accountUserStore.datas);
  const isSaveSuccess = useSelector((state) => state.accountUserStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.accountUserStore.isDeleteSuccess);
  const [datas, setDatas] = React.useState({});
  const [searchCondition, setSearchCondition] = React.useState({
    records: 50,
    page: 0,
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [accountUserId, setAccountUserId] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);

  const search = () => {
    dispatch(accountUserActions.getDatas({
      ...searchCondition, accountId: accountId || null, shopId: shopId || null,
    }));
  };

  const success = (msg) => {
    dispatch(accountUserActions.resetFlg());
    setSuccessMessage(msg);
    setSuccessOpen(true);
    setDialogOpen(false);
  };

  React.useEffect(() => {
    if (accountId || shopId) {
      search();
    }
  }, [accountId, shopId]);
  React.useEffect(() => {
    if (accountUsers) {
      setDatas(accountUsers);
    }
  }, [accountUsers]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      success('保存しました。');
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      search();
      success('削除しました。');
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    setSearchCondition({
      ...searchCondition,
      page: newPage,
    });
    search();
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchCondition({
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    });
    search();
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    setSearchCondition({
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    });
    search();
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setAccountUserId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setAccountUserId(openedMenuRowId);
  };

  const onDelete = (selectedId) => {
    dispatch(accountUserActions.deleteData({ ids: [selectedId] }));
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  const onSuccessSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTableWithMenu
        rows={datas.content || []}
        procName="担当者"
        headCells={HEAD_CELLS}
        totalElements={datas.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <AccountUserDialog
        isOpen={isDialogOpen}
        onClose={dialogClose}
        id={accountUserId}
        shopId={shopId}
        accountId={accountId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSuccessSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
