import React from 'react';
import { connect } from 'react-redux';
import {
  Button, Box, TextField, Grid,
  IconButton, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataGrid from '../../../components/templates/DataGrid';
import { planActions } from '../../../redux/shop/plan/planState';
import {
  RecordBox, withParams, SuccessSnackbar, TextArea, FormSwitch, FormErrorText,
  DateTimePicker, TaxRate, Link,
} from '../../../components/atoms/Base';
import Tabs from '../../../components/templates/Tabs';
import OptionDataTable from './OptionDataTable';
import NumberInput from '../../../components/atoms/NumberInput';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import DataSelectListDebounce from '../../../components/atoms/dataList/DataSelectListDebounce';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import RadioButtons from '../../../components/atoms/RadioButtons';
import detailUrl from '../../../constants/frontUrls';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import Validation from './validation';
import SystemInfo from '../../../components/templates/SystemInfo';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import ServiceType from '../../../components/atoms/ServiceType';
import Accordion from '../../../components/templates/Accordion';
import SelectReviewDialog from '../common/SelectReviewDialog/SelectReviewDialog';
import RecordHeader, { RecordHeaderButton } from '../../../components/templates/RecordHeader';
import ConfirmDialog from '../../../components/templates/ConfirmDialog';

const DEFAULT_PLAN = {
  id: '',
  name: '',
  itemNumber: 0,
  priceNote: '',
  introduction: '',
  detailContent: '',
  shopId: '',
  shopName: '',
  price: 0,
  amount: '',
  public: '',
  accessory: '',
  publishDt: '',
  displayOrder: '',
  planTypeCode: 1,
  serviceTypeCode: 1,
  genreLabel: '',
  budgetLabel: '',
  sceneLabel: '',
  opponentLabel: '',
  tasteLabel: '',
  taxRate: 8,
  published: false,
};
const DEFAULT_ERROR = {
  id: '',
  name: '',
  shopId: '',
  price: '',
  displayOrder: '',
  planTypeCode: '',
  serviceTypeCode: '',
  taxRate: '',
};

class PlanDetail extends React.Component {
  constructor(props) {
    super(props);
    const state = this.props.location?.state;
    const shopId = state ? Number(state.shopId) : null;
    const { id } = this.props.params;

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.planInfo.isCreateSuccess || props.planInfo.isCopySuccess) {
      props.dispatch(planActions.resetFlg());
      successMessage = props.planInfo.isCreateSuccess ? '作成しました。' : 'コピーしました。';
      isSuccessOpen = true;
    }
    const attributes = {
      budgetAttributes: [],
      genreAttributes: [],
      sceneAttributes: [],
      opponentAttributes: [],
      tasteAttributes: [],
    };

    this.state = {
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
      plan: { ...DEFAULT_PLAN, shopId },
      form: { ...DEFAULT_PLAN, shopId },
      isEdit: !id,
      isSuccessOpen,
      successMessage,
      id,
      imageList: [],
      tempImageList: [],
      errorMessage: DEFAULT_ERROR,
      attributes,
      tempAttributes: attributes,
      isSelectOpen: false,
    };

    if (id) {
      props.dispatch(planActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.planInfo.data !== this.props.planInfo.data) {
      if (this.props.planInfo.data) {
        this.setPlan(this.props.planInfo.data);
      }
    }
    if (prevProps.planInfo.isSaveSuccess !== this.props.planInfo.isSaveSuccess) {
      if (this.props.planInfo.isSaveSuccess) {
        this.setSuccess('保存', this.props.planInfo.saveId);
      }
    }
    if (prevProps.planInfo.isDeleteSuccess !== this.props.planInfo.isDeleteSuccess) {
      if (this.props.planInfo.isDeleteSuccess) {
        this.setSuccess('削除');
      }
    }
  }

  setPlan = (plan) => {
    const attributes = {
      budgetAttributes: plan.budgetAttributes || [],
      genreAttributes: plan.genreAttributes,
      sceneAttributes: plan.sceneAttributes,
      opponentAttributes: plan.opponentAttributes,
      tasteAttributes: plan.tasteAttributes || [],
    };
    this.setState({
      plan: plan.data,
      form: plan.data,
      imageList: plan.images,
      tempImageList: plan.images,
      attributes,
      tempAttributes: attributes,
    });
  };

  setSuccess = (type, saveId) => {
    const { id } = this.state;
    this.props.dispatch(planActions.resetFlg());
    if (!id || (Number(id) !== saveId)) {
      this.props.dispatch(!id ? planActions.setCreateSuccess() : planActions.setCopySuccess());
      window.location.href = detailUrl.PLAN_DETAIL + saveId;
      return;
    }
    this.props.dispatch(planActions.getData({ id: saveId }));
    this.setState({
      isSuccessOpen: true,
      successMessage: `${type}しました`,
    });
  };

  onEdit = () => {
    const { plan } = this.state;
    this.props.dispatch(planActions.getData({ id: plan.id }));
    this.setState({
      isEdit: true,
      form: DEFAULT_PLAN,
      errorMessage: DEFAULT_ERROR,
    });
  };

  onCopyConfirm = () => {
    this.setState({ isCopy: true });
  };

  onCopy = () => {
    const { id } = this.state;
    this.props.dispatch(planActions.copyData({ id }));
  };

  onSave = () => {
    const {
      form, tempAttributes, tempImageList, errorMessage,
    } = this.state;
    let isError = false;
    let tempMessage = { ...errorMessage };
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (isError) {
      this.setState({ errorMessage: tempMessage });
      return;
    }
    const saveForm = { ...tempAttributes, data: form, images: tempImageList };
    this.props.dispatch(planActions.saveData(saveForm));
    this.setState({ isEdit: false });
  };

  onCancel = () => {
    const { imageList, attributes } = this.state;
    this.setState({ isEdit: false, tempImageList: imageList, tempAttributes: attributes });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessage } = this.state;

    this.setState({
      form: {
        ...form,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  onSelect = (_, selected) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        reviewId: selected.id,
        reviewTitle: selected.title,
      },
      isSelectOpen: false,
    });
  };

  onClickReviewOpen = () => {
    this.setState({ isSelectOpen: true });
  };

  onClose = () => {
    this.setState({ isSelectOpen: false, isCopy: false });
  };

  onReviewDelete = () => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        reviewId: null,
        reviewTitle: null,
      },
    });
  };

  onAttributeChange = (event) => {
    const { name, value } = event.target;
    const { tempAttributes } = this.state;

    this.setState({
      tempAttributes: {
        ...tempAttributes,
        [name]: value,
      },
    });
  };

  onSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  addImg = (value) => {
    const { tempImageList } = this.state;
    let imgArray = [];
    if (value.isNew) {
      const inputVal = { ...value, displayOrder: tempImageList.length + 1 };
      imgArray = [...tempImageList, inputVal];
    } else {
      imgArray = tempImageList.filter((img) => img.id !== value.id);
      const index = tempImageList.findIndex((img) => img.id === value.id);
      imgArray.splice(index, 0, value);
    }
    this.setState({ tempImageList: imgArray });
  };

  deleteImg = (deleteId) => {
    const { tempImageList } = this.state;
    const imgArray = tempImageList.filter((img) => img.id !== deleteId);
    this.setState({ tempImageList: imgArray });
  };

  render() {
    const {
      plan,
      form,
      isEdit,
      isSuccessOpen,
      successMessage,
      errorMessage,
      tempImageList,
      tempAttributes,
      isNotEdit,
      isNotDelete,
      id,
      isSelectOpen,
      isCopy,
    } = this.state;

    return (
      <>
        { id && !isNotEdit && (
        <RecordHeader
          bottomSection={(<Button color="primary" variant="contained" size="small" onClick={this.onCopyConfirm}>コピーする</Button>)}
        />
        )}

        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={id ? 8 : 12}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isNotEdit}
              onEdit={this.onEdit}
            />
            <RecordBox>
              <Accordion title="基本情報">
                { id && (
                  <DataGrid title="プランコード" value={plan.planCode} />
                )}
                <DataGrid title="プラン名" value={plan.name} isEdit={isEdit} isRequired>
                  <TextField value={form.name} name="name" fullWidth onChange={this.onChange} error={!!errorMessage.name} inputProps={{ maxLength: 100 }} />
                  <FormErrorText>{errorMessage.name}</FormErrorText>
                </DataGrid>
                <DataGrid
                  title="店舗"
                  value={(
                    <Link to={detailUrl.SHOP_DETAIL + plan.shopId}>
                      {plan.shopName}
                    </Link>
                  )}
                  isEdit={isEdit}
                  isRequired
                >
                  <DataSelectList
                    name="shopId"
                    value={form.shopId}
                    target="shops"
                    onChange={this.onChange}
                    disableClearable
                    error={!!errorMessage.shopId}
                  />
                  <FormErrorText>{errorMessage.shopId}</FormErrorText>
                </DataGrid>
                <DataGrid
                  title="店舗test"
                  value={(
                    <Link to={detailUrl.SHOP_DETAIL + plan.shopId}>
                      {plan.shopName}
                    </Link>
                  )}
                  isEdit={isEdit}
                  isRequired
                >
                  <DataSelectListDebounce
                    name="shopId"
                    value={form.shopId}
                    target="shops"
                    onChange={this.onChange}
                    disableClearable
                    error={!!errorMessage.shopId}
                  />
                  <FormErrorText>{errorMessage.shopId}</FormErrorText>
                </DataGrid>
                <DataGrid title="プランタイプ" value={plan.planTypeName} isEdit={isEdit} isRequired>
                  <RadioButtons
                    error={errorMessage.planTypeCode}
                    value={form.planTypeCode}
                    onChange={this.onChange}
                    name="planTypeCode"
                    target="codes"
                    parameter={{ codeCategory: 'plan_type' }}
                    defaultValue={1}
                  />
                  <FormErrorText>{errorMessage.planTypeCode}</FormErrorText>
                </DataGrid>
                <DataGrid title="サービスタイプ" value={plan.serviceTypeName} isEdit={isEdit} isRequired>
                  <ServiceType
                    error={errorMessage.serviceTypeCode}
                    value={form.serviceTypeCode}
                    onChange={this.onChange}
                  />
                  <FormErrorText>{errorMessage.serviceTypeCode}</FormErrorText>
                </DataGrid>
              </Accordion>
              <Accordion title="属性情報">
                <DataGrid title="ジャンル" value={plan.genreLabel} isEdit={isEdit}>
                  <DataMultiSelectList
                    name="genreAttributes"
                    values={tempAttributes.genreAttributes}
                    target="attribute-items"
                    parameter={{ attributeType: 'genre' }}
                    onChange={this.onAttributeChange}
                  />
                </DataGrid>
                <DataGrid title="利用シーン" value={plan.sceneLabel} isEdit={isEdit}>
                  <DataMultiSelectList
                    name="sceneAttributes"
                    values={tempAttributes.sceneAttributes}
                    target="attribute-items"
                    parameter={{ attributeType: 'scene' }}
                    onChange={this.onAttributeChange}
                  />
                </DataGrid>
                <DataGrid title="主な対象" value={plan.opponentLabel} isEdit={isEdit}>
                  <DataMultiSelectList
                    name="opponentAttributes"
                    values={tempAttributes.opponentAttributes}
                    target="attribute-items"
                    parameter={{ attributeType: 'opponent' }}
                    onChange={this.onAttributeChange}
                  />
                </DataGrid>
                <DataGrid title="テイスト" value={plan.tasteLabel} isEdit={isEdit}>
                  <DataMultiSelectList
                    name="tasteAttributes"
                    values={tempAttributes.tasteAttributes}
                    target="attribute-items"
                    parameter={{ attributeType: 'taste' }}
                    onChange={this.onAttributeChange}
                  />
                </DataGrid>
                <DataGrid title="予算" value={plan.budgetLabel} isEdit={isEdit}>
                  <DataMultiSelectList
                    name="budgetAttributes"
                    values={tempAttributes.budgetAttributes}
                    target="attribute-items"
                    parameter={{ attributeType: 'budget' }}
                    onChange={this.onAttributeChange}
                  />
                </DataGrid>
              </Accordion>
              <Accordion title="詳細情報">
                <DataGrid title="プラン紹介" value={plan.introduction} isEdit={isEdit}>
                  <TextArea value={form.introduction} name="introduction" fullWidth onChange={this.onChange} />
                </DataGrid>
                <DataGrid title="メニュー詳細" value={plan.detailContent} isEdit={isEdit}>
                  <TextArea value={form.detailContent} name="detailContent" fullWidth onChange={this.onChange} />
                </DataGrid>
              </Accordion>
              <Accordion title="プラン情報">
                <DataGrid title="品目数" value={plan.itemNumber} isEdit={isEdit}>
                  <NumberInput value={form.itemNumber} name="itemNumber" fullWidth onChange={this.onChange} />
                </DataGrid>
                <DataGrid title="税込価格" value={plan.price} isEdit={isEdit} isRequired>
                  <NumberInput value={form.price} name="price" fullWidth onChange={this.onChange} error={!!errorMessage.price} />
                  <FormErrorText>{errorMessage.price}</FormErrorText>
                </DataGrid>
                <DataGrid title="金額備考" value={plan.priceNote} isEdit={isEdit}>
                  <TextField value={form.priceNote} name="priceNote" fullWidth onChange={this.onChange} inputProps={{ maxLength: 500 }} />
                </DataGrid>
                <DataGrid title="税率" isRequired isEdit={isEdit} value={`${plan.taxRate}%`}>
                  <TaxRate value={form.taxRate} onChange={this.onChange} name="taxRate" />
                  <FormErrorText>{errorMessage.taxRate}</FormErrorText>
                </DataGrid>
                <DataGrid title="付属品" value={plan.accessory} isEdit={isEdit}>
                  <TextField value={form.accessory} name="accessory" fullWidth onChange={this.onChange} inputProps={{ maxLength: 500 }} />
                </DataGrid>
              </Accordion>
              <Accordion title="画像情報">
                <DataGrid title="画像" isEdit>
                  <PublicImageList
                    itemDatas={tempImageList}
                    isEdit={isEdit}
                    isNeedIntroduction
                    onAddImg={this.addImg}
                    onDelete={this.deleteImg}
                    onChange={(e) => this.setState({ tempImageList: e })}
                  />
                </DataGrid>
              </Accordion>
              <Accordion title="ピックアップ口コミ">
                <DataGrid
                  title="口コミ"
                  value={(
                    <Link to={detailUrl.REVIEW_DETAIL + plan.reviewId}>
                      {plan.reviewTitle}
                    </Link>
                  )}
                  isEdit={isEdit}
                >
                  <Box display="flex">
                    <Box display="flex" alignItems="center" mr={1}>{form.reviewTitle}</Box>
                    {form.reviewId && (
                      <Tooltip title="口コミ削除">
                        <IconButton size="small" onClick={this.onReviewDelete}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  {!form.reviewId && (
                    <Box mt={2}>
                      <Button size="small" variant="contained" onClick={this.onClickReviewOpen}>口コミ選択</Button>
                    </Box>
                  )}
                </DataGrid>
              </Accordion>
              <Accordion title="公開情報">
                <DataGrid title="公開日" value={plan.publishDt} isEdit={isEdit}>
                  <DateTimePicker value={form.publishDt} name="publishDt" fullWidth onChange={this.onChange} />
                  <FormErrorText>{errorMessage.publishDt}</FormErrorText>
                </DataGrid>
                <DataGrid title="公開" value={plan.published ? '公開' : '非公開'} isEdit={isEdit}>
                  <FormSwitch checked={form.published} onChange={this.onChange} label="公開する" name="published" />
                </DataGrid>
                <DataGrid title="表示順" value={plan.displayOrder} isEdit={isEdit}>
                  <NumberInput value={form.displayOrder} name="displayOrder" onChange={this.onChange} error={!!errorMessage.displayOrder} />
                  <FormErrorText>{errorMessage.displayOrder}</FormErrorText>
                </DataGrid>
              </Accordion>

              {!isEdit && form.id && (
              <Box mt={2}><SystemInfo data={form} /></Box>
              )}
              {isEdit && (
              <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                { id && (
                <Button color="inherit" variant="contained" size="small" onClick={this.onCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                )}
                <Button variant="contained" size="small" onClick={this.onSave}>保存</Button>
              </Box>
              )}
            </RecordBox>
          </Grid>
          {id && (
          <Grid item xs={12} md={4}>
            <Tabs
              value={0}
              tabs={['オプション']}
            />
            <OptionDataTable
              shopId={plan.shopId}
              planId={id}
              isNotDelete={isNotDelete}
              isNotEdit={isNotEdit}
            />
          </Grid>
          )}
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSuccessSnackbarClose}
          message={successMessage}
        />
        <SelectReviewDialog
          isOpen={isSelectOpen}
          onSelect={this.onSelect}
          shopId={plan.shopId}
          onClose={this.onClose}
        />
        <ConfirmDialog
          open={isCopy}
          onClose={this.onClose}
          onClick={this.onCopy}
          title="プランのコピー"
          content="現在のプランをコピーします。"
          actionText="コピー"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  planInfo: state.planStore,
});

export default withParams(connect(mapStateToProps)(PlanDetail));
