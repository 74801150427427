import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button, Box,
} from '@mui/material';

export default function SmartPhoneTableWithMenu(props) {
  const {
    row, headCells, isNotDelete, isNotEdit,
    onDelete, onEdit, openedMenuRowId,
    setOpenedMenuRowId, setOpenDelete,
  } = props;

  return (
    <TableCell sx={{ padding: 1 }}>
      <Box textAlign="right" sx={{ minHeight: '45px' }}>
        {(onDelete && row.deleteable && !isNotDelete) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => { setOpenDelete(true); }}
          >
            削除
          </Button>
        )}
        {(onEdit && !isNotEdit) && (
          <Button
            sx={{ margin: 1 }}
            variant="outlined"
            size="small"
            onClick={(e) => {
              onEdit(e, openedMenuRowId, row);
              setOpenedMenuRowId(null);
            }}
          >
            編集
          </Button>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px' }}>{`${headCell.label}`}</TableCell>
                <TableCell sx={{ padding: '5px' }}>
                  {headCell.numeric && typeof row[headCell.id] === 'number' && !Number.isNaN(row[headCell.id])
                    ? row[headCell.id].toLocaleString()
                    : row[headCell.id]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </TableCell>
  );
}
