import React, { useState, useEffect } from 'react';
import {
  DialogActions, Box, Button, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../atoms/Dialog';
import { commonActions } from '../../../redux/common/common/commonState';
import NumberInput from '../../atoms/NumberInput';
import { FormTitle, DialogContent } from '../../atoms/Base';

export default function BulkPointGrant(props) {
  const {
    open, onClose, target, procName, onGrant,
  } = props;
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const pointRule = useSelector((state) => state.commonStore.pointRule);

  useEffect(() => {
    if (pointRule) {
      setData({
        point: pointRule.point ? pointRule.point : '',
        periodDays: pointRule.periodDays ? pointRule.periodDays : '',
        reason: target === 2 ? '注文に基づくポイント付与' : '',
      });
    }
  }, [pointRule]);
  useEffect(() => {
    if (open) {
      dispatch(commonActions.getPointRule({ target }));
    }
  }, [open]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Dialog
      title="ポイント一斉付与"
      isOpen={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Box>
          <Box>{`選択した${procName}にポイントを付与します。`}</Box>
          <Box mt={2}>
            <FormTitle title="付与ポイント数" isRequired isNonFlex />
            <NumberInput name="point" onChange={onChange} value={data.point} endAdornment="pt" />
          </Box>
          <Box mt={2}>
            <FormTitle title="有効期間" isRequired isNonFlex />
            <NumberInput name="periodDays" onChange={onChange} value={data.periodDays} endAdornment="日間" />
          </Box>
          <Box mt={2}>
            <FormTitle title="付与理由" isRequired isNonFlex />
            <TextField fullWidth autoComplete="off" name="reason" onChange={onChange} value={data.reason} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>キャンセル</Button>
        <Button onClick={() => onGrant(data)} color="primary" disabled={!data.point || !data.periodDays || !data.reason}>
          付与
        </Button>
      </DialogActions>

    </Dialog>
  );
}
