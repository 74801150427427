import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SuccessSnackbar } from '../../../../components/atoms/Base';
import DataTableWithMenu from '../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { claimAttachActions } from '../../../../redux/opportunity/claim/claimAttachState';
import AddClaimOrderRelationDialog from './AddClaimOrderRelationDialog';
import detailUrl from '../../../../constants/frontUrls';

const headCells = [
  { id: 'orderNumber', label: '注文番号', sortId: 'order_number' },
];

export default function ClaimOrderRelationList(props) {
  const {
    id, isNotEdit, isNotDelete, opportunityId,
  } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const relationDatas = useSelector((state) => state.claimAttachStore.datas);
  const [successMessage, setSuccessMessage] = useState('');
  const [searchCondition, setSearchCondition] = React.useState({
    claimId: id,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const isSaveSuccess = useSelector(
    (state) => state.claimAttachStore.isRelationSaveSuccess,
  );
  const isDeleteSuccess = useSelector(
    (state) => state.claimAttachStore.isRelationDeleteSuccess,
  );
  const [isOpen, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');

  React.useEffect(() => {
    if (id && relationDatas) {
      setData(relationDatas);
    }
  }, [relationDatas]);

  const search = (sc) => {
    dispatch(claimAttachActions.getRelationDatas(sc));
  };

  React.useEffect(() => {
    if (id) {
      const tempCondition = {
        ...searchCondition,
        claimId: id,
      };
      setSearchCondition(tempCondition);
      search(tempCondition);
    }
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setIsSuccessOpen(true);
      dispatch(claimAttachActions.resetFlgs());
      search(searchCondition);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isSaveSuccess) {
      setSuccessMessage('保存しました');
      setIsSuccessOpen(true);
      dispatch(claimAttachActions.resetFlgs());
      search(searchCondition);
    }
  }, [isSaveSuccess]);

  const onCloseSnackbar = () => {
    setIsSuccessOpen(false);
  };

  const onEdit = (_, selectId) => {
    setOpen(true);
    setRowId(selectId);
  };

  const onAdd = () => {
    setOpen(true);
  };

  const onDelete = (deleteId) => {
    dispatch(claimAttachActions.deleteRelationData({ ids: [deleteId] }));
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  return (
    <>
      <DataTableWithMenu
        urlTargets={[{ url: detailUrl.ORDER_DETAIL, label: 'orderNumber', id: 'orderId' }]}
        rows={data || []}
        procName="請求注文リレーション"
        headCells={headCells}
        handleRequestSort={handleRequestSort}
        onDelete={onDelete}
        onEdit={onEdit}
        onAddOpen={onAdd}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onCloseSnackbar}
        message={successMessage}
      />
      <AddClaimOrderRelationDialog
        isOpen={isOpen}
        onClose={() => { setOpen(false); setRowId(''); }}
        id={rowId}
        claimId={id}
        opportunityId={opportunityId}
      />
    </>
  );
}
