import React, { useEffect } from 'react';
import {
  Box, Grid, useTheme, Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  experimentalStyled as styled,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
  FilterAlt as FilterAltIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { SuccessSnackbar } from '../../atoms/Base';
import ConditionSaveDialog from './ConditionSaveDialog';
import ConditionListDialog from './ConditionListDialog';
import DownloadModal from '../DownloadModal';
import { conditionActions } from '../../../redux/common/condition/conditionState';
import { isCsvDisabled } from '../../../utils/authCheck.helper';

const Accordion = styled((props) => (
  <MuiAccordion defaultExpanded disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '10px',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionActions = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, .125)',
  borderWidth: '0px 1px 1px 1px',
  marginBottom: '15px',
  padding: '10px',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    textAlign: 'center',
  },
}));

const AccordionExpand = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: '#696969',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'end',
    marginTop: '10px',
  },
}));

const AccordionActionOne = styled(Box)(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down('md')]: {
    marginBottom: '10px',
  },
}));

export default function SearchBox(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [csvDisabled, setCsvDisabled] = React.useState(true);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);
  const [openCondition, setOpenCondition] = React.useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const isCreateSuccess = useSelector((state) => state.conditionStore.isCreateSuccess);
  const theme = useTheme();
  const dispatch = useDispatch();
  const usetData = useSelector((state) => state.loginStore.userInfo);

  const {
    onSearch, onClear, children, simpleAreaOne, simpleAreaOneText, downloadInfo, onDownload,
    propsExpanded, simpleAreaTwoText, simpleAreaTwo, condition, targetScreen, reSearch,
    downLoadClear,
  } = props;

  useEffect(() => {
    if (usetData && usetData.screenList) {
      setCsvDisabled(isCsvDisabled(usetData));
    }
  }, [usetData]);
  useEffect(() => {
    if (propsExpanded && expanded !== propsExpanded) {
      setExpanded(propsExpanded);
    }
  }, [propsExpanded]);
  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(conditionActions.resetFlg());
      setSuccessOpen(true);
      setOpenSave(false);
    }
  }, [isCreateSuccess]);

  const onDownloadClose = () => {
    setOpenDownload(false);
    downLoadClear();
  };

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleExpansion}>
        <AccordionSummary>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  fontWeight: 'bold', display: 'flex', alignItems: 'center', minWidth: '70px',
                }}
                mr={3}
              >
                検索条件
              </Box>
            </Grid>
            {simpleAreaOne && (
              <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    fontWeight: 'bold', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap',
                  }}
                  mr={1}
                >
                  {simpleAreaOneText}
                </Box>
                {simpleAreaOne}
              </Grid>
            )}
            {simpleAreaTwo && (
              <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    fontWeight: 'bold', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap',
                  }}
                  mr={1}
                >
                  {simpleAreaTwoText}
                </Box>
                {simpleAreaTwo}
              </Grid>
            )}
          </Grid>
          {children && (
            <AccordionExpand>
              <Button
                endIcon={expanded ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                onClick={handleExpansion}
              >
                詳細
              </Button>
            </AccordionExpand>
          )}
        </AccordionSummary>
        {children && (
          <AccordionDetails>
            {children}
          </AccordionDetails>
        )}

      </Accordion>
      <AccordionActions>
        <AccordionActionOne>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            color="inherit"
            size="small"
            onClick={() => { setSuccessOpen(false); setOpenSave(true); }}
            sx={{
              marginRight: '10px',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            検索条件を保存
          </Button>
          <Button variant="contained" color="inherit" size="small" onClick={() => { setSuccessOpen(false); setOpenCondition(true); }} startIcon={<FilterAltIcon />}>
            保存した条件
          </Button>
        </AccordionActionOne>
        <Box>
          {(onDownload && !csvDisabled) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
              onClick={() => setOpenDownload(true)}
              sx={{
                marginRight: '10px',
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            >
              CSVダウンロード
            </Button>
          )}
          <Button variant="contained" color="inherit" size="small" onClick={onClear} sx={{ marginRight: '10px' }}>
            クリア
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={onSearch}>
            検索
          </Button>
        </Box>
      </AccordionActions>
      <ConditionSaveDialog
        isOpen={openSave}
        onClose={() => setOpenSave(false)}
        condition={condition}
        targetScreen={targetScreen}
      />
      <ConditionListDialog
        isOpen={openCondition}
        onClose={() => setOpenCondition(false)}
        selectRow={(d) => { setOpenCondition(false); reSearch(JSON.parse(d)); }}
        targetScreen={targetScreen}
      />
      <SuccessSnackbar
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        message="保存しました。"
      />
      {onDownload && (
        <DownloadModal
          open={openDownload}
          onClose={onDownloadClose}
          complete={downloadInfo.done}
          csvDatas={downloadInfo}
          csvItem={downloadInfo.headerItems}
          detailCsvItem={downloadInfo.detailCsvItem}
          onDownload={onDownload}
        />
      )}
    </>
  );
}
