import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityListActions } from './opportunityListState';
import { getDatas, deleteDatas, saveData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY, payload);
    yield put(opportunityListActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCalendarData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CALENDAR, payload);
    yield put(opportunityListActions.getCalendarDataSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CSV, payload);
    yield put(opportunityListActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY, payload);
    yield put(opportunityListActions.deleteDataSuccess());
  } catch (_) { }
}

function* grantPoint({ payload }) {
  try {
    yield call(saveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_POINT, payload);
    yield put(opportunityListActions.grantPointSuccess());
  } catch (_) { }
}

function* createClaim({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OPPORTUNITY.OPPORTUNITY.OPPORTUNITY_CLAIM_FROM_LIST, payload);
    yield put(opportunityListActions.createClaimSuccess(result));
  } catch (_) { }
}

function* opportunityListSaga() {
  yield takeLatest('opportunityList/getDatas', getData);
  yield takeLatest('opportunityList/getCalendarData', getCalendarData);
  yield takeLatest('opportunityList/getCsvDatas', getCsvDatas);
  yield takeLatest('opportunityList/deleteData', deleteData);
  yield takeLatest('opportunityList/grantPoint', grantPoint);
  yield takeLatest('opportunityList/createClaim', createClaim);
}

export default opportunityListSaga;
