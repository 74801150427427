import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog as MuiDialog, DialogTitle } from '@mui/material';

export default function Dialog(props) {
  const {
    children,
    isOpen,
    onClose,
    maxWidth,
    fullWidth,
    title,
    isError,
  } = props;

  const theme = useTheme();

  const onLocalClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose(event);
    }
  };

  return (
    <MuiDialog
      onClose={onLocalClose}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEscapeKeyDown
    >
      <DialogTitle style={{
        backgroundColor: isError ? theme.palette.secondary.main : theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
      >
        {title}
      </DialogTitle>
      {children}
    </MuiDialog>
  );
}
