import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { deliveryAgencyActions } from './deliveryAgencyState';
import { getDatas, getDataById } from '../../../services/base.service';

function* getdeliveryAgencies({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.DELIVERY_AGENCY.DELIVERY_AGENCY, payload);
    yield put(deliveryAgencyActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SHOP.DELIVERY_AGENCY.DELIVERY_AGENCY_CSV, payload);
    yield put(deliveryAgencyActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SHOP.DELIVERY_AGENCY.DELIVERY_AGENCY_ID, payload);
    yield put(deliveryAgencyActions.getDataSuccess(result));
  } catch (_) { }
}

function* deliveryAgencySaga() {
  yield takeLatest('deliveryAgency/getDatas', getdeliveryAgencies);
  yield takeLatest('deliveryAgency/getCsvDatas', getCsvDatas);
  yield takeLatest('deliveryAgency/getData', getData);
}

export default deliveryAgencySaga;
