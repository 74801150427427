import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = (getDefaultMiddleware) => getDefaultMiddleware().concat(
  save(),
  sagaMiddleware,
);

const rootStore = configureStore({
  reducer: rootReducer,
  preloadedState: load(),
  middleware: middlewareEnhancer,
});

sagaMiddleware.run(rootSaga);

export default rootStore;
