import { createSlice } from '@reduxjs/toolkit';

export const mailTemplateSlice = createSlice({
  name: 'mailTemplate',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
    datas: {},
    folders: [],
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getFolders: (state) => {
      state.folders = [];
    },
    getFoldersSuccess: (state, action) => {
      state.folders = action.payload;
    },
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: (state) => {
      state.csvDatas = null;
    },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: () => { },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    resetFlgs: (state) => {
      state.isSaveSuccess = false;
    },
  },
});

export const mailTemplateActions = mailTemplateSlice.actions;

export default mailTemplateSlice.reducer;
