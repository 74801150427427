import React from 'react';
import { connect } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import { customerActions } from '../../../redux/opportunity/customer/customerState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import {
  FormTitle, DatePicker, SuccessSnackbar, FormSwitch,
} from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  searchCompanyName: '',
  searchCustomerName: '',
  searchDateOfEvent: '',
  searchMail: '',
  searchPhone: '',
  searchIsPointsNotGranted: false,
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'customerNumber', label: '会員番号' },
  { key: 'nameKana', label: 'フリガナ' },
  { key: 'name', label: '名前' },
  { key: 'nickname', label: 'ニックネーム' },
  { key: 'affiliationTypeCode', label: '所属タイプコード' },
  { key: 'affiliationTypeName', label: '所属タイプ名' },
  { key: 'companyName', label: '所属会社' },
  { key: 'branchName', label: '部署名' },
  { key: 'fixedPhone', label: '固定電話番号' },
  { key: 'mobilePhone', label: '携帯電話番号' },
  { key: 'email', label: 'メールアドレス' },
  { key: 'pointGranted', label: '会員登録ポイント付与' },
  { key: 'totalRemainPoint', label: '合計ポイント' },
  { key: 'stripeId', label: 'StripeID' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class CustomerList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'customerNumber', label: '会員番号', sortId: 'customer_number' },
      { id: 'name', label: 'お客様名', sortId: 'name' },
      { id: 'companyName', label: '会社名', sortId: 'company_name' },
      { id: 'phone', label: '電話番号', sortId: 'phone' },
      { id: 'email', label: 'メールアドレス', sortId: 'email' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.customerInfo.searchCondition) {
      searchCondition = props.customerInfo.searchCondition;
    }

    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      download: {
        headerItems: CSV_HEADER,
        fileName: '会員',
        done: false,
        datas: [],
      },
    };
    this.props.dispatch(customerActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerInfo.datas !== this.props.customerInfo.datas) {
      if (this.props.customerInfo.datas) {
        this.setDatas(this.props.customerInfo.datas);
      }
    }
    if (prevProps.customerInfo.csvDatas !== this.props.customerInfo.csvDatas) {
      if (this.props.customerInfo.csvDatas) {
        this.setCsvDatas(this.props.customerInfo.csvDatas);
      }
    }
    if (prevProps.customerInfo.isBulkPointGrantSuccess
      !== this.props.customerInfo.isBulkPointGrantSuccess) {
      if (this.props.customerInfo.isBulkPointGrantSuccess) {
        this.setSuccess('ポイント一斉付与しました。');
      }
    }
    if (prevProps.customerInfo.isDeleteSuccess
      !== this.props.customerInfo.isDeleteSuccess) {
      if (this.props.customerInfo.isDeleteSuccess) {
        this.setSuccess('削除しました。');
      }
    }
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  search = (searchCondition) => {
    this.props.dispatch(customerActions.setSearchCondition(searchCondition));
    this.props.dispatch(customerActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setCsvDatas = (datas) => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(customerActions.deleteData({ ids }));
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(customerActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onBulkPointGrant = (param) => {
    this.props.dispatch(customerActions.bulkPointGrant(param));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDeleteDisabled,
      isEditDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="お客様名"
          condition={searchCondition}
          targetScreen="customer"
          reSearch={this.reSearch}
          simpleAreaOne={(
            <TextField
              value={searchCondition.searchCustomerName}
              fullWidth
              name="searchCustomerName"
              onChange={this.onChange}
            />
          )}
          simpleAreaTwoText="会社名"
          simpleAreaTwo={(
            <TextField
              value={searchCondition.searchCompanyName}
              fullWidth
              name="searchCompanyName"
              onChange={this.onChange}
            />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="開催日" />
              <DatePicker value={searchCondition.searchDateOfEvent} fullWidth name="searchDateOfEvent" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="メールアドレス" />
              <TextField value={searchCondition.searchMail} fullWidth name="searchMail" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="電話番号" />
              <TextField value={searchCondition.searchPhone} fullWidth name="searchPhone" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="ポイント未付与のみ" />
              <FormSwitch checked={searchCondition.searchIsPointsNotGranted} label="ポイント未付与のみ" name="searchIsPointsNotGranted" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title="フリーワード" attention="会員番号、名前、フリガナ、ニックネーム、所属会社" />
              <TextField value={searchCondition.keyword} fullWidth name="keyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.CUSTOMER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="会員"
          isBulkPointGrant
          onBulkPointGrant={this.onBulkPointGrant}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
          pointTarget={3}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerInfo: state.customerStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(CustomerList);
