import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { conditionActions } from './conditionState';
import {
  getDatas, saveData, updateData, deleteDatas,
} from '../../../services/base.service';

function* getFilters({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.CONDITION.CONDITION, payload);
    yield put(conditionActions.getDatasSuccess(result));
  } catch (_) { }
}

function* insert({ payload }) {
  try {
    yield call(saveData, REST_API.CONDITION.CONDITION, payload);
    yield put(conditionActions.insertSuccess());
  } catch (_) { }
}

function* update({ payload }) {
  try {
    yield call(updateData, REST_API.CONDITION.CONDITION_ID, payload);
    yield put(conditionActions.updateSuccess());
  } catch (_) { }
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.CONDITION.CONDITION, payload);
    yield put(conditionActions.deleteSuccess());
  } catch (_) { }
}

function* conditionSaga() {
  yield takeLatest('condition/getDatas', getFilters);
  yield takeLatest('condition/insert', insert);
  yield takeLatest('condition/update', update);
  yield takeLatest('condition/delete', deleteData);
}

export default conditionSaga;
