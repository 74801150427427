import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, DialogActions, Box,
} from '@mui/material';
import Validation from '../validation';
import { shopDetailActions } from '../../../../redux/shop/shop/shopDetailState';
import DataGrid from '../../../../components/templates/DataGrid';
import NumberInput from '../../../../components/atoms/NumberInput';
import {
  FormErrorText, DialogContent, TimePicker,
} from '../../../../components/atoms/Base';
import RadioButtons from '../../../../components/atoms/RadioButtons';
import Dialog from '../../../../components/atoms/Dialog';
import SystemInfo from '../../../../components/templates/SystemInfo';

const baseForm = {
  chargeDays: '',
  chargeTime: '',
  chargeRate: '',
  shopId: '',
  updateAt: '',
  displayOrder: '',
};

export default function AddCancelDialog(props) {
  const [form, setForm] = React.useState(baseForm);
  const [errorMessages, setErrorMessages] = React.useState(baseForm);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.shopDetailStore.cancel);
  const {
    isOpen, onClose, shopId, id,
  } = props;

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  React.useEffect(() => {
    setErrorMessages(baseForm);
    if (isOpen) {
      setForm({
        ...baseForm, shopId, id, serviceTypeCode: 1,
      });
      if (id) {
        dispatch(shopDetailActions.getCancelData({ id }));
      }
    }
  }, [isOpen]);

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.cancelRateValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onClickSave = () => {
    if (beforeSaveCheck()) {
      return;
    }
    const tempForm = { ...form, shopId };
    dispatch(shopDetailActions.saveCancelData(tempForm));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const newform = {
      ...form,
      [name]: value,
    };
    setForm(newform);
    setErrorMessages({ ...errorMessages, [name]: Validation.cancelRateValidate(name, value) });
  };

  return (
    <Dialog isOpen={isOpen} title={`キャンセル規定の${id ? '編集' : '追加'}`} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <DataGrid title="サービスタイプ" isEdit isRequired>
          <RadioButtons
            name="serviceTypeCode"
            target="codes"
            parameter={{ codeCategory: 'service_type' }}
            value={form.serviceTypeCode}
            error={!!errorMessages.serviceTypeCode}
            onChange={onChange}
            fullWidth
            disableClearable
          />
          <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="チャージ日" isEdit isRequired>
          <NumberInput value={form.chargeDays} name="chargeDays" onChange={onChange} error={!!errorMessages.chargeDays} endAdornment="日前" />
          <FormErrorText>{errorMessages.chargeDays}</FormErrorText>
        </DataGrid>
        <DataGrid title="チャージ時間" isEdit isRequired>
          <TimePicker value={form.chargeTime} name="chargeTime" onChange={onChange} error={!!errorMessages.chargeTime} endAdornment="時以降" />
          <FormErrorText>{errorMessages.chargeTime}</FormErrorText>
        </DataGrid>

        <DataGrid title="キャンセル率" isEdit isRequired>
          <NumberInput value={form.chargeRate} name="chargeRate" onChange={onChange} error={!!errorMessages.chargeRate} max={100} endAdornment="%" />
          <FormErrorText>{errorMessages.chargeRate}</FormErrorText>
        </DataGrid>
        <DataGrid title="表示順" isEdit isRequired>
          <NumberInput value={form.displayOrder} fullWidth name="displayOrder" onChange={onChange} error={!!errorMessages.displayOrder} />
          <FormErrorText>{errorMessages.displayOrder}</FormErrorText>
        </DataGrid>
        {form.id && (
          <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
