import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import detailUrl from '../../../../../constants/frontUrls';
import DataTableWithMenu from '../../../../../components/templates/dataTableWithMenu/DataTableWithMenu';
import { reviewActions } from '../../../../../redux/pageSetting/review/reviewState';
import { isScreenEditDisabledFromUrl, isScreenDeleteDisabledFromUrl } from '../../../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  opportunityId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const headCells = [
  { id: 'title', label: 'タイトル', sortId: 'title' },
  { id: 'averageEvaluation', label: '評価', sortId: 'average_evaluation' },
  { id: 'commentExisted', label: 'コメント', sortId: 'comment_existed' },
  { id: 'imageExisted', label: '画像', sortId: 'image_existed' },
  { id: 'postDate', label: '投稿日', sortId: 'post_date' },
  { id: 'reviewStatusName', label: 'ステータス', sortId: 'review_status_name' },
];

export default function ReviewList(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [datas, setDatas] = useState({ content: [], totalElements: 0 });
  const [searchCondition, setSearchCondition] = useState(DEFAULT_SEARCH_CONDITION);
  const reviewDatas = useSelector((state) => state.reviewStore.datas);
  const isNotEdit = useSelector((state) => isScreenEditDisabledFromUrl(state.loginStore.userInfo, '/review'));
  const isNotDelete = useSelector((state) => isScreenDeleteDisabledFromUrl(state.loginStore.userInfo, '/review'));

  React.useEffect(() => {
    if (reviewDatas) {
      setDatas(reviewDatas);
    }
  }, [reviewDatas]);

  const search = (tmpCondition) => {
    dispatch(reviewActions.getDatas(tmpCondition));
  };

  React.useEffect(() => {
    if (id) {
      const tmpCondition = { ...DEFAULT_SEARCH_CONDITION, opportunityId: id };
      setSearchCondition(tmpCondition);
      search(tmpCondition);
    }
  }, []);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onDelete = (deleteId) => {
    dispatch(reviewActions.deleteData({ ids: [deleteId] }));
  };

  return (
    <DataTableWithMenu
      url={detailUrl.REVIEW_DETAIL}
      rows={datas.content || []}
      headCells={headCells}
      urlTargets={[{ url: detailUrl.REVIEW_DETAIL, label: 'title', id: 'id' }]}
      procName="口コミ"
      page={searchCondition.page}
      rowsPerPage={searchCondition.records}
      totalElements={datas.totalElements}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onDelete={onDelete}
      handleRequestSort={handleRequestSort}
      order={searchCondition.order}
      orderBy={searchCondition.orderBy}
      isNotEdit={isNotEdit}
      isNotDelete={isNotDelete}
      isNotAdd
    />
  );
}
