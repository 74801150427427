import React from 'react';
import { connect } from 'react-redux';
import {
  TextField, Button, Grid, Box,
} from '@mui/material';
import { mailTemplateActions } from '../../../redux/systemSetting/mailTemplate/mailTemplateState';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';
import {
  FormTitle, ClipboardCopy, RecordBox, withParams, FreeSoloBox,
  SuccessSnackbar, TextArea, FormErrorText,
} from '../../../components/atoms/Base';
import { isScreenEditDisabled } from '../../../utils/authCheck.helper';
import SystemInfo from '../../../components/templates/SystemInfo';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import characters from './replacement';
import detailUrl from '../../../constants/frontUrls';
import RecordHeader from '../../../components/templates/RecordHeader';

class MailTemplateDetail extends React.Component {
  static notRelpacementChar(target) {
    if (target === 4 || target === 5 || target === 6 || target === 11
      || target === 12 || target === 13
    ) {
      return true;
    }
    return false;
  }

  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id,
      emailUsedTypeCode: null,
      folder: '',
      title: '',
      defaultCc: '',
      subject: '',
      content: '',
      updatedAt: '',
    };

    let isSuccessOpen = false;
    if (props.mailTemplateInfo.isSaveSuccess) {
      props.dispatch(mailTemplateActions.resetFlgs());
      isSuccessOpen = true;
    }

    this.state = {
      isDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isSuccessOpen,
      form,
      inputForm: form,
      errorMessages: {
        folder: '',
        defaultCc: '',
        emailUsedTypeCode: '',
        subject: '',
        content: '',
        title: '',
      },
      folders: [],
      isEdit: !form.id,
    };
    props.dispatch(mailTemplateActions.getFolders());
    if (id) {
      props.dispatch(mailTemplateActions.getData({ id }));
    }
    this.characters = characters;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mailTemplateInfo.data !== this.props.mailTemplateInfo.data) {
      if (this.props.mailTemplateInfo.data) {
        this.setData(this.props.mailTemplateInfo.data);
      }
    }
    if (prevProps.mailTemplateInfo.folders !== this.props.mailTemplateInfo.folders) {
      if (this.props.mailTemplateInfo.folders) {
        this.setFolders(this.props.mailTemplateInfo.folders);
      }
    }
    if (prevProps.mailTemplateInfo.isSaveSuccess
      !== this.props.mailTemplateInfo.isSaveSuccess) {
      if (this.props.mailTemplateInfo.isSaveSuccess) {
        this.setSuccess(this.props.mailTemplateInfo.saveId);
      }
    }
  }

  setFolders = (folders) => {
    this.setState({ folders });
  };

  setData = (form) => {
    this.setState({ form, inputForm: form });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  setSuccess = (id) => {
    const { form } = this.state;
    if ((form.id && form.id !== id) || !form.id) {
      window.location.href = detailUrl.MAIL_TEMPLATE_DETAIL + id;
      return;
    }
    this.props.dispatch(mailTemplateActions.getData({ id }));
    this.props.dispatch(mailTemplateActions.getFolders());
    this.setState({
      isSuccessOpen: true, isEdit: false,
    });
    this.props.dispatch(mailTemplateActions.resetFlgs());
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;
    const changeTempForm = {
      ...inputForm,
      [name]: value,
    };
    this.setState({
      inputForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    const tempErrorMessages = {
      folder: '',
      emailUsedTypeCode: null,
      subject: '',
      content: '',
      defaultCc: '',
    };
    this.setState({ isEdit: true, errorMessages: tempErrorMessages });
    this.props.dispatch(mailTemplateActions.getData({ id: form.id }));
  };

  onCopy = () => {
    const { form } = this.state;
    const tempErrorMessages = {
      folder: '',
      emailUsedTypeCode: null,
      subject: '',
      content: '',
      defaultCc: '',
    };
    this.setState({
      inputForm: { ...form, id: null },
      isEdit: true,
      errorMessages: tempErrorMessages,
    });
  };

  onEditCancel = () => {
    const { form } = this.state;
    this.setState({ isEdit: false, inputForm: form });
  };

  onSave = () => {
    const { inputForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(inputForm, errorMessages)) {
      return;
    }
    this.props.dispatch(mailTemplateActions.saveData(inputForm));
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  render() {
    const {
      errorMessages,
      form,
      isSuccessOpen,
      isEdit,
      folders,
      isDisabled,
      inputForm,
    } = this.state;

    return (
      <>
        {(!isEdit && !isDisabled) && (
          <RecordHeader
            bottomSection={(
              <>
                <Button color="primary" variant="contained" size="small" onClick={this.onCopy}>コピーする</Button>
                <Button color="primary" variant="contained" size="small" onClick={this.onEdit}>編集</Button>
              </>
            )}
          />
        )}
        <RecordBox>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={
                !isEdit
                || MailTemplateDetail.notRelpacementChar(inputForm.emailUsedTypeCode) ? 12 : 8
              }
            >
              <DataGrid title="使用場所" isEdit={!inputForm.id} isRequired value={form.emailUsedTypeName}>
                <DataSelectList
                  name="emailUsedTypeCode"
                  target="codes"
                  disableClearable
                  error={!!errorMessages.emailUsedTypeCode}
                  parameter={{ codeCategory: 'email_used_type', codeValue: '1,2,3' }}
                  value={inputForm.emailUsedTypeCode}
                  onChange={this.onChange}
                />
                <FormErrorText>{errorMessages.emailUsedTypeCode}</FormErrorText>
              </DataGrid>

              <DataGrid title="フォルダ" isEdit={isEdit} isRequired value={form.folder}>
                <FreeSoloBox
                  name="folder"
                  options={folders}
                  value={inputForm.folder}
                  onChange={this.onChange}
                  disableClearable
                  error={!!errorMessages.folder}
                />
                <FormErrorText>{errorMessages.folder}</FormErrorText>
              </DataGrid>

              <DataGrid title="タイトル" isEdit={isEdit} isRequired value={form.title}>
                <TextField error={!!errorMessages.title} value={inputForm.title} fullWidth name="title" onChange={this.onChange} inputProps={{ maxLength: 50 }} />
                <FormErrorText>{errorMessages.title}</FormErrorText>
              </DataGrid>

              <DataGrid title="デフォルトCC" isEdit={isEdit} value={form.defaultCc}>
                <TextField error={!!errorMessages.defaultCc} value={inputForm.defaultCc} fullWidth name="defaultCc" helperText="複数入力する場合はカンマ区切りで入力してください。" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
                <FormErrorText>{errorMessages.defaultCc}</FormErrorText>
              </DataGrid>

              <DataGrid title="件名" isEdit={isEdit} isRequired value={form.subject}>
                <TextField error={!!errorMessages.subject} value={inputForm.subject} fullWidth name="subject" onChange={this.onChange} inputProps={{ maxLength: 100 }} />
                <FormErrorText>{errorMessages.subject}</FormErrorText>
              </DataGrid>
              <DataGrid title="本文" isEdit={isEdit} isRequired value={form.content}>
                <TextArea error={!!errorMessages.content} value={inputForm.content} name="content" onChange={this.onChange} />
                <FormErrorText>{errorMessages.content}</FormErrorText>
              </DataGrid>
            </Grid>
            {isEdit && !MailTemplateDetail.notRelpacementChar(inputForm.emailUsedTypeCode) && (
              <Grid item xs={12} md={4} mb={2}>
                <Box>
                  <FormTitle title="埋込文字" />
                </Box>
                {this.characters.map((item) => {
                  if (item.emailUsedTypeCode === inputForm.emailUsedTypeCode) {
                    return item.values.map((valueItem) => (
                      <Box key={valueItem.value}>
                        <ClipboardCopy value={valueItem.value} />
                        {`${`${valueItem.label}     ${valueItem.value}`}`}
                      </Box>
                    ));
                  }
                  return null;
                })}
              </Grid>
            )}
          </Grid>
          {isEdit && (
            <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
              {form.id && (
                <Button color="inherit" variant="contained" size="small" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
              )}
              <Button color="primary" variant="contained" size="small" onClick={this.onSave}>保存</Button>
            </Box>
          )}
          {!isEdit && form.id && (
            <SystemInfo data={form} />
          )}
        </RecordBox>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="保存しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mailTemplateInfo: state.mailTemplateStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(MailTemplateDetail));
