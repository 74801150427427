import React, { useEffect } from 'react';
import {
  Box, DialogActions, Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../atoms/Dialog';
import {
  FormTitle, FormErrorText, DialogContent, TextArea,
} from '../../atoms/Base';
import Dropzone from '../../atoms/Dropzone';

export default function FileUploadDialog(props) {
  const [data, setData] = React.useState({ introduction: '' });
  const [fileSet, setFileSet] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const {
    isOpen, onClose, onClick, newId, isNeedIntroduction,
  } = props;

  useEffect(() => {
    if (isOpen) {
      setData({ introduction: '' });
      setFileSet(fileSet);
      setErrorMsg('');
    }
  }, [isOpen]);

  const onUpload = (imgData) => {
    setData({ ...data, ...imgData });
    setFileSet(true);
    setErrorMsg('');
  };

  return (
    <Dialog title="ファイルアップロード" isOpen={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box>
          <Dropzone onChange={onUpload} />
          <FormErrorText>{errorMsg}</FormErrorText>
        </Box>
        {isNeedIntroduction && (
          <Box mt={2}>
            <FormTitle title="説明" />
            <TextArea
              name="introduction"
              value={data.introduction}
              onChange={(e) => setData({ ...data, introduction: e.target.value })}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton onClick={() => {
          if (!fileSet) {
            setErrorMsg('ファイルをアップロードして下さい');
            return;
          }
          onClick({ ...data, id: newId });
        }}
        >
          設定
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
