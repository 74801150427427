import { createSlice } from '@reduxjs/toolkit';

export const customerViewFavoriteSlice = createSlice({
  name: 'customerViewFavorite',
  initialState: {
    favorites: {},
  },
  reducers: {
    getDatasByFavorite: () => { },
    getDatasByFavoriteSuccess: (state, action) => {
      state.favorites = action.payload;
    },
    getDatasByViewHistory: () => { },
    getDatasByViewHistorySuccess: (state, action) => {
      state.viewHistories = action.payload;
    },
  },
});

export const customerViewFavoriteActions = customerViewFavoriteSlice.actions;

export default customerViewFavoriteSlice.reducer;
