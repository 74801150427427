import React, { useState } from 'react';
import {
  TextField, Box, Grid, Button,
} from '@mui/material';
import DataGrid from '../DataGrid';
import SelectMailTemplate from './SelectMailTemplate';
import { FormErrorText, FormTitle, TextArea } from '../../atoms/Base';
import { replace } from '../../../utils/common.helper';

export default function Mailer(props) {
  const {
    data, onChange, onSelectMailTemplate, errorMessages, objectData, isNotReplacePassword,
  } = props;
  const [isOpen, setOpen] = useState(false);

  const onSelect = (selectMailTemplate) => {
    setOpen(false);

    const tempMailTemp = objectData ? {
      ...selectMailTemplate,
      subject: replace(objectData, selectMailTemplate.subject),
      body: replace(objectData, selectMailTemplate.body, isNotReplacePassword),
    } : selectMailTemplate;

    onSelectMailTemplate(tempMailTemp);
  };

  return (
    <>
      <SelectMailTemplate
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSelectMailTemplate={onSelect}
      />
      <Box mb={2}>
        <DataGrid title="TO" isEdit isRequired isNonFlex>
          <TextField
            name="to"
            value={data.to}
            fullWidth
            helperText="複数入力する場合はカンマ区切りで入力してください。"
            error={!!errorMessages.to}
            onChange={onChange}
            autoComplete="off"
          />
          <FormErrorText>{errorMessages.to}</FormErrorText>
        </DataGrid>
      </Box>
      <Box mb={2}>
        <DataGrid title="CC" isEdit>
          <TextField
            name="cc"
            value={data.cc}
            fullWidth
            helperText="複数入力する場合はカンマ区切りで入力してください。"
            error={!!errorMessages.cc}
            onChange={onChange}
            autoComplete="off"
          />
          <FormErrorText>{errorMessages.cc}</FormErrorText>
        </DataGrid>
      </Box>
      <Box mb={2}>
        <DataGrid title="件名" isEdit isRequired isNonFlex>
          <TextField
            name="subject"
            value={data.subject}
            fullWidth
            autoComplete="off"
            inputProps={{ maxLength: 255 }}
            error={!!errorMessages.subject}
            onChange={onChange}
          />
          <FormErrorText>{errorMessages.subject}</FormErrorText>
        </DataGrid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormTitle title="本文" isRequired isNonFlex />
          </Grid>
          <Grid item xs={9} md={8}>
            <Button size="small" onClick={() => setOpen(true)}>テンプレート選択</Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <TextArea
            name="body"
            value={data.body}
            error={!!errorMessages.body}
            onChange={onChange}
            minRows={15}
          />
          <FormErrorText>{errorMessages.body}</FormErrorText>
        </Box>
      </Box>
    </>
  );
}
