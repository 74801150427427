import React, { useRef, useState, useEffect } from 'react';
import {
  CircularProgress, Box, Button,
} from '@mui/material';

export default function FileViewer(props) {
  const { url, onError } = props;
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const style = {
    width: '100%',
    height: '700px',
    border: 'none',
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    setLoading(true);
    const handleLoad = () => {
      try {
        setLoading(false);
      } catch (error) {}
    };

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }

    // クリーンアップ関数
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, [url]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url.downloadUrl;
    link.download = url.fileName;
    link.click();
  };

  return (
    <div>
      {loading && (
        <Box textAlign="center">
          <Box mb={1}>Viewを読み込んでいます...</Box>
          <CircularProgress />
        </Box>
      )}
      <Box mb={1} textAlign="center">
        <Button variant="contained" color="primary" onClick={handleDownload}>
          ダウンロード
        </Button>
      </Box>
      <Box textAlign="center"><iframe ref={iframeRef} src={url.previewUrl} onError={onError} style={style} title="viewer" /></Box>
    </div>
  );
}
