import { createSlice } from '@reduxjs/toolkit';

export const orderListSlice = createSlice({
  name: 'order',
  initialState: {
    isDeleteSuccess: false,
    isSaveSuccess: false,
    isSendTextSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getCsvDatas: () => { },
    getCsvDatasSuccess: (state, action) => {
      state.csvDatas = action.payload;
    },
    getPdfDatas: (state) => {
      state.isGetPdfData = false;
    },
    getPdfDatasSuccess: (state, action) => {
      state.pdfDatas = action.payload;
      state.isGetPdfData = true;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.id = action.payload;
    },
    sendText: (state) => {
      state.isSendTextSuccess = false;
    },
    sendTextSuccess: (state) => {
      state.isSendTextSuccess = true;
    },
    resetFlgs: (state) => {
      state.isDeleteSuccess = false;
      state.isSaveSuccess = false;
      state.isSendPurchaseOrderFileSuccess = false;
      state.isGetPdfData = false;
    },
  },
});

export const orderActions = orderListSlice.actions;

export default orderListSlice.reducer;
