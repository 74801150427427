import React from 'react';
import {
  TextField, DialogActions, Button, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FormErrorText, DialogContent } from '../../../components/atoms/Base';
import Dialog from '../../../components/atoms/Dialog';
import Validation from './validation';
import { deliveryAgencyUserActions } from '../../../redux/shop/deliveryAgencyUser/deliveryAgencyUserState';
import Mailer from '../../../components/templates/mailer/Mailer';
import DataGrid from '../../../components/templates/DataGrid';
import SystemInfo from '../../../components/templates/SystemInfo';

const DEFAULT_FORM = {
  id: '',
  lastName: '',
  firstName: '',
  loginId: '',
  email: '',
  isDelete: false,
};

const DEFAULT_EMAIL_FORM = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

const DEFAULT_ERROR_MESSAGE = {
  lastName: '',
  loginId: '',
  email: '',
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function DeliveryAgencyUserDialog(props) {
  const {
    isOpen,
    onClose,
    id,
    deliveryAgencyId,
    deliveryAgencyName,
  } = props;
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState(DEFAULT_ERROR_MESSAGE);
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [emailForm, setEmailForm] = React.useState(DEFAULT_EMAIL_FORM);
  const [isNext, setNext] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [objectData, setObjectData] = React.useState(null);
  const deliveryAgencyUser = useSelector((state) => state.deliveryAgencyUserStore.data);

  React.useEffect(() => {
    if (isOpen) {
      setForm(DEFAULT_FORM);
      setEmailForm(DEFAULT_EMAIL_FORM);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      setNext(false);
    }
    if (isOpen && id) {
      dispatch(deliveryAgencyUserActions.getData({ id }));
      setLoading(true);
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (deliveryAgencyUser) {
      setForm(deliveryAgencyUser);
      setLoading(false);
    }
  }, [deliveryAgencyUser]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrorMessage({
      ...errorMessage,
      [name]: Validation.formValidate(name, value),
    });
  };

  const onSave = () => {
    let tmpMessage = errorMessage;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const message = Validation.formValidate(key, form[key]);
      if (message) {
        isError = true;
      }
      tmpMessage = {
        ...tmpMessage,
        [key]: message,
      };
    });
    if (isError) {
      setErrorMessage(tmpMessage);
      return;
    }
    dispatch(deliveryAgencyUserActions.update({ ...form, deliveryAgencyId }));
  };

  const toNext = () => {
    let tmpMessage = errorMessage;
    let isError = false;
    Object.keys(form).forEach((key) => {
      const message = Validation.formValidate(key, form[key]);
      if (message) {
        isError = true;
      }
      tmpMessage = {
        ...tmpMessage,
        [key]: message,
      };
    });
    if (isError) {
      setErrorMessage(tmpMessage);
      return;
    }
    setEmailForm({ ...emailForm, to: form.email });
    setObjectData({ ...form, deliveryAgencyName });
    setNext(true);
  };

  const onChangeMailer = (event) => {
    const { name, value } = event.target;
    setErrorMessage({
      ...errorMessage,
      [name]: Validation.formValidate(name, value),
    });
    setEmailForm({
      ...emailForm,
      [name]: value,
    });
  };

  const onSelectMailTemplate = (data) => {
    setEmailForm({
      ...emailForm,
      cc: data.cc,
      subject: data.subject,
      body: data.body,
    });
  };

  const onSend = () => {
    let tmpMessage = errorMessage;
    let isError = false;

    Object.keys(emailForm).forEach((key) => {
      const message = Validation.formValidate(key, emailForm[key]);
      if (message) {
        isError = true;
      }
      tmpMessage = {
        ...tmpMessage,
        [key]: message,
      };
    });
    if (isError) {
      setErrorMessage(tmpMessage);
      return;
    }

    dispatch(deliveryAgencyUserActions.insert({
      ...form,
      deliveryAgencyId,
      sendMailRequest: emailForm,
    }));
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={`担当者の${form.id ? '編集' : '追加'}`}
    >
      <DialogContent>
        {!isNext && (
          <>
            <DataGrid title="姓" isEdit isRequired>
              <TextField autoComplete="off" value={form.lastName} name="lastName" fullWidth error={!!errorMessage.lastName} onChange={onChange} inputProps={{ maxLength: 255 }} />
              <FormErrorText>{errorMessage.lastName}</FormErrorText>
            </DataGrid>
            <DataGrid title="名" isEdit>
              <TextField autoComplete="off" value={form.firstName} fullWidth name="firstName" onChange={onChange} inputProps={{ maxLength: 255 }} />
            </DataGrid>
            <DataGrid title="メールアドレス" isEdit isRequired>
              <TextField autoComplete="off" value={form.email} fullWidth name="email" error={!!errorMessage.email} onChange={onChange} inputProps={{ maxLength: 255 }} />
              <FormErrorText>{errorMessage.email}</FormErrorText>
            </DataGrid>
            {form.id && (
              <Box mt={2} fontSize={14}><SystemInfo data={form} /></Box>
            )}
          </>
        )}
        {isNext && (
          <Mailer
            data={emailForm}
            onChange={onChangeMailer}
            onSelectMailTemplate={onSelectMailTemplate}
            objectData={objectData}
            errorMessages={errorMessage}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        {!form.id && !isNext && (
          <Button onClick={toNext}>次へ</Button>
        )}
        {!form.id && isNext && (
          <Button onClick={onSend}>保存して送信</Button>
        )}
        {form.id && (
          <Button disabled={loading} onClick={onSave}>保存</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
