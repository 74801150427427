import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { pointRuleActions } from '../../../redux/systemSetting/pointRule/pointRuleState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import {
  FormTitle, SuccessSnackbar, DatePicker, FormSwitch,
} from '../../../components/atoms/Base';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';

const DEFAULT_SEARCH_CONDITION = {
  targetStartDate: '',
  targetEndDate: '',
  opened: false,
  targetCodes: [],
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'pointTargetCode', label: 'ポイント対象コード' },
  { key: 'pointTargetName', label: 'ポイント対象名' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'evaluationed', label: '評価あり' },
  { key: 'wordCountStart', label: '文字数開始' },
  { key: 'wordCountEnd', label: '文字数終了' },
  { key: 'imageRegisted', label: '画像登録あり' },
  { key: 'point', label: 'ポイント数' },
  { key: 'targetStartDate', label: '付与対象開始日' },
  { key: 'targetEndDate', label: '付与対象終了日' },
  { key: 'periodDays', label: '有効期間' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class PointRuleList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '管理番号', sortId: 'name' },
      { id: 'targetName', label: '対象', sortId: 'target_name' },
      { id: 'targetDate', label: 'ポイント付与期間', sortId: 'target_date' },
      { id: 'point', label: 'ポイント数', sortId: 'point' },
      { id: 'periodDays', label: '有効期間', sortId: 'period_days' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.pointRuleInfo.searchCondition) {
      searchCondition = props.pointRuleInfo.searchCondition;
    }
    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      download: {
        headerItems: CSV_HEADER,
        fileName: 'ポイントルール',
        done: false,
        datas: [],
      },
    };
    this.props.dispatch(pointRuleActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pointRuleInfo.datas !== this.props.pointRuleInfo.datas) {
      if (this.props.pointRuleInfo.datas) {
        this.setDatas(this.props.pointRuleInfo.datas);
      }
    }
    if (prevProps.pointRuleInfo.csvDatas !== this.props.pointRuleInfo.csvDatas) {
      if (this.props.pointRuleInfo.csvDatas) {
        this.setCsvDatas(this.props.pointRuleInfo.csvDatas);
      }
    }
    if (prevProps.pointRuleInfo.isDeleteSuccess
      !== this.props.pointRuleInfo.isDeleteSuccess) {
      if (this.props.pointRuleInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(pointRuleActions.setSearchCondition(searchCondition));
    this.props.dispatch(pointRuleActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(pointRuleActions.deleteData({ ids }));
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(pointRuleActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="point-rule"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="対象"
          simpleAreaOne={(
            <DataMultiSelectList
              name="targetCodes"
              target="codes"
              parameter={{ codeCategory: 'point_target' }}
              values={searchCondition.targetCodes}
              onChange={this.onChange}
              fullWidth
            />
          )}
          simpleAreaTwoText="オープン"
          simpleAreaTwo={(
            <FormSwitch
              name="opened"
              checked={searchCondition.opened}
              label="オープン中のみ"
              onChange={this.onChange}
            />
          )}
        >
          <Box>
            <FormTitle title="公開期間" />
            <Box display="flex" alignItems="center">
              <Box>
                <DatePicker value={searchCondition.targetStartDate} name="targetStartDate" onChange={this.onChange} />
              </Box>
              <Box px={2}>～</Box>
              <Box>
                <DatePicker value={searchCondition.targetEndDate} name="targetEndDate" onChange={this.onChange} />
              </Box>
            </Box>
          </Box>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.POINT_RULE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="ポイント"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pointRuleInfo: state.pointRuleStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(PointRuleList);
