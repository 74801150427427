import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import { roleListActions } from '../../../redux/systemSetting/role/roleListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import detailUrl from '../../../constants/frontUrls';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import RoleDetailDialog from './RoleDetailDialog';

const DEFAULT_SEARCH_CONDITION = {
  roleName: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: '権限名' },
  { key: 'permissionCsv', label: 'CSVダウンロード' },
  { key: 'userName', label: '付与者名' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

class RoleList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '権限名', sortId: 'name' },
      { id: 'permissionCsv', label: 'CSVダウンロード', sortId: 'permission_csv' },
      { id: 'grantNumber', label: '付与人数', sortId: 'grant_number' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.roleInfo.searchCondition) {
      searchCondition = props.roleInfo.searchCondition;
    }
    this.state = {
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      datas: {},
      download: {
        headerItems: CSV_HEADER,
        fileName: '権限',
        done: false,
        datas: [],
      },
      isDetailOpen: false,
      searchCondition,
      isSuccessOpen: false,
    };
    this.props.dispatch(roleListActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roleInfo.datas !== this.props.roleInfo.datas) {
      if (this.props.roleInfo.datas) {
        this.setDatas(this.props.roleInfo.datas);
      }
    }
    if (prevProps.roleInfo.csvDatas !== this.props.roleInfo.csvDatas) {
      if (this.props.roleInfo.csvDatas) {
        this.setCsvDatas(this.props.roleInfo.csvDatas);
      }
    }
    if (prevProps.roleInfo.isDeleteSuccess
      !== this.props.roleInfo.isDeleteSuccess) {
      if (this.props.roleInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  search = (searchCondition) => {
    this.props.dispatch(roleListActions.setSearchCondition(searchCondition));
    this.props.dispatch(roleListActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(roleListActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(roleListActions.deleteData({ ids }));
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(roleListActions.getCsvDatas(searchCondition));
  };

  render() {
    const {
      datas,
      download,
      searchCondition,
      isSuccessOpen,
      isEditDisabled,
      isDeleteDisabled,
      isDetailOpen,
    } = this.state;

    return (
      <>
        <SearchBox
          targetScreen="role"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          onSearch={this.onSearch}
          onClear={this.onClear}
          simpleAreaOneText="権限名"
          simpleAreaOne={(
            <TextField autoComplete="off" value={searchCondition.roleName} fullWidth name="roleName" onChange={this.onChange} />
          )}
        />
        <DataTableWithCheckbox
          url={detailUrl.ROLE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="権限"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
          onAddOpen={() => this.setState({ isDetailOpen: true })}
        />

        <RoleDetailDialog
          open={isDetailOpen}
          onClose={() => this.setState({ isDetailOpen: false })}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message="削除しました。"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  roleInfo: state.roleListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(RoleList);
