import { createSlice } from '@reduxjs/toolkit';

export const roleDetailSlice = createSlice({
  name: 'roleDetail',
  initialState: {
    data: {},
    isUpdateScreen: false,
    isSaveSuccess: false,
  },
  reducers: {
    getScreens: () => {},
    getScreensSuccess: (state, action) => {
      state.screens = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateScreens: (state) => {
      state.isUpdateScreen = false;
    },
    updateScreensSuccess: (state) => {
      state.isUpdateScreen = true;
    },
    saveRole: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveRoleSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    copy: (state) => {
      state.isCopySuccess = false;
      state.copyId = null;
    },
    copySuccess: (state, action) => {
      state.isCopySuccess = true;
      state.copyId = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
      state.isCopySuccess = false;
    },
  },
});

export const roleDetailActions = roleDetailSlice.actions;

export default roleDetailSlice.reducer;
