import {
  call,
  put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { claimAttachActions } from './claimAttachState';
import {
  getDatas as callGetData,
  deleteDatas as callDeleteDatas,
  saveData as callSaveData,
} from '../../../services/base.service';

function* getRelationDatas({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM_ATTACH.ORDER_RELATION, payload);
    yield put(claimAttachActions.getRelationDatasSuccess(result));
  } catch (_) { }
}

function* getRelationData({ payload }) {
  try {
    const result = yield call(callGetData, REST_API.OPPORTUNITY.CLAIM_ATTACH.ORDER_RELATION_ID, payload);
    yield put(claimAttachActions.getRelationDataSuccess(result));
  } catch (_) { }
}

function* saveRelationData({ payload }) {
  try {
    const result = yield call(callSaveData, REST_API.OPPORTUNITY.CLAIM_ATTACH.ORDER_RELATION, payload);
    yield put(claimAttachActions.saveRelationDataSuccess(result));
  } catch (_) { }
}

function* deleteRelationData({ payload }) {
  try {
    const result = yield call(callDeleteDatas, REST_API.OPPORTUNITY.CLAIM_ATTACH.ORDER_RELATION, payload);
    yield put(claimAttachActions.deleteRelationDataSuccess(result));
  } catch (_) { }
}

function* claimAttachSaga() {
  yield takeLatest('claimAttach/getRelationDatas', getRelationDatas);
  yield takeLatest('claimAttach/getRelationData', getRelationData);
  yield takeLatest('claimAttach/saveRelationData', saveRelationData);
  yield takeLatest('claimAttach/deleteRelationData', deleteRelationData);
}

export default claimAttachSaga;
