import CommonValidation from '../../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'content':
      case 'opportunityStatusCode':
        return CommonValidation.requiredValue(value);
      case 'heldDate':
      case 'complianceDt':
      case 'eventDate':
      case 'receptionDate':
        return CommonValidation.nonRequiredDate(value);
      case 'customerPhone':
        return CommonValidation.nonRequiredPhoneNumber(value);
      case 'cateringCleanUpTime':
      case 'cateringEventEndTime':
      case 'cateringPreparationrTime':
        return CommonValidation.nonRequiredTime(value);
      default:
        return '';
    }
  };

  static cancelRateValidate = (type, value) => {
    switch (type) {
      case 'chargeDays':
        return CommonValidation.requiredNotMinusNumber(value);
      case 'chargeRate':
        return CommonValidation.requiredNumber(value);
      case 'chargeTime':
        return CommonValidation.requiredTime(value);
      default:
        return '';
    }
  };

  static fileValidate = (type, value) => {
    switch (type) {
      case 'file':
        return CommonValidation.requiredArray(value);
      case 'title':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };
}

export default Validation;
