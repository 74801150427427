import React from 'react';
import {
  Box, IconButton, Drawer, Divider, Badge, styled,
  List,
  ListItemButton,
  ListItem,
} from '@mui/material';
import {
  ChevronRight as ChevronRightIcon, Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  collection, query,
  where, updateDoc,
  onSnapshot, doc,
  Timestamp, orderBy,
} from 'firebase/firestore';
import db from '../../firebase';
import detailUrl from '../../constants/frontUrls';

export default function AlertButton() {
  const navigate = useNavigate();
  const [isOpen, setOpen] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const currentUser = useSelector((state) => state.loginStore.userInfo);

  React.useEffect(() => {
    if (!currentUser) {
      return;
    }

    const userId = currentUser.user.id;

    const fetchMessages = async () => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1);
      const timestamp = Timestamp.fromDate(currentDate);
      const q = query(collection(db, 'messagesToAdmin'), where('salesUserId', '==', userId), where('date', '>=', timestamp), orderBy('date', 'desc'));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const newMessages = [];
        querySnapshot.docs.forEach(async (docData) => {
          const message = docData.data();

          newMessages.push({
            ...message,
            id: docData.id,
          });
        });
        setMessages(newMessages);
      });
      return unsubscribe;
    };

    fetchMessages();
  }, [currentUser]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onClick = async (_, data) => {
    const messageNoticeRef = doc(db, 'messagesToAdmin', data.id);
    await updateDoc(messageNoticeRef, {
      read: true,
    });
    setOpen(false);
    if (data.type === 'order') {
      navigate(detailUrl.ORDER_DETAIL + data.targetId);
    }
    if (data.type === 'contact') {
      navigate(detailUrl.CONTACT_DETAIL + data.targetId);
    }
    if (data.type === 'opportunity') {
      navigate(detailUrl.OPPORTUNITY_DETAIL + data.targetId);
    }
  };

  const drawerWidth = 400;
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <Box>
      <IconButton
        color="inherit"
        aria-label="open alert"
        onClick={handleDrawerOpen}
        edge="start"
        size="large"
        sx={{ ...(isOpen && { display: 'none' }) }}
      >
        <Badge badgeContent={messages.filter((value) => !value.read).length} color="secondary">
          <NotificationsIcon fontSize="medium" />
        </Badge>
      </IconButton>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box style={{
          marginTop: '12px',
          textAlign: 'center',
          fontSize: '18px',
        }}
        >
          {messages.length <= 0 && (
          <Box p={3} display="flex" justifyContent="center"> 通知はありません。</Box>
          )}
          <List>
            {messages.map((msg, index) => (
              <React.Fragment key={msg.id}>
                {index !== 0 && (
                <Divider />
                )}
                <ListItemButton
                  dense
                  onClick={(e) => onClick(e, msg)}
                  sx={{ background: msg.read ? '#ccc' : '' }}
                >
                  <ListItem sx={{ display: 'block' }}>
                    <Box>{msg.message}</Box>
                    <Box mt={1} sx={{ fontSize: '15px', color: '#777' }} textAlign="right">{msg.dateString}</Box>
                  </ListItem>
                </ListItemButton>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
