import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TextField, DialogActions, Button,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../../components/atoms/Dialog';
import { FormTitle, FormSwitch, DialogContent } from '../../../components/atoms/Base';
import { roleDetailActions } from '../../../redux/systemSetting/role/roleDetailState';
import detailUrl from '../../../constants/frontUrls';

export default function RoleDetailDialog(props) {
  const [btnLoading, setBtnLoading] = useState(false);
  const [info, setInfo] = useState({
    id: null,
    name: '',
    permissionCsv: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSaveSuccess = useSelector((state) => state.roleDetailStore.isSaveSuccess);
  const saveId = useSelector((state) => state.roleDetailStore.saveId);

  const {
    open, id, role, onClose, onSaveClose,
  } = props;

  useEffect(() => {
    if (isSaveSuccess) {
      if (!info.id) {
        navigate(detailUrl.ROLE_DETAIL + saveId);
      } else {
        onSaveClose();
      }
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (open) {
      let temp = {
        id: id || null,
        name: '',
        permissionCsv: false,
      };
      if (id) {
        temp = {
          id,
          ...role,
        };
      }
      setInfo(temp);
      setBtnLoading(false);
    }
  }, [open]);

  const handleSave = () => {
    dispatch(roleDetailActions.saveRole(info));
  };

  const onChange = (event) => {
    const { value, name } = event.target;

    setInfo({ ...info, [name]: value });
  };

  return (
    <Dialog isOpen={open} fullWidth maxWidth="sm" onClose={onClose} title={info.id ? '権限の変更' : '権限の追加'}>
      <DialogContent>
        <Box>
          <FormTitle title="名前" isRequired isNonFlex />
          <TextField
            id="auth-name"
            name="name"
            autoComplete="off"
            size="small"
            variant="outlined"
            value={info.name}
            onChange={onChange}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="CSVダウンロード" />
          <FormSwitch checked={info.permissionCsv} label="許可する" name="permissionCsv" onChange={onChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={handleSave}
          loading={btnLoading}
          disabled={!info.name}
        >
          保存する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
