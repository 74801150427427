import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { accountActions } from '../../../redux/shop/account/accountState';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';

const DEFAULT_SEARCH_CONDITION = {
  name: '',
  phone: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};
const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'accountNumber', label: '契約先コード' },
  { key: 'name', label: '加盟企業名' },
  { key: 'phone', label: '電話番号' },
  { key: 'salesforceId', label: 'SalesforceID' },
];

class AccountList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'accountNumber', label: '契約先コード', sortId: 'account_number' },
      { id: 'name', label: '企業名', sortId: 'name' },
      { id: 'phone', label: '電話番号', sortId: 'phone' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.accountInfo.searchCondition) {
      searchCondition = props.accountInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      download: {
        headerItems: CSV_HEADER,
        fileName: '加盟企業',
        done: false,
        datas: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountInfo.datas !== this.props.accountInfo.datas) {
      if (this.props.accountInfo.datas) {
        this.setDatas(this.props.accountInfo.datas);
      }
    }
    if (prevProps.accountInfo.csvDatas !== this.props.accountInfo.csvDatas) {
      if (this.props.accountInfo.csvDatas) {
        this.setCsvDatas(this.props.accountInfo.csvDatas);
      }
    }
  }

  setCsvDatas(datas) {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  search = (searchCondition) => {
    this.props.dispatch(accountActions.setSearchCondition(searchCondition));
    this.props.dispatch(accountActions.getDatas(searchCondition));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(accountActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  onDownload = () => {
    const { searchCondition } = this.state;
    this.props.dispatch(accountActions.getCsvDatas(searchCondition));
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen="account"
          reSearch={this.reSearch}
          condition={searchCondition}
          downloadInfo={download}
          downLoadClear={this.downLoadClear}
          onDownload={this.onDownload}
          simpleAreaOneText="企業名"
          simpleAreaOne={(
            <TextField fullWidth size="small" value={searchCondition.name} name="name" onChange={this.onChange} />
          )}
          simpleAreaTwoText="電話番号"
          simpleAreaTwo={(
            <TextField fullWidth value={searchCondition.phone} name="phone" onChange={this.onChange} />
          )}
        />
        <DataTableWithCheckbox
          url={detailUrl.ACCOUNT_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="加盟企業"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd
          isNotDelete
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  accountInfo: state.accountStore,
});

export default connect(mapStateToProps)(AccountList);
