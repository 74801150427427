import React from 'react';
import {
  TableCell, TableContainer, Table, TableBody, TableRow, Button, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FireIconTitle } from '../../atoms/Base';

export default function SmartPhoneTable(props) {
  const {
    row, headCells, url, isNotDetail,
  } = props;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(url + row.id);
  };

  return (
    <TableCell sx={{ padding: 1 }}>
      {!isNotDetail && (
        <Box textAlign="right"><Button variant="outlined" size="small" onClick={onClick}>詳細へ</Button></Box>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            {headCells.map((headCell) => (
              <TableRow
                key={headCell.id}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell sx={{ padding: '5px', width: '40%' }}>{`${headCell.label}`}</TableCell>
                <TableCell
                  sx={{
                    padding: '5px', wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal',
                  }}
                >
                  {(() => {
                    if (headCell.numeric && typeof row[headCell.id] === 'number' && !Number.isNaN(row[headCell.id])) {
                      return row[headCell.id].toLocaleString();
                    }
                    if (typeof row[headCell.id] === 'string' && row[headCell.id].length > 50) {
                      return `${row[headCell.id].substring(0, 50)}...`;
                    }
                    return row[headCell.id];
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FireIconTitle checkDateTime={row.fireComplianceDt} />
    </TableCell>
  );
}
