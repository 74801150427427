import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PdfDownloadButton } from '../../../components/atoms/Base';
import PurchaseOrderFile from '../report/PurchaseOrderFile';
import { orderActions } from '../../../redux/opportunity/order/orderListState';

export default function PurchaseOrderDownloadButton(props) {
  const {
    id,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isReadyDownload, setReadyDownload] = React.useState(false);
  const formData = useSelector((state) => state.orderStore.pdfDatas);
  const isGetData = useSelector((state) => state.orderStore.isGetPdfData);
  const [pdfData, setPdfData] = React.useState({ header: {} });
  const [isClickedOnDownload, setClickedOnDownload] = React.useState(false);

  useEffect(() => {
    if (isGetData && isClickedOnDownload) {
      setPdfData(formData);
      setClickedOnDownload(false);
      setLoading(false);
      setReadyDownload(true);
      dispatch(orderActions.resetFlgs());
    }
  }, [isGetData]);

  const onComfimationDownload = () => {
    dispatch(orderActions.getPdfDatas({ id }));
    setPdfData({ header: {} });
    setLoading(true);
    setReadyDownload(false);
    setClickedOnDownload(true);
  };

  return (
    <PdfDownloadButton
      title="発注書ダウンロード"
      file={<PurchaseOrderFile data={pdfData} />}
      pdfData={pdfData}
      isReadyDownload={isReadyDownload}
      onClick={onComfimationDownload}
      loading={loading}
      onClose={() => { setReadyDownload(false); }}
    />
  );
}
