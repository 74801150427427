import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { claimActions } from '../../../../redux/opportunity/claim/claimState';
import ClaimFile from '../../report/ClaimFile';
import { upload } from '../../../../utils/common.helper';
import { REST_API } from '../../../../constants/serverUrls';
import ClaimAffiliationFile from '../../report/ClaimAffiliationFile';
import ClaimEnglishFile from '../../report/ClaimEnglishFile';
import FullScreenMailer from '../../../../components/templates/mailer/FullScreenMailer';

const defaultMailerData = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function ClaimFileDialog(props) {
  const {
    isOpen, onClose, form, sendSuccess,
  } = props;
  const dispatch = useDispatch();
  const dbPdfData = useSelector((state) => state.claimStore.pdfDatas);
  const [mailerData, setMailerData] = useState(defaultMailerData);
  const [pdfData, setPdfData] = useState({ header: {}, details: [] });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (dbPdfData) {
      setPdfData(dbPdfData);
      setLoading(false);
    }
  }, [dbPdfData]);

  useEffect(() => {
    if (isOpen) {
      setMailerData({
        ...defaultMailerData,
        to: form.to,
      });
      setLoading(true);
      dispatch(claimActions.getPdfData({ id: form.id }));
    }
  }, [isOpen]);

  const fileData = () => {
    if (!pdfData.header) return '';
    let returnData = pdfData.header.affiliationId
      ? <ClaimAffiliationFile data={pdfData} /> : <ClaimFile data={pdfData} />;
    if (pdfData.header.englishReport)returnData = <ClaimEnglishFile data={pdfData} />;
    return returnData;
  };

  const onSend = async (mailForm) => {
    const sendMailForm = {
      mailForm,
      id: form.id,
    };
    let returnData = pdfData.header.affiliationId
      ? <ClaimAffiliationFile data={pdfData} /> : <ClaimFile data={pdfData} />;
    if (pdfData.header.englishReport)returnData = <ClaimEnglishFile data={pdfData} />;
    setLoading(true);
    const result = await upload(
      returnData,
      sendMailForm,
      REST_API.OPPORTUNITY.CLAIM.CLAIM_SEND_FILES,
      pdfData.header.fileName,
    );
    setLoading(false);
    if (result == null) return;
    sendSuccess();
  };

  return (
    <FullScreenMailer
      isOpen={isOpen}
      title="請求書送付"
      onClose={onClose}
      onClickAction={onSend}
      data={mailerData}
      objectData={form || {}}
      loading={loading}
      leftArea={(
        <PDFViewer style={{ width: '100%', height: '85vh' }} showToolbar={false}>
          {fileData()}
        </PDFViewer>
      )}
    />
  );
}
