import { createSlice } from '@reduxjs/toolkit';

export const planAttachedSlice = createSlice({
  name: 'planAttached',
  initialState: {
    datas: {},
    isDeleteSuccess: false,
  },
  reducers: {
    getOptions: () => {},
    getOptionsSuccess: (state, action) => {
      state.options = action.payload;
    },
    getOption: () => {},
    getOptionSuccess: (state, action) => {
      state.option = action.payload;
    },
    deleteOption: (state) => {
      state.isOptionDeleteSuccess = false;
    },
    deleteOptionSuccess: (state) => {
      state.isOptionDeleteSuccess = true;
    },
    saveOption: (state) => {
      state.isSaveOptionSuccess = false;
    },
    saveOptionSuccess: (state, action) => {
      state.isSaveOptionSuccess = true;
      state.saveOptionId = action.payload;
    },
    resetFlg: (state) => {
      state.isOptionDeleteSuccess = false;
      state.isSaveOptionSuccess = false;
    },
  },
});

export const planAttachedActions = planAttachedSlice.actions;

export default planAttachedSlice.reducer;
