import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../../../components/templates/FullScreenDialog';
import DataTable from './DataTable';
import { FormTitle } from '../../../../components/atoms/Base';
import { blogActions } from '../../../../redux/pageSetting/blog/blogState';

export default function SelectBlogDialog(props) {
  const { isOpen, onClose, onSelect } = props;
  const [datas, setDatas] = useState({});
  const [condition, setCondition] = useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
    category: '',
    subCategory: '',
    title: '',
  });
  const dispatch = useDispatch();
  const dbDatas = useSelector((state) => state.blogStore.datas);

  useEffect(() => {
    if (isOpen) {
      const tmpCondition = {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
        category: '',
        subCategory: '',
        title: '',
      };
      setCondition(tmpCondition);
      dispatch(blogActions.getDatas(tmpCondition));
    }
  }, [isOpen]);
  useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onChangePage = (_, newPage) => {
    const tmpCondition = {
      ...condition,
      page: newPage,
    };
    setCondition(tmpCondition);
    dispatch(blogActions.getDatas(tmpCondition));
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tmpCondition = {
      ...condition,
      page: 0,
      records: rowsPerPage,
    };
    setCondition(tmpCondition);
    dispatch(blogActions.getDatas(tmpCondition));
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tmpCondition = {
      ...condition,
      page: 0,
      orderBy,
      order,
    };
    setCondition(tmpCondition);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    const tmpCondition = {
      ...condition,
      [name]: value,
      page: 0,
    };
    setCondition(tmpCondition);
  };

  const onSearch = () => {
    dispatch(blogActions.getDatas(condition));
  };

  return (
    <FullScreenDialog
      isOpen={isOpen}
      title="関連幹事のコツ選択"
      onClose={onClose}
    >
      <Box>
        <Box mb={2} display={{ xs: 'block', md: 'flex' }}>
          <Box>
            <FormTitle title="カテゴリー" />
            <TextField autoComplete="off" name="category" value={condition.category} onChange={onChange} />
          </Box>
          <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }}>
            <FormTitle title="サブカテゴリー" />
            <TextField autoComplete="off" name="subCategory" value={condition.subCategory} onChange={onChange} />
          </Box>
          <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }} width="50%">
            <FormTitle title="タイトル" />
            <TextField autoComplete="off" fullWidth name="title" value={condition.title} onChange={onChange} />
          </Box>
          <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }} display="flex" alignItems="flex-end"><Button variant="contained" size="small" onClick={onSearch}>検索</Button></Box>
        </Box>
        <DataTable
          rows={datas.content || []}
          page={condition.page}
          rowsPerPage={condition.records}
          totalElements={datas.totalElements}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={condition.order}
          orderBy={condition.orderBy}
          onSelect={onSelect}
        />
      </Box>
    </FullScreenDialog>
  );
}
