import React, { useState, useEffect } from 'react';
import {
  FormControlLabel, Radio, LinearProgress,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import HttpConnection from '../../utils/http-connection';
import { REST_URL } from '../../constants/serverUrls';
import { FormRadioGroup } from './Base';

export default function RadioButtons(props) {
  const {
    error,
    value,
    onChange,
    name,
    target,
    parameter,
    defaultValue,
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, {
      ...parameter, page: 0, records: 50, id: value, first: true,
    });
    setOptions(tmpData.content);
    setLoading(false);
  };

  useEffect(() => {
    if (name) {
      fetchData();
    }
  }, [name]);

  useEffect(() => {
    fetchData();
  }, [parameter]);

  let targetValue = options ? options.filter((row) => row.id === value) : '';

  targetValue = targetValue.length > 0 ? targetValue[0].id : null;

  return (
    <FormRadioGroup
      row
      value={targetValue}
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
    >
      {loading && (<LinearProgress color="secondary" />)}
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={(
            <Radio
              sx={{
                color: error && pink.A400,
                '&.Mui-checked': {
                  color: error && pink.A700,
                },
              }}
            />
          )}
          label={option.name}
        />
      ))}
    </FormRadioGroup>
  );
}
