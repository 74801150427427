import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'name':
      case 'title':
      case 'displayOrder':
        return CommonValidation.requiredValue(value);
      case 'specialFeatureStatusCode':
      case 'planId':
      case 'shopId':
        return CommonValidation.requiredSelect(value);
      case 'publishStartDt':
      case 'publishEndDt':
        return CommonValidation.nonRequiredDate(value);
      default:
        return '';
    }
  };
}

export default Validation;
