import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from '../../../../redux/opportunity/claim/claimState';
import ReceiptFile from '../../report/ReceiptFile';
import { PdfDownloadButton } from '../../../../components/atoms/Base';

export default function ReceiptDownloadButton(props) {
  const {
    id,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isReadyDownload, setReadyDownload] = React.useState(false);
  const formData = useSelector((state) => state.claimStore.receiptData);
  const isGetReceipt = useSelector((state) => state.claimStore.isGetReceipt);
  const [pdfData, setPdfData] = React.useState({ header: {} });

  useEffect(() => {
    if (isGetReceipt) {
      setPdfData(formData);
      setLoading(false);
      setReadyDownload(true);
      dispatch(claimActions.resetFlgs());
    }
  }, [isGetReceipt]);

  const onReceiptDownload = () => {
    dispatch(claimActions.getReceiptData({ id }));
    setPdfData({ header: {} });
    setLoading(true);
    setReadyDownload(false);
  };

  return (
    <PdfDownloadButton
      title="領収書ダウンロード"
      file={<ReceiptFile data={pdfData} />}
      pdfData={pdfData}
      isReadyDownload={isReadyDownload}
      onClick={onReceiptDownload}
      loading={loading}
      onClose={() => { setReadyDownload(false); }}
    />
  );
}
