import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField,
} from '@mui/material';
import { claimActions } from '../../../redux/opportunity/claim/claimState';
import {
  FormTitle, SuccessSnackbar, DatePicker,
} from '../../../components/atoms/Base';
import DataTableWithCheckbox from '../../../components/templates/dataTableWithCheckbox/DataTableWithCheckbox';
import detailUrl from '../../../constants/frontUrls';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import DataMultiSelectList from '../../../components/atoms/dataList/DataMultiSelectList';

const DEFAULT_SEARCH_CONDITION = {
  customerName: '',
  companyName: '',
  customerEmail: '',
  customerPhone: '',
  opportunityNumber: '',
  heldDate: '',
  statuses: [],
  paymentDueDate: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};
const CSV_HEADER = [
  { key: 'id', label: 'ID' },
  { key: 'opportunityId', label: '案件ID' },
  { key: 'opportunityNumber', label: '案件番号' },
  { key: 'claimNumber', label: '請求番号' },
  { key: 'exemptionEightPercentTotalPrice', label: '免税8%小計(税込)' },
  { key: 'exemptionEightPercentTotalTaxPrice', label: '免税8%消費税' },
  { key: 'exemptionEightPercentDiscountPrice', label: '免税8%割引額' },
  { key: 'exemptionTenPercentTotalPrice', label: '免税10%小計(税込)' },
  { key: 'exemptionTenPercentTotalTaxPrice', label: '免税10%消費税' },
  { key: 'exemptionTenPercentDiscountPrice', label: '免税10%割引額' },
  { key: 'taxationEightPercentTotalPrice', label: '課税8%小計(税込)' },
  { key: 'taxationEightPercentTotalTaxPrice', label: '課税8%消費税' },
  { key: 'taxationEightPercentDiscountPrice', label: '課税8%割引額' },
  { key: 'taxationTenPercentTotalPrice', label: '課税10%小計(税込)' },
  { key: 'taxationTenPercentTotalTaxPrice', label: '課税10%消費税' },
  { key: 'taxationTenPercentDiscountPrice', label: '課税10%割引額' },
  { key: 'commissionPrice', label: '手数料(税込)' },
  { key: 'pointUsagePrice', label: 'ポイント利用額' },
  { key: 'totalPrice', label: '合計金額(税込)' },
  { key: 'customerBillPrice', label: '顧客請求額(税込)' },
  { key: 'discountTargetPrice', label: '割引対象額(税抜)' },
  { key: 'paymentDueDate', label: '支払期限' },
  { key: 'remarks', label: '請求書備考' },
  { key: 'receiptRemarks', label: '領収書備考' },
  { key: 'paymentDate', label: '入金日' },
  { key: 'claimStatusCode', label: 'ステータスコード' },
  { key: 'claimStatusName', label: 'ステータス名' },
  { key: 'bankId', label: '振込先口座ID' },
  { key: 'bankAccountInfo', label: '口座情報' },
  { key: 'paymentLink', label: '支払リンク' },
  { key: 'createdAt', label: '作成日時' },
  { key: 'createdByName', label: '作成者' },
  { key: 'updatedAt', label: '更新日時' },
  { key: 'updatedByName', label: '更新者' },
];

const CSV_DETAIL_HEADER = [
  { key: 'detailId', label: '明細ID' },
  { key: 'shopId', label: '店舗ID' },
  { key: 'shopName', label: '店舗名' },
  { key: 'vendorId', label: '業者ID' },
  { key: 'vendorName', label: '業者名' },
  { key: 'productInputTypeCode', label: '商品入力タイプコード' },
  { key: 'productInputTypeName', label: '商品入力タイプ名' },
  { key: 'planId', label: 'プランID' },
  { key: 'planName', label: 'プラン名' },
  { key: 'freeProductName', label: '自由入力商品' },
  { key: 'shopPlanOptionId', label: 'オプションID' },
  { key: 'shopPlanOptionName', label: 'オプション名' },
  { key: 'unitPrice', label: '単価(税込)' },
  { key: 'quantity', label: '個数' },
  { key: 'taxRate', label: '税率' },
  { key: 'burdenPrice', label: 'Cqree負担額(税込)' },
  { key: 'detailTotalPrice', label: '明細合計金額(税込)' },
  { key: 'displayOrder', label: '表示順' },
  { key: 'claimed', label: '請求対象' },
  { key: 'reportHidden', label: '帳票非表示' },
  { key: 'detailCreatedAt', label: '明細作成日時' },
  { key: 'detailCreatedByName', label: '明細作成者' },
  { key: 'detailUpdatedAt', label: '明細更新日時' },
  { key: 'detailUpdatedByName', label: '明細更新者' },
];

class ClaimList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'claimNumber', label: '請求番号', sortId: 'claim_number' },
      { id: 'claimStatusName', label: 'ステータス', sortId: 'claim_status_code' },
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
      {
        id: 'totalPrice', label: '税込合計金額', sortId: 'total_price', numeric: true,
      },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.claimInfo.searchCondition) {
      searchCondition = props.claimInfo.searchCondition;
    }

    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isEditDisabled: isScreenEditDisabled(props.loginUser.userInfo),
      isDeleteDisabled: isScreenDeleteDisabled(props.loginUser.userInfo),
      download: {
        headerItems: CSV_HEADER,
        detailCsvItem: CSV_DETAIL_HEADER,
        fileName: '請求',
        done: false,
        datas: [],
      },
    };

    this.props.dispatch(claimActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claimInfo.datas !== this.props.claimInfo.datas) {
      if (this.props.claimInfo.datas) {
        this.setDatas(this.props.claimInfo.datas);
      }
    }
    if (prevProps.claimInfo.csvData !== this.props.claimInfo.csvData) {
      if (this.props.claimInfo.csvData) {
        this.setDownload(this.props.claimInfo.csvData);
      }
    }
    if (prevProps.claimInfo.isDeleteSuccess
      !== this.props.claimInfo.isDeleteSuccess) {
      if (this.props.claimInfo.isDeleteSuccess) {
        this.setSuccess('削除しました。');
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDownload = (datas) => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: true,
        datas,
      },
    });
  };

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(claimActions.setSearchCondition(searchCondition));
    this.props.dispatch(claimActions.getDatas(searchCondition));
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDelete = (ids) => {
    this.props.dispatch(claimActions.deleteData({ ids }));
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDownload = (showDetail) => {
    const { searchCondition } = this.state;
    this.props.dispatch(claimActions.getCsvDatas({ ...searchCondition, showDetail }));
  };

  downLoadClear = () => {
    const { download } = this.state;
    this.setState({
      download: {
        ...download,
        done: false,
        datas: [],
      },
    });
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isEditDisabled,
      isDeleteDisabled,
      download,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          downloadInfo={download}
          onDownload={this.onDownload}
          downLoadClear={this.downLoadClear}
          reSearch={this.reSearch}
          condition={searchCondition}
          targetScreen="claim"
          simpleAreaOneText="ステータス"
          simpleAreaOne={(
            <DataMultiSelectList
              name="statuses"
              target="codes"
              parameter={{ codeCategory: 'claim_status' }}
              values={searchCondition.statuses}
              onChange={this.onChange}
              fullWidth
            />
          )}
          simpleAreaTwoText="案件番号"
          simpleAreaTwo={(
            <TextField size="small" value={searchCondition.opportunityNumber} fullWidth name="opportunityNumber" onChange={this.onChange} />
          )}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="お客様名" />
              <TextField size="small" value={searchCondition.customerName} fullWidth name="customerName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="会社名" />
              <TextField size="small" value={searchCondition.companyName} fullWidth name="companyName" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="お客様メールアドレス" />
              <TextField size="small" value={searchCondition.customerEmail} fullWidth name="customerEmail" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="お客様電話番号" />
              <TextField size="small" value={searchCondition.customerPhone} fullWidth name="customerPhone" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="開催日" />
              <DatePicker value={searchCondition.heldDate} fullWidth name="heldDate" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="支払期限" />
              <DatePicker value={searchCondition.paymentDueDate} fullWidth name="paymentDueDate" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTableWithCheckbox
          url={detailUrl.CLAIM_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="請求"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          isNotAdd={isEditDisabled}
          isNotDelete={isDeleteDisabled}
          urlTargets={[{ url: detailUrl.OPPORTUNITY_DETAIL, label: 'opportunityNumber', id: 'opportunityId' }]}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  claimInfo: state.claimStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ClaimList);
